import React, { useState, useEffect, useCallback } from 'react';
import { Grid, withStyles, Typography, Button, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import AutoComplete from '../AutoComplete/AutoComplete.jsx';
import ScheduleStyles from './ScheduleStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import { appConstants } from '../../constants/appConstants';
import { removeDataSourceSchedule, removeSchedule } from '../../actions/scheduleActions';
import CheckboxComponent from '../ChecboxComponent/CheckboxComponent.jsx';
import AlertDialog from '../AlertDialog/AlertDialog.jsx';
import AutoCompleteInput from '../AutoComplete/AutoCompleteInput.jsx';
import { sortTable } from '../../helpers/appHelpers.js';

const EventSchedule = (props) => {
    const { classes, scheduleProps, onClose, sourceType, datasetSchedule, setDatasetSchedule, isExistingSchedule, setExistingSchedule, defaultParams, submission, setSubmission, onSubmitSchedule, loadDatasets } = props;

    const moduleConfig = useSelector(({ setting }) => setting.config);
    const zones = [{ name: moment.tz.guess() }, ...appConstants.TimeZones];

    const [showRemoveSchedule, setShowRemoveSchedule] = useState(false);

    const datasourceList = useSelector(({ metric }) => metric?.datasources?.datasources);
    const datasetList = useSelector(({ metric }) => metric?.datasets?.datasets);

    const [timeZones] = useState([...zones]);
    const dispatch = useDispatch();
    const [updatetime, setupdatetime] = useState(true);
    const [jobOptions, setJobOptions] = useState([]);

    useEffect(() => {
        let jobsList = [];
        if (datasetSchedule?.trigger_datasource) {
            if (datasetSchedule?.trigger_datasource?.scan) {
                jobsList = ['Profile', 'Scan'];
            } else {
                jobsList = ['Pull', 'Push', 'Sense', 'Profile', 'Curate'];
            }
            if (datasetSchedule?.trigger_dataset?.is_drift) {
                jobsList.push('Learning');
            }
            if (datasetSchedule?.trigger_dataset?.has_export_metrics) {
                jobsList.push('ExportMetrics');
            }
        }
        setJobOptions(jobsList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datasetSchedule.trigger_datasource, datasetSchedule.trigger_dataset]);

    const changeSchedule = (value, property) => {
        const schedule = datasetSchedule;
        if (isExistingSchedule) {
            if (property === "date" || property === "time") {
                setupdatetime(false);
            }
            if (updatetime && (property === 'trigger_datasource' || property === 'trigger_dataset' || property === 'job_type')) {
                schedule.date = new Date();
                schedule.time = new Date();
            }
        }
        if (property === "start_profile" && value === false) {
            schedule['start_export_metrics'] = false;
        }

        if (property === "start_export_metrics" && value === true) {
            if (schedule['start_profile'] === false) {
                schedule['start_export_metrics'] = false;
                return false;
            }
        }
        if (property === 'time_zone') {
            schedule.date = moment(schedule.date).tz(value);
            schedule.time = moment(schedule.time).tz(value);
        }
        if (property === 'trigger_datasource') {
            if (value) {
                if (value?.trigger_datasource?.scan) {
                    setJobOptions(['Profile', 'Scan']);
                } else {
                    setJobOptions(['Pull', 'Push', 'Sense', 'Profile', 'Curate']);
                }
            } else {
                setJobOptions([]);
            }
            schedule.trigger_dataset = '';
            schedule.job_type = '';
        }
        schedule[property] = value;
        setDatasetSchedule({ ...schedule });
    };

    const onSchedule = useCallback(() => {
        setSubmission(false);
        const schedule = {
            "schedule_method": datasetSchedule.schedule_method,
            "start_profile": datasetSchedule.start_profile,
            "start_curate": datasetSchedule.start_curate,
            "start_export_metrics": datasetSchedule.start_export_metrics,
            "time_zone": datasetSchedule.time_zone,
            "job_type": datasetSchedule.job_type,
            "trigger_datasource": datasetSchedule?.trigger_datasource?.id,
            "trigger_dataset": datasetSchedule?.trigger_dataset?.id,
            "start_time": `${moment(datasetSchedule.date).format('YYYY-MM-DD')} ${moment(datasetSchedule.time).format('HH:mm')}`,
            "dataset_id": scheduleProps.datasetId,
            "source_id": scheduleProps.datasourceId,
            'user_timezone': moment.tz.guess()
        };

        if (moduleConfig && !moduleConfig.curate) {
            schedule['start_curate'] = false;
        }
        if (moduleConfig && !moduleConfig.export_metrics) {
            schedule['start_export_metrics'] = false;
        }
        if (moduleConfig && !moduleConfig.profile) {
            schedule['start_profile'] = false;
        }
        onSubmitSchedule(schedule);
    }, [datasetSchedule, scheduleProps.datasetId, scheduleProps.datasourceId, scheduleProps.isDataset, scheduleProps.selectedDataset, moduleConfig, dispatch, onClose]);

    const getMinDate = (date) => {
        date = new Date(date);
        let minDate = date;
        const currentDate = new Date();
        if (currentDate < date) {
            minDate = currentDate;
        }
        return minDate;
    };

    const onRemoveSchedule = () => {
        if (scheduleProps.isDataset) {
            const selectedDataset = {
                'dataset_list': scheduleProps.selectedDataset,
                'datasource_id': scheduleProps.datasourceId
            };
            dispatch(removeDataSourceSchedule(selectedDataset)).then(() => {
                setDatasetSchedule({ ...defaultParams });
                setExistingSchedule(false);
                setShowRemoveSchedule(false);
                onClose();
            });
        } else {
            dispatch(removeSchedule(scheduleProps.datasetId)).then(() => {
                setDatasetSchedule({ ...defaultParams });
                setExistingSchedule(false);
                setShowRemoveSchedule(false);
                onClose();
            });
        }
    };

    const showRemoveAlert = () => {
        setShowRemoveSchedule(true);
    };

    const closeScheduleAlert = () => {
        setShowRemoveSchedule(false);
    };

    const onCancel = () => {
        setDatasetSchedule(defaultParams);
        onClose();
    };

    return (
        <ValidatorForm autoComplete="off" className={classNames(classes.scheduleContainer)} onSubmit={() => onSchedule()}>
            <Grid>
                <Typography component="h5" variant="h5">
                    {'Schedule Profile'}
                </Typography>
                <Grid container direction="row" justify="space-between" className={classes.marginTop15}>
                    <DatePicker autoOk
                        variant="inline"
                        label="Start Date"
                        format="MM/DD/YYYY"
                        minDate={datasetSchedule.date ? getMinDate(datasetSchedule.date) : new Date()}
                        value={datasetSchedule.date ? datasetSchedule.date : Date.now()}
                        onChange={(date) => changeSchedule(date, 'date')}
                    />
                    <TimePicker
                        ampm={false}
                        label="Start Time"
                        format="HH:mm"
                        value={datasetSchedule.time ? datasetSchedule.time : Date.now()}
                        onChange={(time) => changeSchedule(time, 'time')}
                    />
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AutoCompleteInput
                            name="trigger_datasource"
                            options={sortTable(datasourceList ? datasourceList : [], "asc", "name")}
                            value={datasetSchedule && datasetSchedule?.trigger_datasource ? datasetSchedule?.trigger_datasource : {}}
                            getOptionLabel={(option) => (option ? option.name : '')}
                            renderInput={
                                (params) =>
                                    <TextField {...params}
                                        label="Datasource"
                                        fullWidth
                                    />
                            }
                            onChange={(_, newValue) => changeSchedule(newValue, 'trigger_datasource')}
                            // onInputChange={(_, value) => loadDataSources(value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AutoCompleteInput
                            name="trigger_dataset"
                            options={sortTable(datasetSchedule?.trigger_datasource && datasetList ? datasetList.filter((elem) => elem.id !== Number(scheduleProps?.datasetId)) : [], "asc", "name")}
                            value={datasetSchedule && datasetSchedule.trigger_dataset ? datasetSchedule.trigger_dataset : {}}
                            getOptionLabel={(option) => (option ? option.name : '')}
                            renderInput={
                                (params) =>
                                    <TextField {...params}
                                        label="Dataset"
                                        fullWidth
                                    />
                            }
                            onChange={(_, newValue) => changeSchedule(newValue, 'trigger_dataset')}
                            // onInputChange={(_, value) => loadDatasets(datasetSchedule, value)}
                            onOpen={() => loadDatasets(datasetSchedule, '')}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AutoCompleteInput
                            name="job_type"
                            options={sortTable(jobOptions, "asc", "name")}
                            // options={['SCAN', "PULL", 'SENSE', 'PROFILE', "CURATE", "LEARNING", "PUSH", "EXPORT METRICS"]}
                            value={datasetSchedule && datasetSchedule.job_type ? datasetSchedule.job_type : ''}
                            getOptionLabel={(option) => (option ? option : '')}
                            renderInput={
                                (params) =>
                                    <TextField {...params}
                                        label="Job Type"
                                        fullWidth
                                    />
                            }
                            onChange={(_, newValue) => changeSchedule(newValue, 'job_type')}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container className={classes.marginTop15}>
                    <Grid item xs={12}>
                        <AutoComplete
                            label="Timezone"
                            placeholder="Select Timezone"
                            onChange={(event) => changeSchedule(event.target.value, "time_zone")}
                            onBlur={() => { }}
                            value={datasetSchedule.time_zone ? datasetSchedule.time_zone : ''}
                            validators={['required']}
                            errorMessages={['Timezone is required']}
                            options={timeZones}
                            selectedValuePath="name"
                            displayMemberPath="name"
                            removeClass
                            openSuggestionsOnClick
                            name="timzone"
                            id="timzone"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="space-between" className={classes.marginTop20}>
                    {
                        sourceType !== "Tableau" && moduleConfig && moduleConfig.profile &&
                        <CheckboxComponent checked={datasetSchedule.start_profile} onChange={(event) => changeSchedule(event.target.checked, "start_profile")} checkboxLabel="Profile" />
                    }
                    {
                        sourceType !== "Tableau" && moduleConfig && moduleConfig.profile && moduleConfig.export_metrics &&
                        <CheckboxComponent checked={datasetSchedule.start_export_metrics && datasetSchedule.start_profile} disabled={!datasetSchedule.start_profile} onChange={(event) => changeSchedule(event.target.checked, "start_export_metrics")} checkboxLabel="Export Metrics" />
                    }
                    {
                        sourceType !== "Tableau" && moduleConfig && moduleConfig.curate &&
                        <CheckboxComponent checked={datasetSchedule.start_curate} onChange={(event) => changeSchedule(event.target.checked, "start_curate")} checkboxLabel="Curate" />
                    }

                </Grid>
                <Grid container justify="space-between" alignItems="center" className={classes.marginTop20}>
                    <Grid item xs={6}>
                        <Button onClick={() => showRemoveAlert()} className={classes.tabBtn} variant="contained" color="primary" disabled={!isExistingSchedule} >
                            Remove Schedule
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container justify="flex-end" alignItems="center">
                            <Button className={classes.tabBtn} type="submit" variant="contained" color="primary" disabled={!submission} >
                                Submit
                            </Button>
                            <Button onClick={() => onCancel()} style={{ marginLeft: '10px' }} variant="contained" className={classNames(classes.cancelBtn, classes.tabBtn)}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                    {
                        showRemoveSchedule && <AlertDialog
                            title="Delete Schedule"
                            message={`Are you sure you want to delete the schedule for the dataset?`}
                            okButtonText="OK"
                            cancelButtonText="Cancel"
                            show={showRemoveSchedule}
                            onClickOK={onRemoveSchedule}
                            onClickCancel={closeScheduleAlert}
                        />
                    }
                </Grid>
            </Grid>
        </ValidatorForm>
    );
};

EventSchedule.propTypes = {
    classes: PropTypes.object,
    scheduleProps: PropTypes.object,
    onClose: PropTypes.func,
    sourceType: PropTypes.string,
    datasetSchedule: PropTypes.object,
    setDatasetSchedule: PropTypes.func,
    isExistingSchedule: PropTypes.bool,
    setExistingSchedule: PropTypes.func,
    defaultParams: PropTypes.object,
    submission: PropTypes.bool,
    setSubmission: PropTypes.func,
    onSubmitSchedule: PropTypes.func,
    loadDataSources: PropTypes.func,
    loadDatasets: PropTypes.func
};

export default withStyles((theme) => ({
    ...ScheduleStyles(theme),
    ...Styles(theme)
}))(EventSchedule);