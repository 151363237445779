import { appConstants } from '../constants/appConstants';
import { request, success, failure } from './baseActions';
import { scheduleActions } from '../constants/actionTypes/scheduleActionTypes';
import * as service from '../services/apiService';
import { alertActions } from '../constants/actionTypes/alertActionTypes';
import { datasetActions } from '../constants/actionTypes/datasetActionTypes';
import { alerts } from '../config/alerts';
import { metricsActions } from '../constants/actionTypes/metricsActionTypes';

export const runProfile = (datasetId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.runProfile}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.RUN_PROFILE_REQUEST));
        return service.postRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.RUN_PROFILE_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, alerts.runProfile));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.RUN_PROFILE_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error));
        });
    };
};

export const getProfileAnalysis = (attributeId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getProfileAnalysis}/${attributeId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_PROFILE_ANALYSIS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            if (response) {
                dispatch(success(scheduleActions.GET_PROFILE_ANALYSIS_SUCCESS, response.data));
                return response.data;
            }
        }, (error) => {
            dispatch(failure(scheduleActions.GET_PROFILE_ANALYSIS_FAILURE, error));
        });
    };
};

export const getDatasetExecutionLogs = (datasetId, filter, offset, limit) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getDatasetExecutionLogs}/${datasetId}/${filter}/${offset}/${limit}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DATASET_EXECUTION_LOGS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_DATASET_EXECUTION_LOGS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DATASET_EXECUTION_LOGS_FAILURE, error));
        });
    };
};

export const getDatasetSchedule = (datasetId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getDatasetSchedule}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DATASET_SCHEDULE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_DATASET_SCHEDULE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DATASET_SCHEDULE_FAILURE, error));
        });
    };
};

export const getDataSourceSchedule = (requestParams) => {
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DATASOURCE_SCHEDULE_REQUEST));
        return service.postRequest(appConstants.END_POINTS.schedule.getDatasourceSchedule, requestParams).then((response) => {
            dispatch(success(scheduleActions.GET_DATASOURCE_SCHEDULE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DATASOURCE_SCHEDULE_FAILURE, error));
        });
    };
};

export const createSchedule = (requestParams) => {
    return (dispatch) => {
        dispatch(request(scheduleActions.CREATE_DATASET_SCHEDULE_REQUEST));
        return service.postRequest(appConstants.END_POINTS.schedule.createSchedule, requestParams).then((response) => {
            dispatch(success(scheduleActions.CREATE_DATASET_SCHEDULE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.CREATE_DATASET_SCHEDULE_FAILURE, error));
        });
    };
};

export const createDatasourceSchedule = (requestParams) => {
    return (dispatch) => {
        dispatch(request(scheduleActions.CREATE_DATASOURCE_SCHEDULE_REQUEST));
        return service.postRequest(appConstants.END_POINTS.schedule.createDatasetSchedule, requestParams).then((response) => {
            dispatch(success(scheduleActions.CREATE_DATASOURCE_SCHEDULE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.CREATE_DATASOURCE_SCHEDULE_FAILURE, error));
        });
    };
};

export const updateSchedule = (requestParams) => {
    return (dispatch) => {
        dispatch(request(scheduleActions.CREATE_DATASET_SCHEDULE_REQUEST));
        return service.putRequest(appConstants.END_POINTS.schedule.updateSchedule, requestParams).then((response) => {
            dispatch(success(scheduleActions.CREATE_DATASET_SCHEDULE_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, alerts.updateSchedule));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.CREATE_DATASET_SCHEDULE_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error));
        });
    };
};

export const getDatasourceExecutionLogs = (datasourceId, filter, offset, limit) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getDatasourceExecutionLogs}/${datasourceId}/${filter}/${offset}/${limit}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DATASOURCE_EXECUTION_LOGS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_DATASOURCE_EXECUTION_LOGS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DATASOURCE_EXECUTION_LOGS_FAILURE, error));
        });
    };
};

export const getCurationAnalysis = (attributeId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getCurateAnalysis}/${attributeId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_CURATE_ANALYSIS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_CURATE_ANALYSIS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_CURATE_ANALYSIS_FAILURE, error));
        });
    };
};

export const getTrending = (attributeId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getTrending}/${attributeId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_TRENDING_CHART_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_TRENDING_CHART_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_TRENDING_CHART_FAILURE, error));
        });
    };
};

export const getDriftTrending = (attributeId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getDriftTrending}/${attributeId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DRIFTTRENDING_CHART_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_DRIFTTRENDING_CHART_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DRIFTTRENDING_CHART_FAILURE, error));
        });
    };
};


export const restartSchedule = (scheduleLogId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.restartSchedule}/${scheduleLogId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.RESTART_SCHEDULE_REQUEST));
        return service.putRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.RESTART_SCHEDULE_SUCCESS, response.data));
            if (response && response.data) {
                const message = alerts.jobStatus.replace("<job_name>", response.data.type);
                dispatch(success(alertActions.ALERT_SUCCESS, message));
            }
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.RESTART_SCHEDULE_FAILURE, error));
        });
    };
};


export const getProfileRules = (attributeId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getProfileRules}/${attributeId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_PROFILE_RULES_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_PROFILE_RULES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_PROFILE_RULES_FAILURE, error));
        });
    };
};

export const getDriftRules = (attributeId, scheduleId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getDriftRules}/${attributeId}/${scheduleId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DRIFT_RULES_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_DRIFT_RULES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DRIFT_RULES_FAILURE, error));
        });
    };
};


export const profileRuleEnable = (requestParams, attributeId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.profileEnable}/${attributeId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.SET_PROFILE_RULE_REQUEST));
        return service.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(scheduleActions.SET_PROFILE_RULE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.SET_PROFILE_RULE_FAILURE, error));
        });
    };
};


export const killJob = (jobId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.killJob}/${jobId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.KILL_JOB_REQUEST));
        return service.postRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.KILL_JOB_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, alerts.killJob));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.KILL_JOB_FAILURE, error));
        });
    };
};


export const getMetricDataQuality = (datasourceId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getSourceDataQuality}/${datasourceId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_METRIC_DATA_QUALITY_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_METRIC_DATA_QUALITY_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_METRIC_DATA_QUALITY_FAILURE, error));
        });
    };
};

export const getDatasourceTimeline = (datasourceId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getSourceTimeline}/${datasourceId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DATASOURCE_TIMELINE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_DATASOURCE_TIMELINE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DATASOURCE_TIMELINE_FAILURE, error));
        });
    };
};

export const getSourceSensitivity = (datasourceId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getSourceSensitivity}/${datasourceId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DATASOURCE_SENSITIVITY_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_DATASOURCE_SENSITIVITY_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DATASOURCE_SENSITIVITY_FAILURE, error));
        });
    };
};

export const learningRule = (attributeId, datasetId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.schedule.learning}/${attributeId}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.DATASET_LEARNING_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(scheduleActions.DATASET_LEARNING_SUCCESS, response.data));
            if (requestParams.type === "Profile") {
                dispatch(success(datasetActions.UPDATE_LEARNING_ATTIBUTE_PROPERTY, requestParams));
            }
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.DATASET_LEARNING_FAILURE, error));
        });
    };
};

export const deleteLearning = (datasetId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.schedule.deleteLearning}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.DELETE_DATASET_LEARNING_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(scheduleActions.DELETE_DATASET_LEARNING_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.DELETE_DATASET_LEARNING_FAILURE, error));
        });
    };
};

export const getDashboardViews = () => {
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DASHBOARD_VIEW_REQUEST));
        return service.getRequest(appConstants.END_POINTS.schedule.getMostView).then((response) => {
            dispatch(success(scheduleActions.GET_DASHBOARD_VIEW_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DASHBOARD_VIEW_FAILURE, error));
        });
    };
};

export const getUserAnalysis = () => {
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DASHBOARD_USER_ANALYSIS_REQUEST));
        return service.getRequest(appConstants.END_POINTS.schedule.getUserAnalysis).then((response) => {
            dispatch(success(scheduleActions.GET_DASHBOARD_USER_ANALYSIS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DASHBOARD_USER_ANALYSIS_FAILURE, error));
        });
    };
};

export const getDqScoreAnalysis = (requestParams) => {
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DASHBOARD_DQSCORE_ANALYSIS_REQUEST));
        return service.postRequest(appConstants.END_POINTS.schedule.getDqScoreAnalysis, requestParams).then((response) => {
            dispatch(success(scheduleActions.GET_DASHBOARD_DQSCORE_ANALYSIS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DASHBOARD_DQSCORE_ANALYSIS_FAILURE, error));
        });
    };
};

export const getRelationShipAnalysis = (requestParams) => {
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DASHBOARD_RELATION_SHIP_ANALYSIS_REQUEST));
        return service.postRequest(appConstants.END_POINTS.schedule.getRelationShipAnalysis, requestParams).then((response) => {
            dispatch(success(scheduleActions.GET_DASHBOARD_RELATION_SHIP_ANALYSIS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DASHBOARD_RELATION_SHIP_ANALYSIS_FAILURE, error));
        });
    };
};

export const getOrganizationTimeline = () => {
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DASHBOARD_TIMELINE_ANALYSIS_REQUEST));
        return service.getRequest(appConstants.END_POINTS.schedule.getOrganizationTimeline).then((response) => {
            dispatch(success(scheduleActions.GET_DASHBOARD_TIMELINE_ANALYSIS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DASHBOARD_TIMELINE_ANALYSIS_FAILURE, error));
        });
    };
};

export const getOrganizationDqscoreDistribution = () => {
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DASHBOARD_DISTRIBUTION_ANALYSIS_REQUEST));
        return service.getRequest(appConstants.END_POINTS.schedule.getOrganizationDqscoreDistribution).then((response) => {
            dispatch(success(scheduleActions.GET_DASHBOARD_DISTRIBUTION_ANALYSIS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DASHBOARD_DISTRIBUTION_ANALYSIS_FAILURE, error));
        });
    };
};

export const getSensitivityAnalysis = () => {
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DASHBOARD_SENSITIVITY_ANALYSIS_REQUEST));
        return service.getRequest(appConstants.END_POINTS.schedule.getSensitivityAnalysis).then((response) => {
            dispatch(success(scheduleActions.GET_DASHBOARD_SENSITIVITY_ANALYSIS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DASHBOARD_SENSITIVITY_ANALYSIS_FAILURE, error));
        });
    };
};


export const getConversationAnalysis = () => {
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DASHBOARD_CONVERSATION_ANALYSIS_REQUEST));
        return service.getRequest(appConstants.END_POINTS.schedule.getConversationAnalysis).then((response) => {
            dispatch(success(scheduleActions.GET_DASHBOARD_CONVERSATION_ANALYSIS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DASHBOARD_CONVERSATION_ANALYSIS_FAILURE, error));
        });
    };
};

export const getOrganizationMetrics = () => {
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DASHBOARD_METRICS_ANALYSIS_REQUEST));
        return service.getRequest(appConstants.END_POINTS.schedule.getOrganizationMetrics).then((response) => {
            dispatch(success(scheduleActions.GET_DASHBOARD_METRICS_ANALYSIS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DASHBOARD_METRICS_ANALYSIS_FAILURE, error));
        });
    };
};

export const getOrganizationMetricAnalysis = () => {
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DASHBOARD_METRICS_TIMELINE_ANALYSIS_REQUEST));
        return service.getRequest(appConstants.END_POINTS.schedule.getOrganizationMetricAnalysis).then((response) => {
            dispatch(success(scheduleActions.GET_DASHBOARD_METRICS_TIMELINE_ANALYSIS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DASHBOARD_METRICS_TIMELINE_ANALYSIS_FAILURE, error));
        });
    };
};

export const getOrganizationGovernanceAnalysis = () => {
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DASHBOARD_METRICS_GOVERNANCE_ANALYSIS_REQUEST));
        return service.getRequest(appConstants.END_POINTS.schedule.getOrganizationGovernanceAnalysis).then((response) => {
            dispatch(success(scheduleActions.GET_DASHBOARD_METRICS_GOVERNANCE_ANALYSIS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DASHBOARD_METRICS_GOVERNANCE_ANALYSIS_FAILURE, error));
        });
    };
};

export const getNotificationAnalysis = (requestParams) => {
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_NOTIFICATION_METRICS_TIMELINE_ANALYSIS_REQUEST));
        return service.postRequest(appConstants.END_POINTS.schedule.getNotificationAnalysis, requestParams).then((response) => {
            dispatch(success(scheduleActions.GET_NOTIFICATION_METRICS_TIMELINE_ANALYSIS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_NOTIFICATION_METRICS_TIMELINE_ANALYSIS_FAILURE, error));
        });
    };
};

export const getConversationMetricAnalysis = () => {
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_CONVERSATION_METRICS_TIMELINE_ANALYSIS_REQUEST));
        return service.getRequest(appConstants.END_POINTS.schedule.getConversationMetricAnalysis).then((response) => {
            dispatch(success(scheduleActions.GET_CONVERSATION_METRICS_TIMELINE_ANALYSIS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_CONVERSATION_METRICS_TIMELINE_ANALYSIS_FAILURE, error));
        });
    };
};

export const getDatasourceMetrics = (datasourceId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getDatasourceMetrics}/${datasourceId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DATASOURCE_METRICS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_DATASOURCE_METRICS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DATASOURCE_METRICS_FAILURE, error));
        });
    };
};

export const getDomainScore = () => {
    const endpoint = `${appConstants.END_POINTS.schedule.getDomainScore}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DOMAIN_SCORE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_DOMAIN_SCORE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DOMAIN_SCORE_FAILURE, error));
        });
    };
};

export const getDomainAsset = () => {
    const endpoint = `${appConstants.END_POINTS.schedule.getDomainAsset}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DOMAIN_ASSET_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_DOMAIN_ASSET_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DOMAIN_ASSET_FAILURE, error));
        });
    };
};

export const getOverAllScore = () => {
    const endpoint = `${appConstants.END_POINTS.schedule.getOverAllScore}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_OVER_ALL_SCORE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_OVER_ALL_SCORE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_OVER_ALL_SCORE_FAILURE, error));
        });
    };
};

export const previewInvalidData = (domainId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.schedule.previewInvalidData}/${domainId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.PREVIEW_INVALID_DATA_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(scheduleActions.PREVIEW_INVALID_DATA_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.PREVIEW_INVALID_DATA_FAILURE, error));
        });
    };
};

export const exportDataInvalidRecords = (domainId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.schedule.exportDataInvalidRecords}/${domainId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.EXPORT_DATA_INVALID_RECORDS_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(scheduleActions.EXPORT_DATA_INVALID_RECORDS_SUCCESS, response));
            return response;
        }, (error) => {
            dispatch(failure(scheduleActions.EXPORT_DATA_INVALID_RECORDS_FAILURE, error));
        });
    };
};


export const getDriftAttribute = (datasetId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getDriftAttribute}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DRIFT_ATTRIBUTE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_DRIFT_ATTRIBUTE_SUCCESS, response));
            return response;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DRIFT_ATTRIBUTE_FAILURE, error));
        });
    };
};


export const getDriftCountStatistical = (attributeId, chartType, scheduleId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.driftCountStatistical}/${attributeId}/${chartType}/${scheduleId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DRIFT_COUNT_STATISTICS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_DRIFT_COUNT_STATISTICS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DRIFT_COUNT_STATISTICS_FAILURE, error));
        });
    };
};

export const getdriftDescriptiveStatistical = (attributeId, chartType, scheduleId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.driftDescriptiveStatistical}/${attributeId}/${chartType}/${scheduleId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DRIFT_DESCRIPTIVE_STATISTICS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_DRIFT_DESCRIPTIVE_STATISTICS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DRIFT_DESCRIPTIVE_STATISTICS_FAILURE, error));
        });
    };
};
export const getDriftLengthtSatistical = (attributeId, scheduleId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.driftLengthtSatistical}/${attributeId}/${scheduleId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DRIFT_LENGTH_STATISTICS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_DRIFT_LENGTH_STATISTICS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DRIFT_LENGTH_STATISTICS_FAILURE, error));
        });
    };
};

export const getdriftPatternStatistical = (attributeId, scheduleId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.driftPatternStatistical}/${attributeId}/${scheduleId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DRIFT_PATTERN_STATISTICS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_DRIFT_PATTERN_STATISTICS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DRIFT_PATTERN_STATISTICS_FAILURE, error));
        });
    };
};

export const getDriftExtremeSatistical = (attributeId, scheduleId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.driftExtremeSatistical}/${attributeId}/${scheduleId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DRIFT_EXTREME_STATISTICS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_DRIFT_EXTREME_STATISTICS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DRIFT_EXTREME_STATISTICS_FAILURE, error));
        });
    };
};

export const driftAcceptRules = (id) => {
    const endpoint = `${appConstants.END_POINTS.schedule.driftRuleAccept}/${id}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.UPDATE_DRIFT_RULES_SCORE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.UPDATE_DRIFT_RULES_SCORE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.UPDATE_DRIFT_RULES_SCORE_FAILURE, error));
        });
    };
};

export const driftRuleDashboard = (attributeId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.driftRuleDashboard}/${attributeId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DRIFT_DASHBOARD_STATISTICS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_DRIFT_DASHBOARD_STATISTICS_SUCCESS, response));
            return response;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DRIFT_DASHBOARD_STATISTICS_FAILURE, error));
        });
    };
};

export const driftAttributeStepChart = (attributeId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.driftAttributeStepChart}/${attributeId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DRIFT_ATTRIBUTE_STEPS_STATISTICS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_DRIFT_ATTRIBUTE_STEPS_STATISTICS_SUCCESS, response));
            return response;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DRIFT_ATTRIBUTE_STEPS_STATISTICS_FAILURE, error));
        });
    };
};

export const driftDatasetDashboard = (datasetId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.driftDatasetDashboard}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DRIFT_DATASET_DASHBOARD_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_DRIFT_DATASET_DASHBOARD_SUCCESS, response));
            return response;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DRIFT_DATASET_DASHBOARD_FAILURE, error));
        });
    };
};

export const driftDatasetStepChart = (datasetId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.driftDatasetStepChart}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DRIFT_DATASET_STEP_STATISTICS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_DRIFT_DATASET_STEP_STATISTICS_SUCCESS, response));
            return response;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DRIFT_DATASET_STEP_STATISTICS_FAILURE, error));
        });
    };
};

export const updateDriftProperties = (driftProperties) => {
    const endpoint = `${appConstants.END_POINTS.schedule.updateattributealerts}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.UPDATE_DRIFT_PROPERTIES_REQUEST));
        return service.postRequest(endpoint, driftProperties).then((response) => {
            dispatch(success(scheduleActions.UPDATE_DRIFT_PROPERTIES_SUCCESS, response));
            return response;
        }, (error) => {
            dispatch(failure(scheduleActions.UPDATE_DRIFT_PROPERTIES_FAILURE, error));
        });
    };
};

export const updateDriftDatasetProperties = (driftProperties) => {
    const endpoint = `${appConstants.END_POINTS.schedule.updatedatasetalerts}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.UPDATE_DRIFT_DATASETPROPERTIES_REQUEST));
        return service.postRequest(endpoint, driftProperties).then((response) => {
            dispatch(success(scheduleActions.UPDATE_DRIFT_DATASETPROPERTIES_SUCCESS, response));
            return response;
        }, (error) => {
            dispatch(failure(scheduleActions.UPDATE_DRIFT_DATASETPROPERTIES_FAILURE, error));
        });
    };
};

export const getDatasetCorrelation = (datasetId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getDatasetCorrelation}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DATASET_CORRELATION_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_DATASET_CORRELATION_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DATASET_CORRELATION_FAILURE, error));
        });
    };
};


export const getAttributePropertyRules = (datasetId, attributeId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getAttributePropertyRules}/${datasetId}/${attributeId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_ATTRIBUTE_PROPERTY_RULES_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_ATTRIBUTE_PROPERTY_RULES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_ATTRIBUTE_PROPERTY_RULES_FAILURE, error));
        });
    };
};

export const getDatasetLevelProfileData = (datasetId, logtype) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getDatasetLevelProfileData}/${datasetId}/${logtype}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DATASET_PROFILE_RULES_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_DATASET_PROFILE_RULES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DATASET_PROFILE_RULES_FAILURE, error));
        });
    };
};

export const deleteMonitoring = (datasetId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.deleteMonitoring}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.DELETE_MONITORING_REQUEST));
        return service.postRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.DELETE_MONITORING_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.DELETE_MONITORING_FAILURE, error));
        });
    };
};

export const muteMonitoring = (data) => {
    const endpoint = `${appConstants.END_POINTS.schedule.muteMonitoring}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.MUTE_MONITORING_REQUEST));
        return service.postRequest(endpoint, data).then((response) => {
            dispatch(success(scheduleActions.MUTE_MONITORING_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.MUTE_MONITORING_FAILURE, error));
        });
    };
};

export const ruleOffMonitoring = (data) => {
    const endpoint = `${appConstants.END_POINTS.schedule.ruleOffMonitoring}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.RULE_OFF_MONITORING_REQUEST));
        return service.postRequest(endpoint, data).then((response) => {
            dispatch(success(scheduleActions.RULE_OFF_MONITORING_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.RULE_OFF_MONITORING_FAILURE, error));
        });
    };
};

export const getMonitoringAlerts = (datasetId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getMonitoringAlerts}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_MONITORING_ALERTS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_MONITORING_ALERTS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_MONITORING_ALERTS_FAILURE, error));
        });
    };
};

export const getMonitoringStatistical = (datasetId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getMonitoringStatistical}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_MONITORING_STATISTICAL_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_MONITORING_STATISTICAL_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_MONITORING_STATISTICAL_FAILURE, error));
        });
    };
};

export const updateAttributePropertyRules = (data) => {
    const endpoint = `${appConstants.END_POINTS.schedule.updateAttributePropertyRules}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.UPDATE_ATTRIBUTE_PROPERTY_RULES_REQUEST));
        return service.postRequest(endpoint, data).then((response) => {
            dispatch(success(scheduleActions.UPDATE_ATTRIBUTE_PROPERTY_RULES_SUCCESS, response));
            return response;
        }, (error) => {
            dispatch(failure(scheduleActions.UPDATE_ATTRIBUTE_PROPERTY_RULES_FAILURE, error));
        });
    };
};

export const addMonitoringDetails = (data) => {
    const endpoint = `${appConstants.END_POINTS.schedule.addMonitoringDetails}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.ADD_MONITORING_DETAILS_REQUEST));
        return service.postRequest(endpoint, data).then((response) => {
            dispatch(success(scheduleActions.ADD_MONITORING_DETAILS_SUCCESS, response));
            dispatch(success(alertActions.ALERT_SUCCESS, alerts.addMonitoringDetails));
            return response;
        }, (error) => {
            dispatch(failure(scheduleActions.ADD_MONITORING_DETAILS_FAILURE, error));
        });
    };
};


export const getAttributeStatistics = (attributeId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getAttributeStatistics}/${attributeId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_ATTRIBUTE_STATISTICS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_ATTRIBUTE_STATISTICS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_ATTRIBUTE_STATISTICS_FAILURE, error));
        });
    };
};

export const getInvalidQueries = (datasetId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getInvalidQueries}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_INVALID_QUERIES_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.GET_INVALID_QUERIES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_INVALID_QUERIES_FAILURE, error));
        });
    };
};

export const downloadExportRules = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.schedule.exportRules}`;
    return (dispatch) => {
        dispatch(request(datasetActions.EXPORT_DOWNLOAD_RULES_REQUEST));
        return service.postRequest(endpoint, requestParams)
            .then((response) => {
                dispatch(success(datasetActions.EXPORT_DOWNLOAD_RULES_SUCCESS, response.data));
                return response.data;
            }, (error) => {
                dispatch(failure(datasetActions.EXPORT_DOWNLOAD_RULES_FAILURE, error));
            });
    };
};


export const getExecutionCurrentJobDetail = (data) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getExecutionCurrentJobDetail}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_EXECUTION_CURRENT_JOB_INFO_REQUEST));
        return service.postRequest(endpoint, data).then((response) => {
            dispatch(success(scheduleActions.GET_EXECUTION_CURRENT_JOB_INFO_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_EXECUTION_CURRENT_JOB_INFO_FAILURE, error));
        });
    };
};

export const getDriftBehaviour = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getDriftBehaviour}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DRIFT_BEHAVIOUR_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(scheduleActions.GET_DRIFT_BEHAVIOUR_SUCCESS, response));
            return response;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DRIFT_BEHAVIOUR_FAILURE, error));
        });
    };
};

export const removeDataSourceSchedule = (requestParams) => {
    return (dispatch) => {
        dispatch(request(scheduleActions.REMOVE_DATASOURCE_SCHEDULE_REQUEST));
        return service.postRequest(appConstants.END_POINTS.schedule.removeDataSourceSchedule, requestParams).then((response) => {
            dispatch(success(scheduleActions.REMOVE_DATASOURCE_SCHEDULE_REQUEST_SUCCESS, response));
            return response;
        }, (error) => {
            dispatch(failure(scheduleActions.REMOVE_DATASOURCE_SCHEDULE_REQUEST_FAILURE, error));
        });
    };
};

export const removeSchedule = (datasetId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.removeSchedule}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.REMOVE_SCHEDULE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(scheduleActions.REMOVE_SCHEDULE_REQUEST_SUCCESS, response));
            return response;
        }, (error) => {
            dispatch(failure(scheduleActions.REMOVE_SCHEDULE_REQUEST_FAILURE, error));
        });
    };
};

export const getDatasetAttributes = (datasourceId, datasetId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getDatasetAttributes}/${datasourceId}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_DATASET_ATTRIBUTE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            const attributes = response && response.data ? response.data : {};
            dispatch(success(scheduleActions.GET_DATASET_ATTRIBUTE_SUCCESS, attributes));
            return attributes;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_DATASET_ATTRIBUTE_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const getAttributeRules = (attributeId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getAttributeRules}/${attributeId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_ATTRIBUTES_RULES_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            const rules = response && response.data ? response.data : {};
            dispatch(success(scheduleActions.GET_ATTRIBUTES_RULES_SUCCESS, rules));
            return rules;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_ATTRIBUTES_RULES_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const getRecipientUsers = (datasourceId, datasetId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getRecipientUsers}/${datasourceId}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_RECIPIENTS_USERS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            let users = response && response.data ? response.data : {};
            users = users.map((user) => {
                if (!user.name.trim()) {
                    return {
                        ...user,
                        name: user.email
                    };
                }
                return user;
            });
            dispatch(success(scheduleActions.GET_RECIPIENTS_USERS_SUCCESS, users));
            return users;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_RECIPIENTS_USERS_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const createUpdateReport = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.schedule.createUpdateReport}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.CREATE_REPORT_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(scheduleActions.CREATE_REPORT_SUCCESS, response));
            dispatch(success(alertActions.ALERT_SUCCESS, requestParams.id ? "Report Updated Successfully" : 'Report Created Successfully'));
        }, (error) => {
            dispatch(failure(scheduleActions.CREATE_REPORT_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const getReport = (requestParams, organizationId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getReport}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_REPORT_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            const reports = response && response.data ? response.data : {};
            dispatch(success(scheduleActions.GET_REPORT_SUCCESS, response));
            return reports;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_REPORT_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const getReportById = (reportId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getReportById}/${reportId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_REPORT_BY_ID_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            const reports = response && response.data ? response.data : {};
            dispatch(success(scheduleActions.GET_REPORT_BY_ID_SUCCESS, response));
            return reports;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_REPORT_BY_ID_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const deleteReportById = (reportId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.deleteReportById}/${reportId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.DELETE_REPORT_BY_ID_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            const reports = response && response.data ? response.data : {};
            dispatch(success(scheduleActions.DELETE_REPORT_BY_ID_SUCCESS, response));
            dispatch(success(alertActions.ALERT_SUCCESS, "Report Deleted Successfully"));
            return reports;
        }, (error) => {
            dispatch(failure(scheduleActions.DELETE_REPORT_BY_ID_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const editReportReportStatus = (reportId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.schedule.editReportStatus}/${reportId}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.DELETE_REPORT_BY_ID_REQUEST));
        return service.putRequest(endpoint, requestParams).then((response) => {
            const reports = response && response.data ? response.data : {};
            dispatch(success(scheduleActions.DELETE_REPORT_BY_ID_SUCCESS, response));
            dispatch(success(alertActions.ALERT_SUCCESS, "Report Status Updated Successfully"));
            return reports;
        }, (error) => {
            dispatch(failure(scheduleActions.DELETE_REPORT_BY_ID_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const createCustomReportSchedule = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.schedule.createCustomReportSchedule}`;
    return (dispatch) => {
        // dispatch(request(scheduleActions.DELETE_REPORT_BY_ID_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            const schedule = response && response.data ? response.data : {};
            // dispatch(success(scheduleActions.DELETE_REPORT_BY_ID_SUCCESS, response));
            dispatch(success(alertActions.ALERT_SUCCESS, "Schedule Created Successfully"));
            return schedule;
        }, (error) => {
            // dispatch(failure(scheduleActions.DELETE_REPORT_BY_ID_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};


export const getCustomReportScheduleByReportId = (reportId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getCustomReportScheduleByReportId}/${reportId}`;
    return (dispatch) => {
        // dispatch(request(scheduleActions.DELETE_REPORT_BY_ID_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            const schedule = response && response.data ? response.data : {};
            // dispatch(success(scheduleActions.DELETE_REPORT_BY_ID_SUCCESS, response));
            return schedule;
        }, (error) => {
            // dispatch(failure(scheduleActions.DELETE_REPORT_BY_ID_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const deleteCustomReportScheduleByReportId = (reportId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.deleteCustomReportScheduleByReportId}/${reportId}`;
    return (dispatch) => {
        // dispatch(request(scheduleActions.DELETE_REPORT_BY_ID_REQUEST));
        return service.putRequest(endpoint).then((response) => {
            const schedule = response && response.data ? response.data : {};
            // dispatch(success(scheduleActions.DELETE_REPORT_BY_ID_SUCCESS, response));
            dispatch(success(alertActions.ALERT_SUCCESS, "Schedule Removed Successfully"));
            return schedule;
        }, (error) => {
            // dispatch(failure(scheduleActions.DELETE_REPORT_BY_ID_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const updateCustomReportScheduleById = (scheduleId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.schedule.updateCustomReportScheduleById}/${scheduleId}`;
    return (dispatch) => {
        // dispatch(request(scheduleActions.DELETE_REPORT_BY_ID_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            const schedule = response && response.data ? response.data : {};
            // dispatch(success(scheduleActions.DELETE_REPORT_BY_ID_SUCCESS, response));
            dispatch(success(alertActions.ALERT_SUCCESS, "Schedule Updated Successfully"));
            return schedule;
        }, (error) => {
            // dispatch(failure(scheduleActions.DELETE_REPORT_BY_ID_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const job_status_chart = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.schedule.jobstatuschart}`;
    return (dispatch) => {
        dispatch(request(scheduleActions.GET_JOB_STATUS_CHART_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            const chartData = response && response.data ? response.data : {};
            dispatch(success(scheduleActions.GET_JOB_STATUS_CHART_SUCCESS, response));
            // dispatch(success(alertActions.ALERT_SUCCESS, "Schedule Updated Successfully"));
            return chartData;
        }, (error) => {
            dispatch(failure(scheduleActions.GET_JOB_STATUS_CHART_FAILURE, error));
            // dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};


export const getDatasourceList = (requestParams, cancellationToken = null) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getDatasourceList}`;
    return (dispatch) => {
        dispatch(request(metricsActions.GET_DATASOURCE_LIST_REQUEST));
        return service.postRequest(endpoint, requestParams, false, cancellationToken).then((response) => {
            dispatch(success(metricsActions.GET_DATASOURCE_LIST_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(metricsActions.GET_DATASOURCE_LIST_FAILURE, error));
        });
    };
};

export const getDatasetList = (requestParams, cancellationToken = null) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getDatasetList}`;
    return (dispatch) => {
        dispatch(request(metricsActions.GET_DATASET_LIST_REQUEST));
        return service.postRequest(endpoint, requestParams, false, cancellationToken).then((response) => {
            dispatch(success(metricsActions.GET_DATASET_LIST_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(metricsActions.GET_DATASET_LIST_FAILURE, error));
        });
    };
};