const QueryBuilderStyles = (theme) => ({
    root: {
        flexGrow: 1
    },
    queryBuilder: {
        width: '100%'
    },
    marginRight10: {
        marginRight: 10
    },
    marginRight15: {
        marginRight: 15
    },
    groupHeader: {
        padding: '5px 0',
        paddingLeft: 0,
        position: 'relative'
    },
    groupChildren: {
        paddingLeft: 10,
        width: 'calc(100% - 10px)'
    },
    parentRule: {
        "&::before, &::after": {
            border: '0 !important',
            width: '0 !important',
            height: '0 !important'
        }
    },
    rule: {
        border: `1px solid ${theme.palette.grey.extraLight}`,
        padding: '8px 4px 8px 8px',
        backgroundColor: theme.palette.common.white,
        borderRadius: 0,
        marginTop: 5,
        marginLeft: 23,
        width: 'calc(100% - 14px)',
        position: 'relative',
        "&:first-child": {
            "&::before": {
                top: -13,
                height: 'calc(50% + 13px)'
            }
        },
        "&:last-child": {
            "&::before": {
                borderRadius: 0
            },
            "&::after": {
                borderWidth: 0
            }
        },
        "&::before, &::after": {
            content: "''",
            position: 'absolute',
            left: -24,
            width: 23,
            height: 'calc(50% + 7px)',
            top: -4,
            borderWidth: '0 0 1px 1px',
            borderStyle: 'solid',
            borderColor: theme.palette.grey.extraLight
        },
        "&::after": {
            top: '50%',
            borderWidth: '0 0 0 1px'
        }
    },
    group: {
        border: `1px solid ${theme.palette.grey.extraLight}`,
        padding: '8px 4px 8px 8px',
        marginTop: 9,
        marginLeft: 23,
        position: 'relative',
        width: 'calc(100% - 14px)',
        "&:first-child": {
            padding: 0,
            borderWidth: 0,
            margin: 0,
            width: 'calc(100% - 5px)',
            "&::before": {
                top: -13,
                height: 'calc(50% + 14px)'
            }
        },
        "&:last-child": {
            "&::before": {
                borderRadius: 0,
                borderWidth: '0 0 1px 1px'
            },
            "&::after": {
                borderWidth: 0
            }
        },
        "&::before, &::after": {
            content: "''",
            position: 'absolute',
            left: -24,
            width: 23,
            height: 'calc(50% + 5px)',
            top: -5,
            borderWidth: '0 0 1px 1px',
            borderStyle: 'solid',
            borderColor: theme.palette.grey.extraLight
        },
        "&::after": {
            top: '50%',
            borderWidth: '0 0 0 1px'
        }
    },
    btnCombinator: {
        height: 'auto !important',
        fontSize: 12,
        padding: '2px 10px',
        borderRadius: 0,
        color: theme.palette.grey.darkTxt,
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey.extraLight}`,
        textTransform: 'uppercase',
        marginLeft: '0px !important',
        '&.active': {
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            '&.not': {
                borderRightColor: theme.palette.common.white
            }
        }
    },
    btnAction: {
        margin: '0 5px',
        borderRadius: '0px !important',
        textTransform: 'capitalize',
        fontFamily: theme.palette.textFont,
        backgroundColor: theme.palette.grey.extraLight,
        padding: '2px 8px',
        height: 'auto !important',
        fontSize: 13,
        fontWeight: 400,
        color: theme.palette.grey.darkTxt,
        '&:last-child': {
            marginRight: 0
        },
        '&:hover': {
            color: "#fff",
            backgroundColor: theme.palette.primary.main
        }
    },
    /*
     * ruleContainer: {
     *     "& .MuiInput-underline": {
     *         "&:hover::before": {
     *             borderBottom: `0px solid ${theme.palette.grey.default} !important`
     *         },
     *         "&::before": {
     *             borderBottom: `0px solid ${theme.palette.grey.default} !important`,
     *             marginRight: 5,
     *             transition: '0.3s'
     *         },
     *         "&::after": {
     *             borderBottom: `0px solid ${theme.palette.grey.default}`
     *         }
     *     },
     *     "& input": {
     *         border: `1px solid ${theme.palette.grey.default}`,
     *         height: 26,
     *         fontSize: 13,
     *         borderColor: theme.palette.grey.extraLight,
     *         borderRadius: 0,
     *         padding: '1px 7px',
     *         marginRight: 14
     *     }
     * },
     */

    ruleContainer: {
        // backgroundColor: theme.palette.background.container,
        "& .MuiInput-underline": {
            "&:hover::before": {
                border: `1px solid ${theme.palette.grey.dark} !important`
            },
            "&::before": {
                border: `1px solid ${theme.palette.grey.light} !important`,
                height: '30px',
                marginRight: '0px !important'
                /*
                 * marginRight: 5,
                 * transition: '0.3s'
                 */
            }

        },
        "& .MuiInput-underline.Mui-focused": {
            "&::after": {
                border: `1px solid ${theme.palette.primary.dark} !important`,
                height: 30,
                transition: "auto !important"
            },
            "&::before": {
                border: `0px solid ${theme.palette.grey.light} !important`
                /*
                 * marginRight: 5,
                 * transition: '0.3s'
                 */
            },
            "&:hover::before": {
                border: `0px solid ${theme.palette.grey.dark} !important`
            }
        },
        "& input": {
            // border: `1px solid ${theme.palette.grey.default}`,
            height: 30,
            fontSize: 13,
            // borderColor: theme.palette.grey.extraLight,
            borderRadius: 0,
            padding: '1px 7px'
            // marginRight: 14
        }
    },

    queryBuilderfooterbtn: {
        padding: 4
    },
    rangeContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        '& input': {
            marginRight: '0 !important'
        },
        '& .MuiTypography-root': {
            color: '#ccc',
            fontSize: 13,
            margin: '6px 8px 0px 8px'
        }
    },
    characterCountContainer: {
        '& .small-input': {
            width: 70,
            minWidth: 70,
            marginRight: 14,
            '& .MuiInputBase-root': {
                width: 'inherit',
                minWidth: 'inherit'
            }
        }
    },
    actionIconButton: {
        padding: 4
    },
    actionButton: {
        height: '27px',
        fontSize: '13px !important',
        minWidth: '68px',
        padding: '6px 12px !important',
        marginTop: 8,
        '&.MuiButton-containedPrimary': {
            marginRight: 11
        }
    },
    selectContainer: {
        '& .MuiInput-root': {
            minWidth: '100px',
            textTransform: 'capitalize'
        },
        '& .MuiSelect-select': {
            paddingLeft: 8,
            textTransform: 'capitalize',
            backgroundColor: 'transparent'
        }
    },
    option: {
        textTransform: 'capitalize'
    },
    complexRuleTitle: {
        color: '#969899',
        fontSize: 11,
        position: 'absolute',
        left: 0
    },
    autoCompleteInput: {
        '& input': {
            height: 30,
            padding: "1px 7px !important",
            width: "100% !important",
            textOverflow: "initial",
            textTransform: "capitalize"
        }
    },
    removeCase: {
        '& input': {
            textTransform: "unset"
        }
    }
});

export default QueryBuilderStyles;