import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, withStyles, Tabs, Tab, IconButton } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import classNames from 'classnames';

import { getOrganizationUser, updateUser } from '../../actions/accountActions';
import {
    getDataSource, updateDataSource, createAuditLog,
    getSourceAuditLogs, createSourceShare, getSourceShare, inviteSourceUser,
    deleteDatasource, getFavorites, updateFavorites, deleteSourceShare, setSelectedDatasourceId, getDomain,
    createDomain, getFieldPropertyByLevel, createFieldProperty, getExportReport, getImportReport, exportPropertiesReport,
    getDatasetByDatasource, getAttributeByDatasource, updateCertified, getCertified, updateTabIndex
} from '../../actions/datasourceActions';
import { getLineageEntry } from '../../actions/lineageActions';
import { getDatasourceCatalog, clearCatalogInfo } from '../../actions/catalogActions';
import { getDatasetInfo } from '../../actions/datasetActions';
import DatasourceInfo from '../../components/Datasource/DatasourceInfo.jsx';
import { appConstants } from '../../constants/appConstants';
import TabPanel from '../../components/TabPanel/TabPanel.jsx';
import DatasourceDetail from '../../components/Datasource/DatasourceDetail.jsx';
import DatasetDetail from '../../components/Datasource/DatasetDetail.jsx';
import Permission from '../../components/Permission/Permission.jsx';
import AuditLogComponent from '../../components/AuditLogComponent/AuditLogComponent.jsx';
import DatasetTab from '../../components/Datasource/DatasetTab.jsx';
import Styles from '../../layouts/Styles.jsx';
import ExecutionLogs from '../../components/Datasource/ExecutionLogs.jsx';
import { getAuditLogText } from '../../helpers/appHelpers';
import Loader from '../../components/Loaders/Loader.jsx';
import DatasourceOverviewStyles from './DatasourceOverviewStyles.jsx';
import AlertDialog from '../../components/AlertDialog/AlertDialog.jsx';
import DatasourceMetrics from '../../components/DatasourceMetrics/DatasourceMetrics.jsx';
import { getActionItemCount, getConversationCount } from '../../actions/notificationActions.js';
import { getCustomTabs, getProperties, getPropertiesOrganization, getCustomFields, getExportMetadata } from '../../actions/settingActions';
import Comments from '../../components/Comments/Comments.jsx';

// Import Component
import DynamicElementByDataType from '../../components/DynamicElementByDataType/DynamicElementByDataType.jsx';
import LineagePannel from '../Lineage/LineagePannel.jsx';
import CatalogInfo from '../../components/Dataset/CatalogInfo.jsx';
import { setCurrentPage } from '../../helpers/apiHelpers';
import datasourceTypes from '../../config/datasourceTypes';
import DatasourceConfiguration from '../../components/Datasource/DatasourceConfiguration.jsx';

class DatasourceOverview extends Component {
    constructor(props) {
        super(props);
        setCurrentPage('datasource');
        this.state = {
            tabIndex: this.navigateIndex(),
            datasourceDetail: {
                "terms_description": "",
                "name": "",
                "platform_use": [],
                "domains": [],
                "domain_ids": [],
                "properties": {}
            },
            datasourceDetailItem: {},
            auditLogs: [],
            breadcrumb: [
                {
                    name: 'Catalog',
                    path: '/catalog',
                    type: 'catalog'
                }
            ],
            isFavourite: false,
            isCertified: false,
            governance: [],
            isDataLoading: true,
            isPermissionLoading: true,
            isStewardUser: false,
            isDeleteDatasource: false,
            domain: [],
            fields: [],
            fieldProperties: [],
            dataSourceTabs: appConstants && appConstants.tableauDatasourceTabList ? this.getTab([...appConstants.tableauDatasourceTabList]) : [],
            isExportLoading: false,
            isImportLoading: false,
            tabFields: [],
            customTabls: [],
            duplicateError: false,
            isGovernanceUpdate: false,
            customFieldLoading: false,
            collapseFlag: false,
            selectedDataset: [],
            locationHistory: this.props.location.state ? this.props.location.state : {},
            dqScoreThreshold: {}
        };
    }

    componentDidMount() {
        this.props.getPropertiesOrganization(this.props.systemConfiguration ? 0 : this.props.user.organization);
        this.props.clearCatalogInfo();
        if (!this.props.users.length) {
            this.getUsers();
        }
        if (this.props.match.params.id) {
            this.props.setSelectedDatasourceId(this.props.match.params.id);
            this.props.getActionItemCount(this.props.match.params.id, 0);
            this.props.getConversationCount(this.props.match.params.id, 0);
            this.props.getDatasourceCatalog(this.props.match.params.id);
        }
        this.getDataSourceDetail();
        this.getAuditLogs();
        this.getDatasetInfo();
        this.getSourceFavorites();
        this.getSourceShare();
        this.props.getProperties(this.props.systemConfiguration ? 0 : this.props.user.organization);
        this.getDomain();
        this.getLineageEntryDetails();
        this.getAllFieldsByLevel();
        this.getSourceCertified();
    }

    navigateIndex = () => {
        if (this.props.location.state && this.props.location.state.dqscore) {
            return 2;
        } else if (this.props.location.state && this.props.location.state.datasetTab && !this.props.datasource.metadata) {
            return 1;
        } else if (this.props.location.state && this.props.location.state.datasetTab && this.props.datasource.metadata) {
            return 2;
        }
        return 0;
    }

    setDuplicateError = (value) => {
        this.setState({ duplicateError: value });
    }


    getLineageEntryDetails() {
        this.props.getLineageEntry(this.props.match.params.id).then((response) => {
            if (response) {
                this.setState({ dataSourceTabs: [...this.state.dataSourceTabs, 'Lineage'] });
            }
        });
    }

    getUsers() {
        this.props.getOrganizationUser();
    }

    loadTab(tabs) {
        if (this.state.locationHistory && this.state.locationHistory.info) {
            return tabs.indexOf("Info");
        } else if (this.state.locationHistory && this.state.locationHistory.dqscore) {
            return tabs.indexOf("Datasets");
        } else if (this.state.locationHistory && this.state.locationHistory.attribute && !this.state.locationHistory.isdrift) {
            return tabs.indexOf("Metrics");
        } else if (this.state.locationHistory && this.state.locationHistory.executionLogs) {
            return tabs.indexOf("Execution Logs");
        }
        return 0;
    }

    getDataSourceDetail() {
        this.setState({ isDataLoading: true });
        this.props.getDataSource(this.props.match.params.id).then((response) => {
            this.setState({ isDataLoading: false });
            if (response) {
                if (response.type !== "Tableau") {
                    this.setState({ dataSourceTabs: appConstants && appConstants.datasourceTabList ? this.getTab([...appConstants.datasourceTabList]) : [] });
                }
                const { breadcrumb } = this.state;
                breadcrumb.push({ name: response.name !== "" ? response.name : 'Untitled Data Source', path: '', type: 'datasource' });
                this.props.getPageTitle(`${response.name}`);
                const dataSourceDetailCopy = JSON.parse(JSON.stringify(response));
                if (!dataSourceDetailCopy.dq_score_threshold || (dataSourceDetailCopy.dq_score_threshold && Object.keys(dataSourceDetailCopy.dq_score_threshold).length === 0)) {
                    if (this.props.user_config.dq_score_threshold && Object.keys(this.props.user_config.dq_score_threshold).length > 0) {
                        dataSourceDetailCopy.dq_score_threshold = this.props.user_config.dq_score_threshold;
                    } else {
                        dataSourceDetailCopy.dq_score_threshold = {
                            quadrant: appConstants.quadrantList[0].value,
                            scores: appConstants.quadrantScoreList[appConstants.quadrantList[0].value]
                        };
                    }
                }
                if (!dataSourceDetailCopy.properties || (dataSourceDetailCopy.properties && Object.keys(dataSourceDetailCopy.properties).length === 0)) {
                    if (this.props.user_config.profileRulesConfig && Object.keys(this.props.user_config.profileRulesConfig).length > 0) {
                        dataSourceDetailCopy.properties = { profileRules: this.props.user_config.profileRulesConfig };
                    } else {
                        dataSourceDetailCopy.properties = { profileRules: this.props.profileRulesConfig };
                    }
                }
                this.setState({ datasourceDetail: JSON.parse(JSON.stringify(dataSourceDetailCopy)), dqScoreThreshold: JSON.parse(JSON.stringify(dataSourceDetailCopy.dq_score_threshold)), breadcrumb, datasourceDetailItem: JSON.parse(JSON.stringify(dataSourceDetailCopy)), tabIndex: this.loadTab(this.state.dataSourceTabs) });
            } else {
                this.props.history.push("/catalog");
            }
        });
    }

    getDatasetInfo() {
        this.props.getDatasetInfo(this.props.match.params.id, 0);
    }

    getAuditLogs = () => {
        this.props.getSourceAuditLogs(this.props.match.params.id, 0).then((response) => {
            if (response) {
                this.setState({ auditLogs: response.logs, auditLogsLength: response.logsLength });
            }
        });
    }

    getDomain = () => {
        this.props.getDomain().then((response) => {
            if (response) {
                this.setState({ domain: response });
            }
        });
    }

    getSourceFavorites() {
        this.props.getFavorites(this.props.match.params.id, "datasource").then((response) => {
            this.setState({ isFavourite: response ? response : false });
        });
    }

    getSourceCertified() {
        this.props.getCertified(this.props.match.params.id, "datasource").then((response) => {
            this.setState({ isCertified: response ? response : false });
        });
    }

    getSourceShare(type) {
        this.props.getSourceShare(this.props.match.params.id).then((response) => {
            if (response) {
                const userId = this.props.user && this.props.user.id ? this.props.user.id : 0;
                const isStewardUser = response.some((user) => user.access_level === 3 && user.user_id === userId);
                this.setState({ governance: response ? response : [], isPermissionLoading: false, isStewardUser });
                if (type === "update") {
                    this.setState({ isGovernanceUpdate: true });
                }
            }
        });
    }

    updateGovernance = () => {
        this.getSourceShare("update");
    }


    onChangeSourceDetail = (name, value) => {
        const { datasourceDetail, domain } = this.state;
        if (name === "updateTags") { datasourceDetail.tags = value; }
        else if (name !== 'removeDomain') { datasourceDetail[name] = value; }
        if (name === 'dq_score_threshold' && !value) { datasourceDetail[name] = this.state.dqScoreThreshold; }
        this.setState({ datasourceDetail });
        if (name === "platform_use" || name === "updateTags" || name === "status" || (name === 'dq_score_threshold' && !value) || name === 'properties') {
            this.updateSourceDetail(name);
        } else if (name === "name") {
            const { breadcrumb } = this.state;
            breadcrumb[breadcrumb.length - 1].name = value;
            this.setState({ breadcrumb });
        }
        if (name === 'removeDomain') {
            const domainToRemove = datasourceDetail.domain_ids[value];
            datasourceDetail.removeDomain = domainToRemove;
            this.props.updateDataSource(this.props.match.params.id, datasourceDetail).then((response) => {
                if (datasourceDetail.removeDomain && response.is_domain_deleted) {
                    const temp_domain = domain.filter((obj) => obj.id !== datasourceDetail.removeDomain);
                    this.setState({ domain: [...temp_domain] });
                }
                delete datasourceDetail.removeDomain;
                datasourceDetail.domains = datasourceDetail.domains.filter((domain, index) => index !== value);
                datasourceDetail.domain_ids = datasourceDetail.domain_ids.filter((domain_id, index) => index !== value);
                this.setState({ datasourceDetail });
            });
            this.updateAuditLog(name);
        }
    }


    updateSourceDetail = (name, type) => {
        if ((name === "domain" || name === "description") && (type.charAt(0) === " ")) {
            return setTimeout(() => { this.props.createDomain("Error in Name", this.props.domainProperties.create_new_domain); }, 1000);
        }
        if (name === "domain" && typeof this.state.datasourceDetail[name] === "undefined" && type !== "") {
            const damainName = this.state.datasourceDetail;
            damainName[name] = type;
        }
        const { datasourceDetail } = this.state;
        name = name === "updateTags" ? "tags" : name;
        const condition = name === "tags" || name === "platform_use" || name === "updateTags" ? JSON.stringify(this.state.datasourceDetail[name]) !== JSON.stringify(this.state.datasourceDetailItem[name]) : this.state.datasourceDetail[name] !== this.state.datasourceDetailItem[name] || (name === 'domain' && type === 'update domain');
        const model = this.state.datasourceDetail;
        if (condition) {
            const datasourceDetailItem = this.state.datasourceDetailItem;
            delete model.created_by_id;
            if (name === "domain" && type === "update domain") {
                this.props.updateDataSource(this.props.match.params.id, this.state.datasourceDetail).then((response) => {
                    model.domains = [...model.domains, model.domain];
                    model.domain_ids = [...model.domain_ids, model.domain_id];
                    this.setState({ datasourceDetail });
                });
            }
            if (name === "name") {
                this.props.updateDataSource(this.props.match.params.id, this.state.datasourceDetail).then((response) => {
                    this.setState({ datasourceDetail });
                });
            }
            if (name === "domain" && type === "") {
                model["domain_id"] = "";
                this.props.updateDataSource(this.props.match.params.id, this.state.datasourceDetail);
            }
            if (name === "description" || name === "tags" || name === "terms_description" || name === "status" || name === 'dq_score_threshold' || name === 'properties') {
                this.props.updateDataSource(this.props.match.params.id, this.state.datasourceDetail);
            }
            datasourceDetailItem[name] = model[name];
            this.updateAuditLog(name);
            this.setState({ datasourceDetailItem: JSON.parse(JSON.stringify(datasourceDetailItem)) });
        }
        if (name === "createDomain" && type !== "") {
            this.props.createDomain({ name: type, create_new_domain: this.props.domainProperties.create_new_domain, is_user_created: true }).then((response) => {
                if (response) {
                    this.setState({ domain: [...this.state.domain, { name: response.name, id: response.id }] });
                    model["domain_id"] = response.id;
                    this.props.updateDataSource(this.props.match.params.id, model);
                    model.domains.push(type);
                    model.domain_ids.push(model.domain_id);
                    this.setState({ datasourceDetail: { ...model } });
                }
            });
        }
        if (name === 'dq_score_threshold') {
            this.props.updateDataSource(this.props.match.params.id, this.state.datasourceDetail);
        }
    }

    exportDatasource = (type) => {
        this.setState({ isExportLoading: true });
        const requestParams = {
            "organization": 0,
            "datasource": this.props.match.params.id,
            "dataset": 0,
            "include_metadata": true,
            "include_profile": true,
            "include_curate": true

        };
        this.props.getExportReport(requestParams, type).then((response) => {
            if (response) {
                this.updateAuditLog("export");
            }
            this.setState({ isExportLoading: false });
        });
    }

    exportProperties = (type) => {
        this.setState({ isExportLoading: true });
        const datasource_id = this.props.match.params.id ? parseInt(this.props.match.params.id, 10) : null;
        this.props.getDatasetByDatasource(datasource_id).then((datasetDetails) => {
            const requestData = {
                datasource_id: datasource_id,
                dataset_id: datasetDetails
            };
            this.props.getAttributeByDatasource(requestData).then((attributeDetails) => {
                const requestParams = {
                    datasources: [this.state.datasourceDetail.id],
                    datasets: datasetDetails ? datasetDetails : [],
                    attributes: attributeDetails ? attributeDetails : []
                };
                this.props.getExportMetadata(requestParams).then((response) => {
                    if (response) {
                        this.updateAuditLog("export");
                    }
                    this.setState({ isExportLoading: false });
                });
            });
        });
    }

    importDataset = (report) => {
        this.setState({ isImportLoading: true });
        const reader = new FileReader();
        reader.onload = (file) => {
            const requestParams = {
                "type": "dataset",
                "organization": 0,
                "datasource": this.props.match.params.id,
                "report": JSON.parse(file.target.result)
            };
            const baseConnector = this.props.datasetInfo.source_type;
            const importConnector = JSON.parse(requestParams.report[0].dataset_info.configuration).input_config.connection_type;
            if (baseConnector === importConnector) {
                this.props.getImportReport(requestParams).then((response) => {
                    if (response) {
                        this.updateAuditLog("import");
                        this.getDatasetInfo();
                    }
                    this.setState({ isImportLoading: false });
                });
            } else {
                this.props.getImportReport("Import Error");
                this.setState({ isImportLoading: false });
            }
        };
        reader.readAsText(report[0]);
    }

    updateAuditLog = (property) => {
        const data = getAuditLogText(property, this.state.datasourceDetail.name);
        const requestParams = {
            "source_id": this.props.match.params.id,
            "audit_logtext": data.text,
            "type": data.type
        };
        this.props.createAuditLog(requestParams).then((response) => {
            if (response) {
                const { auditLogs } = this.state;
                auditLogs.unshift(response);
                this.setState({ auditLogs });
            }
        });
    }

    createSourceShare = (requestParams) => {
        this.props.createSourceShare(requestParams).then((response) => {
            if (response) {
                this.getAuditLogs();
            }
        });
    };

    deleteSource = () => {
        this.setState({ isDeleteDatasource: true });
    }

    handleClick = (datasetlist) => {
        const selectedDatasets = datasetlist.filter((dataset) => dataset.isChecked);
        const dataset_list_ids = selectedDatasets.map(({ id }) => id);
        this.setState({ selectedDataset: dataset_list_ids });
    }

    deleteDatasourcetInfo = () => {
        this.props.deleteDatasource(this.props.match.params.id).then((response) => {
            if (response) {
                this.props.history.push('/catalog');
            }
        });
    }

    changeFavorite = () => {
        const requestParams = {
            "source_id": this.props.match.params.id,
            "favorite": this.state.isFavourite,
            "type": "datasource"
        };
        this.setState({ isFavourite: !this.state.isFavourite });
        this.props.updateFavorites(requestParams).then(() => {
            this.getAuditLogs();
        });
    }

    changeCertified = () => {
        const requestParams = {
            "source_id": this.props.match.params.id,
            "certified": this.state.isCertified,
            "type": "datasource"
        };
        this.setState({ isCertified: !this.state.isCertified });
        this.props.updateCertified(requestParams).then(() => {
            this.getAuditLogs();
        });
    }

    onChangeTabEvent = (index) => {
        const tabName = this.state.dataSourceTabs[index];
        if (this.state.datasourceDetail.name !== "" && !this.state.duplicateError) {
            this.setState({ tabIndex: index });
        }
        this.props.updateTabIndex(tabName);
    }

    onSelectDomain = (domain) => {
        const { datasourceDetail } = this.state;
        datasourceDetail.domain = domain.name;
        datasourceDetail["domain_id"] = domain.id;
        this.setState({ datasourceDetail }, () => this.updateSourceDetail("domain", "update domain"));
    }

    getTab(tabs) {
        if (tabs.indexOf("Info") > -1 && this.props.datasource && Object.keys(this.props.datasource).length > 0 && !this.props.datasource.metadata) {
            tabs.splice(tabs.indexOf("Info"), 1);
        }
        if (tabs.indexOf("Comments") > -1 && this.props.config && !this.props.config.collaboration) {
            tabs.splice(tabs.indexOf("Comments"), 1);
        }
        if (tabs.indexOf("Configuration") > -1 && this.props.user_config && !this.props.user_config.profileSetting.datasource_level_rule) {
            tabs.splice(tabs.indexOf("Configuration"), 1);
        }
        return tabs;
    }

    /**
     * Get Fields List
     */
    getAllFieldsByLevel = () => {
        this.setState({ customFieldLoading: true });
        Promise.all([this.props.getCustomFields(appConstants.fieldKeys.datasource), this.props.getCustomTabs(0, "catalog")]).then((response) => {
            if (response && response.length > 0) {
                let tabFields = [];
                if (response[0]) {
                    const fieldList = response[0].filter((data) => data.tab_name === "");
                    tabFields = response[0].filter((data) => data.tab_name && data.tab_name.length);
                    tabFields = tabFields.sort((a, b) => b.display_order > a.display_order);
                    if (fieldList.length === 0) {
                        this.discardDetailTab();
                    } else {
                        this.setState({ dataSourceTabs: [...this.state.dataSourceTabs, appConstants.fieldKeys.tabName] });
                    }
                    this.setState({ fields: [...fieldList], tabFields: [...tabFields] }, () => {
                        if (this.state.locationHistory && this.state.locationHistory.info) {
                            this.setState({ tabIndex: this.state.dataSourceTabs.indexOf("Info") });
                        } else if (this.state.locationHistory && this.state.locationHistory.dqscore) {
                            this.setState({ tabIndex: this.state.dataSourceTabs.indexOf("Datasets") });
                        } else if (this.state.locationHistory && this.state.locationHistory.executionLogs) {
                            this.setState({ tabIndex: this.state.dataSourceTabs.indexOf("Execution Logs") });
                        } else if (this.state.locationHistory && this.state.locationHistory.metrics) {
                            this.setState({ tabIndex: this.state.dataSourceTabs.indexOf("Metrics") });
                        }
                    });
                    this.getFieldPropertyByLevel();
                } else {
                    this.discardDetailTab();
                }
                if (response[1] && response[1].length !== 0) {
                    const tabs = response[1].filter((data) => data.name !== 'Overview' && tabFields.some((a) => a.tab_name.toLowerCase() === data.name.toLowerCase())
                        && tabFields.some((a) => a.group_name !== "")).map((data) => data.name);
                    this.loadCustomTabs([...tabs]);
                }
            }
            this.setState({ customFieldLoading: false });
        });
    }

    discardDetailTab = () => {
        const dataSourceTabs = [...this.state.dataSourceTabs];
        const index = dataSourceTabs.indexOf(appConstants.fieldKeys.tabName);
        if (index > -1) {
            dataSourceTabs.splice(index, 1);
        }
        this.setState({ fields: [], dataSourceTabs: dataSourceTabs });
    }

    loadCustomTabs = (tabFields) => {
        const datasourceTabs = [...this.state.dataSourceTabs];
        this.setState({ dataSourceTabs: [...datasourceTabs, ...tabFields], customTabls: tabFields });
    }

    /**
     * Get Field Property List
     */
    getFieldPropertyByLevel = () => {
        this.props.getFieldPropertyByLevel(this.props.match.params.id, appConstants.fieldKeys.datasource).then((response) => {
            if (response && response.length > 0) {
                this.setState({ fieldProperties: response });
            }
        });
    }

    /**
     * get Tab Index
     * @param {*} index
     * @returns
     */
    getTabIndex = (tabName) => {
        const index = this.state.dataSourceTabs.indexOf(tabName);
        return index;
    }

    /**
     * Get Dynamic Element On Change Event
     * @param {*} name
     * @param {*} value
     * @param {*} data_type
     */
    dyanamicEleOnChange = (value, field, saveDB, isBoolean) => {
        let properties = [...this.state.fieldProperties];

        const fieldIndex = properties.findIndex((i) => parseInt(i.custom_field) === parseInt(field.id) && parseInt(i.datasource) === parseInt(this.props.match.params.id));
        if (fieldIndex > -1) {
            // Update field in state
            properties[fieldIndex].value = value;
            this.setState({ fieldProperties: properties });

            if (saveDB) {
                if (properties[fieldIndex].id && properties[fieldIndex].id !== '') {
                    // Update field in db
                    this.props.createFieldProperty(properties[fieldIndex]);
                } else {
                    // Create field in db
                    this.props.createFieldProperty(properties[fieldIndex]).then((response) => {
                        if (!response) {
                            return;
                        }
                        properties[fieldIndex].id = response.id;
                        this.setState({ fieldProperties: properties });
                    });
                }
            }
        } else {
            // Create new field in state
            const obj = {
                id: '',
                organization: this.props.user.organization,
                custom_field: field.id,
                datasource: parseInt(this.props.match.params.id),
                value: value
            };

            properties = [...properties, obj];
            this.setState({ fieldProperties: properties });

            if (isBoolean) {
                // Create field in db
                this.props.createFieldProperty(obj).then((response) => {
                    if (!response) {
                        return;
                    }
                    obj.id = response.id;
                    properties = [...properties, obj];
                    this.setState({ fieldProperties: properties });
                });
            }
        }
    }

    updateGovernanceUpdate = () => {
        this.setState({ isGovernanceUpdate: false });
    }

    getActiveConnection = () => {
        const inputConnections = this.props.licenseConfig && this.props.licenseConfig.input_connectors ? this.props.licenseConfig.input_connectors : [];
        const connections = this.props.connectionTypes.filter((connection) => inputConnections.includes(connection.id));
        return connections.some((data) => data?.type?.replace(' ', '') === this.state.datasourceDetail?.type?.replace(' ', ''));
    }

    // ConnectorType and Database path display
    getIcon = (sourceType) => {
        let icon;
        if (sourceType) {
            icon = datasourceTypes.find((p) => p.type.toLowerCase() === sourceType.toLowerCase());
        }
        if (icon && icon.logo && typeof (icon.logo) === 'object') {
            return icon.logo.default;
        }
        return icon ? icon.logo : null;
    }

    sourcePath = () => {
        const path = this.state.datasourceDetail.configuration && typeof this.state.datasourceDetail.configuration === "string" ? JSON.parse(this.state.datasourceDetail.configuration) : {};
        const databasePath = `${path?.connection_type ?? 'File'} / ~ / ${path?.database ?? path?.bucketname ?? ''}`;
        return databasePath;
    }

    handleCollapse = () => {
        const { collapseFlag } = this.state;
        this.setState({ collapseFlag: !collapseFlag });
    }

    render() {
        const { classes, history } = this.props;
        let stewardUser = this.state.governance.find((user) => user.is_steward_user);
        if (stewardUser) {
            const user = this.props.users.find((user) => user.id === stewardUser.user_id);
            stewardUser = { ...user, ...stewardUser };
        } else {
            stewardUser = {};
        }
        const connectionStatus = this.getActiveConnection();
        return (
            <ValidatorForm form="datasource" onSubmit={() => { }}>
                <Grid className={classes.datasourceSection}>
                    <DatasourceInfo
                        source={this.state.datasourceDetail}
                        dq_score_threshold={this.state.datasourceDetail.dq_score_threshold}
                        breadcrumb={this.state.breadcrumb}
                        onChange={(name, value) => this.onChangeSourceDetail(name, value)}
                        onSave={(property) => this.updateSourceDetail(property)}
                        history={this.props.history}
                        type="datasource"
                        deleteData={() => this.deleteSource()}
                        favorite={this.state.isFavourite}
                        isCertified={this.state.isCertified}
                        changeFavorite={() => this.changeFavorite()}
                        datasourceId={this.props.match.params.id}
                        isEditable={this.state.isStewardUser}
                        selectedDataset={this.state.selectedDataset}
                        users={this.props.users}
                        updateAuditLog={this.updateAuditLog}
                        createSourceShare={this.props.createSourceShare}
                        governance={this.state.governance}
                        sourceId={this.props.match.params.id}
                        inviteSourceUser={this.props.inviteSourceUser}
                        getAuditLogs={this.getAuditLogs}
                        user={this.props.user}
                        loading={this.state.isPermissionLoading}
                        deleteShare={this.props.deleteSourceShare}
                        updateUser={this.props.updateUser}
                        exportReport={(type) => this.exportDatasource(type)}
                        importReport={(report) => this.importDataset(report)}
                        isImportLoading={this.state.isImportLoading}
                        isExportLoading={this.state.isExportLoading}
                        exportProperties={(type) => this.exportProperties(type)}
                        setDuplicateError={(value) => this.setDuplicateError(value)}
                        updateGovernance={this.updateGovernance}
                        isGovernanceUpdate={this.state.isGovernanceUpdate}
                        updateGovernanceUpdate={this.updateGovernanceUpdate}
                        sourceName={this.state.datasourceDetailItem ? this.state.datasourceDetailItem.name : ""}
                        connectionStatus={connectionStatus}
                        onChangeTabEvent={(index) => this.onChangeTabEvent(index)}
                        connector_type={this.state.datasourceDetail.type}
                        getIcon={this.getIcon}
                        getPath={this.sourcePath}
                        changeCertified={() => this.changeCertified()}
                    />
                </Grid>
                <Grid className={classes.datasourceTabContainer}>
                    <Tabs
                        value={this.state.tabIndex}
                        indicatorColor="secondary"
                        textColor="secondary"
                        onChange={(event, index) => this.onChangeTabEvent(index)}
                        aria-label="datasource tabs"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {
                            this.state.dataSourceTabs.map((name, index) =>
                                <Tab key={index} label={name === "Datasets" ? `${name} (${this.state.datasourceDetail?.total_datasets ?? '0'})` : name} />
                            )
                        }
                    </Tabs>
                </Grid>
                <Grid className={classNames(classes.marginTop15, classes.tabContainer, classes.tabContainerOverview)}>
                    <TabPanel value={this.state.tabIndex} index={this.getTabIndex('Overview')}>
                        <Grid container spacing={2}>
                            <Grid item xs={this.state.collapseFlag ? 8 : 12} style={{ display: "flex", flexDirection: "column", position: "relative" }}>
                                <DatasourceDetail
                                    source={this.state.datasourceDetail ? this.state.datasourceDetail : {}}
                                    onChange={(name, value) => this.onChangeSourceDetail(name, value)}
                                    onSave={(property, value) => this.updateSourceDetail(property, value)}
                                    isEditable={(this.state.isStewardUser || (this.props.user.role && this.props.user.role.properties_permission && this.props.user.role.properties_permission.is_edit)) && connectionStatus && this.state.datasourceDetail.name !== ""}
                                    onSelectDomain={(domain) => this.onSelectDomain(domain)}
                                    domain={this.state.domain ? this.state.domain : []}
                                    type="datasource"
                                    createNewDomain={this.props.domainProperties.create_new_domain}
                                    isDomainEnabled={this.props.config.domain}
                                />
                                <DatasetDetail type="datasource" datasetInfo={this.props.datasetInfo} />
                                <IconButton className={classes.collapseIcon} onClick={() => this.handleCollapse()}>
                                    {
                                        !this.state.collapseFlag &&
                                        <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                                        </svg>
                                    }
                                    {
                                        this.state.collapseFlag &&
                                        <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                                        </svg>
                                    }
                                </IconButton>
                            </Grid>
                            {
                                this.state.collapseFlag &&
                                <Grid item xs={4} className={classes.auditLogAlertContainer}>
                                    <AuditLogComponent auditLogs={this.state.auditLogs} auditLogsLength={this.state.auditLogsLength} sourceId={this.props.match.params.id} users={this.props.users} history={this.props.history} logtype={'Datasource'} fileType={this.state.datasourceDetail.type} connector_type={this.state.datasourceDetail.type} />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Permission
                                    source={JSON.parse(JSON.stringify(this.state.datasourceDetail))}
                                    isEditable={((this.state.isStewardUser) || (this.props.user.role && this.props.user.role.properties_permission && this.props.user.role.properties_permission.is_edit)) && connectionStatus && this.state.datasourceDetail.name !== ""}
                                    onChange={(name, value) => this.onChangeSourceDetail(name, value)}
                                    onSave={(property) => this.updateSourceDetail(property)}
                                    users={this.props.users}
                                    updateAuditLog={this.updateAuditLog}
                                    createSourceShare={this.props.createSourceShare}
                                    governance={this.state.governance}
                                    sourceId={this.props.match.params.id}
                                    inviteSourceUser={this.props.inviteSourceUser}
                                    getAuditLogs={this.getAuditLogs}
                                    dispatch={this.props.dispatch}
                                    user={this.props.user}
                                    type="datasource"
                                    loading={this.state.isPermissionLoading}
                                    deleteShare={this.props.deleteSourceShare}
                                    updateUser={this.props.updateUser}
                                    updateGovernance={this.updateGovernance}
                                    hideplatform
                                    showComment={Boolean(this.props.datasourceCatalogInfo && this.props.datasourceCatalogInfo.comment && this.props.datasourceCatalogInfo.comment.length !== 0)}
                                    comment={this.props.datasourceCatalogInfo && this.props.datasourceCatalogInfo.comment ? this.props.datasourceCatalogInfo.comment : ""}
                                    isGovernanceUpdate={this.state.isGovernanceUpdate}
                                    updateGovernanceUpdate={this.updateGovernanceUpdate} />
                            </Grid>
                            {
                                (this.props.config && this.props.config.collaboration) &&
                                <Grid item xs={12}>
                                    <Grid className={classes.card}>
                                        <Comments type="datasource" updateAuditLog={this.updateAuditLog} datasourceId={this.props.match.params.id} />
                                    </Grid>
                                </Grid>
                            }
                            {
                                this.state.tabFields && this.state.tabFields.length > 0 &&
                                <Grid item xs={12} style={{ marginBottom: 50 }}>

                                    <DynamicElementByDataType
                                        fields={[...this.state.tabFields.filter((tab) => tab.tab_name === 'Overview')]}
                                        properties={this.state.fieldProperties}
                                        dyanamicEleOnChange={this.dyanamicEleOnChange}
                                        level={appConstants.fieldKeys.datasource}
                                        datasource={parseInt(this.props.match.params.id)}
                                    />
                                </Grid>

                            }
                            {
                                this.state.customFieldLoading &&
                                <Loader />
                            }

                        </Grid>
                    </TabPanel>
                    {
                        (this.state.tabIndex === this.getTabIndex('Info')) &&
                        <TabPanel value={this.state.tabIndex} index={this.getTabIndex("Info")}>
                            <CatalogInfo datasourceId={this.props.match.params.id} type="datasource" />
                        </TabPanel>
                    }

                    {
                        this.state.dataSourceTabs.indexOf(appConstants.fieldKeys.tabName) > -1 &&
                        <TabPanel value={this.state.tabIndex} index={this.getTabIndex('Details')} className={classes.test}>
                            {
                                this.state.tabIndex === this.getTabIndex('Details') &&
                                <DynamicElementByDataType
                                    fields={this.state.fields}
                                    properties={this.state.fieldProperties}
                                    dyanamicEleOnChange={this.dyanamicEleOnChange}
                                    level={appConstants.fieldKeys.datasource}
                                    datasource={parseInt(this.props.match.params.id)}
                                />
                            }
                        </TabPanel>
                    }
                    <TabPanel value={this.state.tabIndex} index={this.getTabIndex('Datasets')} className={classes.test}>
                        {
                            (this.state.tabIndex === this.getTabIndex('Datasets')) && <DatasetTab
                                history={history}
                                datasourceId={this.props.match.params.id}
                                datasource={this.state.datasourceDetail}
                                isEditable={this.state.isStewardUser}
                                getDatasetInfo={this.props.getDatasetInfo}
                                connectionStatus={connectionStatus}
                                // eslint-disable-next-line react/jsx-handler-names
                                handleClick={this.handleClick}
                                datasetInfo={this.props.datasetInfo} />
                        }
                    </TabPanel>
                    <TabPanel value={this.state.tabIndex} index={this.getTabIndex('Metrics')}>
                        {
                            (this.state.tabIndex === this.getTabIndex('Metrics')) &&
                            <DatasourceMetrics
                                datasourceId={this.props.match.params.id}
                                datasetInfo={this.props.datasetInfo}
                                dq_score_threshold={this.state.datasourceDetail.dq_score_threshold}
                                datasourceName={this.state.datasourceDetail.name} />
                        }
                    </TabPanel>
                    <TabPanel value={this.state.tabIndex} index={this.getTabIndex('Comments')}>
                        {
                            (this.state.tabIndex === this.getTabIndex('Comments')) &&
                            <Comments type="datasource" updateAuditLog={this.updateAuditLog} datasourceId={this.props.match.params.id} />
                        }
                    </TabPanel>
                    <TabPanel value={this.state.tabIndex} index={this.getTabIndex('Execution Logs')}>
                        {
                            (this.state.tabIndex === this.getTabIndex('Execution Logs')) &&
                            <ExecutionLogs datasourceId={
                                this.state.datasourceDetail && this.state.datasourceDetail.id
                                    ? this.state.datasourceDetail.id : 0
                            }
                                datasetId={0}
                                datasource={this.state.datasourceDetail}
                                logType={'datasource'}
                                tabIndex={this.state.tabIndex}
                                history={history}
                                isEditable={(this.state.isStewardUser || (this.props.user.role && this.props.user.role.properties_permission && this.props.user.role.properties_permission.is_edit)) && connectionStatus} />
                        }
                    </TabPanel>
                    {
                        this.state.customTabls.map((field, index) =>
                            <TabPanel key={index} value={this.state.tabIndex} index={this.getTabIndex(field)} className={classes.test}>
                                {
                                    this.state.tabIndex === this.getTabIndex(field) &&
                                    <DynamicElementByDataType
                                        fields={[...this.state.tabFields.filter((tab) => tab.tab_name === field)]}
                                        properties={this.state.fieldProperties}
                                        dyanamicEleOnChange={this.dyanamicEleOnChange}
                                        level={appConstants.fieldKeys.datasource}
                                        datasource={parseInt(this.props.match.params.id)}
                                    />
                                }
                            </TabPanel>
                        )
                    }

                    {(this.state.isPermissionLoading && this.state.isDataLoading) && <Loader />}
                    <TabPanel value={this.state.tabIndex} index={this.getTabIndex('Lineage')}>
                        {(this.state.tabIndex === this.getTabIndex('Lineage') && <LineagePannel datasource_id={this.props?.match?.params?.id} />)}
                    </TabPanel>
                    <TabPanel value={this.state.tabIndex} index={this.getTabIndex('Configuration')}>
                        {(this.state.tabIndex === this.getTabIndex('Configuration') && <DatasourceConfiguration configuration={this.state?.datasourceDetail?.properties} onSubmit={(name, value) => this.onChangeSourceDetail(name, value)} />)}
                    </TabPanel>
                </Grid>
                <AlertDialog title="Delete Datasource"
                    message={`Are you sure you want to delete the datasource ${this.state.datasourceDetail ? this.state.datasourceDetail.name : ''}?`}
                    okButtonText="OK"
                    cancelButtonText="Cancel"
                    show={this.state.isDeleteDatasource}
                    onClickOK={() => this.deleteDatasourcetInfo()}
                    onClickCancel={() => this.setState({ isDeleteDatasource: false })} />
            </ValidatorForm>
        );
    }
}


DatasourceOverview.propTypes = {
    classes: PropTypes.object,
    match: PropTypes.object,
    getOrganizationUser: PropTypes.func,
    getDataSource: PropTypes.func,
    createAuditLog: PropTypes.func,
    updateDataSource: PropTypes.func,
    users: PropTypes.array,
    getSourceAuditLogs: PropTypes.func,
    history: PropTypes.object,
    createSourceShare: PropTypes.func,
    getSourceShare: PropTypes.func,
    inviteSourceUser: PropTypes.func,
    user: PropTypes.object,
    getDatasetInfo: PropTypes.func,
    datasetInfo: PropTypes.object,
    deleteDatasource: PropTypes.func,
    getFavorites: PropTypes.func,
    updateFavorites: PropTypes.func,
    updateCertified: PropTypes.func,
    deleteSourceShare: PropTypes.func,
    updateUser: PropTypes.func,
    location: PropTypes.object,
    setSelectedDatasourceId: PropTypes.func,
    getActionItemCount: PropTypes.func,
    dispatch: PropTypes.func,
    getConversationCount: PropTypes.func,
    getDomain: PropTypes.func,
    createDomain: PropTypes.func,
    getExportReport: PropTypes.func,
    getImportReport: PropTypes.func,
    getLineageEntry: PropTypes.func,
    getCustomFields: PropTypes.func,
    getFieldPropertyByLevel: PropTypes.func,
    createFieldProperty: PropTypes.func,
    datasource: PropTypes.object,
    config: PropTypes.object,
    getDatasourceCatalog: PropTypes.func,
    clearCatalogInfo: PropTypes.func,
    datasourceCatalogInfo: PropTypes.object,
    getCustomTabs: PropTypes.func,
    getExportMetadata: PropTypes.func,
    getDatasetByDatasource: PropTypes.func,
    getAttributeByDatasource: PropTypes.func,
    connectionTypes: PropTypes.array,
    licenseConfig: PropTypes.object,
    domainProperties: PropTypes.object,
    getProperties: PropTypes.object,
    getPropertiesOrganization: PropTypes.object,
    systemConfiguration: PropTypes.bool,
    getCertified: PropTypes.func,
    updateTabIndex: PropTypes.func,
    getPageTitle: PropTypes.func,
    dq_score_threshold: PropTypes.object,
    user_config: PropTypes.object,
    profileRulesConfig: PropTypes.object
};

/**
 * Map State To Props
 * @description Get Reducer Props value to Props property
 * @param {object} datasource,account
 */
const mapStateToProps = ({ datasource, dataset, account, setting }) => {
    return {
        ...datasource,
        ...dataset,
        ...account,
        ...setting
    };
};

/**
 * Dispatch Props
 * @description dispatch Props actions
 * @param {object} dispatch
 */
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        dispatch,
        getOrganizationUser, getDataSource, updateDataSource, deleteDatasource,
        createAuditLog, getSourceAuditLogs, createSourceShare, getSourceShare, inviteSourceUser,
        getDatasetInfo, getFavorites, updateFavorites, deleteSourceShare, updateUser, setSelectedDatasourceId,
        getActionItemCount, getConversationCount, getDomain, createDomain, getFieldPropertyByLevel, createFieldProperty,
        getExportReport, getImportReport, getDatasourceCatalog, clearCatalogInfo, getDatasetByDatasource, getAttributeByDatasource,
        getLineageEntry, getCustomTabs, exportPropertiesReport, getProperties, getPropertiesOrganization, getCustomFields, getExportMetadata, updateCertified, getCertified, updateTabIndex
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles((theme) => ({
    ...DatasourceOverviewStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DatasourceOverview));