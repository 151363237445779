import React, { Component } from 'react';
import {
    withStyles, Dialog, DialogTitle, DialogContent, Grid, Typography, IconButton, Slide,
    Table, TableHead, TableBody, TableRow, TableCell, MenuItem, CircularProgress, Button,
    Switch, Card, TextField
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Close } from '@material-ui/icons';
import { ValidatorForm } from 'react-material-ui-form-validator';
import classNames from 'classnames';
import Styles from '../../layouts/Styles.jsx';
import { appConstants } from '../../constants/appConstants';
import QueryFilterModelStyles from './QueryFilterModelStyles.jsx';
import TextBox from '../TextBox/TextBox.jsx';
import QueryBuilder from '../RuleBuilder/QueryBuilder.jsx';
import { getQueryString, createRuleGroup, getSelectedAttributes, getOperators, createRule, getConditionOperators, getAttributeNames, functionQueryString, removeFormatting, getTableNames, isRuleGroup } from '../RuleBuilder/QueryBuilderUtil.jsx';
import { getFieldType, dataAccessRestrict, sortTable } from '../../helpers/appHelpers';
import config from '../RuleBuilder/QueryBuilderConfig.jsx';
import ReactQueryEditor from '../ReactQueryEditor/ReactQueryEditor.jsx';
import ProfileChartFilterOperators from '../../constants/chartOperators.js';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import AutoCompleteInput from '../AutoComplete/AutoCompleteInput.jsx';

const Transition = React.forwardRef(Object.assign((props, ref) => <Slide direction="up" ref={ref} {...props} />), { displayName: 'Transition' });

class QueryFilterModel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchKey: '',
            filterType: appConstants.QueryFilterTypes[1],
            filterTypeOptions: [...appConstants.QueryFilterTypes],
            fields: [],
            showQueryBuilder: false,
            showFilterTypeOptions: true,
            ruleGroup: {
                ...createRuleGroup({
                    defaultField: props.selectedAttribute.name,
                    defaultFieldType: getFieldType(props.selectedAttribute.type),
                    attributeType: props.selectedAttribute.type,
                    attributeFieldType: props.selectedAttribute.fieldType,
                    connectionType: props.selectedDatasource?.type,
                    isScan: props.selectedDatasource?.scan
                })
            },
            conditionalRuleGroup: {
                ...createRuleGroup({
                    connectionType: props.selectedDatasource?.type,
                    isScan: props.selectedDatasource?.scan
                })
            },
            isComplexRule: false,
            isLoading: false,
            previewData: {},
            inputData: [],
            headers: [],
            isQueryFilter: false,
            invalidQuery: "",
            isDefaultQuery: true,
            selectedReferenceDataset: {},
            lookup_attribute: '',
            is_lookup_rule: false
        };
    }

    prepareCustomRule(attributeRule, fieldType) {
        const { selectedAttribute, selectedDatasource } = this.props;
        const operators = getOperators(fieldType);
        const conditionalOperators = getConditionOperators();
        const ruleParams = {
            defaultField: selectedAttribute.name,
            defaultFieldType: fieldType,
            attributeType: selectedAttribute.datatype,
            connectionType: selectedDatasource?.type,
            isScan: selectedDatasource?.scan
        };
        const customAttributeRule = createRule(ruleParams);
        const operator = operators.find((p) => p.label.toLowerCase() === (typeof (attributeRule.operator) === "object" ? attributeRule.operator.label.toLowerCase() : attributeRule.operator.toLowerCase()));
        if (operator) {
            customAttributeRule.operator = operator;
        }
        customAttributeRule.value = attributeRule.value;
        if (attributeRule.condition) {
            const conditionOperator = conditionalOperators.find((p) => p.label.toLowerCase() === attributeRule.condition.toLowerCase());
            if (conditionOperator) {
                customAttributeRule.conditionOperator = conditionOperator;
            }
            customAttributeRule.count = attributeRule.count;
        }
        if (attributeRule.conditionOperator) {
            customAttributeRule.conditionOperator = attributeRule.conditionOperator;
            customAttributeRule.count = attributeRule.count;
        }
        return customAttributeRule;
    }

    prepareCustomRuleGroup(attributeRule, fieldType) {
        const processedRules = [];
        if (attributeRule.rules) {
            for (const rule of attributeRule.rules) {
                const ruleInfo = rule.connector ? this.prepareCustomRuleGroup(rule, fieldType) : this.prepareCustomRule(rule, fieldType);
                processedRules.push({ ...ruleInfo });
            }
        }
        attributeRule.rules = [...processedRules];
        return attributeRule;
    }

    prepareRule(customRule) {
        if (customRule.ruleGroup && typeof (customRule.ruleGroup) === 'object' && Object.keys(customRule.ruleGroup).length > 0) {
            return customRule;
        }
        const rule = customRule.input_params[0];
        const fieldType = getFieldType(this.props.selectedAttribute.datatype);
        const { selectedAttribute } = this.props;

        const ruleGroup = createRuleGroup({
            defaultField: selectedAttribute.name,
            defaultFieldType: fieldType,
            attributeType: selectedAttribute.datatype,
            connectionType: this.props.selectedDatasource?.type,
            isScan: this.props.selectedDatasource?.scan
        });
        if (rule && rule.connector) {
            const connector = config.connectors.find((p) => p.name.toLowerCase() === rule.connector.toLowerCase());
            if (connector) {
                ruleGroup.connector = connector.value;
            }
        }

        const rules = rule && rule.rules ? rule.rules : [];
        const processedRules = [];
        for (const attributeRule of rules) {
            const ruleInfo = !attributeRule.connector ? this.prepareCustomRule(attributeRule, fieldType) : this.prepareCustomRuleGroup(attributeRule, fieldType);
            processedRules.push({ ...ruleInfo });
        }
        ruleGroup.rules = [...processedRules];
        customRule.ruleGroup = { ...ruleGroup };
        customRule.query = getQueryString(ruleGroup);
        return customRule;
    }

    onOpen() {
        this.setState({
            showQueryBuilder: Boolean(this.props.inputParams && this.props.inputParams.filterType),
            showFilterTypeOptions: Boolean(this.props.inputParams && this.props.inputParams.filterOptions),
            isQueryFilter: false,
            invalidQuery: "",
            ruleGroup: {
                ...createRuleGroup({
                    defaultField: this.props.selectedAttribute.name,
                    defaultFieldType: getFieldType(this.props.selectedAttribute.type),
                    attributeType: this.props.selectedAttribute.type,
                    attributeFieldType: this.props.selectedAttribute.fieldType,
                    connectionType: this.props.selectedDatasource?.type,
                    isScan: this.props.selectedDatasource?.scan
                }),
                'is_sql_editor': Boolean(this.props.inputParams?.data?.value?.is_sql_editor ?? false)
            },
            conditionalRuleGroup: {
                ...createRuleGroup({
                    connectionType: this.props.selectedDatasource?.type,
                    isScan: this.props.selectedDatasource?.scan
                })
            },
            previewData: {},
            inputData: []
        });
        this.prepareFields();
        let isQueryFilter = false;
        let is_lookup_rule = false;
        let invalidQuery = "";
        let selectedReferenceDataset = {};
        let lookup_attribute = '';

        if (this.props.inputParams && this.props.inputParams.isCustomRule) {
            let rule = this.props.inputParams.data && this.props.inputParams.data.value ? this.props.inputParams.data.value : null;
            if (this.props.inputParams.data && this.props.inputParams.data.filterType === "Invalid") {
                invalidQuery = rule.invalidQuery;
            }
            if (rule && !rule.is_user_defined && !rule.ruleGroup) {
                rule = this.prepareRule(rule);
            }

            let ruleGroup = null;
            let conditionalRuleGroup = null;
            let isComplexRule = false;
            if (rule) {
                isQueryFilter = rule.isQueryFilter;
                is_lookup_rule = rule.is_lookup_rule;

                ruleGroup = rule.ruleGroup ? { ...JSON.parse(JSON.stringify(rule.ruleGroup)) } : createRuleGroup({
                    connectionType: this.props.selectedDatasource?.type,
                    isScan: this.props.selectedDatasource?.scan
                });
                ruleGroup.is_sql_editor = Boolean(this.props.inputParams?.data?.value?.is_sql_editor ?? false);
                if (rule.isQueryFilter) {
                    ruleGroup.query = removeFormatting(rule.rule_query);
                }
                if (ruleGroup.query && ruleGroup.query.startsWith("select")) {
                    ruleGroup['is_sql_editor'] = true;
                }
                if (!rule.is_complex_rule) {
                    ruleGroup.not = rule.is_user_defined ? !this.props.inputParams.rule.not : this.props.inputParams.rule.not;
                    if (this.props.inputParams && this.props.inputParams.filterType) {
                        const notEnabled = Boolean(ruleGroup.not_enabled);
                        ruleGroup.not = this.props.inputParams.filterType.toLowerCase() === 'valid' ? notEnabled : !notEnabled;
                    }
                }

                conditionalRuleGroup = rule.conditionalRuleGroup ? { ...JSON.parse(JSON.stringify(rule.conditionalRuleGroup)), not: this.props.inputParams.rule.not } : createRuleGroup({
                    connectionType: this.props.selectedDatasource?.type,
                    isScan: this.props.selectedDatasource?.scan
                });
                if (this.props.inputParams && this.props.inputParams.filterType) {
                    conditionalRuleGroup.not = this.props.inputParams.filterType.toLowerCase() === 'valid' ? conditionalRuleGroup.not_enabled : !conditionalRuleGroup.not_enabled;
                }
                isComplexRule = rule.is_complex_rule ? rule.is_complex_rule : false;
                if (is_lookup_rule) {
                    selectedReferenceDataset = this.props.references.filter((elem) => elem?.file_url === rule?.reference_dataset?.file_url)[0] || {};
                    lookup_attribute = rule?.lookup_attribute || '';
                }
            }
            ruleGroup = this.validateRules(ruleGroup);
            this.setState({
                ruleGroup: { ...ruleGroup },
                conditionalRuleGroup: { ...conditionalRuleGroup },
                isComplexRule,
                filterType: this.props.inputParams.filterType,
                isQueryFilter: isQueryFilter,
                is_lookup_rule: is_lookup_rule,
                selectedReferenceDataset: selectedReferenceDataset,
                lookup_attribute: lookup_attribute,
                invalidQuery
            }, () => {
                this.showPreviewData();
            });
            return;
        }

        if (this.props.inputParams?.ruleName === 'Pattern' && this.props.inputParams?.multi && this.props.inputParams?.rule?.ruleGroup) {
            let ruleGroup = JSON.parse(JSON.stringify(this.props.inputParams.rule.ruleGroup));
            ruleGroup = this.validateRules(ruleGroup);
            this.setState({ ruleGroup, filterType: this.props.inputParams.filterType }, () => {
                this.showPreviewData();
            });
            return;
        }

        if (this.props.inputParams && !this.props.inputParams.isCustomRule
            && this.props.inputParams.rule && this.state.ruleGroup) {
            let ruleGroup = this.state.ruleGroup;
            ruleGroup.rules = [{ ...this.props.inputParams.rule }];
            ruleGroup = this.validateRules(ruleGroup);
            this.setState({ ruleGroup, filterType: this.props.inputParams.filterType }, () => {
                this.showPreviewData();
            });
            return;
        }

        this.showPreviewData();
    }

    prepareFields() {
        const fields = [];
        const { attributes, userAttributes } = this.props;
        let isComplexRule = false;
        const rule = this.props.inputParams && this.props.inputParams.data && this.props.inputParams.data.value ? this.props.inputParams.data.value : null;
        if (rule) {
            isComplexRule = rule.is_complex_rule ? rule.is_complex_rule : false;
        }
        if (isComplexRule) {
            for (const attribute of userAttributes) {
                fields.push({
                    ...attribute,
                    type: attribute.datatype ? attribute.datatype : 'Text',
                    attributeSchema: attribute.attribute_schema ? attribute.attribute_schema : '',
                    name: attribute.attribute_schema ? attribute.attribute_schema : ''
                });
            }
        } else {
            for (const attribute of attributes) {
                fields.push({
                    name: attribute.name,
                    type: attribute.type,
                    attributeFieldType: attribute.fieldType,
                    attributeSchema: attribute.name
                });
            }
        }
        this.setState({ fields });
    }


    prepareCurateField(columns) {
        const { fields } = this.state;
        const attributes = fields.map((field) => field.name);
        const curatedFieldIndex = [];
        columns.forEach((column, index) => {
            if (!attributes.includes(column)) {
                fields.push({
                    name: column,
                    type: "Text",
                    attributeFieldType: "Text",
                    attributeSchema: column
                });
                curatedFieldIndex.push(index);
            }
        });
        this.setState({ fields });
        return curatedFieldIndex;
    }

    changeFilterType(filterType) {
        const inputFilterType = this.props.inputParams.filterType;
        const ruleGroup = { ...this.state.ruleGroup };
        ruleGroup.not = (inputFilterType !== filterType);
        this.setState({ filterType, ruleGroup: { ...ruleGroup } }, () => {
            this.showPreviewData();
        });
    }

    onSearchKeyChange(key) {
        this.setState({ searchKey: key }, () => {
            const { previewData, searchKey } = this.state;
            if (this.state.searchKey.length > 0) {
                const headers = previewData.headers.filter((header) => header.name.toString().toLowerCase().includes(searchKey.toLowerCase()));
                this.setState({ headers });
                return;
            }
            const headerList = previewData.headers ? previewData.headers : [];
            this.setState({ headers: [...headerList] });
        });
    }

    getRuleType = () => {
        let ruleType = '';
        const chartType = this.props.inputParams.chartType.toLowerCase();
        if (chartType === 'length') {
            ruleType = 'length';
        }
        else if (chartType === 'soundex frequency') {
            ruleType = 'soundex';
        }
        else if (chartType === 'count') {
            if (this.props.inputParams && this.props.inputParams.data && this.props.inputParams.data.value
                && this.props.inputParams.data.value.name) {
                const ruleName = this.props.inputParams.data.value.name.toLowerCase().replace(' count', '');
                const filterType = this.props.inputParams.data.filterType.toLowerCase();
                switch (ruleName) {
                    case 'distinct':
                        ruleType = (filterType === 'valid') ? 'distinct' : 'duplicate';
                        if (this.state.ruleGroup && this.state.ruleGroup.not) {
                            ruleType = (ruleType === 'distinct') ? 'duplicate' : 'distinct';
                        }
                        break;
                    case 'unique':
                        ruleType = (filterType === 'valid') ? 'unique' : 'not unique';
                        if (this.state.ruleGroup && this.state.ruleGroup.not) {
                            ruleType = (ruleType === 'unique') ? 'not unique' : 'unique';
                        }
                        break;
                    case 'duplicate':
                        ruleType = (filterType === 'valid') ? 'duplicate' : 'distinct';
                        if (this.state.ruleGroup && this.state.ruleGroup.not) {
                            ruleType = (ruleType === 'distinct') ? 'duplicate' : 'distinct';
                        }
                        break;
                    default:
                        ruleType = '';
                        break;
                }
            }
        }
        return ruleType;
    };

    validateRules = (ruleGroup) => {
        const hasNot = ruleGroup?.not ?? false;
        ruleGroup.connectionType = this.props.selectedDatasource?.type?.toLowerCase() ?? '';
        ruleGroup.isScan = this.props.selectedDatasource?.scan ?? '';
        ruleGroup.rules.map((rule) => {
            if (isRuleGroup(rule)) {
                return this.validateRules(rule);
            }
            if (!rule.field) {
                rule.field = this.props.selectedAttribute?.name ?? '';
                rule.fieldType = getFieldType(this.props.selectedAttribute?.type ?? '');
                rule.attributeType = this.props.selectedAttribute?.type ?? '';
                rule.attributeFieldType = this.props.selectedAttribute?.fieldType ?? '';
            }
            rule.not = hasNot;
            rule.connectionType = this.props.selectedDatasource?.type?.toLowerCase() ?? '';
            rule.isScan = this.props.selectedDatasource?.scan ?? '';
            return rule;
        });
        return ruleGroup;
    }

    prepareQueryString = () => {
        let ruleGroup = { ...this.state.ruleGroup };
        ruleGroup = this.validateRules(ruleGroup);

        let conditionalRuleGroup = { ...this.state.conditionalRuleGroup };
        conditionalRuleGroup = this.validateRules(conditionalRuleGroup);
        let ruleGroupAttributes = getSelectedAttributes(ruleGroup);
        if (!this.state.isQueryFilter) {
            const conditionalRuleGroupAttributes = getSelectedAttributes(conditionalRuleGroup);
            ruleGroupAttributes = [...ruleGroupAttributes, ...conditionalRuleGroupAttributes];
            if (this.props.selectedAttribute?.name) {
                ruleGroupAttributes.push(this.props.selectedAttribute.name);
            }
        } else {
            ruleGroupAttributes = getAttributeNames(this.state.fields, ruleGroup.query);
        }
        ruleGroupAttributes = [...new Set(ruleGroupAttributes)];

        const attributes = [];
        const selectedDatasources = [];
        for (const attribute of ruleGroupAttributes) {
            const field = this.state.fields.find((p) => p.name.toLowerCase() === attribute.toLowerCase());
            if (!field) {
                continue;
            }
            const schema = field.attribute_schema ? field.attribute_schema : '';
            attributes.push({
                name: field.name,
                datatype: field.type,
                schema
            });

            if (this.state.isComplexRule) {
                const hasDatasource = selectedDatasources.find((p) => p.dataset_id === field.dataset_id);
                if (!hasDatasource) {
                    selectedDatasources.push({ ...field });
                }
            }
        }
        if (attributes.length === 0) {
            const tables = getTableNames(this.state.fields, ruleGroup.query);
            for (const table of tables) {
                const field = this.state.fields.find((p) => p.dataset_schema.toLowerCase() === table.toLowerCase());
                if (!field) {
                    continue;
                }
                const hasDatasource = selectedDatasources.find((p) => p.dataset_id === field.dataset_id);
                if (!hasDatasource) {
                    selectedDatasources.push({ ...field });
                }
            }
        }
        let query = this.state.isQueryFilter || ruleGroup.is_sql_editor ? functionQueryString(ruleGroup.query, attributes) : getQueryString(ruleGroup);

        if (this.state.invalidQuery && this.state.invalidQuery !== "") {
            query = this.state.invalidQuery;
        }

        const conditionalQuery = (ruleGroup?.isComplexRule) ? getQueryString(conditionalRuleGroup, true) : "";
        if (query && conditionalQuery) {
            if (selectedDatasources && this.props.selectedDataset) {
                const joinedDatasets = [...new Set(selectedDatasources.filter((p) => p.dataset_name !== this.props.selectedDataset.name).map((p) => p.dataset_schema))];
                for (const dataset_schema of joinedDatasets) {
                    query = query.replace('<table_name>', dataset_schema, 1);
                }
            }
            const queryString = `select <table_name>.* from <table_name> ${query} where ${conditionalQuery}`;
            query = queryString;
        } else if (this.state.isQueryFilter && (ruleGroup && !ruleGroup.is_sql_editor)) {
            query = `select * from <table_name> where ${query}`;
        }
        if (this.props.inputParams.chartType === 'customrule' && this.props.inputParams.filterType.toLowerCase() === 'invalid' && ruleGroup.is_sql_editor) {
            query = this.state.invalidQuery;
        }
        const selectedAttributes = [...attributes];
        let datasources = selectedDatasources && selectedDatasources.length > 0 ? [...selectedDatasources] : [];
        if (this.props.inputParams?.data?.value?.datasources) {
            datasources = [...this.props.inputParams.data.value.datasources];
        }
        return {
            query,
            conditionalQuery,
            selectedAttributes,
            datasources,
            chartType: this.props.inputParams.chartType,
            "is_sql": ruleGroup.is_sql_editor
        };
    };

    showPreviewData() {
        const queryInput = this.prepareQueryString();
        /*
         * if (!this.state.showQueryBuilder) {
         *     queryInput.query = '';
         * }
         */
        if (!queryInput.query.length && (this.props.inputParams.chartType === "Curate" || this.props.inputParams.chartType === "Enrich")) {
            queryInput.query = "select * from <table_name>";
        }
        if (this.props.inputParams && this.props.inputParams.defaultQuery && !this.props.inputParams.filterOptions) {
            queryInput.query = this.props.inputParams.defaultQuery;
        }
        if (this.props.inputParams && this.props.inputParams.chartType === "validvaluescheck") {
            queryInput.query = queryInput.query.replace('nan', '');
            if (!this.state.isDefaultQuery) {
                if (this.state.filterType === this.state.filterTypeOptions[2]) {
                    queryInput.query = "";
                } else {
                    queryInput.query = this.props.inputParams.filterType !== this.state.filterType ? queryInput.query.replace(/==/g, "!=") : queryInput.query;
                }
            } else {
                this.setState({ isDefaultQuery: false });
            }

            /*
             * if (this.state.filterType === appConstants.QueryFilterTypes[1]) {
             *     queryInput.query = !this.props.inputParams.invalidData ? queryInput.query.replace(/==/g, "!=") : queryInput.query;
             * }
             */
        }
        if (this.props.inputParams.chartType === "Pattern") { queryInput.query = queryInput.query.replace('[\n]{1}', ''); }

        const selectedAttributes = [];
        for (const attribute of this.props.attributes) {
            selectedAttributes.push({
                name: attribute.name,
                datatype: attribute.type,
                fieldType: attribute.fieldType
            });
        }
        const queryParams = {
            attribute: this.props.selectedAttribute.name,
            'is_complex_rule': this.state.isComplexRule,
            "rule_type": this.getRuleType(),
            "rule_name": this.props.inputParams.ruleName,
            "data_type": this.props.inputParams.datatype,
            "query_string": queryInput.query,
            "filter_type": this.state.filterType.toLowerCase(),
            "is_query_filter": true,
            is_lookup_rule: this.state.is_lookup_rule,
            reference_dataset: this.state.selectedReferenceDataset,
            lookup_attribute: this.state.lookup_attribute,
            ...queryInput
        };
        this.setState({ isLoading: true });
        this.props.getPreviewData({
            "dataset_id": this.props.datasetId,
            "method": "userquery",
            "schedule_log_id": this.props.scheduleId,
            "query_params": queryParams
        }).then((response) => {
            this.setState({ isLoading: false });
            if (response) {
                const { properties, selectedAttribute, inputParams } = this.props;
                let headers = [];
                let columns = [...response.columns];
                let curatedFieldIndex = [];
                if (inputParams && inputParams.ruleType === "curate") {
                    curatedFieldIndex = this.prepareCurateField(columns);
                }
                if (inputParams && inputParams.rule && inputParams.rule.isComplexRule && !this.state.ruleGroup.is_sql_editor) {
                    columns = [...Object.keys(properties)];
                }
                for (const column of columns) {
                    if (column === 'temp_row_index') {
                        continue;
                    }
                    let sensitivity = 1;
                    if (column in properties) {
                        const property = properties[column];
                        sensitivity = property.sensitivity;
                    }
                    headers.push({
                        name: column,
                        isSelectedColumn: (column === selectedAttribute.name),
                        sensitivity
                    });
                }
                const selectedHeader = headers.find((p) => p.isSelectedColumn);
                const selectedHeaderIndex = headers.indexOf(selectedHeader);
                if (selectedHeaderIndex >= 0) {
                    headers.splice(selectedHeaderIndex, 1);
                    headers.splice(0, 0, { ...selectedHeader });
                }
                const inputData = response.result ? [...response.result] : [];
                if (inputParams?.chartType === "Curate") {
                    headers = headers.filter((header, index) => !curatedFieldIndex.includes(index));
                    const curatedFields = headers.filter((header, index) => curatedFieldIndex.includes(index)).map((d) => d.name);
                    inputData.map((row) => {
                        const modifiedRow = row;
                        curatedFields.forEach((fieldName) => {
                            delete modifiedRow[fieldName];
                        });
                        return modifiedRow;
                    });
                }
                this.setState({
                    previewData: { ...response, headers: [...headers] },
                    headers,
                    inputData
                });
            }
        });
    }

    onClear() {
        const { selectedAttribute } = this.props;
        this.setState({
            ruleGroup: {
                ...createRuleGroup({
                    defaultField: selectedAttribute.name,
                    defaultFieldType: getFieldType(selectedAttribute.type),
                    attributeType: selectedAttribute.type,
                    attributeFieldType: selectedAttribute.fieldType,
                    connectionType: this.props.selectedDatasource?.type,
                    isScan: this.props.selectedDatasource?.scan
                })
            },
            conditionalRuleGroup: {
                ...createRuleGroup({
                    connectionType: this.props.selectedDatasource?.type,
                    isScan: this.props.selectedDatasource?.scan
                })
            }
        }, () => {
            this.showPreviewData();
        });
    }

    closeModal() {
        const { selectedAttribute } = this.props;
        this.setState({
            searchKey: '',
            filterType: appConstants.QueryFilterTypes[1],
            ruleGroup: {
                ...createRuleGroup({
                    defaultField: selectedAttribute.name,
                    defaultFieldType: getFieldType(selectedAttribute.type),
                    attributeType: selectedAttribute.type,
                    attributeFieldType: selectedAttribute.fieldType,
                    connectionType: this.props.selectedDatasource?.type,
                    isScan: this.props.selectedDatasource?.scan
                })
            },
            conditionalRuleGroup: {
                ...createRuleGroup({
                    connectionType: this.props.selectedDatasource?.type,
                    isScan: this.props.selectedDatasource?.scan
                })
            },
            isComplexRule: false
        });
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    updateQueryString(ruleGroup, isConditionalRule) {
        if (isConditionalRule) {
            this.setState({ conditionalRuleGroup: { ...ruleGroup } });
        } else {
            this.setState({ ruleGroup: { ...ruleGroup } });
        }
    }

    getChartOperators(chartType) {
        let operators = [];
        if (!chartType) { return operators; }
        const chartOperators = ProfileChartFilterOperators[chartType];
        operators = chartOperators && chartOperators.length > 0 ? [...chartOperators] : [];
        return operators;
    }

    updateQuery(property, value) {
        this.setState({
            ruleGroup: {
                ...this.state.ruleGroup,
                [property]: value
            },
            invalidQuery: ""
        });
    }

    getBuilder() {
        const { classes, inputParams, selectedAttribute, references } = this.props;
        const { fields, isLoading, isQueryFilter, is_lookup_rule, selectedReferenceDataset, lookup_attribute } = this.state;
        if (isQueryFilter) {
            return (<ReactQueryEditor
                fields={fields}
                showSubmitButton
                rule={this.state.ruleGroup ? this.state.ruleGroup : {}}
                isInvalidQuery={this.state.ruleGroup.isInvalidQuery}
                isShowValidate={false}
                onChange={(property, value) => this.updateQuery(property, value)}
                onSubmit={() => this.showPreviewData()}
                onClear={() => this.onClear()}
            />);
        } else if (is_lookup_rule) {
            return (
                <Grid container spacing={2} style={{ marginTop: 12 }}>
                    <Grid item xs={12}>
                        <AutoCompleteInput
                            name="reference_dataset"
                            options={sortTable(references ? references : [], "asc", "name")}
                            value={selectedReferenceDataset ? selectedReferenceDataset : {}}
                            getOptionLabel={(option) => (option && option.name ? `${option.name}` : '')}
                            renderInput={
                                (params) =>
                                    <TextField
                                        {...params}
                                        label="Reference Dataset"
                                        fullWidth
                                    />
                            }
                            onChange={
                                (_, newValue) => {
                                    this.updateQuery('reference_dataset', {
                                        file_url: newValue.file_url,
                                        file_type: newValue.file_type,
                                        storage_method: newValue.storage_method,
                                        name: newValue.name,
                                        id: newValue.id
                                    });
                                    this.setState({ selectedReferenceDataset: newValue });
                                }
                            }
                            onInputChange={(_, value) => { }}
                            onOpen={() => { }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AutoCompleteInput
                            name="lookup_attribute"
                            options={sortTable(selectedReferenceDataset && selectedReferenceDataset.attributes ? selectedReferenceDataset.attributes : [], "asc", "name")}
                            value={lookup_attribute ? { attribute: lookup_attribute } : {}}
                            getOptionLabel={(option) => (option && option.attribute ? option.attribute : '')}
                            renderInput={
                                (params) =>
                                    <TextField
                                        {...params}
                                        label="Lookup Attribute"
                                        fullWidth
                                    />
                            }
                            onChange={(_, newValue) => { this.setState({ lookup_attribute: newValue.attribute }); }}
                            onInputChange={(_, value) => { }}
                            onOpen={() => { }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction="row" justify="flex-start">
                            <Grid item className={classes.btnContainer}>
                                <Button variant="contained"
                                    disabled={isLoading}
                                    color="primary"
                                    className={classNames(classes.actionButton)}
                                    onClick={() => { this.showPreviewData(); }}
                                >
                                    {'Submit'}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button className={classNames(classes.cancelButton, classes.actionButton)}
                                    disabled={isLoading}
                                    onClick={() => this.onClear()}>
                                    {'Clear All'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
        return (
            <QueryBuilder fields={fields}
                ruleGroup={this.state.ruleGroup}
                defaultField={selectedAttribute.name}
                defaultFieldType={selectedAttribute.type}
                attributeFieldType={selectedAttribute.fieldType}
                conditionalRuleGroup={this.state.conditionalRuleGroup}
                showSubmitButton
                selectedDatasource={this.props.selectedDatasource}
                isPatternChart={inputParams && inputParams.chart_name === 'Pattern'}
                onSubmit={() => this.showPreviewData()}
                onClear={() => this.onClear()}
                operators={this.getChartOperators(inputParams && inputParams.chartType ? inputParams.chartType : '')}
                isLoading={isLoading}
                chartType={this.props.inputParams && this.props.inputParams.chartType ? this.props.inputParams && this.props.inputParams.chartType : ''}
                onQueryChange={(ruleGroup, isConditionalRule) => this.updateQueryString(ruleGroup, isConditionalRule)}
                isComplexRule={this.state.isComplexRule} />
        );
    }

    render() {
        const { classes, open, inputParams, onClose, selectedAttribute, sensitivityLevel } = this.props;
        const { filterType, filterTypeOptions, searchKey, isLoading, headers, previewData, inputData } = this.state;

        return (
            <Dialog open={open}
                disableBackdropClick
                disableEscapeKeyDown
                TransitionComponent={Transition}
                className={classes.queryFilterDialog}
                onEnter={() => this.onOpen()}
                onExit={() => this.closeModal()}>
                <DialogTitle style={{ paddingBottom: 0 }}>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="h6" className={classNames(classes.marginBottom5, classes.QueryfilterTitle)}>
                                {'Query Filter'}
                                {inputParams && inputParams.chartName ? ` - ${inputParams.chartName}` : ''}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton className={classes.nopadding} onClick={() => onClose && onClose()}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent style={{ position: "relative" }} className={classes.scroll}>
                    <Grid container justify="space-between" alignItems="flex-end">
                        <Grid item className={classes.switchContainer}>
                            <Typography>
                                {'Add some queries to filter the content'}
                            </Typography>
                            <Switch checked={this.state.showQueryBuilder}
                                onChange={() => this.setState({ showQueryBuilder: !this.state.showQueryBuilder })}
                                color="secondary"
                                size="small" />
                        </Grid>

                        {
                            this.state.showFilterTypeOptions &&
                            <ValidatorForm onSubmit={() => { }}>
                                <TextBox
                                    value={filterType}
                                    onChange={(event) => this.changeFilterType(event.target.value)}
                                    name="filterTypeOption"
                                    select
                                    fullWidth>
                                    {
                                        filterTypeOptions.map((filterTypeOption, index) =>
                                            <MenuItem key={`filterTypeOption_${index}`} value={filterTypeOption}>
                                                {filterTypeOption}
                                            </MenuItem>
                                        )
                                    }
                                </TextBox>
                            </ValidatorForm>
                        }
                    </Grid>
                    {
                        this.state.showQueryBuilder &&
                        <Grid container className={classes.queryBuilder}>
                            {this.getBuilder()}
                        </Grid>
                    }
                    <Grid container>
                        <Grid container justify="space-between" alignItems="center" style={{ marginTop: 4 }}>
                            <Grid item>
                                <Grid container direction="row" style={{ margin: '3px 0' }}>
                                    <Grid item className={classNames(classes.textboxContainer, classes.includeSearchContainer)}>
                                        <TextBox placeholder={'Search Attributes'}
                                            onChange={(event) => this.onSearchKeyChange(event.target.value)}
                                            name="searchKey"
                                            id="searchKey"
                                            value={searchKey}
                                            fullWidth
                                            className={classNames(classes.inputOutline, classes.searchInput)} />
                                        {
                                            searchKey.length === 0 ?
                                                <IconButton className={classes.includeSearchIcon}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.094" height="13.092" viewBox="0 0 13.094 13.092">
                                                        <g transform="translate(0 -0.035)">
                                                            <path fill="#afb2b3" d="M5.27,10.57A5.257,5.257,0,0,0,8.5,9.46l3.483,3.483a.655.655,0,0,0,.926-.926L9.427,8.534A5.267,5.267,0,1,0,5.27,10.57ZM2.471,2.5a3.958,3.958,0,1,1,0,5.6h0a3.944,3.944,0,0,1-.02-5.577l.02-.02Z" transform="translate(0 0)" />
                                                        </g>
                                                    </svg>
                                                </IconButton>
                                                :
                                                <IconButton className={classes.includeDeleteIcon} onClick={() => this.onSearchKeyChange('')}>
                                                    <svg id="close-24px_4_" data-name="close-24px (4)" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                                        <path id="Path_1435" data-name="Path 1435" d="M0,0H24V24H0Z" fill="none" />
                                                        <path id="Path_1436" data-name="Path 1436" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" fill="#afb2b3" />
                                                    </svg>
                                                </IconButton>
                                        }
                                    </Grid>
                                    <Grid>
                                        <Button className={classNames(classes.cancelButton, classes.actionButton)}
                                            disabled={isLoading}
                                            onClick={() => this.onSearchKeyChange('')}>
                                            {'Clear All'}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {
                                (!isLoading && inputData && inputData.length > 0 && previewData) &&
                                <Grid item>
                                    <Typography>
                                        {inputParams?.rule?.connectionType && inputParams?.rule?.connectionType === "Snowflake" ? `${previewData.count} records` : `${previewData.count} of ${previewData.total_records} records`}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={12} className={classNames(classes.tablecontainer, classes.previewContainer)}>
                            {
                                (!isLoading && inputData && inputData.length > 0) &&
                                <Card className={classNames(classes.previewTable)}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                {
                                                    headers && headers.map((header, index) => {
                                                        return (
                                                            <TableCell key={'dataPreviewTableHeader' + index}
                                                                className={
                                                                    classNames(classes.tableheadbg,
                                                                        (selectedAttribute && (header.isSelectedColumn))
                                                                            ? classes.selecttableth : null)
                                                                }>
                                                                <ToolTipComponent title={header.name} arrow>
                                                                    <Typography noWrap>
                                                                        {header.name}
                                                                    </Typography>
                                                                </ToolTipComponent>
                                                            </TableCell>
                                                        );
                                                    })
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                inputData.map((row, index) => {
                                                    return (
                                                        <TableRow key={'executionTable' + index}>
                                                            {
                                                                headers.map((header, index) => {
                                                                    return (
                                                                        <TableCell key={'executionTableCell' + index}>
                                                                            <ToolTipComponent title={dataAccessRestrict(row[header.name], header.sensitivity, sensitivityLevel)} arrow>
                                                                                <Typography noWrap>
                                                                                    {dataAccessRestrict(row[header.name], header.sensitivity, sensitivityLevel)}
                                                                                </Typography>
                                                                            </ToolTipComponent>
                                                                        </TableCell>
                                                                    );
                                                                })
                                                            }
                                                        </TableRow>
                                                    );
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </Card>
                            }
                            {
                                (!isLoading && inputData && inputData.length <= 0) &&
                                <Grid container justify="center" alignItems="center">
                                    <Typography variant="h4"
                                        color="textSecondary"
                                        className={
                                            classNames(classes.noDataFoundMessage,
                                                classes.noDataMessage)
                                        }>
                                        No data found
                                    </Typography>
                                </Grid>
                            }
                            {
                                isLoading &&
                                <Grid container justify="center" alignItems="center" className={classes.loader}>
                                    <CircularProgress color="primary" />
                                </Grid>
                            }
                        </Grid>

                    </Grid>
                </DialogContent>
            </Dialog >
        );
    }
}

QueryFilterModel.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    inputParams: PropTypes.object,
    selectedAttribute: PropTypes.object,
    selectedDataset: PropTypes.object,
    attributes: PropTypes.array,
    getPreviewData: PropTypes.func,
    onClose: PropTypes.func,
    properties: PropTypes.object,
    sensitivityLevel: PropTypes.number,
    userAttributes: PropTypes.array,
    datasetId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    scheduleId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    selectedDatasource: PropTypes.object,
    references: PropTypes.array
};


export default withStyles((theme) => ({
    ...QueryFilterModelStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(QueryFilterModel);