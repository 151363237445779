import { domainActions } from '../constants/actionTypes/domainActionTypes';

const initialState = {
    isLoading: false,
    rules: [],
    selectedDomainId: 0,
    actionItemCount: 0,
    config: { filters: {}, tabConfig: { view: "List" } }
};

export const domainReducer = (state = initialState, action) => {
    if (!action || (action && !action.type)) { return state; }
    switch (action.type) {

        case domainActions.GET_DOMAIN_DETAIL_REQUEST:
        case domainActions.GET_DOMAIN_DETAIL_SUCCESS:
        case domainActions.GET_DOMAIN_DETAIL_FAILURE:
            return {
                ...state
            };
        case domainActions.SELECT_DOMAIN_ID:
            return {
                ...state,
                selectedDomainId: action.domainId
            };
        case domainActions.SET_ACTION_ITEM_COUNT:
            return {
                ...state,
                actionItemCount: action.count
            };
        case domainActions.SET_FILTERS:
            return {
                ...state,
                config: { ...action.filters }
            };
        default:
            return state;
    }
};