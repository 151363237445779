import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid, Typography, Button } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect, batch } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';
import classNames from 'classnames';
import { getOrganizationUser, updateUser } from '../../actions/accountActions';
import {
    getSourceAuditLogs, createAuditLog, createSourceShare, updateFavorites, getFavorites, inviteSourceUser, deleteDatasetShare, setSelectedDatasourceId, getDataSource, createFieldProperty, getExportReport, getDomain, createDomain, saveDuplicate, updateCertified, getCertified
} from '../../actions/datasourceActions';
import { clearPreviewData, getDataSetDetail, updateDataset, getDatasetInfo, deleteDataSet, getDatasetShare, createDatasetShare, loadDataset, setSelectedDataset, updateTableauItem, deleteWorkbookItems, getWorkbookItems } from '../../actions/datasetActions';
import { updateSelectedAttribute } from '../../actions/searchActions';
import DatasourceInfo from '../../components/Datasource/DatasourceInfo.jsx';
import { appConstants } from '../../constants/appConstants';
import Styles from '../../layouts/Styles.jsx';
import { getAuditLogText } from '../../helpers/appHelpers';
import AlertDialog from '../../components/AlertDialog/AlertDialog.jsx';
import { getConversationCount } from '../../actions/notificationActions.js';
import { getAllFieldsByLevel, getStorage, getCustomTabs } from '../../actions/settingActions';

// Import Component
import { clearCatalogInfo, getDatasetCatalog } from '../../actions/catalogActions';
import TextBox from '../../components/TextBox/TextBox.jsx';
import Comments from '../../components/Comments/Comments.jsx';
import Filetype from '../../assets/images/logo-sql-server.png';
import datasourceTypes from '../../config/datasourceTypes';

class ItemOverview extends Component {
    constructor(props) {
        super(props);
        if (this.props.location && this.props.location.search) {
            this.getUrlString();
        }
        const tabs = appConstants ? this.getTab([...appConstants.datasetTabList]) : [];
        this.state = {
            workbookItem: {},
            workbook: {},
            selectedAttribute: this.props.location.state && this.props.location.state.attribute ? this.props.location.state.attribute : '',
            datasetDetail: {},
            datasetDetailItem: {},
            datasourceDetail: {},
            auditLogs: [],
            breadcrumb: [
                {
                    name: 'Catalog',
                    path: '/catalog',
                    type: 'catalog'
                }
            ],
            isFavourite: false,
            isCertified: false,
            governance: [],
            isPermissionLoading: true,
            isStewardUser: false,
            isDeleteDataset: false,
            domain: [],
            tabs: tabs,
            fieldProperties: [],
            isExportLoading: false,
            isGovernanceUpdate: false,
            tabAttribute: (this.props.location.state && this.props.location.state.tabAttribute) ? this.props.location.state.tabAttribute : null,
            locationHistory: this.props.location.state ? this.props.location.state : {}
        };
    }

    componentDidMount() {
        batch(() => {
            this.props.clearCatalogInfo();
            this.props.clearPreviewData();
            if (this.props.match.params.id) {
                if (this.state.datasourceDetail && this.state.datasourceDetail.id) {
                    this.props.setSelectedDatasourceId(this.state.datasourceDetail.id ? this.state.datasourceDetail.id : this.props.location.state.datasourceId);
                }
            }
            if (!this.props.users.length) {
                this.getUsers();
                this.props.getStorage(this.props.user.organization);
            }
            this.getDataSourceDetail();
            this.getDomain();
            this.getSourceFavorites();
            this.getSourceCertified();
            this.getDatasetShare();
            if (this.state.datasourceDetail && this.state.datasourceDetail.id) {
                this.loadLogs();
            }
            if (this.props.location.state && this.props.location.state.datasourceId && !Object.keys(this.props.location.state.datasource).length) {
                this.getDatasourceById(this.props.location.state.datasourceId);
            } else {
                this.props.getConversationCount(this.state.datasetDetail.source_id, this.props.match.params.id);
            }
            this.getAllFieldsByLevel();
            this.props.getPageTitle('Item');
        });
    }

    getDataSourceDetail() {
        this.props.getDataSource(this.props.location.state.dataId.source_id).then((response) => {
            const crumbTemp = this.state.breadcrumb;
            crumbTemp.push({
                name: response.name,
                path: `/catalog/${response.id}`,
                type: 'datasource'
            });
            if (response) {
                this.setState({ datasourceDetail: JSON.parse(JSON.stringify(response)), breadcrumb: [...crumbTemp] }, this.getDataSetDetail());
            }
        });
    }

    getUrlString() {
        const regex = /[?&]([^=#]+)=([^&#]*)/g;
        const url = window.location.href;
        const params = {};
        let match;
        // eslint-disable-next-line no-cond-assign
        while (match = regex.exec(url)) {
            params[match[1]] = match[2];
        }
        this.props.history.replace({ ...this.props.history.location, state: { datasource: {}, datasourceId: params.source_id ? params.source_id : "", attribute: params.attribute ? params.attribute : "", isdrift: Boolean(params.isdrift === "true"), ruleName: params.rule_name ? params.rule_name : "" } });
    }

    loadLogs() {
        this.getAuditLogs();
        this.getDatasetInfo();
    }

    loadTab(tabs) {
        if (this.state.locationHistory && this.state.locationHistory.dqscore) {
            return tabs.indexOf("Profile");
        } else if (this.state.locationHistory && this.state.locationHistory.isdrift) {
            this.setState({ selectedAttribute: this.state.locationHistory.attribute ? this.state.locationHistory.attribute : "" });
            return tabs.indexOf("Drift");
        } else if (this.state.locationHistory && this.state.locationHistory.attribute && !this.state.locationHistory.isdrift) {
            return tabs.indexOf("Properties");
        }
        return 0;
    }

    getTab(tabs) {
        if (tabs.indexOf("Profile") > -1 && this.props.config && !this.props.config.profile) {
            tabs.splice(tabs.indexOf("Profile"), 1);
        }
        if (tabs.indexOf("Curate") > -1 && this.props.config && !this.props.config.curate) {
            tabs.splice(tabs.indexOf("Curate"), 1);
        }
        if (tabs.indexOf("Info") > -1 && this.props.datasource && Object.keys(this.props.datasource).length > 0 && !this.props.datasource.metadata) {
            tabs.splice(tabs.indexOf("Info"), 1);
        }
        if (tabs.indexOf("Comments") > -1 && this.props.config && !this.props.config.collaboration) {
            tabs.splice(tabs.indexOf("Comments"), 1);
        }
        if (tabs.indexOf("Monitoring") > -1 && this.props.config && !this.props.config.monitoring) {
            tabs.splice(tabs.indexOf("Monitoring"), 1);
        }
        if (tabs.indexOf("Exportmetrics") > -1 && this.props.config && !this.props.config.exportmetrics) {
            tabs.splice(tabs.indexOf("Exportmetrics"), 1);
        }
        return tabs;
    }

    clearLocationHistory() {
        if (this.props.history.location.state) {
            const state = { ...this.props.history.location.state };
            delete state.dqscore;
            delete state.attribute;
            delete state.isdrift;
            delete state.ruleName;
            this.props.history.replace({ ...this.props.history.location, state });
        }
    }


    getUsers() {
        this.props.getOrganizationUser();
    }

    getDataSetDetail() {
        this.props.getDataSetDetail(this.props.location.state.dataId.dataset_id).then((response) => {
            if (response) {
                const crumbTemp = this.state.breadcrumb;
                crumbTemp.push({
                    name: response.name,
                    path: `/dataset/${response.id}`,
                    type: 'dataset',
                    connector: 'tableau'
                });
                const dataset = JSON.parse(JSON.stringify(response));
                this.setState({ datasetDetail: dataset, workbook: dataset, breadcrumb: [...crumbTemp] }, this.getWorksheetDetail());
            }
        });
    }

    getWorksheetDetail() {
        const requestParams = {
            datasource_id: this.props.location.state.dataId.source_id,
            workbook_id: this.props.location.state.dataId.dataset_id
        };
        this.props.getWorkbookItems(requestParams).then((response) => {
            const crumbTemp = this.state.breadcrumb;
            crumbTemp.push({
                name: this.props.location.state.dataId.item_name,
                type: 'item'
            });
            if (response) {
                const workbookItems = JSON.parse(JSON.stringify(response));
                const filteredItem = workbookItems.filter((item) => item.id === Number(this.props.location.state.dataId.item_id));
                this.setState({ workbookItem: filteredItem[0], breadcrumb: [...crumbTemp] });
            }
        });
    }

    getDatasetInfo() {
        this.props.getDatasetInfo(this.state.datasourceDetail.id ? this.state.datasourceDetail.id : this.props.location.state.datasourceId, this.props.match.params.id);
    }

    getAuditLogs = () => {
        this.props.getSourceAuditLogs(this.state.datasourceDetail.id ? this.state.datasourceDetail.id : this.props.location.state.datasourceId, this.props.match.params.id).then((response) => {
            if (response) {
                this.setState({ auditLogs: response.logs });
            }
        });
    }

    getSourceFavorites() {
        this.props.getFavorites(this.props.match.params.id, "tableau_item").then((response) => {
            this.setState({ isFavourite: response ? response : false });
        });
    }

    getSourceCertified() {
        this.props.getCertified(this.props.match.params.id, "tableau_item").then((response) => {
            this.setState({ isCertified: response ? response : false });
        });
    }

    getDatasetShare(type) {
        this.props.getDatasetShare(this.props.location.state.dataId.dataset_id).then((response) => {
            if (response) {
                const userId = this.props.user && this.props.user.id ? this.props.user.id : 0;
                const isStewardUser = response.some((user) => user.is_steward_user && user.user_id === userId);
                this.setState({ governance: response ? response : [], isPermissionLoading: false, isStewardUser });
                if (type === "update") {
                    this.setState({ isGovernanceUpdate: true });
                }
            }
        });
    }

    updateGovernanceUpdate = () => {
        this.setState({ isGovernanceUpdate: false });
    }

    updateGovernance = () => {
        this.getDatasetShare("update");
    }

    onChangeDatasetDetail = (name, value) => {
        const { workbookItem, breadcrumb } = this.state;
        /*
         * if (name === "updateTags") { datasetDetail.tags = value; }
         * else { datasetDetail[name] = value; }
         * this.setState({ datasetDetail });
         * if (name === "platform_use" || name === "updateTags") {
         *     this.updateDataseteDetail(name);
         */
        // TODO:Need to optimise
        if (name === "name") {
            workbookItem[name] = value;
            breadcrumb[breadcrumb.length - 1].name = value;
            this.setState({ workbookItem });
        }
        if (name === "description") {
            workbookItem[name] = value;
            this.setState({ workbookItem });
        }
        /*
         * else if (name === 'removeDomain') {
         *     const domainToRemove = datasetDetail.domain_ids[value];
         *     datasetDetail.removeDomain = domainToRemove;
         *     datasetDetail.doPropagate = this.props.domainProperties.domain_propagation;
         *     this.props.updateDataset(this.props.match.params.id, datasetDetail).then((response) => {
         *         const { datasetDetail, domain } = this.state;
         *         if (response) {
         *             if (datasetDetail.removeDomain && response.is_domain_deleted) {
         *                 const temp_domain = domain.filter((obj) => obj.id !== datasetDetail.removeDomain);
         *                 this.setState({ domain: [...temp_domain] });
         *             }
         *             datasetDetail.domains.splice(value, 1);
         *             datasetDetail.domain_ids.splice(value, 1);
         *             datasetDetail.doPropagate = this.props.domainProperties.domain_propagation;
         *             this.setState({ datasetDetail: { ...datasetDetail } });
         *         }
         *     });
         *     this.updateAuditLogs(name);
         * }
         */
    }


    updateDataseteDetail = (property, value) => {
        property = (property === "updateTags") ? "tags" : property;
        /*
         * !Need to analyse
         * const condition = property === "tags" || property === "updateTags" || property === "platform_use" ? JSON.parse(JSON.stringify(this.state.workbookItem[property])) !== JSON.parse(JSON.stringify(this.state.datasetDetailItem[property])) : this.state.datasetDetail[property] !== this.state.datasetDetailItem[property];
         */
        const condition = true;
        const model = this.state.workbookItem;
        if (condition || property === "createDomain") {
            if (property === 'domain') {
                if (model.domain === "") {
                    model["domain_id"] = "";
                } else if ((model.domain).charAt(0) === " ") {
                    this.props.createDomain("Error in Name", this.props.domainProperties.create_new_domain);
                    return false;
                } else {
                    this.props.createDomain({ name: model.domain, create_new_domain: this.props.domainProperties.create_new_domain, is_user_created: true }).then((response) => {
                        if (response) {
                            this.setState({ domain: [...this.state.domain, { name: response.name, id: response.id }] });
                            model["domain_id"] = response.id;
                            model.doPropagate = this.props.domainProperties.domain_propagation;
                            this.props.updateDataset(this.props.match.params.id, model);
                        }
                    });
                }
            }
            if (property === 'description' && (this.state.datasetDetail.description)?.charAt(0) === " ") {
                setTimeout(() => { this.props.createDomain("Error in Name", this.props.domainProperties.create_new_domain); }, 1000);
                return false;
            }
            if (property === "createDomain" && value !== "") {
                this.props.createDomain({ name: value, create_new_domain: this.props.domainProperties.create_new_domain, is_user_created: true }).then((response) => {
                    if (response) {
                        model.domain_id = response.id;
                        model.domain = response.name;
                        model.domains.push(value);
                        model.domain_ids.push(response.id);
                        model.doPropagate = this.props.domainProperties.domain_propagation;
                        this.setState({ datasetDetail: { ...model } });
                        this.setState({ domain: [...this.state.domain, { name: response.name, id: response.id }] });
                        this.props.updateDataset(this.props.match.params.id, model);
                    }
                });
            }
            if (property === "description" || property === "tags" || property === "name") {
                this.props.updateTableauItem(this.props.match.params.id, this.state.workbookItem);
            }
            this.updateAuditLogs(property);
            const { datasetDetailItem } = this.state;
            datasetDetailItem[property] = this.state.datasetDetail[property];
            this.setState({ datasetDetailItem: JSON.parse(JSON.stringify(datasetDetailItem)) });
        }
        if (property === 'updateDomain') {
            model.doPropagate = this.props.domainProperties.domain_propagation;
            this.props.updateDataset(this.props.match.params.id, model);
        }
    };

    updateAuditLogs = (property) => {
        const data = getAuditLogText(property, this.state.datasetDetail.name, "dataset");
        const requestParams = {
            "source_id": this.state.datasourceDetail.id,
            "audit_logtext": "custom",
            "type": data.type,
            "worksheet_id": this.props.match.params.id
        };
        this.props.createAuditLog(requestParams).then((response) => {
            if (response) {
                const { auditLogs } = this.state;
                auditLogs.unshift(response);
                this.setState({ auditLogs });
            }
        });
    }

    createSourceShare = (requestParams) => {
        this.props.createDatasetShare(requestParams).then((response) => {
            if (response) {
                this.getAuditLogs();
            }
        });
    };

    deleteDataSet = () => {
        this.setState({ isDeleteDataset: true });
        this.getAuditLogs();
    }

    deleteDatasetInfo = () => {
        const model = {
            items: [this.props.match.params.id]
        };
        this.props.deleteWorkbookItems(model).then((response) => {
            if (response) {
                this.props.history.push(`/catalog/${this.state.datasourceDetail.id}`);
                this.getAuditLogs();
            }
        });
    }

    changeFavorite = () => {
        const requestParams = {
            "source_id": this.props.match.params.id,
            "favorite": this.state.isFavourite,
            "type": "tableau_item"
        };
        this.setState({ isFavourite: !this.state.isFavourite });
        this.props.updateFavorites(requestParams).then(() => {
            this.getAuditLogs();
        });
    }

    changeCertified = () => {
        const requestParams = {
            "source_id": this.props.match.params.id,
            "certified": this.state.isCertified,
            "type": "tableau_item"
        };
        this.setState({ isCertified: !this.state.isCertified });
        this.props.updateCertified(requestParams).then(() => {
            this.getAuditLogs();
        });
    }

    getDomain = () => {
        this.props.getDomain().then((response) => {
            if (response) {
                this.setState({ domain: response });
            }
        });
    }

    onSelectDomain = (domain) => {
        const { datasetDetail } = this.state;
        datasetDetail.domain = domain.name;
        datasetDetail["domain_id"] = domain.id;
        datasetDetail.domains = [...datasetDetail.domains, domain.name];
        datasetDetail.domain_ids = [...datasetDetail.domain_ids, domain.id];
        this.setState({ datasetDetail }, () => this.updateDataseteDetail("updateDomain"));
    }

    /**
     * Get Fields List
     */
    getAllFieldsByLevel = () => {
        if (this.state.datasourceDetail.type === "Tableau") {
            this.setState({ tabs: [...appConstants.datasetTableauList] });
        }

        Promise.all([this.props.getAllFieldsByLevel(appConstants.fieldKeys.dataset), this.props.getCustomTabs(0, "catalog")]).then((response) => {
            if (response && response.length > 0) {
                let tabFields = [];
                if (response[0]) {
                    const fieldList = response[0].filter((data) => data.tab_name === "");
                    tabFields = response[0].filter((data) => data.tab_name !== "");
                    tabFields = tabFields.sort((a, b) => b.display_order > a.display_order);
                    if (fieldList.length === 0) {
                        let datasetTabs = [...this.state.tabs];
                        datasetTabs = this.discardDetailTab(datasetTabs);
                        this.setState({ tabs: datasetTabs });
                    } else {
                        this.setState({ tabs: [...this.state.tabs, appConstants.fieldKeys.tabName] });
                    }
                    this.clearLocationHistory();
                } else {
                    this.clearLocationHistory();
                }
                if (response[1] && response[1].length !== 0) {
                    const tabs = response[1].filter((data) => data.name !== 'Overview' && tabFields.some((a) => a.tab_name.toLowerCase() === data.name.toLowerCase())).map((data) => data.name);
                    this.loadCustomTabs([...tabs]);
                }
            }
        });
    }

    discardDetailTab = (datasetTabs) => {
        const index = datasetTabs.indexOf(appConstants.fieldKeys.tabName);
        if (index > -1) {
            datasetTabs.splice(index, 1);
        }
        return datasetTabs;
    }

    loadCustomTabs = (tabFields) => {
        const tabs = [...this.state.tabs];
        this.setState({ tabs: [...tabs, ...tabFields] });
    }

    /**
     * get Tab Index
     * @param {*} tabName
     * @returns
     */
    getTabIndex = (tabName) => {
        const index = this.state.tabs.indexOf(tabName);
        return index;
    }

    /**
     * Get Dynamic Element On Change Event
     * @param {*} name
     * @param {*} value
     * @param {*} data_type
     */
    dyanamicEleOnChange = (value, field, saveDB, isBoolean) => {
        let properties = [...this.state.fieldProperties];
        const fieldIndex = properties.findIndex((i) => parseInt(i.custom_field) === parseInt(field.id) && parseInt(i.datasource) === parseInt(this.state.datasourceDetail.id) && parseInt(i.dataset) === parseInt(this.props.match.params.id));

        if (fieldIndex > -1) {
            // Update field in state
            properties[fieldIndex].value = value;
            this.setState({ fieldProperties: properties });

            if (saveDB) {
                if (properties[fieldIndex].id && properties[fieldIndex].id !== '') {
                    // Update field in db
                    this.props.createFieldProperty(properties[fieldIndex]);
                } else {
                    // Create field in db
                    this.props.createFieldProperty(properties[fieldIndex]).then((response) => {
                        if (!response) {
                            return;
                        }
                        properties[fieldIndex].id = response.id;
                        this.setState({ fieldProperties: properties });
                    });
                }
            }
        } else {
            // Create new field in state
            const obj = {
                id: '',
                organization: this.props.user.organization,
                custom_field: field.id,
                datasource: this.state.datasourceDetail.id,
                dataset: parseInt(this.props.match.params.id),
                value: value
            };

            properties = [...properties, obj];
            this.setState({ fieldProperties: properties });

            if (isBoolean) {
                // Create field in db
                this.props.createFieldProperty(obj).then((response) => {
                    if (!response) {
                        return;
                    }
                    obj.id = response.id;
                    properties = [...properties, obj];
                    this.setState({ fieldProperties: properties });
                });
            }
        }
    }

    getActiveConnection = () => {
        const inputConnections = this.props.licenseConfig && this.props.licenseConfig.input_connectors ? this.props.licenseConfig.input_connectors : [];
        const connections = this.props.connectionTypes.filter((connection) => inputConnections.includes(connection.id));
        return connections.some((data) => data?.type?.replace(' ', '') === this.state.datasourceDetail?.type?.replace(' ', ''));
    }

    updateTab = (index) => {
        if (this.state.tabs[index] === 'Properties' || this.state.tabs[index] === 'Profile' || this.state.tabs[index] === 'Drift' || this.state.tabs[index] === 'Curate') {
            this.setState({ tabAttribute: this.state.selectedAttribute });
        } else {
            this.setState({ tabAttribute: null });
        }
    }

    navigateOverview = () => {
        this.setState({ tabAttribute: null });
    }

    getIcon = (sourceType) => {
        let icon;
        if (sourceType) {
            icon = datasourceTypes.find((p) => p.type.toLowerCase() === sourceType.toLowerCase());
        }
        if (icon && icon.logo && typeof (icon.logo) === 'object') {
            return icon.logo.default;
        }
        return icon ? icon.logo : Filetype;
    }

    getPath = () => {
        const datasourcePath = this.state.datasourceDetail.configuration && typeof this.state.datasourceDetail.configuration === "string" ? JSON.parse(this.state.datasourceDetail.configuration) : {};
        const path = this.state.datasetDetail.configuration && typeof this.state.datasetDetail.configuration === "string" ? JSON.parse(this.state.datasetDetail.configuration) : {};
        const datasetPath = `${datasourcePath?.connection_type ?? 'File'} / ~ / ${path?.schema ?? ''} / ~ / ${path?.name ?? ''}`;
        return datasetPath;
    }


    render() {
        const { classes } = this.props;
        let stewardUser = this.state.governance.find((user) => user.is_steward_user);
        if (stewardUser) {
            const user = this.props.users.find((user) => user.id === stewardUser.user_id);
            stewardUser = { ...user, ...stewardUser };
        } else {
            stewardUser = {};
        }
        const connectionStatus = this.getActiveConnection();
        let attributeCrumb = this.state.breadcrumb;
        if (this.state.tabAttribute) {
            const { breadcrumb } = this.state;
            const arrayTemp = breadcrumb.filter((crumb) => crumb.type !== 'attribute');
            arrayTemp.push({ name: this.state.tabAttribute, path: ``, type: 'attribute' });
            attributeCrumb = [...arrayTemp];
        }

        return (
            <ValidatorForm form="datasource" onSubmit={() => { }}>
                <Grid className={classes.datasourceSection}>
                    <DatasourceInfo source={this.state.datasetDetail}
                        tableauItems={this.state.workbookItem}
                        breadcrumb={attributeCrumb}
                        onChange={(name, value) => this.onChangeDatasetDetail(name, value)}
                        onSave={(property) => this.updateDataseteDetail(property)}
                        history={this.props.history}
                        type="tableau_item"
                        deleteData={() => this.deleteDataSet()}
                        datasourceId={this.state.datasourceDetail.id}
                        datasetId={this.state.workbookItem?.dataset_id}
                        favorite={this.state.isFavourite}
                        isCertified={this.state.isCertified}
                        changeFavorite={() => this.changeFavorite()}
                        isEditable={this.state.isStewardUser}
                        users={this.props.users}
                        updateAuditLog={this.updateAuditLog}
                        createSourceShare={this.props.createDatasetShare}
                        governance={this.state.governance}
                        sourceId={this.state.datasourceDetail.id}
                        inviteSourceUser={this.props.inviteSourceUser}
                        getAuditLogs={this.getAuditLogs}
                        user={this.props.user}
                        getDatasetShare={this.props.getDatasetShare}
                        loading={this.state.isPermissionLoading}
                        deleteShare={this.props.deleteDatasetShare}
                        profileTab={this.getTabIndex("Profile")}
                        updateUser={this.props.updateUser}
                        isExportLoading={this.state.isExportLoading}
                        updateGovernance={this.updateGovernance}
                        isGovernanceUpdate={this.state.isGovernanceUpdate}
                        updateGovernanceUpdate={this.updateGovernanceUpdate}
                        sourceName={""}
                        connectionStatus={connectionStatus}
                        dataSetLink={Boolean(this.state.tabAttribute)}
                        navigateOverview={this.navigateOverview}
                        connector_type={this.state.datasourceDetail.type}
                        workbookId={this.state.datasetDetail ? this.state.datasetDetail.id : 0}
                        itemId={this.props.match.params.id}
                        getIcon={this.getIcon}
                        getPath={this.getPath}
                        changeCertified={() => this.changeCertified()}
                    />
                </Grid>
                <Grid container justify="space-between" className={classNames(classes.itemoverviewPanel)}>
                    <Grid className={classNames(classes.card, classes.itemoverviewCard)}>
                        <Grid container>
                            <Grid item xs={10}>
                                <Typography variant="h5" gutterBottom component="div">
                                    Description
                                </Typography>
                                <TextBox
                                    name="description"
                                    placeholder="Enter Description"
                                    multiline
                                    value={this.state.workbookItem?.description}
                                    disabled={((!this.state.isStewardUser && !(this.props?.user?.role?.properties_permission.is_edit)))}
                                    rows="1"
                                    onChange={(event) => this.onChangeDatasetDetail('description', event.target.value)}
                                    onBlur={(event) => this.updateDataseteDetail('description', event.target.value)}
                                    fullWidth
                                    className={classNames(classes.inlinetxt, classes.descriptionTextBox)}
                                />
                            </Grid>
                            <Grid item xs={2} className={classes.itemSubmitBtnDiv}>
                                <Button size="small" variant="contained" color="primary" className={classes.submitBtn} onClick={() => (this.state.workbookItem.url ? window.open(this.state.workbookItem.url, "_blank") : null)}>
                                    Open in Tableau
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid className={classes.imagePreview}>
                            {
                                this.state.workbook && this.state.workbook.configuration &&
                                <iframe style={{ height: '100vh', width: '100%' }} src={this.state.workbookItem.iframe_url} title="Tableau " />
                            }
                        </Grid>
                    </Grid>
                    {
                        (this.props.config && this.props.config.collaboration) &&
                        <Grid item xs={12}>
                            <Grid className={classes.card}>
                                <Comments type="tableau_item" updateAuditLog={this.updateAuditLogs} datasourceId={this.state.datasourceDetail.id} datasetId={this.state.workbookItem.dataset_id} itemId={this.props.match.params.id} />
                            </Grid>
                        </Grid>
                    }
                </Grid>
                <AlertDialog title="Delete View"
                    message={`Are you sure you want to delete the view ${this.state.workbookItem ? this.state.workbookItem.name : ''}?`}
                    okButtonText="OK"
                    cancelButtonText="Cancel"
                    show={this.state.isDeleteDataset}
                    onClickOK={() => this.deleteDatasetInfo()}
                    onClickCancel={() => this.setState({ isDeleteDataset: false })} />
            </ValidatorForm >
        );
    }
}

ItemOverview.propTypes = {
    classes: PropTypes.object,
    match: PropTypes.object,
    getOrganizationUser: PropTypes.func,
    getDataSetDetail: PropTypes.func,
    getWorkbookItems: PropTypes.func,
    users: PropTypes.array,
    datasource: PropTypes.object,
    getSourceAuditLogs: PropTypes.func,
    location: PropTypes.object,
    updateDataset: PropTypes.func,
    updateTableauItem: PropTypes.func,
    createAuditLog: PropTypes.func,
    history: PropTypes.object,
    inviteSourceUser: PropTypes.func,
    user: PropTypes.object,
    getDatasetInfo: PropTypes.func,
    updateFavorites: PropTypes.func,
    getFavorites: PropTypes.func,
    getDatasetShare: PropTypes.func,
    createDatasetShare: PropTypes.func,
    config: PropTypes.object,
    deleteDatasetShare: PropTypes.func,
    updateUser: PropTypes.func,
    setSelectedDatasourceId: PropTypes.func,
    getDataSource: PropTypes.func,
    getConversationCount: PropTypes.func,
    /*
     * curationRules: PropTypes.array,
     * references: PropTypes.array,
     */
    getAllFieldsByLevel: PropTypes.func,
    createFieldProperty: PropTypes.func,
    // loadDataset: PropTypes.func,
    getDomain: PropTypes.func,
    createDomain: PropTypes.func,
    clearCatalogInfo: PropTypes.func,
    /*
     * getDatasetCatalog: PropTypes.func,
     */
    getStorage: PropTypes.func,
    getCustomTabs: PropTypes.func,
    connectionTypes: PropTypes.array,
    clearPreviewData: PropTypes.func,
    licenseConfig: PropTypes.object,
    domainProperties: PropTypes.object,
    deleteWorkbookItems: PropTypes.func,
    updateCertified: PropTypes.func,
    getCertified: PropTypes.func,
    getPageTitle: PropTypes.func
};

const mapStateToProps = ({ datasource, dataset, account, setting, search }) => {
    return {
        ...datasource,
        ...dataset,
        ...account,
        ...setting,
        selected: search.selectedAttr
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getOrganizationUser, getDataSetDetail, getDomain, getSourceAuditLogs,
        updateDataset, createAuditLog, createSourceShare,
        getDatasetInfo, deleteDataSet, updateFavorites, getFavorites, getDatasetShare, inviteSourceUser, getStorage,
        createDatasetShare, deleteDatasetShare, createDomain,
        updateUser, loadDataset, setSelectedDatasourceId, getDataSource, getConversationCount, getAllFieldsByLevel, createFieldProperty, getExportReport,
        clearCatalogInfo, saveDuplicate, getDatasetCatalog, getCustomTabs, clearPreviewData, setSelectedDataset, updateSelectedAttribute, updateTableauItem, deleteWorkbookItems, getWorkbookItems, updateCertified, getCertified
    }, dispatch);

};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles((theme) => ({
    ...Styles(theme)
}), { withTheme: true })(ItemOverview));