import React, { useState, useCallback, useEffect } from 'react';
import { Button, Divider, Grid, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import SettingComponentStyles from './SettingComponentStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import TextBox from '../TextBox/TextBox.jsx';
import PasswordInput from '../TextBox/PasswordInput.jsx';
import Loader from '../Loaders/Loader.jsx';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { updateOrganizationNotificationConfig } from '../../actions/settingActions';
import CheckboxComponent from '../ChecboxComponent/CheckboxComponent.jsx';

const EmailConfig = (props) => {
    const { classes, organizationId, config, notificationConfig } = props;
    const dispatch = useDispatch();
    const [isChanged, setChanged] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [emailConfig, setEmailConfig] = useState({
        host: '',
        port: '',
        userName: '',
        password: '',
        use_ssl: false
    });
    const [orgId, setOrgId] = useState(0);

    const onChange = useCallback((property, value) => {
        const config = { ...emailConfig };
        config[property] = value;
        setEmailConfig({ ...config });
        setChanged(true);
        setLoading(false);
    }, [emailConfig]);

    const onSubmit = useCallback(() => {
        setLoading(true);
        setChanged(false);
        notificationConfig.email_notification_config.email_config = { ...emailConfig };
        dispatch(updateOrganizationNotificationConfig(organizationId, notificationConfig)).then(() => {
            setLoading(false);
        });
    }, [dispatch, emailConfig, notificationConfig, organizationId]);

    const loadEmailConfig = useCallback((organizationId, config) => {
        setOrgId(organizationId);
        setEmailConfig({
            host: config?.host ?? '',
            port: config?.port ?? '',
            userName: config?.userName ?? '',
            password: config?.password ?? '',
            use_ssl: config?.use_ssl ?? false
         });
    }, []);

    const onCancel = useCallback((organizationId, config) => {
        setLoading(false);
        setChanged(false);
        loadEmailConfig(organizationId, { ...config });
    }, [loadEmailConfig]);

    useEffect(() => {
        if (organizationId !== 0 && orgId !== organizationId && config) {
            loadEmailConfig(organizationId, config);
        }
    }, [config, loadEmailConfig, orgId, organizationId]);

    return (
        <Grid container justify="space-between" className={classes.emailConfigContainer}>
            <ValidatorForm form="Email Config Form" className={"configForm"} onSubmit={() => onSubmit()}>
                <Grid container justify="flex-start">
                    <Typography>
                        Configure Email Server
                    </Typography>
                </Grid>
                <Divider className={classes.divider} />
                <Grid container>
                    <Grid item className={"btnContainer"} xs={6}>
                        <Grid container>
                            <Grid xs={8}>
                                <TextBox
                                    label="SMTP Server"
                                    name="host"
                                    validators={['required']}
                                    errorMessages={['Server is required']}
                                    value={emailConfig.host ? emailConfig.host : ''}
                                    onChange={(event) => onChange(event.target.name, event.target.value)}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={"btnContainer"} xs={6}>
                        <Grid container>
                            <Grid xs={8}>
                                <TextBox
                                    label="Port"
                                    name="port"
                                    validators={['required']}
                                    errorMessages={['Port is required']}
                                    value={emailConfig.port ? emailConfig.port : ''}
                                    onChange={(event) => onChange(event.target.name, event.target.value)}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={"btnContainer"} xs={6}>
                        <Grid container>
                            <Grid xs={8}>
                                <TextBox
                                    label={"Username"}
                                    name="userName"
                                    validators={['required']}
                                    errorMessages={['Username is required']}
                                    value={emailConfig.userName ? emailConfig.userName : ''}
                                    onChange={(event) => onChange(event.target.name, event.target.value)}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={"btnContainer"} xs={6}>
                        <Grid container>
                            <Grid xs={8}>
                                <PasswordInput
                                    label="Password"
                                    name="password"
                                    validators={['required']}
                                    errorMessages={['Password is required']}
                                    password={emailConfig.password ? emailConfig.password : ''}
                                    onChange={(event, value) => onChange(event.target.name, value, event)}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={"btnContainer"} xs={6}>
                        <Grid container>
                            <Grid xs={8}>
                                <CheckboxComponent
                                    checked={Boolean(emailConfig.use_ssl)}
                                    onClick={() => onChange("use_ssl", !emailConfig.use_ssl)}
                                    checkboxLabel={'Use SSL'}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    isChanged &&
                    <Grid container justify="flex-start" alignItems="center" className={"btnContainer"}>
                        <Grid item>
                            <Button disabled={isLoading} variant="contained" color="primary" type="submit" className={classnames(classes.actionButtons, classes.submitBtn)}>
                                Save
                            {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button disabled={isLoading} onClick={() => onCancel(organizationId, config)} className={classnames(classes.cancelButton, classes.actionButtons)}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                }
            </ValidatorForm>
        </Grid>
    );
};

EmailConfig.propTypes = {
    classes: PropTypes.object,
    config: PropTypes.object,
    organizationId: PropTypes.number,
    notificationConfig: PropTypes.object
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(EmailConfig);