import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Grid, withStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import Circular from '../Charts/Circular.jsx';
import DatasetProfileStyles from './DatasetProfileStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import LengthChart from '../Charts/LengthChart.jsx';
import PatternChart from '../Charts/PatternChart.jsx';
import SoundexChart from '../Charts/SoundexChart.jsx';
import SpiderChart from '../Charts/SpiderChart.jsx';
import HorizontalStackedBarChart from '../Charts/HorizontalStackedBarChart.jsx';
import AttributeSelect from '../AttributeSelect/AttributeSelect.jsx';
import ValueChart from '../Charts/ValueChart.jsx';
import { appConstants } from '../../constants/appConstants';
import { getValue, getFieldType } from '../../helpers/appHelpers';
import AnomalyChart from '../Charts/AnomalyChart.jsx';
import HistogramChart from '../Charts/HistogramChart.jsx';
import TrendingChart from '../Charts/TrendingChart.jsx';
import RulesStatistics from '../Charts/RulesStatistics.jsx';
import QueryFilterModal from '../QueryFilterModel/QueryFilterModel.jsx';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Loader from '../Loaders/Loader.jsx';
import { getOperators, getConditionOperators, createRuleGroup, createRule, getQueryString } from '../RuleBuilder/QueryBuilderUtil.jsx';
import config from '../RuleBuilder/QueryBuilderConfig.jsx';
import ExtremeValueChart from '../Charts/ExtremeValueChart.jsx';
import Statistics from '../Charts/Statistics.jsx';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';
import ProfileInvalidRecordsPreview from './ProfileInvalidRecordsPreview.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import { getPropertiesList } from '../../actions/datasetActions';
// import DatasetLevelProfile from './DatasetLevelProfile.jsx';

class DatasetProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            attributes: [],
            profile: {},
            trending: [],
            scheduleLogId: -1,
            queryFilterModal: {
                isOpen: false,
                inputParams: null
            },
            isLoading: true,
            profileRules: {},
            anomalyRule: { name: 'Normal Distribution', isChanged: true },
            attributeChanged: true,
            attributeRules: [],
            selectedAttributeIdTemp: 0,
            openInvalidRecordsPreview: false,
            disableAttribute: false,
            attributeStatistics: {},
            invalidQuery: {}
        };
        this.containerRef = React.createRef();
    }


    componentDidMount() {
        this.setState({ isLoading: true });
        this.getAttributes();
        this.getInvalidQuery();
    }

    getInvalidQuery() {
        this.props.getInvalidQueries(this.props.datasetId).then((response) => {
            if (response) {
                this.setState({ invalidQuery: { ...response } });
            }
        }, []);
    }


    getAttributes() {
        const attributes = [];
        let selectedAttributeId = 0;
        let datasetAttributes = this.props.attributeList;
        if (this.props.attributeList.length <= 0) {
            this.props.getPropertiesList(this.props.datasetId).then((attributesList) => {
                datasetAttributes = attributesList ? [...attributesList] : [];
            });
        }
        for (const index in datasetAttributes) {
            const attribute = datasetAttributes[index];
            attributes.push({
                id: attribute.id ? attribute.id : 0,
                name: attribute.name ? attribute.name : "",
                isSelected: this.props.selectedAttribute === "" ? index === "0" : this.props.selectedAttribute === attribute.name,
                type: attribute.type
            });
            if ((this.props.selectedAttribute === "" && index === "0") || this.props.selectedAttribute === attribute.name) {
                selectedAttributeId = attribute.id;
            }
        }
        this.setState({ attributes: [...attributes] });
        this.setState({ selectedAttributeIdTemp: selectedAttributeId });
        this.getAttributeStatistics(selectedAttributeId);
        this.getProfile(selectedAttributeId);
        this.getProfileRules(selectedAttributeId);
        this.getTrendingData(selectedAttributeId);
        this.getAttributePropertyRules(selectedAttributeId);
    }

    getAttributeStatistics = (attributeId) => {
        this.props.getAttributeStatistics(attributeId).then((response) => {
            if (response) {
                response.type = response.datatype ? response.datatype : "Text";
                response.fieldType = response.fieldtype ? response.fieldtype : "";
                this.setState({ attributeStatistics: { ...response } });
                const attributeKeys = Object.keys(this.props.properties);
                if (attributeKeys.indexOf(response.name) === -1) {
                    this.props.updateDatasetProperties({ ...this.props.properties, ...{ [response.name]: response } });
                }
            }
        });
    };

    getTrendingData(attributeId) {
        this.props.getTrending(attributeId).then((response) => {
            if (response) {
                const analysis = response.map((data) => {
                    return {
                        "end_time": new Date(data.end_time),
                        "dqscore": data.dqscore
                    };
                });
                this.setState({ trending: [...analysis] });
            }
        });
    }

    getProfile(attributeId) {
        this.setState({ isLoading: true });
        this.props.getProfileAnalysis(attributeId).then((response) => {
            if (response && Object.keys(response).length !== 0) {
                const ruleKeys = Object.keys(response);
                this.setState({ profile: { ...response }, scheduleLogId: response[ruleKeys[0]].schedule_id }, () => {
                    this.disableRender();
                });
            }
            this.setState({ isLoading: false });
        });
    }

    getProfileRules(attributeId) {
        this.props.getProfileRules(attributeId).then((response) => {
            if (response) {
                this.setState({ profileRules: { ...response } });
            }
        });
    }

    getAttributePropertyRules(attributeId) {
        this.props.getAttributePropertyRules(this.props.datasetId, attributeId).then((response) => {
            if (response) {
                const alteredResponse = {};
                Object.keys(response).forEach((key) => {
                    alteredResponse[key] = response[key];
                });
                this.setState({ attributeRules: { ...alteredResponse } });
            }
        });
    }


    prepareCustomRule(attributeRule, fieldType, attribute) {
        const operators = getOperators(fieldType);
        const conditionalOperators = getConditionOperators();
        const ruleParams = {
            defaultField: attribute.name,
            defaultFieldType: fieldType,
            attributeType: attribute.datatype,
            connectionType: this.props.datasource?.type,
            isScan: this.props.datasource?.scan
        };
        const customAttributeRule = createRule(ruleParams);
        const operator = operators.find((p) => p.label.toLowerCase() === (typeof (attributeRule.operator) === "object" ? attributeRule.operator.label.toLowerCase() : attributeRule.operator.toLowerCase()));
        if (operator) {
            customAttributeRule.operator = operator;
        }
        const isSql = (this.props.datasource?.type.toLowerCase() === 'sql');
        const isScan = this.props.datasource?.scan ?? false;
        customAttributeRule.value = isSql && isScan && attributeRule?.sqlValue ? attributeRule.sqlValue : attributeRule.value;
        if (attributeRule.condition) {
            const conditionOperator = conditionalOperators.find((p) => p.label.toLowerCase() === attributeRule.condition.toLowerCase());
            if (conditionOperator) {
                customAttributeRule.conditionOperator = conditionOperator;
            }
            customAttributeRule.count = attributeRule.count;
        }
        if (attributeRule.conditionOperator) {
            customAttributeRule.conditionOperator = attributeRule.conditionOperator;
            customAttributeRule.count = attributeRule.count;
        }
        return customAttributeRule;
    }

    prepareCustomRuleGroup(attributeRule, fieldType, attribute) {
        const processedRules = [];
        if (attributeRule.rules) {
            for (const rule of attributeRule.rules) {
                const ruleInfo = rule.connector ? this.prepareCustomRuleGroup(rule, fieldType, attribute) : this.prepareCustomRule(rule, fieldType, attribute);
                processedRules.push({ ...ruleInfo });
            }
        }
        attributeRule.rules = [...processedRules];
        return attributeRule;
    }

    prepareRule = (customRule, attribute) => {
        if (customRule.is_user_defined && customRule.ruleGroup && typeof (customRule.ruleGroup) === 'object' && Object.keys(customRule.ruleGroup).length > 0) {
            return customRule;
        }
        let rule = customRule.input_params;
        const fieldType = getFieldType(attribute.datatype);

        const ruleGroupParams = {
            defaultField: attribute.name,
            defaultFieldType: fieldType,
            attributeType: attribute.datatype,
            attributeFieldType: attribute.fieldType,
            connectionType: this.props.datasource?.type,
            isScan: this.props.datasource?.scan
        };
        const ruleGroup = createRuleGroup(ruleGroupParams);
        if (rule?.constructor?.name === 'Array' && rule.length > 0) {
            rule = rule[0];
        }
        if (rule && rule.connector) {
            const connector = config.connectors.find((p) => p.name.toLowerCase() === rule.connector.toLowerCase());
            if (connector) {
                ruleGroup.connector = connector.value;
            }
        }

        const rules = rule && rule.rules ? rule.rules : [];
        const processedRules = [];
        for (const attributeRule of rules) {
            const ruleInfo = !attributeRule.connector ? this.prepareCustomRule(attributeRule, fieldType, attribute) : this.prepareCustomRuleGroup(attributeRule, fieldType, attribute);
            processedRules.push({ ...ruleInfo });
        }
        ruleGroup.rules = [...processedRules];
        customRule.ruleGroup = { ...ruleGroup };
        customRule.query = getQueryString(ruleGroup);
        return customRule;
    };

    setChartData(chartType) {
        let chartData = [];
        if (this.state.profile && this.state.attributeStatistics && Object.keys(this.state.attributeStatistics).length > 0) {
            if (chartType === "CustomRule") {
                chartData = [];
                const rules = this.state.profile;
                const keys = Object.keys(rules);
                for (const key of keys) {
                    if (rules[key].rule_name === "CustomRule") {
                        const rule = this.prepareRule(rules[key], this.state.attributeStatistics);
                        chartData.push({
                            name: key,
                            ...rule
                        });
                    }
                }
            } else if (chartType === "CountStatistics") {
                chartData = [];
                const selectedAttribute = this.state.attributeStatistics;
                const rules = ["null_count", "blank", "digits", "distinct_count", "duplicate", "unique_count", "characters", "white_space", "special_characters", "zero_values"];
                for (const rule of rules) {
                    const count = (selectedAttribute && rule in selectedAttribute) ? selectedAttribute[rule] : 0;
                    let total_records = selectedAttribute.total_records;
                    if (!total_records && selectedAttribute.count) {
                        total_records = selectedAttribute.count;
                    }
                    const name = rule.replace('_', ' ').toUpperCase();
                    const invalid_records = (total_records - count);
                    let valid_percentage = total_records ? (count / total_records) * 100 : 0;
                    valid_percentage = valid_percentage > 0 ? valid_percentage : 0;
                    if (valid_percentage > 100) {
                        valid_percentage = 100;
                    }
                    const invalid_percentage = (100 - valid_percentage);
                    chartData.unshift({
                        "default_name": rule,
                        "name": name === "count" ? "Numeric Values" : name,
                        "valid_records": count,
                        valid_percentage,
                        invalid_percentage,
                        invalid_records
                    });
                }
            } else if (chartType === "Rules") {
                chartData = [];
                const selectedAttribute = this.state.attributeStatistics;
                const rules = ["null_count", "blank", "digits", "distinct_count", "duplicate", "unique_count", "characters", "white_space", "special_characters", "zero_values"];
                for (const rule of rules) {
                    const name = rule.replace('_', ' ').toUpperCase();
                    let count = selectedAttribute[rule];
                    count = (count < 0) ? 0 : count;
                    let total_records = selectedAttribute.total_records;
                    if (!total_records && selectedAttribute.count) {
                        total_records = selectedAttribute.count;
                    }
                    const { attributeRules } = this.state;
                    const attributeStatisticsRules = Object.keys(attributeRules).filter((data) => attributeRules[data]);

                    count = (count > total_records) ? total_records : count;
                    const invalid_records = (total_records - count);
                    let valid_percentage = total_records ? (count / total_records) * 100 : 0;
                    valid_percentage = valid_percentage > 0 ? valid_percentage : 0;
                    let validRule = false;
                    if (attributeStatisticsRules.includes(rule)) {
                        validRule = true;
                        valid_percentage = total_records ? ((total_records - count) / total_records) * 100 : 0;
                    }
                    if (valid_percentage > 100) {
                        valid_percentage = 100;
                    }
                    const invalid_percentage = (100 - valid_percentage);
                    chartData.push({
                        "default_name": rule,
                        "name": name === "count" ? "Numeric Values" : name,
                        "description": "",
                        "valid_records": count,
                        "total_records_processed": selectedAttribute.total_records,
                        "status": "Completed",
                        "type": 'countstatistics',
                        "rule_name": 'countstatistics',
                        valid_percentage,
                        invalid_percentage,
                        invalid_records,
                        isEnable: validRule
                    });
                }
                const keys = Object.keys(this.state.profile);
                for (const key of keys) {
                    const value = this.state.profile[key];
                    if (value.allow_dqscore) {
                        chartData.push({
                            ...value,
                            "name": key,
                            "type": key,
                            "valid_percentage": value.valid_percentage,
                            isEnable: true
                        });
                    }
                }
                /*
                 * const profileKeys = Object.keys(this.state.profileRules).filter((data) => !keys.includes(data));
                 * for (const profileKey of profileKeys) {
                 *     chartData.push({
                 *         "name": profileKey,
                 *         "type": profileKey,
                 *         "valid_percentage": null,
                 *         isEnable: true
                 *     });
                 * }
                 */
            } else {
                chartData = this.state.profile[chartType];
            }

        }
        return chartData;
    }

    onChangeAttribute(event) {
        if (!event.target.value) {
            return;
        }
        let selectedAttributeId = 0;
        const attributes = this.state.attributes.map((attribute) => {
            const attributeItem = attribute;
            attributeItem.isSelected = false;
            if (attributeItem.name === event.target.value) {
                attributeItem.isSelected = true;
                selectedAttributeId = attribute.id;
            }
            return attributeItem;
        });

        this.setState({ selectedAttributeIdTemp: selectedAttributeId });
        this.props.setSelectedAttribute(event.target.value);
        this.setState({ attributes: [...attributes], profile: {}, attributeChanged: true }, () => {
            this.getAttributeStatistics(selectedAttributeId);
            this.getTrendingData(selectedAttributeId);
            this.getProfile(selectedAttributeId);
            this.getProfileRules(selectedAttributeId);
            this.getAttributePropertyRules(selectedAttributeId);
        });
        if (this.containerRef.current) {
            this.containerRef.current.scrollTop = 0;
        }
    }

    redirectChart = (chartName) => {
        let chart = appConstants.charts[chartName];
        if (!chart) {
            chartName = chartName.toString().trim().toLowerCase().replace(/\s/g, '');
            chart = appConstants.charts[chartName];
        }
        if (chart && this.containerRef) {
            if (chart.type === "anomaly") {
                this.setState({ anomalyRule: { name: chart.name, isChanged: true } });
            }
            this.containerRef.current.scrollTop = document.getElementById(chart.id).offsetTop;
        }
    }

    renderAnomaly = () => {
        this.setState({ anomalyRule: { name: '', isChanged: false } });
    }

    showQueryFilter(inputParams) {
        if (this.props.profile_query) {
            this.setState({
                queryFilterModal: {
                    isOpen: true,
                    inputParams: {
                        ...inputParams
                    }
                }
            });
        }
    }

    hasCustomRules = () => {
        const attribute = this.state.attributes.find((attribute) => attribute.isSelected);
        let hasCustomRules = false;
        if (attribute) {
            const rules = this.state.profile;
            const keys = Object.keys(rules);
            for (const key of keys) {
                if (rules[key].rule_name === "CustomRule") {
                    hasCustomRules = true;
                    break;
                }
            }
        }
        return hasCustomRules;
    }

    closeQueryFilter() {
        this.setState({
            queryFilterModal: {
                isOpen: false
            }
        });
    }

    showPreview = (params) => {
        const model = {
            ...params,
            "dataset_id": this.props.datasetId
        };
        this.props.filterInputData(model);
    }

    getStatistics = (type) => {
        const selectedAttribute = this.state.attributeStatistics;
        if (type === "Descriptive Statistics") {
            return {
                "Sum": selectedAttribute.sum,
                "Mean": selectedAttribute.mean,
                "Kurtosis": selectedAttribute.kurtosis,
                "Skewness": selectedAttribute.skewness,
                "Variance": selectedAttribute.variance,
                "Standard deviation": selectedAttribute.standard_deviation,
                "Coefficient of variation": selectedAttribute['coefficient_of_variation'],
                "Median absolute deviation": selectedAttribute['median_absolute_deviation']
            };
        }
        return {
            "Q1": selectedAttribute.q1,
            "Q2": selectedAttribute.q2,
            "Q3": selectedAttribute.q3,
            "Iqr": selectedAttribute.iqr,
            "Range": selectedAttribute.range,
            "Median": selectedAttribute.median,
            "Maximum": selectedAttribute.max,
            "Minimum": selectedAttribute.min
        };
    }

    profileRuleEnable = (property, value) => {
        const { profileRules } = this.state;
        if (profileRules && profileRules[property]) {
            profileRules[property]["is_active"] = value;
            this.setState({ profileRules: { ...profileRules } });
            this.props.profileRuleEnable({ "is_active": value }, profileRules[property].id);
        }
    }

    getRulePlan = (type) => {
        const { profileRules } = this.state;
        const ruleActive = true;
        if (profileRules && typeof profileRules[type] !== 'undefined') {
            return profileRules[type].is_active;
        }
        return ruleActive;
    }

    getCountStatisticsRules = (rule) => {
        const { attributeRules } = this.state;
        const statisticsType = rule.default_name;
        let ruleActive = false;
        if (attributeRules && statisticsType in attributeRules && typeof attributeRules[statisticsType] !== 'undefined') {
            ruleActive = Boolean(attributeRules[statisticsType]);
        }
        return ruleActive;
    }

    enableCountStatisticsRule = (property, value) => {
        const { attributeRules } = this.state;
        if (attributeRules) {
            attributeRules[property] = value;
            this.setState({ attributeRules: { ...attributeRules } });
            this.props.enableCountStatisticsRule({ "dataset_id": this.props.datasetId, "attribute_id": this.state.selectedAttributeIdTemp, "statistics_rules": attributeRules });
        }
    }

    showAnomaly() {
        const attribute = this.state.attributes.find((attribute) => attribute.isSelected);
        return attribute && attribute.name ? this.state.profile['Scatter Plot'] || this.state.profile['Normal Distribution'] || this.state.profile['Box Plot'] || this.state.profile['K Means'] : false;
    }

    getAnomalyFilters() {
        const properties = { filters: [], chartData: {} };
        if (this.state.profile['Normal Distribution'] && this.state.profile['Normal Distribution'].status === "Completed") {
            properties.filters.push("Normal Distribution");
            properties.chartData['Normal Distribution'] = this.state.profile['Normal Distribution'];
        }
        if (this.state.profile['Scatter Plot'] && this.state.profile['Scatter Plot'].status === "Completed") {
            properties.filters.push("Scatter With Marginal Plot");
            properties.chartData['Scatter Plot'] = this.state.profile['Scatter Plot'];
        }
        if (this.state.profile['Box Plot'] && this.state.profile['Box Plot'].status === "Completed") {
            properties.filters.push("Box Plot");
            properties.chartData['Box Plot'] = this.state.profile['Box Plot'];
        }
        if (this.state.profile['K Means'] && this.state.profile['K Means'].status === "Completed") {
            properties.filters.push("KMeans");
            properties.chartData['K Means'] = this.state.profile['K Means'];
        }
        return properties;
    }

    getInvalidPercentage(attributeStatistics) {
        let invalidPercentage = 0;
        if (attributeStatistics) {
            const invalidRecordsCount = attributeStatistics?.invalid_record ?? 0;
            const totalRecords = attributeStatistics?.total_records ?? 0;
            invalidPercentage = invalidRecordsCount && totalRecords ? (invalidRecordsCount / totalRecords) * 100 : 0;
            if (invalidPercentage > 100) {
                invalidPercentage = 100;
            }
            invalidPercentage = invalidPercentage ? Math.round(parseFloat(attributeStatistics.invalid_percentage)) : 0;
        }
        return `(${invalidPercentage}%)`;
    }

    disableRender() {
        setTimeout(() => {
            this.setState({ attributeChanged: false });
        }, 100);
    }


    render() {
        const { classes, tabIndex, properties, sensitivityLevel, statistical, count, dq_score_threshold } = this.props;
        const { attributeStatistics } = this.state;
        let selectedAttribute = {};
        const attribute = this.state.attributes.find((attribute) => attribute.isSelected);
        if (attribute) {
            selectedAttribute = attribute;
        }
        const invalidRecords = attributeStatistics.invalid_record && attributeStatistics.invalid_record > 999 ? `${attributeStatistics.invalid_record / 1000} K` : attributeStatistics.invalid_record;
        let totalRecords = attributeStatistics.total_records && attributeStatistics.total_records > 999 ? `${attributeStatistics.total_records / 1000} K` : attributeStatistics.total_records;
        if (!totalRecords && attributeStatistics.count) {
            totalRecords = attributeStatistics.count && attributeStatistics.count > 999 ? `${attributeStatistics.count / 1000} K` : attributeStatistics.count;
        }

        if (attributeStatistics && Object.keys(attributeStatistics).length <= 0) {
            return <Loader />;
        }

        const shortenText = () => {
            if (attributeStatistics.fieldType.length > 10) {
                return attributeStatistics.fieldType.slice(0, 10) + '...';
            } return attributeStatistics.fieldType;
        };

        return (
            <Grid container className={classNames(classes.tabSection, classes.scrollWidth)}>
                {this.state.isLoading && <Loader />}

                <Grid container>
                    <Grid item xs={12} className={classes.marginRight5}>
                        <Grid container alignItems="center" justify="space-between" direction="row" className={classNames(classes.card, classes.container)}>
                            <Grid item xs={3} className={classNames(classes.selectContainer, classes.ProfileSelectAttribute)}>
                                <AttributeSelect
                                    label="Select Attribute"
                                    placeholder="Select Attribute"
                                    value={selectedAttribute.name ? selectedAttribute.name : ''}
                                    onChange={(event) => this.onChangeAttribute(event)}
                                    options={this.state.attributes}
                                    selectedValuePath="name"
                                    displayMemberPath="name"
                                    removeClass
                                    openSuggestionsOnClick
                                    name="Attribute"
                                    id="Attribute"
                                    disabled={this.state.disableAttribute}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Grid className={classes.scoreContainer}>
                                    <Circular customStyle={classes.profileScore} showPercentage showValue value={attributeStatistics.dqscore ? attributeStatistics.dqscore : 0} size={55} dqScoreThreshold={dq_score_threshold} />
                                    <Grid>
                                        <Typography className={classes.dqscoreText}>
                                            {'DQScore'}
                                        </Typography>
                                        <Grid className={classes.curateScore}>
                                            <ArrowDropUpIcon className={classNames(classes.impactIcon, classes.curateIcon)} />
                                            <Typography className={classes.curateTxt}>
                                                {`${getValue(attributeStatistics.impact_score ?? attributeStatistics.impact_score)}%`}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography component="h5" variant="h5" className={classes.attributeAnalysisText}>
                                    {attributeStatistics.type}
                                </Typography>
                                <Typography>
                                    Data Type
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <ToolTipComponent title={attributeStatistics.fieldType} arrow placement="top">
                                    <Typography component="h5" variant="h5" className={classes.attributeAnalysisText}>
                                        {
                                            attributeStatistics.fieldType ? shortenText() :
                                                <span style={{ visibility: "hidden" }}>
                                                    " "
                                                </span>
                                        }
                                    </Typography>
                                </ToolTipComponent>
                                <Typography>
                                    Field Type
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography component="h5" variant="h5" className={classes.attributeAnalysisText}>
                                    {totalRecords ? totalRecords : 0}
                                </Typography>
                                <Typography>
                                    Total Records
                                </Typography>
                            </Grid>
                            <Grid item xs={1} className={classes.invalidRecords}>
                                {
                                    attributeStatistics.invalid_record === "NA" &&
                                    <Typography component="h5" variant="h5" title="Invalid record is disabled" className={classes.invalidAttributeAnalysisText}>
                                        {'NA'}
                                    </Typography>
                                }
                                {
                                    attributeStatistics.invalid_record !== "NA" &&
                                    <Typography component="h5" variant="h5" className={classes.invalidAttributeAnalysisText} onClick={() => this.setState({ openInvalidRecordsPreview: true })}>
                                        {attributeStatistics.invalid_record && invalidRecords ? invalidRecords : 0}
                                        {' '}
                                        {this.getInvalidPercentage(attributeStatistics)}
                                    </Typography>
                                }
                                <Typography>
                                    Invalid Records
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography component="h5" variant="h5" className={classes.attributeAnalysisText}>
                                    {attributeStatistics && attributeStatistics.sensitivity ? attributeStatistics.sensitivity : '1'}
                                </Typography>
                                <Typography>
                                    Sensitivity
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        <Grid container spacing={2} className={classNames(classes.tabProfileContainer, classes.paddingTop15)} ref={this.containerRef}>
                            {
                                this.state.trending.length ?
                                    <Grid item xs={12}>
                                        <TrendingChart
                                            createdDate={this.props.createdDate}
                                            chartData={this.state.trending}
                                            chartClassName="profile-trending"
                                            title={appConstants.charts.trending.name}
                                            showYAxis
                                            indicatorType="circle"
                                            domain={[0, 100]}
                                            loadChart={this.state.openInvalidRecordsPreview} />
                                    </Grid>
                                    : null
                            }
                            {
                                attributeStatistics && attributeStatistics.type && getFieldType(attributeStatistics.type.toLowerCase()) === 'numeric' && statistical
                                && statistical.length > 0 &&
                                <Grid item xs={count && count.length > 0 ? 6 : 12}>
                                    <Statistics getStatistics={(type) => this.getStatistics(type)} statisticalRules={statistical} />
                                </Grid>
                            }
                            {
                                this.setChartData("CountStatistics") && count && count.length > 0 &&
                                <Grid item xs={attributeStatistics && attributeStatistics.type && statistical && statistical.length > 0 && getFieldType(attributeStatistics.type.toLowerCase()) === 'numeric' ? 6 : 12}>
                                    <HorizontalStackedBarChart id={appConstants.charts.countstatistics.id}
                                        chartType={appConstants.charts.countstatistics.type}
                                        chartWidth="98%"
                                        lengend={["Presence", "Absence"]}
                                        colors={["#73e5ed", "#f1f3f3"]}
                                        changeTick
                                        chartClass="count-statistics"
                                        name={appConstants.charts.countstatistics.name}
                                        chartData={this.setChartData("CountStatistics")}
                                        attribute={attributeStatistics}
                                        tabIndex={tabIndex}
                                        onFilter={(params) => this.showQueryFilter(params)}
                                        config={{ learning: this.props.profile_learning, query: this.props.profile_query }}
                                        wrapWidth={attributeStatistics && attributeStatistics.type && getFieldType(attributeStatistics.type.toLowerCase()) === 'numeric'}
                                        attributeChange={this.state.attributeChanged}
                                        attributeRules={this.state.attributeRules}
                                        countRules={count}
                                        barStack={40}
                                    />
                                </Grid>
                            }
                            {
                                this.state.profile && Object.keys(this.state.profile).length !== 0 && this.state.attributes.length !== 0 &&
                                this.state.profile.Length &&
                                <Grid item xs={12}>
                                    <LengthChart invalidQuery={this.state.invalidQuery[selectedAttribute.name] && this.state.invalidQuery[selectedAttribute.name].Length} datasetId={this.props.datasetId} isActive={this.getRulePlan("Length")} profileRuleEnable={this.profileRuleEnable} config={{ learning: this.props.profile_learning, query: this.props.profile_query }} attribute={selectedAttribute} profileData={this.state.profile} tabIndex={tabIndex} onFilter={(params) => this.showQueryFilter(params)} attributeChange={this.state.attributeChanged} />
                                </Grid>
                            }
                            {
                                this.state.profile && Object.keys(this.state.profile).length !== 0 && this.state.attributes.length !== 0 &&
                                this.state.profile.Pattern &&
                                <Grid item xs={12}>
                                    <PatternChart invalidQuery={this.state.invalidQuery[selectedAttribute.name] && this.state.invalidQuery[selectedAttribute.name].Pattern} datasetId={this.props.datasetId} isActive={this.getRulePlan("Pattern")} profileRuleEnable={this.profileRuleEnable} config={{ learning: this.props.profile_learning, query: this.props.profile_query }} attribute={selectedAttribute} profileData={this.state.profile} tabIndex={tabIndex} onFilter={(params) => this.showQueryFilter(params)} attributeChange={this.state.attributeChanged} />
                                </Grid>
                            }
                            {
                                this.state.profile && Object.keys(this.state.profile).length !== 0 && this.state.attributes.length !== 0 &&
                                attributeStatistics && attributeStatistics.type && this.showAnomaly() &&
                                <Grid item xs={12}>
                                    <AnomalyChart renderAnomaly={this.renderAnomaly} anomalyRule={this.state.anomalyRule} profileRuleEnable={this.profileRuleEnable} getRulePlan={this.getRulePlan} onFilter={(params) => this.showQueryFilter(params)} selectedAttribute={selectedAttribute} chartType={this.state.anomalyChart} properties={this.getAnomalyFilters()} attributeChange={this.state.attributeChanged} />
                                </Grid>
                            }
                            {
                                this.state.profile && Object.keys(this.state.profile).length !== 0 && this.state.attributes.length !== 0 &&
                                this.state.profile['Valid Values Check'] && this.state.profile['Valid Values Check'].status !== 'Failed' &&
                                <Grid item xs={12}>
                                    <ValueChart invalidQuery={this.state.invalidQuery[selectedAttribute.name] && this.state.invalidQuery[selectedAttribute.name]['Valid Values Check']} datasetId={this.props.datasetId} isActive={this.getRulePlan("Valid Values Check")} profileRuleEnable={this.profileRuleEnable} config={{ learning: this.props.profile_learning, query: this.props.profile_query }} attribute={selectedAttribute} profileData={this.state.profile} tabIndex={tabIndex} onFilter={(params) => this.showQueryFilter(params)} attributeChange={this.state.attributeChanged} />
                                </Grid>
                            }
                            {
                                this.state.profile && Object.keys(this.state.profile).length !== 0 && this.state.attributes.length !== 0 &&
                                attributeStatistics && attributeStatistics.type && getFieldType(attributeStatistics.type.toLowerCase()) === 'text' && this.state.profile['Soundex Frequency'] &&
                                <Grid item xs={7}>
                                    <SoundexChart isActive={this.getRulePlan("Soundex Frequency")} profileRuleEnable={this.profileRuleEnable} config={{ learning: this.props.profile_learning, query: this.props.profile_query }} attribute={selectedAttribute} profileData={this.state.profile} tabIndex={tabIndex} onFilter={(params) => this.showQueryFilter(params)} attributeChange={this.state.attributeChanged} />
                                </Grid>
                            }
                            {
                                this.state.profile && Object.keys(this.state.profile).length !== 0 && this.state.attributes.length !== 0 &&
                                this.state.profile['Case Sensitivity'] && this.state.profile['Case Sensitivity'].status !== 'Failed' &&
                                <Grid item xs={5}>
                                    <SpiderChart config={{ learning: this.props.profile_learning, query: this.props.profile_query }}
                                        isActive={this.getRulePlan("Case Sensitivity")}
                                        profileRuleEnable={this.profileRuleEnable}
                                        name={appConstants.charts.casesensitivity.name}
                                        id={appConstants.charts.casesensitivity.id}
                                        attribute={selectedAttribute}
                                        profileData={this.state.profile}
                                        tabIndex={tabIndex}
                                        onFilter={(params) => this.showQueryFilter(params)}
                                        attributeChange={this.state.attributeChanged} />
                                </Grid>
                            }
                            {
                                this.state.profile && Object.keys(this.state.profile).length !== 0 && this.state.attributes.length !== 0 &&
                                this.state.profile.Histogram && this.state.profile.Histogram.status !== "Failed" &&
                                <Grid item xs={12}>
                                    <HistogramChart attributeChange={this.state.attributeChanged} isActive={this.getRulePlan("Histogram")} profileRuleEnable={this.profileRuleEnable} tabIndex={tabIndex} attribute={selectedAttribute} profileData={this.state.profile} config={{ learning: this.props.profile_learning, query: this.props.profile_query }} onFilter={(params) => this.showQueryFilter(params)} />
                                </Grid>
                            }
                            {
                                this.state.profile && Object.keys(this.state.profile).length !== 0 && this.state.attributes.length !== 0 &&
                                this.state.profile.Range && this.state.profile.Range.status !== "Failed" &&
                                <Grid item xs={12}>
                                    <ExtremeValueChart invalidQuery={this.state.invalidQuery[selectedAttribute.name] && this.state.invalidQuery[selectedAttribute.name].Range} attributeChange={this.state.attributeChanged} isActive={this.getRulePlan("Range")} profileRuleEnable={this.profileRuleEnable} config={{ learning: this.props.profile_learning, query: this.props.profile_query }} attribute={selectedAttribute} profileData={this.state.profile} tabIndex={tabIndex} onFilter={(params) => this.showQueryFilter(params)} />
                                </Grid>
                            }
                            {
                                this.state.profile && Object.keys(this.state.profile).length !== 0 && this.state.attributes.length !== 0 &&
                                this.hasCustomRules() && attributeStatistics &&
                                <Grid item xs={12}>
                                    <HorizontalStackedBarChart yLabel="Rule"
                                        lengend={["Valid", "Invalid"]}
                                        colors={['#B4D9E8', '#F9A099']}
                                        showAxis
                                        chartWidth="90%"
                                        chartClass="custom-rule"
                                        attribute={attributeStatistics}
                                        tabIndex={tabIndex}
                                        chartType={appConstants.charts.customrule.type}
                                        name={appConstants.charts.customrule.name}
                                        id={appConstants.charts.customrule.id}
                                        chartData={this.setChartData("CustomRule")}
                                        onFilter={(params) => this.showQueryFilter(params)}
                                        config={{ learning: this.props.profile_learning, query: this.props.profile_query }}
                                        attributeChange={this.state.attributeChanged}
                                        invalidQuery={this.state.invalidQuery[selectedAttribute.name]}
                                    />
                                </Grid>
                            }
                            {
                                this.props.profile_rules &&
                                <Grid item xs={12}>
                                    <RulesStatistics
                                        profileRuleEnable={this.profileRuleEnable}
                                        getRulePlan={this.getRulePlan}
                                        getCountStatisticsRules={this.getCountStatisticsRules}
                                        enableCountStatisticsRule={this.enableCountStatisticsRule}
                                        redirectChart={this.redirectChart}
                                        rules={this.setChartData("Rules")}
                                        dq_score_threshold={dq_score_threshold}
                                    />
                                </Grid>
                            }
                            <QueryFilterModal open={this.state.queryFilterModal.isOpen}
                                selectedAttribute={
                                    {
                                        name: attributeStatistics.name
                                            ? attributeStatistics.name : '',
                                        type: attributeStatistics.type
                                            ? attributeStatistics.type : '',
                                        fieldType: attributeStatistics.fieldType ? attributeStatistics.fieldType : ''
                                    }
                                }
                                selectedDataset={this.props.selectedDataset}
                                properties={properties}
                                datasetId={this.props.datasetId}
                                scheduleId={this.state.scheduleLogId}
                                onClose={() => this.closeQueryFilter()}
                                getPreviewData={(params) => this.props.filterInputData(params)}
                                attributes={this.state.attributes}
                                userAttributes={this.props.userAttributes}
                                sensitivityLevel={sensitivityLevel}
                                selectedDatasource={this.props.datasource}
                                references={this.props.references}
                                {...this.state.queryFilterModal}
                            />

                        </Grid>
                    }
                    {/* {
                        Object.keys(this.state.profile).length !== 0 && this.state.attributes.length !== 0 &&
                        <Grid container spacing={2} className={classes.tabProfileContainer} ref={this.containerRef}>
                            <Grid item xs={12}>
                                <DatasetLevelProfile datasetId={this.props.datasetId} />
                            </Grid>
                        </Grid>
                    } */}
                </Grid>

                {
                    this.state.attributeStatistics && Object.keys(this.state.attributeStatistics).length === 0 && this.state.trending.length > 0 && !this.state.isLoading &&
                    <NoResultFound />
                }

                <ProfileInvalidRecordsPreview
                    open={this.state.openInvalidRecordsPreview}
                    datasetId={this.props.datasetId}
                    selectedAttribute={selectedAttribute}
                    invalidRecords={this.state.invalidRecords}
                    onClose={() => this.setState({ openInvalidRecordsPreview: false })} />
            </Grid>
        );
    }
}

DatasetProfile.propTypes = {
    classes: PropTypes.object,
    datasetId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tabIndex: PropTypes.number,
    attributeList: PropTypes.object,
    datasetName: PropTypes.string,
    getProfileAnalysis: PropTypes.func,
    theme: PropTypes.object,
    getCurationAnalysis: PropTypes.func,
    getTrending: PropTypes.func,
    filterInputData: PropTypes.func,
    properties: PropTypes.object,
    profileRuleEnable: PropTypes.func,
    getProfileRules: PropTypes.func,
    createdDate: PropTypes.string,
    selectedAttribute: PropTypes.string,
    sensitivityLevel: PropTypes.number,
    getAttributePropertyRules: PropTypes.func,
    enableCountStatisticsRule: PropTypes.func,
    setSelectedAttribute: PropTypes.func,
    user: PropTypes.object,
    statistical: PropTypes.array,
    count: PropTypes.array,
    systemConfiguration: PropTypes.bool,
    profile_query: PropTypes.bool,
    profile_learning: PropTypes.bool,
    profile_rules: PropTypes.bool,
    getAttributeStatistics: PropTypes.func,
    updateDatasetProperties: PropTypes.func,
    selectedDataset: PropTypes.object,
    selectedDatasourceId: PropTypes.number,
    datasource: PropTypes.object,
    getInvalidQueries: PropTypes.func,
    getPropertiesList: PropTypes.func,
    userAttributes: PropTypes.array,
    dq_score_threshold: PropTypes.object,
    references: PropTypes.array
};

const mapStateToProps = ({ setting, account, dataset, datasource }) => {
    return {
        ...setting.config,
        ...account,
        ...dataset,
        ...datasource,
        count: setting?.user_config?.profileSetting?.datasource_level_rule ? datasource?.datasource?.properties?.profileRules?.count : setting?.user_config?.profileRulesConfig?.count ?? [],
        statistical: setting?.user_config?.profileSetting?.datasource_level_rule ? datasource?.datasource?.properties?.profileRules?.statistical : setting?.user_config?.profileRulesConfig?.statistical
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getPropertiesList
    }, dispatch);

};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles((theme) => ({
    ...DatasetProfileStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DatasetProfile));