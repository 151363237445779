export const domainActions = {
    GET_DOMAIN_DETAIL_REQUEST: 'GET_DOMAIN_DETAIL_REQUEST',
    GET_DOMAIN_DETAIL_SUCCESS: 'GET_DOMAIN_DETAIL_SUCCESS',
    GET_DOMAIN_DETAIL_FAILURE: 'GET_DOMAIN_DETAIL_FAILURE',

    UPDATE_DOMAIN_DETAIL_REQUEST: 'UPDATE_DOMAIN_DETAIL_REQUEST',
    UPDATE_DOMAIN_DETAIL_SUCCESS: 'UPDATE_DOMAIN_DETAIL_SUCCESS',
    UPDATE_DOMAIN_DETAIL_FAILURE: 'UPDATE_DOMAIN_DETAIL_FAILURE',

    GET_DOMAIN_RULES_REQUEST: 'GET_DOMAIN_RULES_REQUEST',
    GET_DOMAIN_RULES_SUCCESS: 'GET_DOMAIN_RULES_SUCCESS',
    GET_DOMAIN_RULES_FAILURE: 'GET_DOMAIN_RULES_FAILURE',

    GET_DOMAIN_METRICS_REQUEST: 'GET_DOMAIN_METRICS_REQUEST',
    GET_DOMAIN_METRICS_SUCCESS: 'GET_DOMAIN_METRICS_SUCCESS',
    GET_DOMAIN_METRICS_FAILURE: 'GET_DOMAIN_METRICS_FAILURE',

    GET_DOMAIN_METRIC_ANALYSIS_REQUEST: 'GET_DOMAIN_METRIC_ANALYSIS_REQUEST',
    GET_DOMAIN_METRIC_ANALYSIS_SUCCESS: 'GET_DOMAIN_METRIC_ANALYSIS_SUCCESS',
    GET_DOMAIN_METRIC_ANALYSIS_FAILURE: 'GET_DOMAIN_METRIC_ANALYSIS_FAILURE',

    GET_DOMAIN_TIMELINE_REQUEST: 'GET_DOMAIN_TIMELINE_REQUEST',
    GET_DOMAIN_TIMELINE_SUCCESS: 'GET_DOMAIN_TIMELINE_SUCCESS',
    GET_DOMAIN_TIMELINE_FAILURE: 'GET_DOMAIN_TIMELINE_FAILURE',

    GET_DOMAIN_DIMENSIONS_REQUEST: 'GET_DOMAIN_DIMENSIONS_REQUEST',
    GET_DOMAIN_DIMENSIONS_SUCCESS: 'GET_DOMAIN_DIMENSIONS_SUCCESS',
    GET_DOMAIN_DIMENSIONS_FAILURE: 'GET_DOMAIN_DIMENSIONS_FAILURE',

    GET_DOMAIN_RULE_PROPERTY_REQUEST: 'GET_DOMAIN_RULE_PROPERTY_REQUEST',
    GET_DOMAIN_RULE_PROPERTY_SUCCESS: 'GET_DOMAIN_RULE_PROPERTY_SUCCESS',
    GET_DOMAIN_RULE_PROPERTY_FAILURE: 'GET_DOMAIN_RULE_PROPERTY_FAILURE',

    UPDATE_DOMAIN_RULE_PROPERTY_REQUEST: 'UPDATE_DOMAIN_RULE_PROPERTY_REQUEST',
    UPDATE_DOMAIN_RULE_PROPERTY_SUCCESS: 'UPDATE_DOMAIN_RULE_PROPERTY_SUCCESS',
    UPDATE_DOMAIN_RULE_PROPERTY_FAILURE: 'UPDATE_DOMAIN_RULE_PROPERTY_FAILURE',

    REMOVE_ATTRIBUTE_RULE_REQUEST: 'REMOVE_ATTRIBUTE_RULE_REQUEST',
    REMOVE_ATTRIBUTE_RULE_SUCCESS: 'REMOVE_ATTRIBUTE_RULE_SUCCESS',
    REMOVE_ATTRIBUTE_RULE_FAILURE: 'REMOVE_ATTRIBUTE_RULE_FAILURE',

    GET_ORGANIZATION_DOMAINS_REQUEST: 'GET_ORGANIZATION_DOMAINS_REQUEST',
    GET_ORGANIZATION_DOMAINS_SUCCESS: 'GET_ORGANIZATION_DOMAINS_SUCCESS',
    GET_ORGANIZATION_DOMAINS_FAILURE: 'GET_ORGANIZATION_DOMAINS_FAILURE',

    GET_DOMAIN_DATASOURCE_REQUEST: 'GET_DOMAIN_DATASOURCE_REQUEST',
    GET_DOMAIN_DATASOURCE_SUCCESS: 'GET_DOMAIN_DATASOURCE_SUCCESS',
    GET_DOMAIN_DATASOURCE_FAILURE: 'GET_DOMAIN_DATASOURCE_FAILURE',

    GET_DOMAIN_DATASET_REQUEST: 'GET_DOMAIN_DATASET_REQUEST',
    GET_DOMAIN_DATASET_SUCCESS: 'GET_DOMAIN_DATASET_SUCCESS',
    GET_DOMAIN_DATASET_FAILURE: 'GET_DOMAIN_DATASET_FAILURE',

    SELECT_DOMAIN_ID: 'SELECT_DOMAIN_ID',
    SET_ACTION_ITEM_COUNT: 'SET_ACTION_ITEM_COUNT',

    SET_FILTERS: 'SET_FILTERS',

    UPDATE_RULE_WEIGHTAGE_REQUEST: 'UPDATE_RULE_WEIGHTAGE_REQUEST',
    UPDATE_RULE_WEIGHTAGE_SUCCESS: 'UPDATE_RULE_WEIGHTAGE_SUCCESS',
    UPDATE_RULE_WEIGHTAGE_FAILURE: 'UPDATE_RULE_WEIGHTAGE_FAILURE',

    GET_DOMAIN_SEMANTIC_RULES_REQUEST: 'GET_DOMAIN_SEMANTIC_RULES_REQUEST',
    GET_DOMAIN_SEMANTIC_RULES_SUCCESS: 'GET_DOMAIN_SEMANTIC_RULES_SUCCESS',
    GET_DOMAIN_SEMANTIC_RULES_FAILURE: 'GET_DOMAIN_SEMANTIC_RULES_FAILURE',

    UPDATE_ATTRIBUTE_SEMANTIC_FIELD_REQUEST: 'UPDATE_ATTRIBUTE_SEMANTIC_FIELD_REQUEST',
    UPDATE_ATTRIBUTE_SEMANTIC_FIELD_SUCCESS: 'UPDATE_ATTRIBUTE_SEMANTIC_FIELD_SUCCESS',
    UPDATE_ATTRIBUTE_SEMANTIC_FIELD_FAILURE: 'UPDATE_ATTRIBUTE_SEMANTIC_FIELD_FAILURE'
};