import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, MenuItem, Typography, withStyles } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import ChartContainer from '../ChartContainer/ChartContainer.jsx';
import { job_status_chart } from '../../actions/scheduleActions';
import classNames from 'classnames';
import TextBox from '../TextBox/TextBox.jsx';
import StackedBarChart from '../Charts/StackedBarChart.jsx';
import AutoCompleteCheckBox from '../AutoCompleteCheckBox/AutoCompleteCheckBox.jsx';
import { getDatasourceList, getDatasetList, setSearchKey } from '../../actions/metricsActions';
import { sortTable } from '../../helpers/appHelpers.js';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import DashboardStyles from './DashboardStyles.jsx';
import { Link } from 'react-router-dom';

const timelineFilterOptions = [
    {
        name: 'All',
        value: 'all'
    },
    {
        name: 'Today',
        value: '0'
    },
    {
        name: 'Past 3 Days',
        value: '3'
    },
    {
        name: 'Past 7 Days',
        value: '7'
    },
    {
        name: 'Past Month',
        value: '30'
    }
];

const jobTypeOptions = [
    { id: 'Pull', name: 'Pull', isSelected: false },
    { id: 'Push', name: 'Push', isSelected: false },
    { id: 'Sense', name: 'Sense', isSelected: false },
    { id: 'Profile', name: 'Profile', isSelected: false },
    { id: 'Curate', name: 'Curate', isSelected: false },
    { id: 'Scan', name: 'Scan', isSelected: false },
    { id: "ExportMetrics", name: "Export Metrics", isSelected: false },
    { id: "Monitoring", name: "Monitoring", isSelected: false },
    { id: "CustomReport", name: "Custom Report", isSelected: false },
    { id: "Catalog", name: "Catalog", isSelected: false },
    { id: "Learning", name: "Learning", isSelected: false },
    { id: "Enrich", name: "Enrich", isSelected: false }
];

const DashboardJobStatus = (props) => {
    const { classes, theme } = props;
    const dispatch = useDispatch();
    const [widgetData, setWidgetData] = useState({});
    const [recentRunJobs, setRecentRunJobs] = useState([]);
    const [currentRunJobs, setCurrentRunJobs] = useState([]);
    const containerRef = useRef();
    const [datasourceFilter, setDatasourceFilter] = useState([]);
    const [datasetFilter, setDatasetFilter] = useState([]);
    const [jobFilter, setJobFilter] = useState([]);
    const [datasourceList, setDatasourceList] = useState([]);
    const [datasetList, setDatasetList] = useState([]);
    const [jobTypeList, setJobTypeList] = useState(jobTypeOptions);
    const [timelineFilter, setTimelineFilter] = useState('all');

    const datasourceControllerRef = useRef();
    const datasetControllerRef = useRef();

    const datasources = useSelector(({ metric }) => metric?.datasources?.datasources);
    const datasets = useSelector(({ metric }) => metric?.datasets?.datasets);

    useEffect(() => {
        const datasource_ids = datasourceFilter.length === 0 ? 'all' : datasourceFilter.map((elem) => elem.id);
        const dataset_ids = datasetFilter.length === 0 ? 'all' : datasetFilter.map((elem) => elem.id);
        const jobTypes = jobFilter.length === 0 ? 'all' : jobFilter.map((elem) => elem.id);
        getChartData({ datasources: datasource_ids, datasets: dataset_ids, job_type: jobTypes, timeline: timelineFilter });
    }, [datasourceFilter, datasetFilter, jobFilter, timelineFilter]);

    useEffect(() => {
        const selectedItems = datasources.map((item) => {
            return { ...item, isSelected: datasourceFilter.some((elem) => elem.id === item.id) };
        });
        setDatasourceList(selectedItems);
    }, [datasources]);

    useEffect(() => {
        const selectedItems = datasets.map((item) => {
            return { ...item, isSelected: datasetFilter.some((elem) => elem.id === item.id) };
        });
        setDatasetList(selectedItems);
    }, [datasets]);

    const getChartData = (requestParams) => {
        dispatch(job_status_chart(requestParams)).then((response) => {
            const chart_data = response?.chart_data || [];
            const recent_run_jobs = response?.recent_run_jobs || [];
            const current_run_jobs = response?.current_run_jobs || [];
            const legends = [];
            const chartData = [];
            chart_data.forEach((elem) => {
                if (legends.indexOf(elem.type) < 0) {
                    legends.push(elem.type);
                }
                const index = chartData.findIndex((item) => item.name === elem.status);
                if (index > -1) {
                    const chartDataKey = Object.keys(chartData[index]);
                    const keyIndex = chartDataKey.indexOf(elem.type);
                    if (keyIndex > -1) {
                        chartData[index] = {
                            ...chartData[index],
                            [elem.type]: chartData[index][elem.type] + elem.count,
                            totalCount: chartData[index].totalCount + elem.count
                        };
                    } else {
                        chartData[index] = {
                            ...chartData[index],
                            [elem.type]: elem.count,
                            totalCount: chartData[index].totalCount + elem.count
                        };
                    }
                } else {
                    chartData.push({
                        date: elem.status,
                        name: elem.status,
                        [elem.type]: elem.count,
                        totalCount: elem.count
                    });
                }
            });
            setWidgetData({ data: chartData, legends: legends });
            setRecentRunJobs(recent_run_jobs);
            setCurrentRunJobs(current_run_jobs);
        });
    };

    const loadDataSources = useCallback((search_key = '') => {
        if (datasourceControllerRef && datasourceControllerRef.current) {
            datasourceControllerRef.current.abort();
        }
        const controller = new AbortController();
        datasourceControllerRef.current = controller;
        const token = { signal: datasourceControllerRef?.current?.signal };

        const key = search_key ? search_key : 'all';
        const requestParams = {
            datasource_ids: datasourceFilter,
            key
        };
        dispatch(setSearchKey('datasource', key));
        dispatch(getDatasourceList(requestParams, token));
    }, [dispatch]);

    const loadDatasets = useCallback((search_key = '') => {
        if (!datasourceFilter || (datasourceFilter && datasourceFilter?.length === 0)) {
            return;
        }

        if (datasetControllerRef && datasetControllerRef.current) {
            datasetControllerRef.current.abort();
        }
        const controller = new AbortController();
        datasetControllerRef.current = controller;
        const token = { signal: datasetControllerRef?.current?.signal };

        const key = search_key ? search_key : 'all';
        let datasource_ids = datasourceFilter;
        if (datasource_ids) {
            datasource_ids = datasource_ids.map((data) => (typeof data === 'object' && 'id' in data ? data.id : data));
        }
        const requestParams = {
            datasources: datasource_ids,
            datasets: datasetFilter ? datasetFilter : [],
            key
        };
        dispatch(setSearchKey('dataset', key));
        dispatch(getDatasetList(requestParams, token));
    }, [datasourceFilter]);

    const onFilter = useCallback((property, value) => {
        if (value) {
            if (property === 'timelineFilter') {
                setTimelineFilter(value);
            } else if (property === 'datasourceFilter') {
                setDatasetFilter([]);
                if (value.name === 'all') {
                    const selectedItems = datasourceList.map((item) => { return { ...item, isSelected: value.isSelected }; });
                    // if (!value.isSelected) {
                    //     setDatasetFilter([]);
                    // }
                    setDatasourceFilter(selectedItems.filter((elem) => elem.isSelected));
                    setDatasourceList(selectedItems);
                } else {
                    const index = datasourceFilter.findIndex((elem) => elem.id === value.id);
                    const datasourceFilterCopy = [...datasourceFilter];
                    // let datasetFilterCopy = [...datasetFilter];
                    if (index > -1) {
                        // const capturedDatasource = datasourceFilterCopy[index];
                        // datasetFilterCopy = datasetFilterCopy.filter((elem) => elem.source_id !== capturedDatasource.id);
                        datasourceFilterCopy.splice(index, 1);
                    } else {
                        datasourceFilterCopy.push({ ...value, isSelected: true });
                    }
                    const selectedItems = datasourceList.map((item) => {
                        if (value.id === item.id) {
                            return { ...item, isSelected: datasourceFilterCopy.some((elem) => elem.id === value.id) };
                        }
                        return item;
                    });
                    // const selectedDatasets = datasetList.map((item) => {
                    //     if (value.id === item.id) {
                    //         return { ...item, isSelected: datasetFilterCopy.some((elem) => elem.id === value.id) };
                    //     }
                    //     return item;
                    // });
                    setDatasourceFilter(datasourceFilterCopy);
                    setDatasourceList(selectedItems);
                    // setDatasetFilter(datasetFilterCopy);
                    // setDatasetList(selectedDatasets);
                }
            } else if (property === 'datasetFilter') {
                if (value.name === 'all') {
                    const selectedItems = datasetList.map((item) => { return { ...item, isSelected: value.isSelected }; });
                    setDatasetFilter(selectedItems.filter((elem) => elem.isSelected));
                    setDatasetList(selectedItems);
                } else {
                    const index = datasetFilter.findIndex((elem) => elem.id === value.id);
                    const datasetFilterCopy = [...datasetFilter];
                    if (index > -1) {
                        datasetFilterCopy.splice(index, 1);
                    } else {
                        datasetFilterCopy.push({ ...value, isSelected: true });
                    }
                    const selectedItems = datasetList.map((item) => {
                        if (value.id === item.id) {
                            return { ...item, isSelected: datasetFilterCopy.some((elem) => elem.id === value.id) };
                        }
                        return item;
                    });
                    setDatasetFilter(datasetFilterCopy);
                    setDatasetList(selectedItems);
                }
            } else if (property === 'jobFilter') {
                if (value.name === 'all') {
                    const selectedItems = jobTypeList.map((item) => { return { ...item, isSelected: value.isSelected }; });
                    setJobFilter(selectedItems.filter((elem) => elem.isSelected));
                    setJobTypeList(selectedItems);
                } else {
                    const index = jobFilter.findIndex((elem) => elem.id === value.id);
                    const jobFilterCopy = [...jobFilter];
                    if (index > -1) {
                        jobFilterCopy.splice(index, 1);
                    } else {
                        jobFilterCopy.push({ ...value, isSelected: true });
                    }
                    const selectedItems = jobTypeList.map((item) => {
                        if (value.id === item.id) {
                            return { ...item, isSelected: jobFilterCopy.some((elem) => elem.id === value.id) };
                        }
                        return item;
                    });
                    setJobFilter(jobFilterCopy);
                    setJobTypeList(selectedItems);
                }
            }
        }
        // widgetUnCountData(value);
    }, [datasourceList, datasetList, datasourceFilter, datasetFilter, timelineFilter, jobFilter, jobTypeList]);

    const DropDownIcon = (iconProps) => {
        return (
            <svg {...iconProps} className={classes.DropDownIcon} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="12px" height="18px" viewBox="0 0 451.847 451.847">
                <g>
                    <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" fill={theme.palette.grey.dark} />
                </g>
            </svg>
        );
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <ChartContainer
                    title="Status of the Jobs"
                    chartData
                >
                    <Grid container spacing={1} style={{ marginTop: 12 }}>
                        <Grid item xs={9}>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <AutoCompleteCheckBox
                                        value={datasourceFilter}
                                        selectionData={datasourceFilter}
                                        availableList={sortTable(datasourceList ? datasourceList : [], "asc", "name")}
                                        displayValue="name"
                                        onChange={(value) => onFilter('datasourceFilter', value)}
                                        onSearch={(value) => loadDataSources(value)}
                                        fullWidth
                                        label="Datasources"
                                        noMargin
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <AutoCompleteCheckBox
                                        value={datasetFilter}
                                        selectionData={datasetFilter}
                                        availableList={sortTable(datasetList ? datasetList : [], "asc", "name")}
                                        displayValue="name"
                                        onChange={(value) => onFilter('datasetFilter', value)}
                                        onSearch={(value) => loadDatasets(value)}
                                        fullWidth
                                        label="Dataset"
                                        noMargin
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <AutoCompleteCheckBox
                                        value={jobFilter}
                                        selectionData={jobFilter}
                                        availableList={sortTable(jobTypeList, "asc", "name")}
                                        displayValue="name"
                                        onChange={(value) => onFilter('jobFilter', value)}
                                        fullWidth
                                        label="Job Type"
                                        noMargin
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3} style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                            <TextBox
                                className={classNames(classes.inlinetxt, classes.minWidth)}
                                onChange={(event) => onFilter('timelineFilter', event.target.value)}
                                value={timelineFilter}
                                select
                                SelectProps={
                                    {
                                        MenuProps: {
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "center"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "center"
                                            },
                                            getContentAnchorEl: null
                                        },
                                        IconComponent: (iconProps) => DropDownIcon(iconProps)
                                    }
                                }
                            >
                                {
                                    timelineFilterOptions.map((option, index) => (
                                        <MenuItem key={`menuProperty_Options_${index}`} value={option.value} className={classes.menuItem}>
                                            {option.name}
                                        </MenuItem>
                                    ))
                                }
                            </TextBox>
                        </Grid>
                        <Grid item xs={12} ref={containerRef}>
                            <StackedBarChart
                                colors={["#ffaf97", "#b2dbf7", "#f295b6", "#85e8ef", "#656d98", "#676B97", "#CE98DE", "#F16C9A", "#F194B3", "#F6BDD1", "#6BB3D2", "#ABDBFA", "#92DDE5", "#6CCCCA", "#A5DBCO", "#A7DF97", "#7DC460", "#E3F792", "#F8AD93"]}
                                xAxis={widgetData && widgetData.xaxis ? widgetData.xaxis : "date"}
                                yAxis={widgetData && widgetData.yaxis ? widgetData.yaxis : "totalCount"}
                                legends={widgetData && widgetData.legends ? widgetData.legends : []}
                                showXAxis
                                chartData={widgetData && widgetData.data ? widgetData.data : []}
                                showYAxis
                                chartClassName={`status_of_the_jobs`}
                                height={400}
                                widgetDirection="vertical"
                                xAxisType="string"
                                yAxisType="number"
                                xAxisTypeDate={false}
                                mouseOverData={widgetData?.mouseOverData || null}
                            />
                        </Grid>
                    </Grid>
                </ChartContainer>
            </Grid>
            <Grid item xs={3}>
                <ChartContainer
                    title="Recently Run Jobs"
                    chartData
                >
                    {
                        recentRunJobs.map((item, i) => (
                            <Grid key={i} container alignItems="center" wrap="nowrap" className={classes.viewList} style={{ marginTop: 12 }}>
                                <Grid item className={classes.viewListData}>
                                    <ToolTipComponent title={item.name} arrow>
                                        <Link style={{ textDecoration: 'unset', color: 'inherit' }} to={item.dataset_id ? `/dataset/${item.dataset_id}` : `/catalog/${item.source_id}`} >
                                            <Typography style={{ fontWeight: 500 }}>
                                                {item.name}
                                            </Typography>
                                        </Link>
                                    </ToolTipComponent>
                                    <Typography className={classes.viewListDateTxt}>
                                        {item.message}
                                    </Typography>
                                </Grid>

                            </Grid>
                        ))
                    }
                </ChartContainer>
            </Grid>
            <Grid item xs={3}>
                <ChartContainer
                    title="Currently Running Jobs"
                    chartData
                >
                    {
                        currentRunJobs.map((item, i) => (
                            <Grid key={i} container alignItems="center" wrap="nowrap" className={classes.viewList} style={{ marginTop: 12 }}>
                                <Grid item className={classes.viewListData}>
                                    <ToolTipComponent title={item.name} arrow>
                                        <Link style={{ textDecoration: 'unset', color: 'inherit' }} to={item.dataset_id ? `/dataset/${item.dataset_id}` : `/catalog/${item.source_id}`} >
                                            <Typography style={{ fontWeight: 500 }}>
                                                {item.name}
                                            </Typography>
                                        </Link>
                                    </ToolTipComponent>
                                    <Typography className={classes.viewListDateTxt}>
                                        {item.message}
                                    </Typography>
                                </Grid>

                            </Grid>
                        ))
                    }
                </ChartContainer>
            </Grid>
        </Grid>
    );
};

DashboardJobStatus.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object
};

export default withStyles((theme) => ({
    ...DashboardStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DashboardJobStatus);