export const settingActions = {
    GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',
    GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
    GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',

    SET_PROPERTIES_SUCCESS: 'SET_PROPERTIES_SUCCESS',
    SET_PROPERTIES_ORGANIZATION_SUCCESS: 'SET_PROPERTIES_ORGANIZATION_SUCCESS',

    UPDATE_PROFILE_REQUEST: 'UPDATE_PROFILE_REQUEST',
    UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
    UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE',

    GET_ORGANIZATONS_REQUEST: 'GET_ORGANIZATONS_REQUEST',
    GET_ORGANIZATONS_SUCCESS: 'GET_ORGANIZATONS_SUCCESS',
    GET_ORGANIZATONS_FAILURE: 'GET_ORGANIZATONS_FAILURE',

    CREATE_ORGANIZATION_REQUEST: 'CREATE_ORGANIZATION_REQUEST',
    CREATE_ORGANIZATION_SUCCESS: 'CREATE_ORGANIZATION_SUCCESS',
    CREATE_ORGANIZATION_FAILURE: 'CREATE_ORGANIZATION_FAILURE',

    GET_ORGANIZATION_DETAIL_REQUEST: 'GET_ORGANIZATION_DETAIL_REQUEST',
    GET_ORGANIZATION_DETAIL_SUCCESS: 'GET_ORGANIZATION_DETAIL_SUCCESS',
    GET_ORGANIZATION_DETAIL_FAILURE: 'GET_ORGANIZATION_DETAIL_FAILURE',

    GET_ROLES_REQUEST: 'GET_ROLES_REQUEST',
    GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
    GET_ROLES_FAILURE: 'GET_ROLES_FAILURE',

    DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',

    UPLOAD_USER_PROFILE_REQUEST: 'UPLOAD_USER_PROFILE_REQUEST',
    UPLOAD_USER_PROFILE_SUCCESS: 'UPLOAD_USER_PROFILE_SUCCESS',
    UPLOAD_USER_PROFILE_FAILURE: 'UPLOAD_USER_PROFILE_FAILURE',

    GET_REFERENCE_LIBRARY_REQUEST: 'GET_REFERENCE_LIBRARY_REQUEST',
    GET_REFERENCE_LIBRARY_SUCCESS: 'GET_REFERENCE_LIBRARY_SUCCESS',
    GET_REFERENCE_LIBRARY_FAILURE: 'GET_REFERENCE_LIBRARY_FAILURE',

    GET_ENRICHMENT_LIBRARY_REQUEST: 'GET_ENRICHMENT_LIBRARY_REQUEST',
    GET_ENRICHMENT_LIBRARY_SUCCESS: 'GET_ENRICHMENT_LIBRARY_SUCCESS',
    GET_ENRICHMENT_LIBRARY_FAILURE: 'GET_ENRICHMENT_LIBRARY_FAILURE',

    UPDATE_REFERENCE_LIBRARY_REQUEST: 'UPDATE_REFERENCE_LIBRARY_REQUEST',
    UPDATE_REFERENCE_LIBRARY_SUCCESS: 'UPDATE_REFERENCE_LIBRARY_SUCCESS',
    UPDATE_REFERENCE_LIBRARY_FAILURE: 'UPDATE_REFERENCE_LIBRARY_FAILURE',

    UPDATE_ENRICHMENT_LIBRARY_REQUEST: 'UPDATE_ENRICHMENT_LIBRARY_REQUEST',
    UPDATE_ENRICHMENT_LIBRARY_SUCCESS: 'UPDATE_ENRICHMENT_LIBRARY_SUCCESS',
    UPDATE_ENRICHMENT_LIBRARY_FAILURE: 'UPDATE_ENRICHMENT_LIBRARY_FAILURE',

    UPLOAD_REFERENCE_LIBARARY_REQUEST: 'UPLOAD_REFERENCE_LIBARARY_REQUEST',
    UPLOAD_REFERENCE_LIBARARY_SUCCESS: 'UPLOAD_REFERENCE_LIBARARY_SUCCESS',
    UPLOAD_REFERENCE_LIBARARY_FAILURE: 'UPLOAD_REFERENCE_LIBARARY_FAILURE',

    UPLOAD_ENRICHMENT_LIBARARY_REQUEST: 'UPLOAD_ENRICHMENT_LIBARARY_REQUEST',
    UPLOAD_ENRICHMENT_LIBARARY_SUCCESS: 'UPLOAD_ENRICHMENT_LIBARARY_SUCCESS',
    UPLOAD_ENRICHMENT_LIBARARY_FAILURE: 'UPLOAD_ENRICHMENT_LIBARARY_FAILURE',

    DELETE_REFERENCE_LIBARARY_REQUEST: 'DELETE_REFERENCE_LIBARARY_REQUEST',
    DELETE_REFERENCE_LIBARARY_SUCCESS: 'DELETE_REFERENCE_LIBARARY_SUCCESS',
    DELETE_REFERENCE_LIBARARY_FAILURE: 'DELETE_REFERENCE_LIBARARY_FAILURE',

    DELETE_ENRICHMENT_LIBARARY_REQUEST: 'DELETE_ENRICHMENT_LIBARARY_REQUEST',
    DELETE_ENRICHMENT_LIBARARY_SUCCESS: 'DELETE_ENRICHMENT_LIBARARY_SUCCESS',
    DELETE_ENRICHMENT_LIBARARY_FAILURE: 'DELETE_ENRICHMENT_LIBARARY_FAILURE',

    UPDATE_ORGANIZATION_REQUEST: 'UPDATE_ORGANIZATION_REQUEST',
    UPDATE_ORGANIZATION_SUCCESS: 'UPDATE_ORGANIZATION_SUCCESS',
    UPDATE_ORGANIZATION_FAILURE: 'UPDATE_ORGANIZATION_FAILURE',

    DELETE_ORGANIZATION_REQUEST: 'DELETE_ORGANIZATION_REQUEST',
    DELETE_ORGANIZATION_SUCCESS: 'DELETE_ORGANIZATION_SUCCESS',
    DELETE_ORGANIZATION_FAILURE: 'DELETE_ORGANIZATION_FAILURE',

    GET_THEME_REQUEST: 'GET_THEME_REQUEST',
    GET_THEME_SUCCESS: 'GET_THEME_SUCCESS',
    GET_THEME_FAILURE: 'GET_THEME_FAILURE',

    UPDATE_THEME_REQUEST: 'UPDATE_THEME_REQUEST',
    UPDATE_THEME_SUCCESS: 'UPDATE_THEME_SUCCESS',
    UPDATE_THEME_FAILURE: 'UPDATE_THEME_FAILURE',

    UPLOAD_THEME_FILE_REQUEST: 'UPLOAD_THEME_FILE_REQUEST',
    UPLOAD_THEME_FILE_SUCCESS: 'UPLOAD_THEME_FILE_SUCCESS',
    UPLOAD_THEME_FILE_FAILURE: 'UPLOAD_THEME_FILE_FAILURE',

    SET_THEME: 'SET_THEME',

    GET_MODULE_CONFIG_REQUEST: 'GET_MODULE_CONFIG_REQUEST',
    GET_MODULE_CONFIG_SUCCESS: 'GET_MODULE_CONFIG_SUCCESS',
    GET_MODULE_CONFIG_FAILURE: 'GET_MODULE_CONFIG_FAILURE',

    UPDATE_MODULE_CONFIG_REQUEST: 'UPDATE_MODULE_CONFIG_REQUEST',
    UPDATE_MODULE_CONFIG_SUCCESS: 'UPDATE_MODULE_CONFIG_SUCCESS',
    UPDATE_MODULE_CONFIG_FAILURE: 'UPDATE_MODULE_CONFIG_FAILURE',

    GET_STORAGE_CONFIG_REQUEST: 'GET_STORAGE_CONFIG_REQUEST',
    GET_STORAGE_CONFIG_SUCCESS: 'GET_STORAGE_CONFIG_SUCCESS',
    GET_STORAGE_CONFIG_FAILURE: 'GET_STORAGE_CONFIG_FAILURE',

    UPDATE_STORAGE_CONFIG_REQUEST: 'UPDATE_STORAGE_CONFIG_REQUEST',
    UPDATE_STORAGE_CONFIG_SUCCESS: 'UPDATE_STORAGE_CONFIG_SUCCESS',
    UPDATE_STORAGE_CONFIG_FAILURE: 'UPDATE_STORAGE_CONFIG_FAILURE',

    GET_ORGANIZATION_CONFIG_REQUEST: 'GET_ORGANIZATION_CONFIG_REQUEST',
    GET_ORGANIZATION_CONFIG_SUCCESS: 'GET_ORGANIZATION_CONFIG_SUCCESS',
    GET_ORGANIZATION_CONFIG_FAILURE: 'GET_ORGANIZATION_CONFIG_FAILURE',

    LOAD_DEFAULT_CONFIG_REQUEST: 'LOAD_DEFAULT_CONFIG_REQUEST',
    LOAD_DEFAULT_CONFIG_SUCCESS: 'LOAD_DEFAULT_CONFIG_SUCCESS',
    LOAD_DEFAULT_CONFIG_FAILURE: 'LOAD_DEFAULT_CONFIG_FAILURE',

    GET_PROPERTIES_REQUEST: 'GET_PROPERTIES_REQUEST',
    GET_PROPERTIES_SUCCESS: 'GET_PROPERTIES_SUCCESS',
    GET_PROPERTIES_FAILURE: 'GET_PROPERTIES_FAILURE',

    GET_PROPERTIES_ORGANIZATION_REQUEST: 'GET_PROPERTIES_ORGANIZATION_REQUEST',
    GET_PROPERTIES_ORGANIZATION_SUCCESS: 'GET_PROPERTIES_ORGANIZATION_SUCCESS',
    GET_PROPERTIES_ORGANIZATION_FAILURE: 'GET_PROPERTIES_ORGANIZATION_FAILURE',

    GET_LICENSE_REQUEST: 'GET_LICENSE_REQUEST',
    GET_LICENSE_SUCCESS: 'GET_LICENSE_SUCCESS',
    GET_LICENSE_FAILURE: 'GET_LICENSE_FAILURE',

    VALIDATE_LICENSE_REQUEST: 'VALIDATE_LICENSE_REQUEST',
    VALIDATE_LICENSE_SUCCESS: 'VALIDATE_LICENSE_SUCCESS',
    VALIDATE_LICENSE_FAILURE: 'VALIDATE_LICENSE_FAILURE',

    UPDATE_PROPERTIES_REQUEST: 'UPDATE_PROPERTIES_REQUEST',
    UPDATE_PROPERTIES_SUCCESS: 'UPDATE_PROPERTIES_SUCCESS',
    UPDATE_PROPERTIES_FAILURE: 'UPDATE_PROPERTIES_FAILURE',

    GET_VERSION_REQUEST: 'GET_VERSION_REQUEST',
    GET_VERSION_SUCCESS: 'GET_VERSION_SUCCESS',
    GET_VERSION_FAILURE: 'GET_VERSION_FAILURE',

    GET_SSODOMAIN_REQUEST: 'GET_SSODOMAIN_REQUEST',
    GET_SSODOMAIN_SUCCESS: 'GET_SSODOMAIN_SUCCESS',
    GET_SSODOMAIN_FAILURE: 'GET_SSODOMAIN_FAILURE',

    GET_DNS_DETAILS_REQUEST: 'GET_DNS_DETAILS_REQUEST',
    GET_DNS_DETAILS_SUCCESS: 'GET_DNS_DETAILS_SUCCESS',
    GET_DNS_DETAILS_FAILURE: 'GET_DNS_DETAILS_FAILURE',

    GET_ENRICHMENTS_REQUEST: 'GET_ENRICHMENTS_REQUEST',
    GET_ENRICHMENTS_SUCCESS: 'GET_ENRICHMENTS_SUCCESS',
    GET_ENRICHMENTS_FAILURE: 'GET_ENRICHMENTS_FAILURE',

    CREATE_ENRICHMENT_REQUEST: 'CREATE_ENRICHMENT_REQUEST',
    CREATE_ENRICHMENT_SUCCESS: 'CREATE_ENRICHMENT_SUCCESS',
    CREATE_ENRICHMENT_FAILURE: 'CREATE_ENRICHMENT_FAILURE',

    GET_ENRICHMENT_DETAIL_REQUEST: 'GET_ENRICHMENT_DETAIL_REQUEST',
    GET_ENRICHMENT_DETAIL_SUCCESS: 'GET_ENRICHMENT_DETAIL_SUCCESS',
    GET_ENRICHMENT_DETAIL_FAILURE: 'GET_ENRICHMENT_DETAIL_FAILURE',

    UPDATE_ENRICHMENT_REQUEST: 'UPDATE_ENRICHMENT_REQUEST',
    UPDATE_ENRICHMENT_SUCCESS: 'UPDATE_ENRICHMENT_SUCCESS',
    UPDATE_ENRICHMENT_FAILURE: 'UPDATE_ENRICHMENT_FAILURE',

    DELETE_ENRICHMENT_REQUEST: 'DELETE_ENRICHMENT_REQUEST',
    DELETE_ENRICHMENT_SUCCESS: 'DELETE_ENRICHMENT_SUCCESS',
    DELETE_ENRICHMENT_FAILURE: 'DELETE_ENRICHMENT_FAILURE',

    /**
     * @name FieldReduxConstants
     * @description Organization Field Redux Constants Declaration
     * @author Ashok
     */

    GETALL_ORGANIZATION_FIELD_REQUEST: 'GETALL_ORGANIZATION_FIELD_REQUEST',
    GETALL_ORGANIZATION_FIELD_SUCCESS: 'GETALL_ORGANIZATION_FIELD_SUCCESS',
    GETALL_ORGANIZATION_FIELD_FAILURE: 'GETALL_ORGANIZATION_FIELD_FAILURE',

    GETALL_ORGANIZATION_FIELD_BY_LEVEL_REQUEST: 'GETALL_ORGANIZATION_FIELD_BY_LEVEL_REQUEST',
    GETALL_ORGANIZATION_FIELD_BY_LEVEL_SUCCESS: 'GETALL_ORGANIZATION_FIELD_BY_LEVEL_SUCCESS',
    GETALL_ORGANIZATION_FIELD_BY_LEVEL_FAILURE: 'GETALL_ORGANIZATION_FIELD_BY_LEVEL_FAILURE',

    CREATE_ORGANIZATION_FIELD_REQUEST: 'CREATE_ORGANIZATION_FIELD_REQUEST',
    CREATE_ORGANIZATION_FIELD_SUCCESS: 'CREATE_ORGANIZATION_FIELD_SUCCESS',
    CREATE_ORGANIZATION_FIELD_FAILURE: 'CREATE_ORGANIZATION_FIELD_FAILURE',

    UPDATE_ORGANIZATION_FIELD_REQUEST: 'UPDATE_ORGANIZATION_FIELD_REQUEST',
    UPDATE_ORGANIZATION_FIELD_SUCCESS: 'UPDATE_ORGANIZATION_FIELD_SUCCESS',
    UPDATE_ORGANIZATION_FIELD_FAILURE: 'UPDATE_ORGANIZATION_FIELD_FAILURE',

    UPDATE_ORGANIZATION_GROUP_REQUEST: 'UPDATE_ORGANIZATION_GROUP_REQUEST',
    UPDATE_ORGANIZATION_GROUP_SUCCESS: 'UPDATE_ORGANIZATION_GROUP_SUCCESS',
    UPDATE_ORGANIZATION_GROUP_FAILURE: 'UPDATE_ORGANIZATION_GROUP_FAILURE',

    UPDATE_ORGANIZATION_GROUP_ORDER_REQUEST: 'UPDATE_ORGANIZATION_GROUP_ORDER_REQUEST',
    UPDATE_ORGANIZATION_GROUP_ORDER_SUCCESS: 'UPDATE_ORGANIZATION_GROUP_ORDER_SUCCESS',
    UPDATE_ORGANIZATION_GROUP_ORDER_FAILURE: 'UPDATE_ORGANIZATION_GROUP_ORDER_FAILURE',

    DELETE_ORGANIZATION_FIELD_REQUEST: 'DELETE_ORGANIZATION_FIELD_REQUEST',
    DELETE_ORGANIZATION_FIELD_SUCCESS: 'DELETE_ORGANIZATION_FIELD_SUCCESS',
    DELETE_ORGANIZATION_FIELD_FAILURE: 'DELETE_ORGANIZATION_FIELD_FAILURE',

    EXPORT_REPORT: 'EXPORT_REPORT',
    IMPORT_REPORT: 'IMPORT_REPORT',

    GET_ORGANIZATION_NOTIFICATION_CONFIG_REQUEST: 'GET_ORGANIZATION_NOTIFICATION_CONFIG_REQUEST',
    GET_ORGANIZATION_NOTIFICATION_CONFIG_SUCCESS: 'GET_ORGANIZATION_NOTIFICATION_CONFIG_SUCCESS',
    GET_ORGANIZATION_NOTIFICATION_CONFIG_FAILURE: 'GET_ORGANIZATION_NOTIFICATION_CONFIG_FAILURE',

    UPDATE_ORGANIZATION_NOTIFICATION_CONFIG_REQUEST: 'UPDATE_ORGANIZATION_NOTIFICATION_CONFIG_REQUEST',
    UPDATE_ORGANIZATION_NOTIFICATION_CONFIG_SUCCESS: 'UPDATE_ORGANIZATION_NOTIFICATION_CONFIG_SUCCESS',
    UPDATE_ORGANIZATION_NOTIFICATION_CONFIG_FAILURE: 'UPDATE_ORGANIZATION_NOTIFICATION_CONFIG_FAILURE',

    GENERATE_ORGANIZATION_TOKEN_REQUEST: 'GENERATE_ORGANIZATION_TOKEN_REQUEST',
    GENERATE_ORGANIZATION_TOKEN_SUCCESS: 'GENERATE_ORGANIZATION_TOKEN_SUCCESS',
    GENERATE_ORGANIZATION_TOKEN_FAILURE: 'GENERATE_ORGANIZATION_TOKEN_FAILURE',

    REVOKE_ORGANIZATION_TOKEN_REQUEST: 'REVOKE_ORGANIZATION_TOKEN_REQUEST',
    REVOKE_ORGANIZATION_TOKEN_SUCCESS: 'REVOKE_ORGANIZATION_TOKEN_SUCCESS',
    REVOKE_ORGANIZATION_TOKEN_FAILURE: 'REVOKE_ORGANIZATION_TOKEN_FAILURE',

    GET_ORGANIZATION_TOKEN_REQUEST: 'GET_ORGANIZATION_TOKEN_REQUEST',
    GET_ORGANIZATION_TOKEN_SUCCESS: 'GET_ORGANIZATION_TOKEN_SUCCESS',
    GET_ORGANIZATION_TOKEN_FAILURE: 'GET_ORGANIZATION_TOKEN_FAILURE',

    CREATE_GLOSSARY_LIST_REQUEST: 'CREATE_GLOSSARY_LIST_REQUEST',
    CREATE_GLOSSARY_LIST_SUCCESS: 'CREATE_GLOSSARY_LIST_SUCCESS',
    CREATE_GLOSSARY_LIST_FAILURE: 'CREATE_GLOSSARY_LIST_FAILURE',

    GET_GLOSSARY_LIST_REQUEST: 'GET_GLOSSARY_LIST_REQUEST',
    GET_GLOSSARY_LIST_SUCCESS: 'GET_GLOSSARY_LIST_SUCCESS',
    GET_GLOSSARY_LIST_FAILURE: 'GET_GLOSSARY_LIST_FAILURE',

    GET_GLOSSARY_LIST_BY_ID_REQUEST: 'GET_GLOSSARY_LIST_BY_ID_REQUEST',
    GET_GLOSSARY_LIST_BY_ID_SUCCESS: 'GET_GLOSSARY_LIST_BY_ID_SUCCESS',
    GET_GLOSSARY_LIST_BY_ID_FAILURE: 'GET_GLOSSARY_LIST_BY_ID_FAILURE',

    UPDATE_GLOSSARY_LIST_REQUEST: 'UPDATE_GLOSSARY_LIST_REQUEST',
    UPDATE_GLOSSARY_LIST_SUCCESS: 'UPDATE_GLOSSARY_LIST_SUCCESS',
    UPDATE_GLOSSARY_LIST_FAILURE: 'UPDATE_GLOSSARY_LIST_FAILURE',

    DELETE_GLOSSARY_LIST_REQUEST: 'DELETE_GLOSSARY_LIST_REQUEST',
    DELETE_GLOSSARY_LIST_SUCCESS: 'DELETE_GLOSSARY_LIST_SUCCESS',
    DELETE_GLOSSARY_LIST_FAILURE: 'DELETE_GLOSSARY_LIST_FAILURE',

    LINK_ASSET_REQUEST: 'LINK_ASSET_REQUEST',
    LINK_ASSET_SUCCESS: 'LINK_ASSET_SUCCESS',
    LINK_ASSET_FAILURE: 'LINK_ASSET_FAILURE',

    GET_LINK_ASSET_REQUEST: 'GET_LINK_ASSET_REQUEST',
    GET_LINK_ASSET_SUCCESS: 'GET_LINK_ASSET_SUCCESS',
    GET_LINK_ASSET_FAILURE: 'GET_LINK_ASSET_FAILURE',

    DELETE_LINK_ASSET_REQUEST: 'DELETE_LINK_ASSET_REQUEST',
    DELETE_LINK_ASSET_SUCCESS: 'DELETE_LINK_ASSET_SUCCESS',
    DELETE_LINK_ASSET_FAILURE: 'DELETE_LINK_ASSET_FAILURE',

    GET_GLOSSARY_TERMS_REQUEST: 'GET_GLOSSARY_TERMS_REQUEST',
    GET_GLOSSARY_TERMS_SUCCESS: 'GET_GLOSSARY_TERMS_SUCCESS',
    GET_GLOSSARY_TERMS_FAILURE: 'GET_GLOSSARY_TERMS_FAILURE',

    GET_LOGGING_REQUEST: 'GET_LOGGING_REQUEST',
    GET_LOGGING_SUCCESS: 'GET_LOGGING_SUCCESS',
    GET_LOGGING_FAILURE: 'GET_LOGGING_FAILURE',

    GET_RUNNINGJOBS_REQUEST: 'GET_RUNNINGJOBS_REQUEST',
    GET_RUNNINGJOBS_SUCCESS: 'GET_RUNNINGJOBS_SUCCESS',
    GET_RUNNINGJOBS_FAILURE: 'GET_RUNNINGJOBS_FAILURE',

    GET_RUNNING_LIVYJOBS_REQUEST: 'GET_RUNNING_LIVYJOBS_REQUEST',
    GET_RUNNING_LIVYJOBS_SUCCESS: 'GET_RUNNING_LIVYJOBS_SUCCESS',
    GET_RUNNING_LIVYJOBS_FAILURE: 'GET_RUNNING_LIVYJOBS_FAILURE',

    GET_KILL_LIVYSTATEMENT_REQUEST: 'GET_KILL_LIVYSTATEMENT_REQUEST',
    GET_KILL_LIVYSTATEMENT_SUCCESS: 'GET_KILL_LIVYSTATEMENT_SUCCESS',
    GET_KILL_LIVYSTATEMENT_FAILURE: 'GET_KILL_LIVYSTATEMENT_FAILURE',

    GET_KILL_LIVYBATCH_REQUEST: 'GET_KILL_LIVYBATCH_REQUEST',
    GET_KILL_LIVYBATCH_SUCCESS: 'GET_KILL_LIVYBATCH_SUCCESS',
    GET_KILL_LIVYBATCH_FAILURE: 'GET_KILL_LIVYBATCH_FAILURE',

    GET_KILL_LIVYSESSION_REQUEST: 'GET_KILL_LIVYSESSION_REQUEST',
    GET_KILL_LIVYSESSION_SUCCESS: 'GET_KILL_LIVYSESSION_SUCCESS',
    GET_KILL_LIVYSESSION_FAILURE: 'GET_KILL_LIVYSESSION_FAILURE',

    GET_SCHEDULE_LIST_REQUEST: 'GET_SCHEDULE_LIST_REQUEST',
    GET_SCHEDULE_LIST_SUCCESS: 'GET_SCHEDULE_LIST_SUCCESS',
    GET_SCHEDULE_LIST_FAILURE: 'GET_SCHEDULE_LIST_FAILURE',

    GET_CUSTOM_TABS_REQUEST: 'GET_CUSTOM_TABS_REQUEST',
    GET_CUSTOM_TABS_SUCCESS: 'GET_CUSTOM_TABS_SUCCESS',
    GET_CUSTOM_TABS_FAILURE: 'GET_CUSTOM_TABS_FAILURE',

    GET_CUSTOM_GROUPS_REQUEST: 'GET_CUSTOM_GROUPS_REQUEST',
    GET_CUSTOM_GROUPS_SUCCESS: 'GET_CUSTOM_GROUPS_SUCCESS',
    GET_CUSTOM_GROUPS_FAILURE: 'GET_CUSTOM_GROUPS_FAILURE',

    GET_ROLES_LIST_REQUEST: 'GET_ROLES_LIST_REQUEST',
    GET_ROLES_LIST_SUCCESS: 'GET_ROLES_LIST_SUCCESS',
    GET_ROLES_LIST_FAILURE: 'GET_ROLES_LIST_FAILURE',

    GET_ISSUPER_ORGANIZATION_REQUEST: 'GET_ISSUPER_ORGANIZATION_REQUEST',
    GET_ISSUPER_ORGANIZATION_SUCCESS: 'GET_ISSUPER_ORGANIZATION_SUCCESS',
    GET_ISSUPER_ORGANIZATION_FAILURE: 'GET_ISSUPER_ORGANIZATION_FAILURE',

    GET_ROLES_CONFIGURATION_REQUEST: 'GET_ROLES_CONFIGURATION_REQUEST',
    GET_ROLES_CONFIGURATION_SUCCESS: 'GET_ROLES_CONFIGURATION_SUCCESS',
    GET_ROLES_CONFIGURATION_FAILURE: 'GET_ROLES_CONFIGURATION_FAILURE',

    CREATE_ROLE_REQUEST: 'CREATE_ROLE_REQUEST',
    CREATE_ROLE_SUCCESS: 'CREATE_ROLE_SUCCESS',
    CREATE_ROLE_FAILURE: 'CREATE_ROLE_FAILURE',

    EDIT_ROLE_REQUEST: 'EDIT_ROLE_REQUEST',
    EDIT_ROLE_SUCCESS: 'EDIT_ROLE_SUCCESS',
    EDIT_ROLE_FAILURE: 'EDIT_ROLE_FAILURE',

    DELETE_ROLE_REQUEST: 'DELETE_ROLE_REQUEST',
    DELETE_ROLE_SUCCESS: 'DELETE_ROLE_SUCCESS',
    DELETE_ROLE_FAILURE: 'DELETE_ROLE_FAILURE',

    GET_SYSTEM_LICENSE_REQUEST: 'GET_SYSTEM_LICENSE_REQUEST',
    GET_SYSTEM_LICENSE_SUCCESS: 'GET_SYSTEM_LICENSE_SUCCESS',
    GET_SYSTEM_LICENSE_FAILURE: 'GET_SYSTEM_LICENSE_FAILURE',

    GET_LICENSE_MODULES_REQUEST: 'GET_LICENSE_MODULES_REQUEST',
    GET_LICENSE_MODULES_SUCCESS: 'GET_LICENSE_MODULES_SUCCESS',
    GET_LICENSE_MODULES_FAILURE: 'GET_LICENSE_MODULES_FAILURE',

    UPDATE_LICENSE_CONFIG_REQUEST: 'UPDATE_LICENSE_CONFIG_REQUEST',
    UPDATE_LICENSE_CONFIG_SUCCESS: 'UPDATE_LICENSE_CONFIG_SUCCESS',
    UPDATE_LICENSE_CONFIG_FAILURE: 'UPDATE_LICENSE_CONFIG_FAILURE',

    UPDATE_CONNECTOR_CONFIG_REQUEST: 'UPDATE_CONNECTOR_CONFIG_REQUEST',
    UPDATE_CONNECTOR_CONFIG_SUCCESS: 'UPDATE_CONNECTOR_CONFIG_SUCCESS',
    UPDATE_CONNECTOR_CONFIG_FAILURE: 'UPDATE_CONNECTOR_CONFIG_FAILURE',

    GET_UPDATE_LICENSE_CONNECTION_STATUS_REQUEST: 'GET_UPDATE_LICENSE_CONNECTION_STATUS_REQUEST',
    GET_UPDATE_LICENSE_CONNECTION_STATUS_SUCCESS: 'GET_UPDATE_LICENSE_CONNECTION_STATUS_SUCCESS',
    GET_UPDATE_LICENSE_CONNECTION_STATUS_FAILURE: 'GET_UPDATE_LICENSE_CONNECTION_STATUS_FAILURE',

    RESET_CONFIG: 'RESET_CONFIG',
    RESET_ORGANIZATION_CONFIG: 'RESET_ORGANIZATION_CONFIG',
    SET_NOTIFICATION_FILTERS: 'SET_NOTIFICATION_FILTERS',

    EXPORT_DATA_REQUEST: 'EXPORT_DATA_REQUEST',
    EXPORT_DATA_SUCCESS: 'EXPORT_DATA_SUCCESS',
    EXPORT_DATA_FAILURE: 'EXPORT_DATA_FAILURE',

    USERACTIVITY_EXPORT_DATA_REQUEST: 'USERACTIVITY_EXPORT_DATA_REQUEST',
    USERACTIVITY_EXPORT_DATA_SUCCESS: 'USERACTIVITY_EXPORT_DATA_SUCCESS',
    USERACTIVITY_EXPORT_DATA_FAILURE: 'USERACTIVITY_EXPORT_DATA_FAILURE',

    USERACTIVITYINFO_EXPORT_DATA_REQUEST: 'USERACTIVITYINFO_EXPORT_DATA_REQUEST',
    USERACTIVITYINFO_EXPORT_DATA_SUCCESS: 'USERACTIVITYINFO_EXPORT_DATA_SUCCESS',
    USERACTIVITYINFO_EXPORT_DATA_FAILURE: 'USERACTIVITYINFO_EXPORT_DATA_FAILURE',

    UPDATE_SSO_PRIVATE_KEY_REQUEST: 'UPDATE_SSO_PRIVATE_KEY_REQUEST',
    UPDATE_SSO_PRIVATE_KEY_SUCCESS: 'UPDATE_SSO_PRIVATE_KEY_SUCCESS',
    UPDATE_SSO_PRIVATE_KEY_FAILURE: 'UPDATE_SSO_PRIVATE_KEY_FAILURE',

    GET_LICENSE_INFO_REQUEST: 'GET_LICENSE_INFO_REQUEST',
    GET_LICENSE_INFO_SUCCESS: 'GET_LICENSE_INFO_SUCCESS',
    GET_LICENSE_INFO_FAILURE: 'GET_LICENSE_INFO_FAILURE',

    GET_EXPORT_DQMETRICS_REQUEST: 'GET_EXPORT_DQMETRICS_REQUEST',
    GET_EXPORT_DQMETRICS_SUCCESS: 'GET_EXPORT_DQMETRICS_SUCCESS',
    GET_EXPORT_DQMETRICS_FAILURE: 'GET_EXPORT_DQMETRICS_FAILURE',

    GET_SSO_DETAILS_REQUEST: 'GET_SSO_DETAILS_REQUEST',
    GET_SSO_DETAILS_SUCCESS: 'GET_SSO_DETAILS_SUCCESS',
    GET_SSO_DETAILS_FAILURE: 'GET_SSO_DETAILS_FAILURE',

    GET_LDAP_DETAILS_REQUEST: 'GET_LDAP_DETAILS_REQUEST',
    GET_LDAP_DETAILS_SUCCESS: 'GET_LDAP_DETAILS_SUCCESS',
    GET_LDAP_DETAILS_FAILURE: 'GET_LDAP_DETAILS_FAILURE',

    UPDATE_EXPORT_DQMETRICS_REQUEST: 'UPDATE_EXPORT_DQMETRICS_REQUEST',
    UPDATE_EXPORT_DQMETRICS_SUCCESS: 'UPDATE_EXPORT_DQMETRICS_SUCCESS',
    UPDATE_EXPORT_DQMETRICS_FAILURE: 'UPDATE_EXPORT_DQMETRICS_FAILURE',

    UPDATE_LDAP_DETAILS_REQUEST: 'UPDATE_LDAP_DETAILS_REQUEST',
    UPDATE_LDAP_DETAILS_SUCCESS: 'UPDATE_LDAP_DETAILS_SUCCESS',
    UPDATE_LDAP_DETAILS_FAILURE: 'UPDATE_LDAP_DETAILS_FAILURE',

    UPDATE_USER_LOGOUT_REQUEST: 'UPDATE_USER_LOGOUT_REQUEST',
    UPDATE_USER_LOGOUT_SUCCESS: 'UPDATE_USER_LOGOUT_SUCCESS',
    UPDATE_USER_LOGOUT_FAILURE: 'UPDATE_USER_LOGOUT_FAILURE',

    UPLOAD_SSO_FILE_REQUEST: 'UPLOAD_SSO_FILE_REQUEST',
    UPLOAD_SSO_FILE_SUCCESS: 'UPLOAD_SSO_FILE_SUCCESS',
    UPLOAD_SSO_FILE_FAILURE: 'UPLOAD_SSO_FILE_FAILURE',

    UPLOAD_SSO_DOMAIN_REQUEST: 'UPLOAD_SSO_DOMAIN_REQUEST',
    UPLOAD_SSO_DOMAIN_SUCCESS: 'UPLOAD_SSO_DOMAIN_SUCCESS',
    UPLOAD_SSO_DOMAIN_FAILURE: 'UPLOAD_SSO_DOMAIN_FAILURE',

    UPLOAD_LDAP_DOMAIN_REQUEST: 'UPLOAD_LDAP_DOMAIN_REQUEST',
    UPLOAD_LDAP_DOMAIN_SUCCESS: 'UPLOAD_LDAP_DOMAIN_SUCCESS',
    UPLOAD_LDAP_DOMAIN_FAILURE: 'UPLOAD_LDAP_DOMAIN_FAILURE',

    GET_DRIFT_PROPERTIES_REQUEST: 'GET_DRIFT_PROPERTIES_REQUEST',
    GET_DRIFT_PROPERTIES_SUCCESS: 'GET_DRIFT_PROPERTIES_SUCCESS',
    GET_DRIFT_PROPERTIES_FAILURE: 'GET_DRIFT_PROPERTIES_FAILURE'

};