import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import TextBox from '../TextBox/TextBox.jsx';
import { failure } from '../../actions/baseActions';
import { alertActions } from '../../constants/actionTypes/alertActionTypes';


// import material-ui components
import {
    withStyles, Grid, Typography, Button, Table, TableHead,
    TableBody, TableRow, TableCell, MenuItem
} from '@material-ui/core';

import { getExportMetricsSettingDetails, getExportAttributeRules, removeExportMetricsSettingDetails, saveExportMetricsSettingDetails, checkExportMetricsSettingName } from '../../actions/datasetActions';

// import styles
import Styles from '../../layouts/Styles.jsx';
import DatasetStyles from './DatasetStyles.jsx';


const DatasetExport = (props) => {
    const { classes, datasetId, datasourceId, isSelected } = props;
    const dispatch = useDispatch();

    const [tablename, settablename] = useState("");
    const [exportattribute, setExportAttribute] = useState([]);
    const [isDuplicateName, setIsDuplicateName] = useState(true);
    const [isChanged, setIsChanged] = useState(false);

    const getExportMetrixStting = useCallback((datasetId) => {
        dispatch(getExportMetricsSettingDetails(datasetId)).then((response) => {
            settablename(response);
        });

        dispatch(getExportAttributeRules(datasourceId, datasetId)).then((response) => {
            setExportAttribute(response);
        });
    }, [datasourceId, dispatch]);

    const onChangeFilter = (property, value, attr_index) => {
        exportattribute[attr_index].mappeddatatype = value;
        setExportAttribute([...exportattribute]);
        const input_details = {
            'dataset_id': datasetId,
            'source_id': datasourceId,
            'table_name': tablename,
            'exportattribute': exportattribute
        };
        dispatch(saveExportMetricsSettingDetails(input_details));
    };


    const removeSetting = useCallback(() => {
        const input_details = {
            'dataset_id': datasetId,
            'source_id': datasourceId,
            'table_name': tablename
        };
        dispatch(removeExportMetricsSettingDetails(input_details)).then((response) => {
            if (response) {
                getExportMetrixStting(datasetId);
                settablename("");
                setExportAttribute([]);
            }
        });
    }, [datasetId, datasourceId, dispatch, getExportMetrixStting, tablename]);

    const saveDetails = useCallback(() => {
        if (tablename.match("^[A-Za-z][A-Za-z0-9_]+$") && tablename.length > 4) {
            const input_details = {
                'dataset_id': datasetId,
                'source_id': datasourceId,
                'table_name': tablename,
                'exportattribute': exportattribute
            };
            dispatch(saveExportMetricsSettingDetails(input_details));

        } else {
            return false;
        }
    }, [datasetId, datasourceId, dispatch, exportattribute, tablename]);

    const onChangeComment = useCallback((value) => {
        settablename(value);
        checkTableName(value);
        setIsChanged(true);
    }, []);

    useEffect(() => {
        if (!datasetId || !isSelected) {
            return;
        }
        getExportMetrixStting(datasetId);
    }, [datasetId, getExportMetrixStting, isSelected]);

    const checkTableName = (tableName) => {
        dispatch(checkExportMetricsSettingName({ table_name: tableName, dataset_id: datasetId })).then((response) => {
            if (response === true) {
                setIsDuplicateName(true);
                dispatch(failure(alertActions.ALERT_ERROR, "Table Name is Duplicate."));
            } else {
                setIsDuplicateName(false);

            }
        });
    };

    return (
        <Grid className={classes.tabSection} style={{ overflow: 'auto' }}>
            <Grid container>
                <Grid item xs={10}>
                    <Typography variant="h3" color="textSecondary">
                        {'Export Metrics Settings'}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={4}>
                    <TextBox
                        label="Table Name"
                        name="tablename"
                        value={tablename}
                        validators={['required', 'matchRegexp:^[A-Za-z][A-Za-z0-9_]+$']}
                        errorMessages={["Table Name is required", "Enter Valid Table Name"]}
                        onChange={(event) => onChangeComment(event.target.value)}
                        onBlur={
                            () => {
                                if (!isDuplicateName && isChanged) {
                                    saveDetails();
                                }
                            }
                        }
                        fullWidth
                    />
                </Grid>
                {/* <Grid item xs={1}>
                    <Button variant="contained" color="primary" size="large" style={{ width: '90%' }} onClick={() => saveDetails()}>
                        {'Save'}
                    </Button>
                </Grid> */}
                <Grid item xs={2}>
                    <Button variant="contained" color="primary" size="large" onClick={() => removeSetting()}>
                        {'Remove Setting'}
                    </Button>
                </Grid>
            </Grid>
            <Grid container>
                {
                    exportattribute &&
                    <Grid container style={{ marginTop: '15px' }}>
                        <Grid container>
                            <Grid item xs={10}>
                                <Typography variant="h3" color="textSecondary">
                                    {'Define target table attributes'}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container style={{ marginTop: '15px' }}>
                            <Table>
                                <TableHead>
                                    <TableRow className={classes.noBackground}>
                                        {
                                            ["Attributes/Rules", "Target table data types"].map((header, index) =>
                                                <TableCell key={`attribute_${index}`}>
                                                    {header}
                                                </TableCell>
                                            )
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        exportattribute.map((attribute, attrIndex) =>
                                            <TableRow className={classes.tableRowClass} key={`datasetattribute${attrIndex}`}>
                                                <TableCell>
                                                    <Typography className={classes.typeText}>
                                                        {attribute.name ? attribute.name : ''}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <TextBox
                                                        value={attribute.mappeddatatype}
                                                        name={attribute.name}
                                                        fullWidth
                                                        select
                                                        onChange={(event) => onChangeFilter(event.target.name, event.target.value, attrIndex)}
                                                        SelectProps={
                                                            {
                                                                className: classes.cursorPointer,
                                                                value: attribute.mappeddatatype,
                                                                onChange: (event) => onChangeFilter(event.target.name, event.target.value, attrIndex),
                                                                MenuProps: {
                                                                    anchorOrigin: {
                                                                        vertical: "bottom",
                                                                        horizontal: "center"
                                                                    },
                                                                    transformOrigin: {
                                                                        vertical: "top",
                                                                        horizontal: "center"
                                                                    },
                                                                    getContentAnchorEl: null
                                                                }
                                                                // IconComponent: () => DropDownIcon()
                                                            }
                                                        }
                                                    >
                                                        {
                                                            // "Time" - removed as there is no datatype time in spark sql
                                                            ["Text", "Integer", "Numeric", "Boolean", "Binary", "Date", "Datetime"].map((status, index) =>
                                                                <MenuItem key={index} value={status}>
                                                                    {status}
                                                                </MenuItem>
                                                            )
                                                        }
                                                    </TextBox>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Grid>
    );
};

DatasetExport.propTypes = {
    classes: PropTypes.object,
    datasetId: PropTypes.number,
    datasourceId: PropTypes.number,
    isSelected: PropTypes.bool
};

export default withStyles((theme) => ({
    ...DatasetStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DatasetExport);