import React, { useState, useEffect } from 'react';
import { withStyles, Grid, Typography, Switch, Slider } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import DomainStyles from './DomainStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import Linear from '../Charts/Linear.jsx';
import { getValue } from '../../helpers/appHelpers.js';

const DomainListCard = (props) => {
    const { classes, rule, onChange, openDialog, redirect, dq_score_threshold } = props;
    const [metricWeight, setMetricWeight] = useState(0);

    useEffect(() => {
        if (!rule) {
            return;
        }
        setMetricWeight(rule.metric_weight ? rule.metric_weight : 100);
    }, [rule]);

    return (
        <Grid container className={classes.domainListCardContainer}>
            <Grid item className={classes.gridNameContainer}>
                <Typography onClick={() => redirect(rule, "rule")} className={classes.cursor}>
                    {rule.name ? rule.name : ''}
                </Typography>
            </Grid>
            <Grid item className={classes.countContainer} align="center" style={{ paddingLeft: '5px' }}>
                <Typography className={classes.ruleCountText}>
                    {rule.total_records ? rule.total_records : 0}
                </Typography>
            </Grid>
            {
                <Grid item className={classes.countContainer} align="center" style={{ paddingLeft: '5px' }}>
                    {
                        rule && rule.invalid_records === "NA" &&
                        <Typography className={classNames(classes.invalidRecordText, classes.ruleCountText)} onClick={(event) => openDialog(event, rule, "rule", "Invalid_Records")}>
                            {'NA'}
                        </Typography>
                    }
                    {
                        rule && rule.invalid_records !== "NA" &&
                        <Typography className={classNames(classes.invalidRecordText, classes.ruleCountText)} onClick={(event) => openDialog(event, rule, "rule", "Invalid_Records")}>
                            {rule && (rule.name === 'distinct_count' || rule.name === 'unique_count') ? `${rule.total_records - rule.invalid_records} (${getValue(100 - rule.invalid_percentage) < 0 ? 0 : getValue(100 - rule.invalid_percentage)}%)` : `${rule.invalid_records} (${getValue(rule.invalid_percentage)}%)`}
                        </Typography>
                    }
                </Grid>
            }
            {
                <Grid item className={classes.countContainer} align="center" style={{ paddingLeft: '5px' }}>
                    {
                        rule && rule.invalid_records === "NA" &&
                        <Typography className={classNames(classes.invalidRecordText, classes.ruleCountText)} onClick={(event) => openDialog(event, rule, "rule", "Invalid_Records")}>
                            {'NA'}
                        </Typography>
                    }
                    {
                        rule && rule.invalid_records !== "NA" &&
                        <Typography className={classNames(classes.invalidRecordText, classes.ruleCountText)} onClick={(event) => openDialog(event, rule, "rule", "Valid_Records")}>
                            {rule && (rule.name === 'distinct_count' || rule.name === 'unique_count') ? `${rule.invalid_records} (${getValue(rule.invalid_percentage)}%)` : `${rule.total_records - rule.invalid_records} (${getValue(100 - rule.invalid_percentage) < 0 ? 0 : getValue(100 - rule.invalid_percentage)}%)`}
                        </Typography>
                    }
                </Grid>
            }
            <Grid item className={classNames(classes.gridScoreContainer, classes.collapsegridScoreContainer, (rule && !rule.allow_dqscore) ? classes.noScoreContainer : '')}>
                {
                    rule && rule.allow_dqscore ?
                        <Grid style={{ display: 'flex' }}>
                            <Grid style={{ width: '95%' }}>
                                {
                                    (rule.name === 'distinct_count' || rule.name === 'unique_count') ?
                                        <Linear showPercentage value={(100 - rule.dqscore) < 0 ? 0 : (100 - rule.dqscore)} dqScoreThreshold={dq_score_threshold} /> :
                                        <Linear showPercentage value={rule.dqscore} dqScoreThreshold={dq_score_threshold} />
                                }
                            </Grid>
                            <Typography variant="body2" className={classNames(classes.notApplicableText, classes.marginLeft20)}>
                                {`(${getValue(rule.actual_score)})`}
                            </Typography>
                        </Grid>
                        :
                        <Typography variant="body2" className={classes.notApplicableText}>
                            {'NA'}
                        </Typography>
                }

            </Grid>
            <Grid item className={classNames(classes.gridNameContainer, classes.sliderContainer)}>
                <Slider
                    style={{ width: '55%', marginLeft: 23 }}
                    defaultValue={100}
                    valueLabelDisplay="auto"
                    value={metricWeight ? metricWeight : 100}
                    onChange={(_, value) => setMetricWeight(value)}
                    onChangeCommitted={(_, value) => onChange('metric_weight', value)}
                    step={1}
                    min={0}
                    max={100}
                    color="secondary"
                    disabled={rule && !rule.is_active}
                    className={classNames(classes.slider, classes.filterSlider, classes.SensitivityMuiSlider)}
                />
                <Typography variant="body2" className={classes.sliderValue}>
                    {metricWeight ? `${metricWeight}%` : '100%'}
                </Typography>
            </Grid>
            <Grid item align="right" className={classes.actionContainer}>
                <Switch
                    color="secondary"
                    size="small"
                    checked={rule.is_active}
                    onChange={(event) => onChange("is_active", event.target.checked)}
                />
            </Grid>
        </Grid>
    );
};

DomainListCard.propTypes = {
    classes: PropTypes.object,
    rule: PropTypes.object,
    onChange: PropTypes.func,
    openDialog: PropTypes.func,
    redirect: PropTypes.func,
    dq_score_threshold: PropTypes.object
};

export default withStyles((theme) => ({
    ...DomainStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DomainListCard);