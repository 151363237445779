import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Popover, withStyles, Tabs, Tab } from '@material-ui/core';
import PropTypes from 'prop-types';
import ScheduleStyles from './ScheduleStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import TabPanel from '../TabPanel/TabPanel.jsx';
import TimeSchedule from './TimeSchedule.jsx';
import EventSchedule from './EventSchedule.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { createDatasourceSchedule, createSchedule, getDatasetList, getDatasetSchedule, getDatasourceList, getDataSourceSchedule, updateSchedule } from '../../actions/scheduleActions.js';
import moment from 'moment-timezone';
import { appConstants } from '../../constants/appConstants.js';
import { setSearchKey } from '../../actions/metricsActions.js';

const Schedule = (props) => {
    const dispatch = useDispatch();

    const { classes, scheduleProps, onClose, sourceType } = props;

    const zones = [{ name: moment.tz.guess() }, ...appConstants.TimeZones];

    const defaultEventParams = {
        "schedule_method": "Event",
        "start_profile": true,
        "start_curate": true,
        "start_export_metrics": false,
        "trigger_datasource": '',
        "trigger_dataset": '',
        "job_type": '',
        "date": new Date(),
        "time": new Date(),
        "time_zone": zones[0] ? zones[0].name : ""
    };

    const defaultTimeParams = {
        "schedule_method": "Month",
        "start_profile": true,
        "start_curate": true,
        "start_export_metrics": false,
        "week_day": ["Sunday"],
        "month_date": [moment(new Date()).format('DD').replace(/^0+/, '')],
        "date": new Date(),
        "time": new Date(),
        "time_zone": zones[0] ? zones[0].name : "",
        'day': [moment(new Date())]
    };

    const [datasetSchedule, setDatasetSchedule] = useState({ ...defaultTimeParams });
    const [existingDatasetSchedule, setExistingDatasetSchedule] = useState(null);
    const [isExistingSchedule, setExistingSchedule] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [submission, setSubmission] = useState(true);

    const userConfig = useSelector(({ setting }) => setting.user_config);

    const datasourceControllerRef = useRef();
    const datasetControllerRef = useRef();

    useEffect(() => {
        loadDataSources();
        loadDatasets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (scheduleProps.isDataset) {
            const selectedDataset = {
                'dataset_list': scheduleProps.selectedDataset
            };
            dispatch(getDataSourceSchedule(selectedDataset)).then((response) => {
                setExistingSchedule(response);
            });
        } else {
            dispatch(getDatasetSchedule(scheduleProps.datasetId)).then((response) => {
                if (response && Object.keys(response).length !== 0) {
                    let schedule = {};
                    if (response.schedule_method === 'Event') {
                        schedule = {
                            "id": response.id,
                            "start_profile": response.is_profile_schedule ? response.is_profile_schedule : false,
                            "start_curate": response.has_curation ? response.has_curation : false,
                            "start_export_metrics": response.has_export_metrics ? response.has_export_metrics : false,
                            "job_type": response.job_type,
                            "time_zone": response.time_zone,
                            "trigger_datasource": response.trigger_datasource,
                            "trigger_dataset": response.trigger_dataset,
                            "schedule_method": response.schedule_method,
                            "date": moment(response.start_time).tz(response.time_zone),
                            "time": moment(response.start_time).tz(response.time_zone)
                        };
                        setTabIndex(1);
                    } else {
                        let time = [];
                        const hasTime = response.schedule_time.length > 0;
                        if (!hasTime) {
                            time = [Date.now()];
                        } else {
                            // time = time ? moment(moment().tz(response.time_zone).format('YYYY-MM-DD') + ` ${time}`) : '';
                            time = response.schedule_time.map((elem) => moment(elem));
                        }
                        let minutesInterval = 0;
                        if (response.schedule_method === "Hour") {
                            minutesInterval = (response.minitues_interval !== "" ? parseInt(response.minitues_interval) / 60 : 0);
                        } else {
                            minutesInterval = (response.minitues_interval !== "" ? response.minitues_interval : 0);
                        }
                        schedule = {
                            "id": response.id,
                            "start_profile": response.is_profile_schedule ? response.is_profile_schedule : false,
                            "start_curate": response.has_curation ? response.has_curation : false,
                            "start_export_metrics": response.has_export_metrics ? response.has_export_metrics : false,
                            "minitues_interval": minutesInterval,
                            "time_zone": response.time_zone,
                            "week_day": response.week_day !== "" ? response.week_day.split(',') : ["Sunday"],
                            "month_date": response.month_date !== "" ? response.month_date.split(',') : [moment(new Date()).format('DD').replace(/^0+/, '')],
                            "schedule_method": response.schedule_method,
                            "date": moment(response.start_time).tz(response.time_zone),
                            "time": moment(response.start_time).tz(response.time_zone),
                            "day": time
                        };
                        setTabIndex(0);
                    }
                    setExistingSchedule(true);
                    setDatasetSchedule(schedule);
                    setExistingDatasetSchedule(schedule);
                }
            });
        }
    }, [scheduleProps.datasetId, dispatch, setDatasetSchedule, scheduleProps.isDataset, scheduleProps.selectedDataset]);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
        if (isExistingSchedule) {
            if ((newValue === 1 && existingDatasetSchedule?.schedule_method === 'Event') || (newValue === 0 && existingDatasetSchedule?.schedule_method !== 'Event')) {
                setDatasetSchedule(existingDatasetSchedule);
            } else if (newValue === 0) {
                setDatasetSchedule({ ...defaultTimeParams, id: existingDatasetSchedule.id });
            } else {
                setDatasetSchedule({ ...defaultEventParams, id: existingDatasetSchedule.id });
            }
        } else {
            if (newValue === 0) {
                setDatasetSchedule(defaultTimeParams);
            } else {
                setDatasetSchedule(defaultEventParams);
            }
        }
    };

    const onSubmitSchedule = (schedule) => {
        if (scheduleProps.isDataset) {
            if (scheduleProps.selectedDataset) {
                schedule.selectedDataset = scheduleProps.selectedDataset;
            }
            else {
                schedule.selectedDataset = [];
            }

            dispatch(createDatasourceSchedule(schedule)).then((response) => {
                if (response) {
                    setSubmission(true);
                    onClose();
                }
            });
        } else {
            if (!datasetSchedule.id) {
                dispatch(createSchedule(schedule)).then((response) => {
                    if (response) {
                        setDatasetSchedule({ ...datasetSchedule, id: response.id });
                        setSubmission(true);
                        onClose();
                    }
                });
            } else {
                dispatch(updateSchedule(schedule)).then((response) => {
                    if (response) {
                        setSubmission(true);
                        onClose();
                    }
                });
            }
        }
    };


    const loadDataSources = useCallback((search_key = '') => {
        if (datasourceControllerRef && datasourceControllerRef.current) {
            datasourceControllerRef.current.abort();
        }
        const controller = new AbortController();
        datasourceControllerRef.current = controller;
        const token = { signal: datasourceControllerRef?.current?.signal };

        const key = search_key ? search_key : 'all';
        const requestParams = {
            key
        };
        dispatch(setSearchKey('datasource', key));
        dispatch(getDatasourceList(requestParams, token));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const loadDatasets = useCallback((datasetSchedule, search_key = '') => {
        if (!datasetSchedule?.trigger_datasource) {
            return;
        }

        if (datasetControllerRef && datasetControllerRef.current) {
            datasetControllerRef.current.abort();
        }
        const controller = new AbortController();
        datasetControllerRef.current = controller;
        const token = { signal: datasetControllerRef?.current?.signal };

        const key = search_key ? search_key : 'all';
        let datasource_ids = datasetSchedule.trigger_datasource && datasetSchedule.trigger_datasource.id ? [datasetSchedule.trigger_datasource.id] : datasetSchedule.trigger_datasource;
        if (datasource_ids) {
            datasource_ids = datasource_ids.map((data) => (typeof data === 'object' && 'id' in data ? data.id : data));
        }
        const requestParams = {
            datasources: datasource_ids,
            key
        };
        dispatch(setSearchKey('dataset', key));
        dispatch(getDatasetList(requestParams, token));
    }, [dispatch]);


    return (
        <Popover
            open={scheduleProps.open}
            anchorEl={scheduleProps.anchorElement}
            onClose={() => onClose()}
            anchorOrigin={
                {
                    vertical: 'top',
                    horizontal: 'left'
                }
            }
            transformOrigin={
                {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            }>
            <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                indicatorColor="secondary"
                textColor="secondary"
                aria-label="Schedule tabs"
                variant="fullWidth"
                centered
                className={userConfig && userConfig.enable_event_based_scheduling ? '' : classes.tabs}
            >
                <Tab label="Time Schedule" value={0} />
                {userConfig && userConfig.enable_event_based_scheduling && <Tab label="Event Schedule" value={1} />}
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
                <TimeSchedule
                    scheduleProps={scheduleProps}
                    onClose={onClose}
                    sourceType={sourceType}
                    datasetSchedule={datasetSchedule}
                    setDatasetSchedule={setDatasetSchedule}
                    isExistingSchedule={isExistingSchedule}
                    setExistingSchedule={setExistingSchedule}
                    defaultParams={defaultTimeParams}
                    onSubmitSchedule={onSubmitSchedule}
                    submission={submission}
                    setSubmission={setSubmission}
                />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
                <EventSchedule
                    {...props}
                    datasetSchedule={datasetSchedule}
                    setDatasetSchedule={setDatasetSchedule}
                    isExistingSchedule={isExistingSchedule}
                    setExistingSchedule={setExistingSchedule}
                    defaultParams={defaultTimeParams}
                    onSubmitSchedule={onSubmitSchedule}
                    submission={submission}
                    setSubmission={setSubmission}
                    loadDataSources={loadDataSources}
                    loadDatasets={loadDatasets}
                />
            </TabPanel>

        </Popover>
    );
};

Schedule.propTypes = {
    classes: PropTypes.object,
    scheduleProps: PropTypes.object,
    onClose: PropTypes.func,
    sourceType: PropTypes.string
};

export default withStyles((theme) => ({
    ...ScheduleStyles(theme),
    ...Styles(theme)
}))(Schedule);