/* eslint-disable multiline-comment-style */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Button, Grid, withStyles, Tabs, Tab, Checkbox, Switch, Dialog, Popover, FormControlLabel, TextField, FormHelperText } from '@material-ui/core';
import TextBox from '../../../TextBox/TextBox.jsx';
import QueryBuilder from '../../../RuleBuilder/QueryBuilder.jsx';
import PropertiesListViewStyles from './PropertiesListViewStyles.jsx';
import DatasetStyles from '../../DatasetStyles.jsx';
import TileViewStyles from '../TileView/TileViewStyles.jsx';
import Styles from '../../../../layouts/Styles.jsx';
import TabPanel from '../../../TabPanel/TabPanel.jsx';
import { createRuleGroup, getFieldType, getQueryString, getSelectedAttributes, getAttributeNames, removeFormatting, functionQueryString, getTableNames } from '../../../RuleBuilder/QueryBuilderUtil.jsx';
import ReactQueryEditor from '../../../ReactQueryEditor/ReactQueryEditor.jsx';
import { useSelector } from 'react-redux';
import SemanticQueryBuilder from '../../../RuleBuilder/SemanticQueryBuilder.jsx';
import ToolTipComponent from '../../../Tooltip/Tooltip.jsx';
// import SettingsIcon from '@material-ui/icons/Settings';
import NumberInput from '../../../TextBox/NumberInput.jsx';
import AutoCompleteInput from '../../../AutoComplete/AutoCompleteInput.jsx';
import { sortTable } from '../../../../helpers/appHelpers.js';

const defaultCustomNotification = {
    custom_rule: false,
    custom_rule_simple: false,
    custom_rule_detailed: false,
    send_as_link: false,
    previewcount: null,
    threshold: null
};

const RuleBuilderModal = (props) => {
    const { classes, open, attribute, rule, userAttributes, properties, profileSetting, onSave, onClose, isSemanticBuilder, duplicateNameError, updateRulename, defaultField } = props;
    const [selectedRule, setSelectedRule] = useState({});
    const selectedDataset = useSelector(({ dataset }) => dataset.selectedDataset);
    const selectedDatasource = useSelector(({ datasource }) => datasource.datasource);
    const referenceDatasets = useSelector(({ datasource }) => datasource.references);
    const lookup = useSelector(({ setting }) => setting?.user_config?.profileRulesConfig?.lookup);
    // const configSettings = useSelector(({ setting }) => setting.config);
    const [fields, setFields] = useState([]);
    const containerRef = useRef();
    const [tabIndex, setTabIndex] = useState(0);
    const [ruleLoaded, setRuleLoaded] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedReferenceDataset, setSelectedReferenceDataset] = useState({});
    const [isError, setIsError] = useState(false);

    let ruleGroupTabs = ["Conditional", "Functions"];

    if (isSemanticBuilder) {
        ruleGroupTabs = ["Conditional"];
    }
    if (lookup && lookup.length > 0) {
        if (isSemanticBuilder) {
            ruleGroupTabs = ["Conditional", 'Lookup'];
        } else {
            ruleGroupTabs = ["Conditional", "Functions", 'Lookup'];
        }
    }

    const prepareFields = useCallback((isComplexRule) => {
        const fields = [];
        if (isComplexRule) {
            for (const selectedAttribute of userAttributes) {
                fields.push({
                    ...selectedAttribute,
                    type: selectedAttribute.datatype ? selectedAttribute.datatype : 'Text',
                    attributeSchema: selectedAttribute.attribute_schema ? selectedAttribute.attribute_schema : '',
                    name: selectedAttribute.attribute_schema ? selectedAttribute.attribute_schema : ''
                });
            }
        } else {
            for (const attribute of Object.keys(properties)) {
                const selectedAttribute = properties[attribute];
                fields.push({
                    name: selectedAttribute.name,
                    type: selectedAttribute.datatype,
                    datatype: selectedAttribute.datatype ? selectedAttribute.datatype : 'Text',
                    attributeSchema: ''
                });
            }
        }
        return [...fields];
    }, [properties, userAttributes]);

    const handleChange = useCallback((property, value) => {
        if (property === 'name' && duplicateNameError) {
            updateRulename(false);
        }
        if (property === 'reference_dataset') {
            selectedRule['lookup_attribute'] = '';
        }
        selectedRule[property] = value;
        setSelectedRule({ ...selectedRule });
    }, [duplicateNameError, selectedRule, updateRulename]);

    const onChangeRuleType = useCallback((isComplexRule) => {
        const fields = prepareFields(isComplexRule);
        handleChange('is_complex_rule', isComplexRule);
        if (isComplexRule) {
            const ruleGroupParams = {
                isComplexRule,
                isTriggerRule: true,
                connectionType: selectedDatasource?.type,
                isScan: selectedDatasource?.scan
            };
            const conditionalRuleGroupParams = {
                isComplexRule,
                connectionType: selectedDatasource?.type,
                isScan: selectedDatasource?.scan
            };
            handleChange('ruleGroup', { ...createRuleGroup(ruleGroupParams) });
            handleChange('conditionalRuleGroup', { ...createRuleGroup(conditionalRuleGroupParams) });
        } else {
            const ruleGroupParams = {
                defaultField: attribute.name,
                defaultFieldType: getFieldType(attribute.datatype),
                attributeType: attribute.datatype,
                isComplexRule,
                connectionType: selectedDatasource?.type,
                isScan: selectedDatasource?.scan
            };
            const conditionalRuleGroupParams = {
                isComplexRule,
                connectionType: selectedDatasource?.type,
                isScan: selectedDatasource?.scan
            };
            handleChange('ruleGroup', { ...createRuleGroup(ruleGroupParams) });
            handleChange('conditionalRuleGroup', { ...createRuleGroup(conditionalRuleGroupParams) });
        }
        setFields([...fields]);
    }, [attribute.datatype, attribute.name, handleChange, prepareFields, selectedDatasource?.scan, selectedDatasource?.type]);


    const prepareSelectedAttributes = useCallback((rule) => {
        let ruleGroupAttributes = getSelectedAttributes(rule.ruleGroup);
        if (!rule.isQueryFilter) {
            const conditionalRuleGroupAttributes = getSelectedAttributes(rule.conditionalRuleGroup);
            ruleGroupAttributes = [...ruleGroupAttributes, ...conditionalRuleGroupAttributes];
        } else {
            ruleGroupAttributes = getAttributeNames(fields, rule.query);
        }
        ruleGroupAttributes = [...new Set(ruleGroupAttributes)];
        const attributes = [];
        const selectedDatasources = [];
        for (const attribute of ruleGroupAttributes) {
            const fields = prepareFields(rule.is_complex_rule);
            const field = fields.find((p) => p.name.toLowerCase() === attribute.toLowerCase());
            if (!field) {
                continue;
            }
            const schema = field.attribute_schema ? field.attribute_schema : '';
            attributes.push({
                name: field.name,
                datatype: field.datatype,
                schema
            });
            if (rule.is_complex_rule || (!selectedDatasource?.scan && rule.is_sql_editor)) {
                const hasDatasource = selectedDatasources.find((p) => p.dataset_id === field.dataset_id);
                if (!hasDatasource) {
                    selectedDatasources.push({ ...field });
                }
            }
        }
        if (attributes.length === 0 && (rule.is_complex_rule || (!selectedDatasource?.scan && rule.is_sql_editor))) {
            const tables = getTableNames(fields, rule.query);
            for (const table of tables) {
                const field = fields.find((p) => p.dataset_schema.toLowerCase() === table.toLowerCase());
                if (!field) {
                    continue;
                }
                const hasDatasource = selectedDatasources.find((p) => p.dataset_id === field.dataset_id);
                if (!hasDatasource) {
                    selectedDatasources.push({ ...field });
                }
            }
        }
        rule.query = rule.isQueryFilter ? functionQueryString(rule.query, attributes) : getQueryString(rule.ruleGroup);
        if (!rule.isQueryFilter) {
            rule.conditionalQuery = getQueryString(rule.conditionalRuleGroup, true);
        }
        if (rule.query && rule.conditionalQuery) {
            if (selectedDatasources) {
                const datasetName = selectedDataset ? selectedDataset.name : '';
                const joinedDatasets = [...new Set(selectedDatasources.filter((p) => p.dataset_name !== datasetName).map((p) => p.dataset_schema))];
                for (const dataset_schema of joinedDatasets) {
                    rule.query = rule.query.replace('<table_name>', dataset_schema, 1);
                }
            }
            const queryString = `select * from <table_name> ${rule.query} where ${rule.conditionalQuery}`;
            rule.query = queryString;
        }
        rule.selectedAttributes = [...attributes];
        rule.datasources = [...selectedDatasources];
    }, [fields, prepareFields, selectedDataset, selectedDatasource?.scan]);

    const saveRule = useCallback(() => {
        let formError = false;
        if (lookup) {
            if (!selectedReferenceDataset || (selectedRule && selectedRule.is_lookup_rule)) {
                if (!selectedRule.lookup_attribute) {
                    formError = true;
                }
            }
        }
        if (!formError) {
            if (!onSave) {
                return;
            }
            if (!isSemanticBuilder) {
                prepareSelectedAttributes(selectedRule);
            }
            onSave(selectedRule);
        }
        setIsError(formError);
    }, [isSemanticBuilder, lookup, onSave, prepareSelectedAttributes, selectedReferenceDataset, selectedRule]);

    const loadRule = useCallback((rule) => {
        const fields = prepareFields(rule && rule.is_complex_rule);
        setFields([...fields]);
        if (rule.isQueryFilter) {
            rule.query = removeFormatting(rule.query ? rule.query : "");
        }
        setSelectedRule({ ...rule, custom_notification: rule.custom_notification ? rule.custom_notification : defaultCustomNotification });
    }, [prepareFields]);

    useEffect(() => {
        if (rule && !ruleLoaded) {
            loadRule(rule);
            setRuleLoaded(true);
            if (rule.isQueryFilter) {
                setTabIndex(1);
            } else if (rule.is_lookup_rule) {
                setTabIndex(isSemanticBuilder ? 1 : 2);
            } else {
                setTabIndex(0);
            }
        }
    }, [loadRule, rule, ruleLoaded]);

    useEffect(() => {
        if (referenceDatasets && referenceDatasets.length > 0 && rule?.reference_dataset) {
            setSelectedReferenceDataset(referenceDatasets.filter((elem) => elem?.file_url === rule?.reference_dataset?.file_url)[0] || {});

        }
    }, [referenceDatasets, rule]);

    const onChangeBuilderType = useCallback((value) => {
        const ruleGroupParams = {
            defaultField: attribute.name,
            defaultFieldType: getFieldType(attribute.datatype),
            attributeType: attribute.datatype,
            connectionType: selectedDatasource?.type,
            isScan: selectedDatasource?.scan
        };
        handleChange('ruleGroup', { ...createRuleGroup(ruleGroupParams) });
        handleChange('conditionalRuleGroup', {
            ...createRuleGroup({
                connectionType: selectedDatasource?.type,
                isScan: selectedDatasource?.scan
            })
        });
        handleChange('query', '');
        handleChange("isQueryFilter", value === 1 && !isSemanticBuilder);
        handleChange("is_sql_editor", value === 1 && !isSemanticBuilder);
        handleChange("is_complex_rule", value === 1 && !isSemanticBuilder);
        handleChange("is_lookup_rule", ((value === 1 && isSemanticBuilder) || (value === 2 && !isSemanticBuilder)));
        setTabIndex(value);
        (value === 1 && !isSemanticBuilder) ? onChangeRuleType(true) : onChangeRuleType(false);
    }, [attribute.datatype, attribute.name, handleChange, onChangeRuleType, selectedDatasource?.scan, selectedDatasource?.type]);

    const updateRule = useCallback((property, value) => {
        const rule = { ...selectedRule };
        rule[property] = value;
        if (selectedRule?.custom_notification?.custom_rule === rule?.custom_notification?.custom_rule) {
            if (!rule?.custom_notification?.custom_rule_simple && !rule?.custom_notification?.custom_rule_detailed && !rule?.custom_notification?.send_as_link) {
                rule[property]['custom_rule'] = false;
            } else if ((rule?.custom_notification?.custom_rule_simple || rule?.custom_notification?.custom_rule_detailed)) {
                rule[property]['custom_rule'] = true;
                rule[property]['send_as_link'] = false;
            } else if (rule?.custom_notification?.send_as_link) {
                rule[property]['custom_rule'] = true;
                rule[property]['custom_rule_simple'] = false;
                rule[property]['custom_rule_detailed'] = false;
            }
        } else {
            if (rule?.custom_notification?.custom_rule) {
                rule[property]['custom_rule_simple'] = true;
            } else {
                rule[property]['custom_rule_simple'] = false;
                rule[property]['custom_rule_detailed'] = false;
            }
        }
        setSelectedRule({ ...rule });
    }, [selectedRule]);

    return (
        <Dialog open={open} onClose={() => onClose()} maxWidth="lg">
            <ValidatorForm className={classes.fullwidth} name="ruleForm" autoComplete="off" onSubmit={() => saveRule()}>
                <Grid container direction="column" className={classes.ruleBuilderModalContainer}>
                    <Grid container direction="column" justify="flex-start" wrap="nowrap" style={{ height: '100%' }}>
                        <Grid container direction="row" justify="space-between" alignItems="center" wrap="nowrap">
                            <Grid container direction="row" justify="space-between" alignItems="center" wrap="nowrap" xs={isSemanticBuilder ? 11 : 12}>
                                <TextBox
                                    id="rule_name"
                                    name="name"
                                    placeholder="Rule Name"
                                    value={selectedRule.name ? selectedRule.name : ''}
                                    validators={['required']}
                                    errorMessages={['Rule Name is required']}
                                    fullWidth
                                    className={classNames(classes.inlinetxt, classes.ruleTitle)}
                                    error={duplicateNameError}
                                    helperText={duplicateNameError ? "Name is Duplicated" : ""}
                                    onChange={(event) => handleChange(event.target.name, event.target.value)}
                                />
                                {/* {
                                    configSettings?.custom_rule_notification &&
                                    <ToolTipComponent title="Custom Rule Notification" arrow>
                                        <IconButton className={classes.actionIconButton} onClick={(event) => setAnchorEl(event.currentTarget)}>
                                            <SettingsIcon style={{ color: theme.palette.primary.main }} />
                                        </IconButton>
                                    </ToolTipComponent>
                                } */}
                                <Popover
                                    id="custom-notification"
                                    open={Boolean(anchorEl)}
                                    anchorEl={anchorEl}
                                    onClose={() => { setAnchorEl(null); }}
                                    anchorOrigin={
                                        {
                                            vertical: 'bottom',
                                            horizontal: 'left'
                                        }
                                    }
                                >
                                    <Grid container style={{ padding: '16px 24px', maxWidth: '20vw' }}>
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={selectedRule?.custom_notification?.custom_rule}
                                                        onChange={() => updateRule('custom_notification', { ...selectedRule?.custom_notification, 'custom_rule': !selectedRule?.custom_notification?.custom_rule })}
                                                        name="custom_rule"
                                                        color="primary"
                                                    />
                                                }
                                                label="Custom Rule Notification"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={selectedRule?.custom_notification?.custom_rule_simple}
                                                        onChange={() => updateRule('custom_notification', { ...selectedRule?.custom_notification, 'custom_rule_simple': !selectedRule?.custom_notification?.custom_rule_simple })}
                                                        name="custom_rule_simple"
                                                        color="primary"
                                                    />
                                                }
                                                label="Simple"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={selectedRule?.custom_notification?.custom_rule_detailed}
                                                        onChange={() => updateRule('custom_notification', { ...selectedRule?.custom_notification, 'custom_rule_detailed': !selectedRule?.custom_notification?.custom_rule_detailed })}
                                                        name="custom_rule_detailed"
                                                        color="primary"
                                                    />
                                                }
                                                label="Detailed"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={selectedRule?.custom_notification?.send_as_link}
                                                        onChange={() => updateRule('custom_notification', { ...selectedRule?.custom_notification, 'send_as_link': !selectedRule?.custom_notification?.send_as_link, 'custom_rule_simple': selectedRule?.custom_notification?.send_as_link, 'custom_rule_detailed': selectedRule?.custom_notification?.send_as_link })}
                                                        name="send_as_link"
                                                        color="primary"
                                                    />
                                                }
                                                label="Send as a Link"
                                            />
                                        </Grid>
                                        <Grid item xs={12} style={{ paddingTop: 8 }}>
                                            <NumberInput
                                                disabled={!selectedRule?.custom_notification?.custom_rule}
                                                name="previewcount"
                                                label="Preview Count"
                                                fullWidth
                                                onChange={(event) => updateRule('custom_notification', { ...selectedRule?.custom_notification, 'previewcount': event.target.value })}
                                                value={selectedRule?.custom_notification?.previewcount}
                                            />
                                        </Grid>
                                        <Grid item xs={12} style={{ paddingTop: 8 }}>
                                            <NumberInput
                                                disabled={!selectedRule?.custom_notification?.custom_rule}
                                                name="threshold"
                                                label="Threshold"
                                                fullWidth
                                                onChange={(event) => updateRule('custom_notification', { ...selectedRule?.custom_notification, 'threshold': event.target.value })}
                                                value={selectedRule?.custom_notification?.threshold}
                                            />
                                        </Grid>
                                    </Grid>
                                </Popover>
                            </Grid>
                            {
                                isSemanticBuilder &&
                                <Grid item xs={1} align="right">
                                    <ToolTipComponent title="Rule ON/OFF">
                                        <Switch color="secondary"
                                            name="is_active"
                                            checked={selectedRule.is_active}
                                            onChange={(event) => handleChange(event.target.name, event.target.checked)}
                                        />
                                    </ToolTipComponent>
                                </Grid>
                            }

                        </Grid>
                        <TextBox
                            name="description"
                            placeholder="Rule Descriptions1"
                            value={selectedRule.description ? selectedRule.description : ''}
                            fullWidth
                            className={classNames(classes.inlinetxt, classes.ruleDescription)}
                            onChange={(event) => handleChange(event.target.name, event.target.value)}
                        />
                        {
                            profileSetting && profileSetting.valid_records_notification &&
                            <Grid container justify="flex-end">
                                <ToolTipComponent title={"Mark as Valid"}>
                                    <Switch color="secondary"
                                        name="isvalidrule"
                                        checked={selectedRule.isvalidrule ? selectedRule.isvalidrule : false}
                                        onChange={(event) => handleChange(event.target.name, event.target.checked)}
                                    />
                                </ToolTipComponent>
                            </Grid>
                        }
                        {
                            !isSemanticBuilder ?
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classNames(classes.queryContainer, classes.noMaxHeight)}>
                                    <Tabs
                                        value={tabIndex}
                                        indicatorColor="secondary"
                                        textColor="secondary"
                                        onChange={(event, index) => onChangeBuilderType(index)}
                                        aria-label="dataset tabs">
                                        {
                                            ruleGroupTabs.map((name, index) =>
                                                <Tab key={index} label={name} />
                                            )
                                        }
                                    </Tabs>
                                    {
                                        tabIndex === 0 &&
                                        <TabPanel value={tabIndex} index={0}>
                                            <QueryBuilder
                                                fields={fields}
                                                defaultField={attribute.name}
                                                defaultFieldType={attribute.datatype}
                                                ruleGroup={selectedRule.ruleGroup}
                                                onQueryChange={(ruleGroup, isConditionalRule) => handleChange(isConditionalRule ? 'conditionalRuleGroup' : 'ruleGroup', ruleGroup)}
                                                conditionalRuleGroup={selectedRule.conditionalRuleGroup}
                                                onRuleTypeChange={(isComplexRule) => onChangeRuleType(isComplexRule)}
                                                selectedDatasource={selectedDatasource}
                                                isComplexRule={selectedRule && selectedRule.is_complex_rule}
                                            />
                                        </TabPanel>
                                    }
                                    {
                                        tabIndex === 1 &&
                                        <TabPanel value={tabIndex} index={1}>
                                            <ReactQueryEditor
                                                fields={fields}
                                                rule={selectedRule ? selectedRule : {}}
                                                isShowValidate={Boolean(true)}
                                                isModel={Boolean(true)}
                                                // isValidQuery={!selectedRule.isValidQuery}
                                                onChange={(property, value) => handleChange(property, value)}
                                                container={containerRef}
                                                selectedRuleChanged />
                                        </TabPanel>
                                    }
                                    {
                                        tabIndex === 2 &&
                                        <TabPanel value={tabIndex} index={2}>
                                            <Grid container spacing={2} style={{ marginTop: 12 }}>
                                                <Grid item xs={12}>
                                                    <AutoCompleteInput
                                                        name="reference_dataset"
                                                        options={sortTable(referenceDatasets ? referenceDatasets : [], "asc", "name")}
                                                        value={selectedReferenceDataset ? selectedReferenceDataset : {}}
                                                        getOptionLabel={(option) => (option && option.name ? `${option.name}` : '')}
                                                        renderInput={
                                                            (params) =>
                                                                <TextField
                                                                    {...params}
                                                                    label="Reference Dataset"
                                                                    fullWidth
                                                                />
                                                        }
                                                        onChange={
                                                            (_, newValue) => {
                                                                if (newValue) {
                                                                    handleChange('reference_dataset', {
                                                                        file_url: newValue.file_url,
                                                                        file_type: newValue.file_type,
                                                                        storage_method: newValue.storage_method,
                                                                        name: newValue.name,
                                                                        id: newValue.id
                                                                    });
                                                                } else {
                                                                    handleChange('reference_dataset', {});
                                                                }
                                                                setSelectedReferenceDataset(newValue);
                                                            }
                                                        }
                                                        onInputChange={(_, value) => { }}
                                                        onOpen={() => { }}
                                                        fullWidth
                                                    />
                                                    {
                                                        isError && !selectedReferenceDataset &&
                                                        <FormHelperText error style={{ marginTop: -8 }}>
                                                            Reference Dataset is Required
                                                        </FormHelperText>
                                                    }
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <AutoCompleteInput
                                                        name="lookup_attribute"
                                                        options={sortTable(selectedReferenceDataset && selectedReferenceDataset.attributes ? selectedReferenceDataset.attributes : [], "asc", "name")}
                                                        value={selectedRule && selectedRule.lookup_attribute ? { attribute: selectedRule.lookup_attribute } : {}}
                                                        getOptionLabel={(option) => (option && option.attribute ? option.attribute : '')}
                                                        renderInput={
                                                            (params) =>
                                                                <TextField
                                                                    {...params}
                                                                    label="Lookup Attribute"
                                                                    fullWidth
                                                                />
                                                        }
                                                        onChange={(_, newValue) => { handleChange('lookup_attribute', newValue?.attribute || ''); }}
                                                        onInputChange={(_, value) => { }}
                                                        onOpen={() => { }}
                                                        fullWidth
                                                    />
                                                    {
                                                        isError && !selectedRule.lookup_attribute &&
                                                        <FormHelperText error style={{ marginTop: -8 }}>
                                                            Lookup Attribute is Required
                                                        </FormHelperText>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                    }
                                </Grid> :
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classNames(classes.queryContainer, classes.noMaxHeight)}>
                                    <Tabs
                                        value={tabIndex}
                                        indicatorColor="secondary"
                                        textColor="secondary"
                                        onChange={(event, index) => onChangeBuilderType(index)}
                                        aria-label="dataset tabs">
                                        {
                                            ruleGroupTabs.map((name, index) =>
                                                <Tab key={index} label={name} />
                                            )
                                        }
                                    </Tabs>
                                    {
                                        tabIndex === 0 &&
                                        <TabPanel value={tabIndex} index={0}>
                                            <SemanticQueryBuilder
                                                defaultFieldType={defaultField}
                                                params={selectedRule.params ? selectedRule.params : []}
                                                selectedRule={selectedRule ? selectedRule : {}}
                                                onQueryChange={(ruleGroup) => handleChange('params', ruleGroup)}
                                            />
                                        </TabPanel>
                                    }
                                    {
                                        tabIndex === 1 &&
                                        <TabPanel value={tabIndex} index={1}>
                                            <Grid container spacing={2} style={{ marginTop: 12 }}>
                                                <Grid item xs={12}>
                                                    <AutoCompleteInput
                                                        name="reference_dataset"
                                                        options={sortTable(referenceDatasets ? referenceDatasets : [], "asc", "name")}
                                                        value={selectedReferenceDataset ? selectedReferenceDataset : {}}
                                                        getOptionLabel={(option) => (option && option.name ? `${option.name}` : '')}
                                                        renderInput={
                                                            (params) =>
                                                                <TextField
                                                                    {...params}
                                                                    label="Reference Dataset"
                                                                    fullWidth
                                                                />
                                                        }
                                                        onChange={
                                                            (_, newValue) => {
                                                                if (newValue) {
                                                                    handleChange('reference_dataset', {
                                                                        file_url: newValue.file_url,
                                                                        file_type: newValue.file_type,
                                                                        storage_method: newValue.storage_method,
                                                                        name: newValue.name,
                                                                        id: newValue.id
                                                                    });
                                                                } else {
                                                                    handleChange('reference_dataset', {});
                                                                }
                                                                setSelectedReferenceDataset(newValue);
                                                            }
                                                        }
                                                        onInputChange={(_, value) => { }}
                                                        onOpen={() => { }}
                                                        fullWidth
                                                    />
                                                    {
                                                        isError && !selectedReferenceDataset &&
                                                        <FormHelperText error style={{ marginTop: -8 }}>
                                                            Reference Dataset is Required
                                                        </FormHelperText>
                                                    }
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <AutoCompleteInput
                                                        name="lookup_attribute"
                                                        options={sortTable(selectedReferenceDataset && selectedReferenceDataset.attributes ? selectedReferenceDataset.attributes : [], "asc", "name")}
                                                        value={selectedRule && selectedRule.lookup_attribute ? { attribute: selectedRule.lookup_attribute } : {}}
                                                        getOptionLabel={(option) => (option && option.attribute ? option.attribute : '')}
                                                        renderInput={
                                                            (params) =>
                                                                <TextField
                                                                    {...params}
                                                                    label="Lookup Attribute"
                                                                    fullWidth
                                                                />
                                                        }
                                                        onChange={(_, newValue) => { handleChange('lookup_attribute', newValue?.attribute || ''); }}
                                                        onInputChange={(_, value) => { }}
                                                        onOpen={() => { }}
                                                        fullWidth
                                                    />
                                                    {
                                                        isError && !selectedRule.lookup_attribute &&
                                                        <FormHelperText error style={{ marginTop: -8 }}>
                                                            Lookup Attribute is Required
                                                        </FormHelperText>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                    }
                                </Grid>
                        }

                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Grid container direction="row" justify="flex-end">
                                <Grid item className={classes.btnContainer}>
                                    <Button type="submit" variant="contained" color="primary" className={classes.actionButtons}>
                                        {'Save'}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button className={classNames(classes.cancelButton, classes.actionButtons, classes.paddingCancelButton)}
                                        onClick={() => onClose && onClose()}>
                                        {'Cancel'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </ValidatorForm>
        </Dialog>
    );
};

RuleBuilderModal.defaultProps = {
    attribute: {},
    rule: {},
    fields: [],
    open: false
};

RuleBuilderModal.propTypes = {
    classes: PropTypes.object,
    attribute: PropTypes.object,
    profileSetting: PropTypes.object,
    rule: PropTypes.object,
    userAttributes: PropTypes.array,
    properties: PropTypes.object,
    open: PropTypes.bool,
    onSave: PropTypes.func,
    updateRulename: PropTypes.func,
    onClose: PropTypes.func,
    isSemanticBuilder: PropTypes.bool,
    duplicateNameError: PropTypes.bool,
    defaultField: PropTypes.string
    // theme: PropTypes.object
};

export default withStyles((theme) => ({
    ...PropertiesListViewStyles(theme),
    ...DatasetStyles(theme),
    ...TileViewStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(RuleBuilderModal);