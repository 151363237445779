export const scheduleActions = {
    RUN_PROFILE_REQUEST: 'RUN_PROFILE_REQUEST',
    RUN_PROFILE_SUCCESS: 'RUN_PROFILE_SUCCESS',
    RUN_PROFILE_FAILURE: 'RUN_PROFILE_FAILURE',

    GET_PROFILE_ANALYSIS_REQUEST: 'GET_PROFILE_ANALYSIS_REQUEST',
    GET_PROFILE_ANALYSIS_SUCCESS: 'GET_PROFILE_ANALYSIS_SUCCESS',
    GET_PROFILE_ANALYSIS_FAILURE: 'GET_PROFILE_ANALYSIS_FAILURE',

    GET_DATASET_SCHEDULE_REQUEST: 'GET_DATASET_SCHEDULE_REQUEST',
    GET_DATASET_SCHEDULE_SUCCESS: 'GET_DATASET_SCHEDULE_SUCCESS',
    GET_DATASET_SCHEDULE_FAILURE: 'GET_DATASET_SCHEDULE_FAILURE',

    GET_DATASOURCE_SCHEDULE_REQUEST: 'GET_DATASOURCE_SCHEDULE_REQUEST',
    GET_DATASOURCE_SCHEDULE_SUCCESS: 'GET_DATASOURCE_SCHEDULE_SUCCESS',
    GET_DATASOURCE_SCHEDULE_FAILURE: 'GET_DATASOURCE_SCHEDULE_FAILURE',

    CREATE_DATASET_SCHEDULE_REQUEST: 'CREATE_DATASET_SCHEDULE_REQUEST',
    CREATE_DATASET_SCHEDULE_SUCCESS: 'CREATE_DATASET_SCHEDULE_SUCCESS',
    CREATE_DATASET_SCHEDULE_FAILURE: 'CREATE_DATASET_SCHEDULE_FAILURE',

    CREATE_DATASOURCE_SCHEDULE_REQUEST: 'CREATE_DATASOURCE_SCHEDULE_REQUEST',
    CREATE_DATASOURCE_SCHEDULE_SUCCESS: 'CREATE_DATASOURCE_SCHEDULE_SUCCESS',
    CREATE_DATASOURCE_SCHEDULE_FAILURE: 'CREATE_DATASOURCE_SCHEDULE_FAILURE',

    GET_DATASET_EXECUTION_LOGS_REQUEST: 'GET_DATASET_EXECUTION_LOGS_REQUEST',
    GET_DATASET_EXECUTION_LOGS_SUCCESS: 'GET_DATASET_EXECUTION_LOGS_SUCCESS',
    GET_DATASET_EXECUTION_LOGS_FAILURE: 'GET_DATASET_EXECUTION_LOGS_FAILURE',

    GET_DATASOURCE_EXECUTION_LOGS_REQUEST: 'GET_DATASOURCE_EXECUTION_LOGS_REQUEST',
    GET_DATASOURCE_EXECUTION_LOGS_SUCCESS: 'GET_DATASOURCE_EXECUTION_LOGS_SUCCESS',
    GET_DATASOURCE_EXECUTION_LOGS_FAILURE: 'GET_DATASOURCE_EXECUTION_LOGS_FAILURE',

    GET_CURATE_ANALYSIS_REQUEST: 'GET_CURATE_ANALYSIS_REQUEST',
    GET_CURATE_ANALYSIS_SUCCESS: 'GET_CURATE_ANALYSIS_SUCCESS',
    GET_CURATE_ANALYSIS_FAILURE: 'GET_CURATE_ANALYSIS_FAILURE',

    GET_TRENDING_CHART_REQUEST: 'GET_TRENDING_CHART_REQUEST',
    GET_TRENDING_CHART_SUCCESS: 'GET_TRENDING_CHART_SUCCESS',
    GET_TRENDING_CHART_FAILURE: 'GET_TRENDING_CHART_FAILURE',

    GET_DRIFTTRENDING_CHART_REQUEST: 'GET_DRIFTTRENDING_CHART_REQUEST',
    GET_DRIFTTRENDING_CHART_SUCCESS: 'GET_DRIFTTRENDING_CHART_SUCCESS',
    GET_DRIFTTRENDING_CHART_FAILURE: 'GET_DRIFTTRENDING_CHART_FAILURE',

    RESTART_SCHEDULE_REQUEST: 'RESTART_SCHEDULE_REQUEST',
    RESTART_SCHEDULE_SUCCESS: 'RESTART_SCHEDULE_SUCCESS',
    RESTART_SCHEDULE_FAILURE: 'RESTART_SCHEDULE_FAILURE',

    SET_PROFILE_RULE_REQUEST: 'SET_PROFILE_RULE_REQUEST',
    SET_PROFILE_RULE_SUCCESS: 'SET_PROFILE_RULE_SUCCESS',
    SET_PROFILE_RULE_FAILURE: 'SET_PROFILE_RULE_FAILURE',

    GET_PROFILE_RULES_REQUEST: 'GET_PROFILE_RULES_REQUEST',
    GET_PROFILE_RULES_SUCCESS: 'GET_PROFILE_RULES_SUCCESS',
    GET_PROFILE_RULES_FAILURE: 'GET_PROFILE_RULES_FAILURE',

    GET_DRIFT_RULES_REQUEST: 'GET_DRIFT_RULES_REQUEST',
    GET_DRIFT_RULES_SUCCESS: 'GET_DRIFT_RULES_SUCCESS',
    GET_DRIFT_RULES_FAILURE: 'GET_DRIFT_RULES_FAILURE',

    KILL_JOB_REQUEST: 'KILL_JOB_REQUEST',
    KILL_JOB_SUCCESS: 'KILL_JOB_SUCCESS',
    KILL_JOB_FAILURE: 'KILL_JOB_FAILURE',

    GET_METRIC_DATA_QUALITY_REQUEST: 'GET_METRIC_DATA_QUALITY_REQUEST',
    GET_METRIC_DATA_QUALITY_SUCCESS: 'GET_METRIC_DATA_QUALITY_SUCCESS',
    GET_METRIC_DATA_QUALITY_FAILURE: 'GET_METRIC_DATA_QUALITY_FAILURE',

    GET_DATASOURCE_TIMELINE_REQUEST: 'GET_DATASOURCE_TIMELINE_REQUEST',
    GET_DATASOURCE_TIMELINE_SUCCESS: 'GET_DATASOURCE_TIMELINE_SUCCESS',
    GET_DATASOURCE_TIMELINE_FAILURE: 'GET_DATASOURCE_TIMELINE_FAILURE',

    GET_DATASOURCE_SENSITIVITY_REQUEST: 'GET_DATASOURCE_SENSITIVITY_REQUEST',
    GET_DATASOURCE_SENSITIVITY_SUCCESS: 'GET_DATASOURCE_SENSITIVITY_SUCCESS',
    GET_DATASOURCE_SENSITIVITY_FAILURE: 'GET_DATASOURCE_SENSITIVITY_FAILURE',

    DATASET_LEARNING_REQUEST: 'DATASET_LEARNING_REQUEST',
    DATASET_LEARNING_SUCCESS: 'DATASET_LEARNING_SUCCESS',
    DATASET_LEARNING_FAILURE: 'DATASET_LEARNING_FAILURE',

    DELETE_DATASET_LEARNING_REQUEST: 'DELETE_DATASET_LEARNING_REQUEST',
    DELETE_DATASET_LEARNING_SUCCESS: 'DELETE_DATASET_LEARNING_SUCCESS',
    DELETE_DATASET_LEARNING_FAILURE: 'DELETE_DATASET_LEARNING_FAILURE',

    GET_DASHBOARD_VIEW_REQUEST: 'GET_DASHBOARD_VIEW_REQUEST',
    GET_DASHBOARD_VIEW_SUCCESS: 'GET_DASHBOARD_VIEW_SUCCESS',
    GET_DASHBOARD_VIEW_FAILURE: 'GET_DASHBOARD_VIEW_FAILURE',

    GET_DASHBOARD_USER_ANALYSIS_REQUEST: 'GET_DASHBOARD_USER_ANALYSIS_REQUEST',
    GET_DASHBOARD_USER_ANALYSIS_SUCCESS: 'GET_DASHBOARD_USER_ANALYSIS_SUCCESS',
    GET_DASHBOARD_USER_ANALYSIS_FAILURE: 'GET_DASHBOARD_USER_ANALYSIS_FAILURE',

    GET_DASHBOARD_DQSCORE_ANALYSIS_REQUEST: 'GET_DASHBOARD_DQSCORE_ANALYSIS_REQUEST',
    GET_DASHBOARD_DQSCORE_ANALYSIS_SUCCESS: 'GET_DASHBOARD_DQSCORE_ANALYSIS_SUCCESS',
    GET_DASHBOARD_DQSCORE_ANALYSIS_FAILURE: 'GET_DASHBOARD_DQSCORE_ANALYSIS_FAILURE',

    GET_DASHBOARD_RELATION_SHIP_ANALYSIS_REQUEST: 'GET_DASHBOARD_RELATION_SHIP_ANALYSIS_REQUEST',
    GET_DASHBOARD_RELATION_SHIP_ANALYSIS_SUCCESS: 'GET_DASHBOARD_RELATION_SHIP_ANALYSIS_SUCCESS',
    GET_DASHBOARD_RELATION_SHIP_ANALYSIS_FAILURE: 'GET_DASHBOARD_RELATION_SHIP_ANALYSIS_FAILURE',

    GET_DASHBOARD_TIMELINE_ANALYSIS_REQUEST: 'GET_DASHBOARD_TIMELINE_ANALYSIS_REQUEST',
    GET_DASHBOARD_TIMELINE_ANALYSIS_SUCCESS: 'GET_DASHBOARD_TIMELINE_ANALYSIS_SUCCESS',
    GET_DASHBOARD_TIMELINE_ANALYSIS_FAILURE: 'GET_DASHBOARD_TIMELINE_ANALYSIS_FAILURE',

    GET_DASHBOARD_DISTRIBUTION_ANALYSIS_REQUEST: 'GET_DASHBOARD_DISTRIBUTION_ANALYSIS_REQUEST',
    GET_DASHBOARD_DISTRIBUTION_ANALYSIS_SUCCESS: 'GET_DASHBOARD_DISTRIBUTION_ANALYSIS_SUCCESS',
    GET_DASHBOARD_DISTRIBUTION_ANALYSIS_FAILURE: 'GET_DASHBOARD_DISTRIBUTION_ANALYSIS_FAILURE',

    GET_DASHBOARD_SENSITIVITY_ANALYSIS_REQUEST: 'GET_DASHBOARD_SENSITIVITY_ANALYSIS_REQUEST',
    GET_DASHBOARD_SENSITIVITY_ANALYSIS_SUCCESS: 'GET_DASHBOARD_SENSITIVITY_ANALYSIS_SUCCESS',
    GET_DASHBOARD_SENSITIVITY_ANALYSIS_FAILURE: 'GET_DASHBOARD_SENSITIVITY_ANALYSIS_FAILURE',

    GET_DASHBOARD_CONVERSATION_ANALYSIS_REQUEST: 'GET_DASHBOARD_CONVERSATION_ANALYSIS_REQUEST',
    GET_DASHBOARD_CONVERSATION_ANALYSIS_SUCCESS: 'GET_DASHBOARD_CONVERSATION_ANALYSIS_SUCCESS',
    GET_DASHBOARD_CONVERSATION_ANALYSIS_FAILURE: 'GET_DASHBOARD_CONVERSATION_ANALYSIS_FAILURE',

    GET_DASHBOARD_METRICS_ANALYSIS_REQUEST: 'GET_DASHBOARD_METRICS_ANALYSIS_REQUEST',
    GET_DASHBOARD_METRICS_ANALYSIS_SUCCESS: 'GET_DASHBOARD_METRICS_ANALYSIS_SUCCESS',
    GET_DASHBOARD_METRICS_ANALYSIS_FAILURE: 'GET_DASHBOARD_METRICS_ANALYSIS_FAILURE',

    GET_DASHBOARD_METRICS_TIMELINE_ANALYSIS_REQUEST: 'GET_DASHBOARD_METRICS_TIMELINE_ANALYSIS_REQUEST',
    GET_DASHBOARD_METRICS_TIMELINE_ANALYSIS_SUCCESS: 'GET_DASHBOARD_METRICS_TIMELINE_ANALYSIS_SUCCESS',
    GET_DASHBOARD_METRICS_TIMELINE_ANALYSIS_FAILURE: 'GET_DASHBOARD_METRICS_TIMELINE_ANALYSIS_FAILURE',

    GET_DASHBOARD_METRICS_GOVERNANCE_ANALYSIS_REQUEST: 'GET_DASHBOARD_METRICS_GOVERNANCE_ANALYSIS_REQUEST',
    GET_DASHBOARD_METRICS_GOVERNANCE_ANALYSIS_SUCCESS: 'GET_DASHBOARD_METRICS_GOVERNANCE_ANALYSIS_SUCCESS',
    GET_DASHBOARD_METRICS_GOVERNANCE_ANALYSIS_FAILURE: 'GET_DASHBOARD_METRICS_GOVERNANCE_ANALYSIS_FAILURE',

    GET_NOTIFICATION_METRICS_TIMELINE_ANALYSIS_REQUEST: 'GET_NOTIFICATION_METRICS_TIMELINE_ANALYSIS_REQUEST',
    GET_NOTIFICATION_METRICS_TIMELINE_ANALYSIS_SUCCESS: 'GET_NOTIFICATION_METRICS_TIMELINE_ANALYSIS_SUCCESS',
    GET_NOTIFICATION_METRICS_TIMELINE_ANALYSIS_FAILURE: 'GET_NOTIFICATION_METRICS_TIMELINE_ANALYSIS_FAILURE',

    GET_CONVERSATION_METRICS_TIMELINE_ANALYSIS_REQUEST: 'GET_CONVERSATION_METRICS_TIMELINE_ANALYSIS_REQUEST',
    GET_CONVERSATION_METRICS_TIMELINE_ANALYSIS_SUCCESS: 'GET_CONVERSATION_METRICS_TIMELINE_ANALYSIS_SUCCESS',
    GET_CONVERSATION_METRICS_TIMELINE_ANALYSIS_FAILURE: 'GET_CONVERSATION_METRICS_TIMELINE_ANALYSIS_FAILURE',

    GET_DATASOURCE_METRICS_REQUEST: 'GET_DATASOURCE_METRICS_REQUEST',
    GET_DATASOURCE_METRICS_SUCCESS: 'GET_DATASOURCE_METRICS_SUCCESS',
    GET_DATASOURCE_METRICS_FAILURE: 'GET_DATASOURCE_METRICS_FAILURE',

    GET_DOMAIN_SCORE_REQUEST: 'GET_DOMAIN_SCORE_REQUEST',
    GET_DOMAIN_SCORE_SUCCESS: 'GET_DOMAIN_SCORE_SUCCESS',
    GET_DOMAIN_SCORE_FAILURE: 'GET_DOMAIN_SCORE_FAILURE',

    GET_DOMAIN_ASSET_REQUEST: 'GET_DOMAIN_ASSET_REQUEST',
    GET_DOMAIN_ASSET_SUCCESS: 'GET_DOMAIN_ASSET_SUCCESS',
    GET_DOMAIN_ASSET_FAILURE: 'GET_DOMAIN_ASSET_FAILURE',

    GET_OVER_ALL_SCORE_REQUEST: 'GET_OVER_ALL_SCORE_REQUEST',
    GET_OVER_ALL_SCORE_SUCCESS: 'GET_OVER_ALL_SCORE_SUCCESS',
    GET_OVER_ALL_SCORE_FAILURE: 'GET_OVER_ALL_SCORE_FAILURE',

    PREVIEW_INVALID_DATA_REQUEST: 'PREVIEW_INVALID_DATA_REQUEST',
    PREVIEW_INVALID_DATA_SUCCESS: 'PREVIEW_INVALID_DATA_SUCCESS',
    PREVIEW_INVALID_DATA_FAILURE: 'PREVIEW_INVALID_DATA_FAILURE',

    EXPORT_DATA_INVALID_RECORDS_REQUEST: 'EXPORT_DATA_INVALID_RECORDS_REQUEST',
    EXPORT_DATA_INVALID_RECORDS_SUCCESS: 'EXPORT_DATA_INVALID_RECORDS_SUCCESS',
    EXPORT_DATA_INVALID_RECORDS_FAILURE: 'EXPORT_DATA_INVALID_RECORDS_FAILURE',

    GET_DRIFT_ATTRIBUTE_REQUEST: 'GET_DRIFT_ATTRIBUTE_REQUEST',
    GET_DRIFT_ATTRIBUTE_SUCCESS: 'GET_DRIFT_ATTRIBUTE_SUCCESS',
    GET_DRIFT_ATTRIBUTE_FAILURE: 'GET_DRIFT_ATTRIBUTE_FAILURE',

    GET_DRIFT_COUNT_STATISTICS_REQUEST: 'GET_DRIFT_COUNT_STATISTICS_REQUEST',
    GET_DRIFT_COUNT_STATISTICS_SUCCESS: 'GET_DRIFT_COUNT_STATISTICS_SUCCESS',
    GET_DRIFT_COUNT_STATISTICS_FAILURE: 'GET_DRIFT_COUNT_STATISTICS_FAILURE',

    GET_DRIFT_DESCRIPTIVE_STATISTICS_REQUEST: 'GET_DRIFT_DESCRIPTIVE_STATISTICS_REQUEST',
    GET_DRIFT_DESCRIPTIVE_STATISTICS_SUCCESS: 'GET_DRIFT_DESCRIPTIVE_STATISTICS_SUCCESS',
    GET_DRIFT_DESCRIPTIVE_STATISTICS_FAILURE: 'GET_DRIFT_DESCRIPTIVE_STATISTICS_FAILURE',

    GET_DRIFT_LENGTH_STATISTICS_REQUEST: 'GET_DRIFT_LENGTH_STATISTICS_REQUEST',
    GET_DRIFT_LENGTH_STATISTICS_SUCCESS: 'GET_DRIFT_LENGTH_STATISTICS_SUCCESS',
    GET_DRIFT_LENGTH_STATISTICS_FAILURE: 'GET_DRIFT_LENGTH_STATISTICS_FAILURE',

    GET_DRIFT_PATTERN_STATISTICS_REQUEST: 'GET_DRIFT_PATTERN_STATISTICS_REQUEST',
    GET_DRIFT_PATTERN_STATISTICS_SUCCESS: 'GET_DRIFT_PATTERN_STATISTICS_SUCCESS',
    GET_DRIFT_PATTERN_STATISTICS_FAILURE: 'GET_DRIFT_PATTERN_STATISTICS_FAILURE',

    GET_DRIFT_EXTREME_STATISTICS_REQUEST: 'GET_DRIFT_EXTREME_STATISTICS_REQUEST',
    GET_DRIFT_EXTREME_STATISTICS_SUCCESS: 'GET_DRIFT_EXTREME_STATISTICS_SUCCESS',
    GET_DRIFT_EXTREME_STATISTICS_FAILURE: 'GET_DRIFT_EXTREME_STATISTICS_FAILURE',

    UPDATE_DRIFT_RULES_SCORE_REQUEST: 'UPDATE_DRIFT_RULES_SCORE_REQUEST',
    UPDATE_DRIFT_RULES_SCORE_SUCCESS: 'UPDATE_DRIFT_RULES_SCORE_SUCCESS',
    UPDATE_DRIFT_RULES_SCORE_FAILURE: 'UPDATE_DRIFT_RULES_SCORE_FAILURE',

    GET_DRIFT_DASHBOARD_STATISTICS_REQUEST: 'GET_DRIFT_DASHBOARD_STATISTICS_REQUEST',
    GET_DRIFT_DASHBOARD_STATISTICS_SUCCESS: 'GET_DRIFT_DASHBOARD_STATISTICS_SUCCESS',
    GET_DRIFT_DASHBOARD_STATISTICS_FAILURE: 'GET_DRIFT_DASHBOARD_STATISTICS_FAILURE',

    GET_DRIFT_ATTRIBUTE_STEPS_STATISTICS_REQUEST: 'GET_DRIFT_ATTRIBUTE_STEPS_STATISTICS_REQUEST',
    GET_DRIFT_ATTRIBUTE_STEPS_STATISTICS_SUCCESS: 'GET_DRIFT_ATTRIBUTE_STEPS_STATISTICS_SUCCESS',
    GET_DRIFT_ATTRIBUTE_STEPS_STATISTICS_FAILURE: 'GET_DRIFT_ATTRIBUTE_STEPS_STATISTICS_FAILURE',

    GET_DRIFT_DATASET_DASHBOARD_REQUEST: 'GET_DRIFT_DATASET_DASHBOARD_REQUEST',
    GET_DRIFT_DATASET_DASHBOARD_SUCCESS: 'GET_DRIFT_DATASET_DASHBOARD_SUCCESS',
    GET_DRIFT_DATASET_DASHBOARD_FAILURE: 'GET_DRIFT_DATASET_DASHBOARD_FAILURE',

    GET_DRIFT_DATASET_STEP_STATISTICS_REQUEST: 'GET_DRIFT_DATASET_STEP_STATISTICS_REQUEST',
    GET_DRIFT_DATASET_STEP_STATISTICS_SUCCESS: 'GET_DRIFT_DATASET_STEP_STATISTICS_SUCCESS',
    GET_DRIFT_DATASET_STEP_STATISTICS_FAILURE: 'GET_DRIFT_DATASET_STEP_STATISTICS_FAILURE',

    UPDATE_DRIFT_PROPERTIES_REQUEST: 'UPDATE_DRIFT_PROPERTIES_REQUEST',
    UPDATE_DRIFT_PROPERTIES_SUCCESS: 'UPDATE_DRIFT_PROPERTIES_SUCCESS',
    UPDATE_DRIFT_PROPERTIES_FAILURE: 'UPDATE_DRIFGET_DATASET_CORRELATION_FAILURET_PROPERTIES_FAILURE',

    UPDATE_DRIFT_DATASETPROPERTIES_REQUEST: 'UPDATE_DRIFT_DATASETPROPERTIES_REQUEST',
    UPDATE_DRIFT_DATASETPROPERTIES_SUCCESS: 'UPDATE_DRIFT_DATASETPROPERTIES_SUCCESS',
    UPDATE_DRIFT_DATASETPROPERTIES_FAILURE: 'UPDATE_DRIFT_DATASETPROPERTIES_FAILURE',

    GET_DATASET_CORRELATION_REQUEST: 'GET_DATASET_CORRELATION_REQUEST',
    GET_DATASET_CORRELATION_SUCCESS: 'GET_DATASET_CORRELATION_SUCCESS',
    GET_DATASET_CORRELATION_FAILURE: 'GET_DATASET_CORRELATION_FAILURE',

    GET_ATTRIBUTE_PROPERTY_RULES_REQUEST: 'GET_ATTRIBUTE_PROPERTY_RULES_REQUEST',
    GET_ATTRIBUTE_PROPERTY_RULES_SUCCESS: 'GET_ATTRIBUTE_PROPERTY_RULES_SUCCESS',
    GET_ATTRIBUTE_PROPERTY_RULES_FAILURE: 'GET_ATTRIBUTE_PROPERTY_RULES_FAILURE',

    GET_DATASET_PROFILE_RULES_REQUEST: 'GET_DATASET_PROFILE_RULES_REQUEST',
    GET_DATASET_PROFILE_RULES_SUCCESS: 'GET_DATASET_PROFILE_RULES_SUCCESS',
    GET_DATASET_PROFILE_RULES_FAILURE: 'GET_DATASET_PROFILE_RULES_FAILURE',

    ADD_MONITORING_DETAILS_REQUEST: 'ADD_MONITORING_DETAILS_REQUEST',
    ADD_MONITORING_DETAILS_SUCCESS: 'ADD_MONITORING_DETAILS_SUCCESS',
    ADD_MONITORING_DETAILS_FAILURE: 'ADD_MONITORING_DETAILS_FAILURE',

    GET_MONITORING_ALERTS_REQUEST: 'GET_MONITORING_ALERTS_REQUEST',
    GET_MONITORING_ALERTS_SUCCESS: 'GET_MONITORING_ALERTS_SUCCESS',
    GET_MONITORING_ALERTS_FAILURE: 'GET_MONITORING_ALERTS_FAILURE',

    GET_MONITORING_STATISTICAL_REQUEST: 'GET_MONITORING_STATISTICAL_REQUEST',
    GET_MONITORING_STATISTICAL_SUCCESS: 'GET_MONITORING_STATISTICAL_SUCCESS',
    GET_MONITORING_STATISTICAL_FAILURE: 'GET_MONITORING_STATISTICAL_FAILURE',

    DELETE_MONITORING_REQUEST: 'DELETE_MONITORING_REQUEST',
    DELETE_MONITORING_SUCCESS: 'DELETE_MONITORING_SUCCESS',
    DELETE_MONITORING_FAILURE: 'DELETE_MONITORING_FAILURE',

    MUTE_MONITORING_REQUEST: 'MUTE_MONITORING_REQUEST',
    MUTE_MONITORING_SUCCESS: 'MUTE_MONITORING_SUCCESS',
    MUTE_MONITORING_FAILURE: 'MUTE_MONITORING_FAILURE',

    RULE_OFF_MONITORING_REQUEST: 'RULE_OFF_MONITORING_REQUEST',
    RULE_OFF_MONITORING_SUCCESS: 'RULE_OFF_MONITORING_SUCCESS',
    RULE_OFF_MONITORING_FAILURE: 'RULE_OFF_MONITORING_FAILURE',

    UPDATE_ATTRIBUTE_PROPERTY_RULES_REQUEST: 'UPDATE_ATTRIBUTE_PROPERTY_RULES_REQUEST',
    UPDATE_ATTRIBUTE_PROPERTY_RULES_SUCCESS: 'UPDATE_ATTRIBUTE_PROPERTY_RULES_SUCCESS',
    UPDATE_ATTRIBUTE_PROPERTY_RULES_FAILURE: 'UPDATE_ATTRIBUTE_PROPERTY_RULES_FAILURE',

    GET_ATTRIBUTE_STATISTICS_REQUEST: 'GET_ATTRIBUTE_STATISTICS_REQUEST',
    GET_ATTRIBUTE_STATISTICS_SUCCESS: 'GET_ATTRIBUTE_STATISTICS_SUCCESS',
    GET_ATTRIBUTE_STATISTICS_FAILURE: 'GET_ATTRIBUTE_STATISTICS_FAILURE',

    GET_INVALID_QUERIES_REQUEST: 'GET_INVALID_QUERIES_REQUEST',
    GET_INVALID_QUERIES_SUCCESS: 'GET_INVALID_QUERIES_SUCCESS',
    GET_INVALID_QUERIES_FAILURE: 'GET_INVALID_QUERIES_FAILURE',

    GET_EXECUTION_CURRENT_JOB_INFO_REQUEST: 'GET_EXECUTION_CURRENT_JOB_INFO_REQUEST',
    GET_EXECUTION_CURRENT_JOB_INFO_SUCCESS: 'GET_EXECUTION_CURRENT_JOB_INFO_SUCCESS',
    GET_EXECUTION_CURRENT_JOB_INFO_FAILURE: 'GET_EXECUTION_CURRENT_JOB_INFO_FAILURE',

    GET_DRIFT_BEHAVIOUR_REQUEST: 'GET_DRIFT_BEHAVIOUR_REQUEST',
    GET_DRIFT_BEHAVIOUR_SUCCESS: 'GET_DRIFT_BEHAVIOUR_SUCCESS',
    GET_DRIFT_BEHAVIOUR_FAILURE: 'GET_DRIFT_BEHAVIOUR_FAILURE',

    REMOVE_DATASOURCE_SCHEDULE_REQUEST: 'REMOVE_DATASOURCE_SCHEDULE_REQUEST',
    REMOVE_DATASOURCE_SCHEDULE_REQUEST_SUCCESS: 'REMOVE_DATASOURCE_SCHEDULE_REQUEST_SUCCESS',
    REMOVE_DATASOURCE_SCHEDULE_REQUEST_FAILURE: 'REMOVE_DATASOURCE_SCHEDULE_REQUEST_FAILURE',

    REMOVE_SCHEDULE_REQUEST: 'REMOVE_SCHEDULE_REQUEST',
    REMOVE_SCHEDULE_REQUEST_SUCCESS: 'REMOVE_SCHEDULE_REQUEST_SUCCESS',
    REMOVE_SCHEDULE_REQUEST_FAILURE: 'REMOVE_SCHEDULE_REQUEST_FAILURE',

    CREATE_REPORT_REQUEST: 'CREATE_REPORT_REQUEST',
    CREATE_REPORT_SUCCESS: 'CREATE_REPORT_SUCCESS',
    CREATE_REPORT_FAILURE: 'CREATE_REPORT_FAILURE',

    GET_REPORT_REQUEST: 'GET_REPORT_REQUEST',
    GET_REPORT_SUCCESS: 'GET_REPORT_SUCCESS',
    GET_REPORT_FAILURE: 'GET_REPORT_FAILURE',

    GET_REPORT_BY_ID_REQUEST: 'GET_REPORT_BY_ID_REQUEST',
    GET_REPORT_BY_ID_SUCCESS: 'GET_REPORT_BY_ID_SUCCESS',
    GET_REPORT_BY_ID_FAILURE: 'GET_REPORT_BY_ID_FAILURE',

    GET_DATASET_ATTRIBUTE_REQUEST: 'GET_DATASET_ATTRIBUTE_REQUEST',
    GET_DATASET_ATTRIBUTE_SUCCESS: 'GET_DATASET_ATTRIBUTE_SUCCESS',
    GET_DATASET_ATTRIBUTE_FAILURE: 'GET_DATASET_ATTRIBUTE_FAILURE',

    DELETE_REPORT_BY_ID_REQUEST: 'DELETE_REPORT_BY_ID_REQUEST',
    DELETE_REPORT_BY_ID_SUCCESS: 'DELETE_REPORT_BY_ID_SUCCESS',
    DELETE_REPORT_BY_ID_FAILURE: 'DELETE_REPORT_BY_ID_FAILURE',

    GET_ATTRIBUTES_RULES_REQUEST: 'GET_ATTRIBUTES_RULES_REQUEST',
    GET_ATTRIBUTES_RULES_SUCCESS: 'GET_ATTRIBUTES_RULES_SUCCESS',
    GET_ATTRIBUTES_RULES_FAILURE: 'GET_ATTRIBUTES_RULES_FAILURE',

    GET_RECIPIENTS_USERS_REQUEST: 'GET_RECIPIENTS_USERS_REQUEST',
    GET_RECIPIENTS_USERS_SUCCESS: 'GET_RECIPIENTS_USERS_SUCCESS',
    GET_RECIPIENTS_USERS_FAILURE: 'GET_RECIPIENTS_USERS_FAILURE',

    GET_JOB_STATUS_CHART_REQUEST: 'GET_JOB_STATUS_CHART_REQUEST',
    GET_JOB_STATUS_CHART_SUCCESS: 'GET_JOB_STATUS_CHART_SUCCESS',
    GET_JOB_STATUS_CHART_FAILURE: 'GET_JOB_STATUS_CHART_FAILURE',

    GET_DATASOURCE_LIST_REQUEST: 'GET_DATASOURCE_LIST_REQUEST',
    GET_DATASOURCE_LIST_SUCCESS: 'GET_DATASOURCE_LIST_SUCCESS',
    GET_DATASOURCE_LIST_FAILURE: 'GET_DATASOURCE_LIST_FAILURE',

    GET_DATASET_LIST_REQUEST: 'GET_DATASET_LIST_REQUEST',
    GET_DATASET_LIST_SUCCESS: 'GET_DATASET_LIST_SUCCESS',
    GET_DATASET_LIST_FAILURE: 'GET_DATASET_LIST_FAILURE'


};