import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect, batch } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';
import classNames from 'classnames';
import { getOrganizationUser, updateUser } from '../../actions/accountActions';
import {
    updateDataSource, getSourceAuditLogs, createAuditLog, createSourceShare, updateFavorites, getFavorites, getCurationRules, getReferences, inviteSourceUser, deleteDatasetShare, setSelectedDatasourceId, getDataSource, getFieldPropertyByLevel, createFieldProperty, getExportReport, getDomain, createDomain, saveDuplicate, getDuplicateColumn, updateCertified, getCertified
} from '../../actions/datasourceActions';
import { clearPreviewData, getDataSetDetail, updateDataset, getDatasetInfo, deleteDataSet, getDatasetShare, createDatasetShare, setSelectedDatasetId, getDatasetRules, filterInputData, getUserAttributes, loadDataset, setPropertySliderIndex, setPropertyView, datasetPropertiesTab, getPropertiesList, getAttributePropertiesList, updateDatasetProperties, setSelectedDataset, getExportRulesValidity, updateTabIndex, setAttributeName } from '../../actions/datasetActions';
import { updateSelectedAttribute } from '../../actions/searchActions';
import { getProfileAnalysis, getCurationAnalysis, getTrending, profileRuleEnable, getProfileRules, getDriftRules, getDriftTrending, getDriftAttribute, driftAcceptRules, getAttributePropertyRules, updateAttributePropertyRules, getAttributeStatistics, getInvalidQueries } from '../../actions/scheduleActions';
import { getMasterFieldTypes } from '../../actions/masterActions';
import DatasourceInfo from '../../components/Datasource/DatasourceInfo.jsx';
import { appConstants } from '../../constants/appConstants';
import TabPanel from '../../components/TabPanel/TabPanel.jsx';
import DatasourceDetail from '../../components/Datasource/DatasourceDetail.jsx';
import DatasetDetail from '../../components/Datasource/DatasetDetail.jsx';
import Permission from '../../components/Permission/Permission.jsx';
import AuditLogComponent from '../../components/AuditLogComponent/AuditLogComponent.jsx';
import Styles from '../../layouts/Styles.jsx';
import DataSetAttributes from '../../components/Dataset/DataSetAttributes.jsx';
import WorkbookItems from '../../components/Dataset/WorkbookItems.jsx';
import WorkbookDatasource from '../../components/Dataset/WorkbookDatasource.jsx';
import ExecutionLogs from '../../components/Datasource/ExecutionLogs.jsx';
import DatasetProfile from '../../components/DatasetProfile/DatasetProfile.jsx';
import DatasetProperties from '../../components/Dataset/DatasetProperties.jsx';
import { getAuditLogText } from '../../helpers/appHelpers';
import Loader from '../../components/Loaders/Loader.jsx';
import DatasetCuration from '../../components/DatasetProfile/DatasetCuration.jsx';
import DatasetLearning from '../../components/DatasetProfile/DatasetLearning.jsx';
import AlertDialog from '../../components/AlertDialog/AlertDialog.jsx';
import { getActionItemCount, getConversationCount } from '../../actions/notificationActions.js';
import { getPropertiesOrganization as getProfileProperties, getStorage, getCustomTabs, getCustomFields, getExportMetadata } from '../../actions/settingActions';
import NoResultFound from '../../components/NoResultFound/NoResultFound.jsx';
import DatasetMonitoring from '../../components/Dataset/DatasetMonitoring.jsx';
import DatasetExport from '../../components/Dataset/DatasetExport.jsx';

// Import Component
import DynamicElementByDataType from '../../components/DynamicElementByDataType/DynamicElementByDataType.jsx';
import CatalogInfo from '../../components/Dataset/CatalogInfo.jsx';
import { clearCatalogInfo, getDatasetCatalog, getAttributesCatalog } from '../../actions/catalogActions';
import Comments from '../../components/Comments/Comments.jsx';
import LineageTableau from '../Lineage/LineageTableau.jsx';
import { setCurrentPage } from '../../helpers/apiHelpers';
import { Grid, withStyles, Tabs, Tab, IconButton } from '@material-ui/core';

import datasourceTypes from '../../config/datasourceTypes';

class DatasetOverview extends Component {
    constructor(props) {
        super(props);
        setCurrentPage('dataset');
        this.state = {
            tabIndex: this.navigateIndex(),
            selectedAttribute: this.props.location.state && this.props.location.state.attribute && this.props.location.state.attribute !== "None" ? this.props.location.state.attribute : '',
            datasetDetail: {
                "terms_description": "",
                "name": "",
                "platform_use": [],
                "source_id": this.props.location.state && this.props.location.state.datasource ? this.props.location.state.datasource.id : null,
                "tags": [],
                "domains": [],
                "domain_ids": [],
                "doPropagate": this.props.domainProperties.domain_propagation
            },
            datasetDetailItem: {},
            datasourceDetail: (this.props.location.state && this.props.location.state.datasource) ? this.props.location.state.datasource : {},
            auditLogs: [],
            auditLogsLength: 0,
            breadcrumb: [
                {
                    name: 'Catalog',
                    path: '/catalog',
                    type: 'catalog'
                }
            ],
            isFavourite: false,
            isCertified: false,
            governance: [],
            isPermissionLoading: true,
            isDataLoading: true,
            isStewardUser: false,
            isDeleteDataset: false,
            domain: [],
            driftAttributes: [],
            isDrift: false,
            isTableau: false,
            tabs: [],
            fields: [],
            fieldProperties: [],
            isExportLoading: false,
            duplicateColumnList: [],
            tabFields: [],
            customTabls: [],
            isGovernanceUpdate: false,
            customFieldLoading: false,
            tabAttribute: (this.props.location.state && this.props.location.state.tabAttribute) ? this.props.location.state.tabAttribute : null,
            selectedRule: "",
            locationHistory: this.props.location.state ? this.props.location.state : {},
            collapseFlag: false,
            subTabIndex: 0
        };
    }

    componentDidMount() {
        const tabs = appConstants ? this.getTab(this.props.location?.state?.connector === "tableau" ? [...appConstants.datasetTableauList] : [...appConstants.datasetTabList]) : [];
        this.setState({ tabs: tabs });
        if (this.props.location && this.props.location.search) {
            this.getUrlString();
        }
        batch(() => {
            this.props.clearCatalogInfo();
            this.props.clearPreviewData();
            this.props.getMasterFieldTypes();
            if (this.props.match.params.id) {
                this.props.getDatasetCatalog(this.props.match.params.id);
                this.props.getAttributesCatalog(this.props.match.params.id);
                this.props.loadDataset(this.props.match.params.id);
                this.props.setSelectedDatasetId(this.props.match.params.id);
                if (this.state.datasourceDetail && this.state.datasourceDetail.id) {
                    this.props.getUserAttributes(this.state.datasourceDetail.id ? this.state.datasourceDetail.id : this.props.location.state.datasourceId);
                    this.props.setSelectedDatasourceId(this.state.datasourceDetail.id ? this.state.datasourceDetail.id : this.props.location.state.datasourceId);
                }
                if (this.props.licenseConfig.drift) {
                    this.getDriftAttribute();
                }
                this.getDuplicate(this.props.match.params.id);
            }
            if (!this.props.users.length) {
                this.getUsers();
                this.props.getStorage(this.props.user.organization);
                const organizationId = this.props.systemConfiguration ? 0 : this.props.user.organization;
                this.props.getProfileProperties(organizationId).then((response) => {
                    if (response && response.data) {
                        this.setState({ datasetDetail: { doPropagate: response.data.domainProperties.domain_propagation } });
                    }
                });
            }
            if (!this.props.curationRules.length) {
                this.props.getCurationRules();
            }
            if (!this.props.references.length) {
                this.props.getReferences();
            }
            this.getDataSetDetail();
            this.getDomain();
            this.props.getPropertiesList(this.props.match.params.id);
            this.props.getDatasetRules(this.props.match.params.id);
            this.getSourceFavorites();
            this.getSourceCertified();
            this.getDatasetShare();
            if (this.state.datasourceDetail && this.state.datasourceDetail.id) {
                this.loadLogs();
            }
            if (this.props.location.state && this.props.location.state.datasourceId && !Object.keys(this.props.location.state.datasource).length) {
                this.getDatasourceById(this.props.location.state.datasourceId);
            } else {
                this.props.getActionItemCount(this.state.datasetDetail.source_id, this.props.match.params.id);
                this.props.getConversationCount(this.state.datasetDetail.source_id, this.props.match.params.id);
            }
            this.getAllFieldsByLevel();
            this.props.setAttributeName(this.state.selectedAttribute);
        });
    }

    navigateIndex = () => {
        if (this.props.location?.state?.connector === "tableau") {
            return 1;
        }
        return 0;
    }

    searchAttribute() {
        if (this.props.selected !== "") {
            const changeTab = this.getTabIndex("Properties");
            this.setState({ selectedAttribute: this.props.selected, tabIndex: changeTab });
            this.props.setAttributeName(this.props.selected);
            this.props.updateSelectedAttribute("");
        }
    }

    getUrlString() {
        const regex = /[?&]([^=#]+)=([^&#]*)/g;
        const url = window.location.href;
        const params = {};
        let match;
        // eslint-disable-next-line no-cond-assign
        while (match = regex.exec(url)) {
            params[match[1]] = match[2];
        }

        const attribute = params.attribute && params.attribute !== "None" ? params.attribute : "";
        // locationHistory: this.props.location.state ? this.props.location.state : {}
        this.setState({ locationHistory: { ...{ datasource: {}, datasourceId: params.source_id ? params.source_id : "", attribute: attribute, isdrift: Boolean(params.isdrift === "true"), ruleName: params.rule_name ? params.rule_name : "" } } });
        this.props.history.replace({ ...this.props.history.location, state: { datasource: {}, datasourceId: params.source_id ? params.source_id : "", attribute: attribute, isdrift: Boolean(params.isdrift === "true"), ruleName: params.rule_name ? params.rule_name : "" } });
    }

    loadLogs() {
        this.getAuditLogs();
        this.getDatasetInfo();
    }

    componentWillUnmount() {
        this.props.setPropertySliderIndex(0);
        this.props.setPropertyView('list');
        this.props.datasetPropertiesTab(0);
    }

    onSaveDuplicateCloumn(dataset_id, columns) {
        const requestParams = {
            'dataset_id': dataset_id,
            'columns': columns
        };
        this.props.saveDuplicate(requestParams);
    }

    getDriftAttribute() {
        this.props.getDriftAttribute(this.props.match.params.id).then((response) => {
            if (response && response.is_drift) {
                this.setState({ driftAttributes: [...response.data] });
            }
        }, []);
    }

    getDuplicate() {
        this.props.getDuplicateColumn(this.props.match.params.id).then((response) => {
            if (response) {
                this.setState({ duplicateColumnList: [...response] });
            }
        });
    }

    updateDuplicate(value) {
        this.setState({ duplicateColumnList: [...value] });
    }

    getDatasourceById(datasourceId) {
        this.props.getDataSource(datasourceId).then((response) => {
            if (response) {
                if (response.type === 'Tableau') {
                    this.setState({ isTableau: true }, this.setState({ tabs: [...appConstants.datasetTableauList] }));
                }
                const { breadcrumb } = this.state;
                breadcrumb[1] = { name: response.name, path: `/catalog/${response.id}`, type: 'datasource' };
                this.props.getPageTitle(`${breadcrumb[1].name} | ${breadcrumb[2].name}`);
                const dataSourceDetailCopy = JSON.parse(JSON.stringify(response));
                if (!dataSourceDetailCopy.dq_score_threshold || (dataSourceDetailCopy.dq_score_threshold && Object.keys(dataSourceDetailCopy.dq_score_threshold).length === 0)) {
                    if (this.props.user_config.dq_score_threshold && Object.keys(this.props.user_config.dq_score_threshold).length > 0) {
                        dataSourceDetailCopy.dq_score_threshold = this.props.user_config.dq_score_threshold;
                    } else {
                        dataSourceDetailCopy.dq_score_threshold = {
                            quadrant: appConstants.quadrantList[0].value,
                            scores: appConstants.quadrantScoreList[appConstants.quadrantList[0].value]
                        };
                    }
                }
                this.setState({ datasourceDetail: dataSourceDetailCopy, breadcrumb }, () =>
                    this.loadLogs());
                this.props.getActionItemCount(response.id, this.props.match.params.id);
                this.props.getConversationCount(response.id, this.props.match.params.id);
            }
        });
    }

    loadTab(tabs) {
        if (this.state.locationHistory && this.state.locationHistory.dqscore) {
            return tabs.indexOf("Profile");
        } else if (this.state.locationHistory && this.state.locationHistory.isdrift) {
            this.setState({ selectedAttribute: this.state.locationHistory.attribute ? this.state.locationHistory.attribute : "", selectedRule: this.state.locationHistory.ruleName ? this.state.locationHistory.ruleName : "" });
            this.props.setAttributeName(this.state.locationHistory.attribute);
            return tabs.indexOf("Drift");
        } else if (this.state.locationHistory && this.state.locationHistory.attribute && !this.state.locationHistory.isdrift) {
            this.setState({ subTabIndex: this.state.locationHistory.propertyTabIndex ? this.state.locationHistory.propertyTabIndex : 0 });
            return tabs.indexOf("Properties");
        } else if (this.state.locationHistory && this.state.locationHistory.executionLogs) {
            return tabs.indexOf("Execution Logs");
        } else if (this.state.locationHistory && this.state.locationHistory.curate) {
            return tabs.indexOf("Curate");
        } else if (this.state.locationHistory && this.state.locationHistory.monitoring) {
            return tabs.indexOf("Monitoring");
        } else if (this.state.locationHistory && this.state.locationHistory.exportmetrics) {
            return tabs.indexOf("Exportmetrics");
        } else if (this.state.locationHistory && this.state.locationHistory.attributeList) {
            return tabs.indexOf("Attributes");
        }
        return 0;
    }

    getTab(tabs) {
        const datasource = Object.keys(this.props.datasource).length > 0 ? this.props.datasource : this.state.datasourceDetail;
        if (tabs.indexOf("Profile") > -1 && this.props.config && !this.props.config.profile) {
            tabs.splice(tabs.indexOf("Profile"), 1);
        }
        if (tabs.indexOf("Curate") > -1 && this.props.config && !this.props.config.curate) {
            tabs.splice(tabs.indexOf("Curate"), 1);
        }
        if (datasource && !datasource.metadata) {
            tabs.splice(tabs.indexOf("Info"), 1);
        }
        if (tabs.indexOf("Comments") > -1 && this.props.config && !this.props.config.collaboration) {
            tabs.splice(tabs.indexOf("Comments"), 1);
        }
        if (tabs.indexOf("Monitoring") > -1 && this.props.config && !this.props.config.monitoring) {
            tabs.splice(tabs.indexOf("Monitoring"), 1);
        }
        if (tabs.indexOf("Exportmetrics") > -1 && this.props.config && !this.props.config.export_metrics) {
            tabs.splice(tabs.indexOf("Exportmetrics"), 1);
        }
        return tabs;
    }

    clearLocationHistory() {
        if (this.props.history.location.state) {
            const state = { ...this.props.history.location.state };
            delete state.dqscore;
            delete state.attribute;
            delete state.isdrift;
            delete state.ruleName;
            this.props.history.replace({ ...this.props.history.location, state });
        }
    }


    getUsers() {
        this.props.getOrganizationUser();
    }

    getDataSetDetail() {
        this.props.getDataSetDetail(this.props.match.params.id).then((response) => {
            this.setState({ isDataLoading: false });
            if (response) {
                const { breadcrumb } = this.state;
                this.props.getUserAttributes(response.source_id);
                this.props.setSelectedDatasourceId(response.source_id);
                this.props.setSelectedDataset(response);
                this.getDatasourceById(response.source_id);
                breadcrumb.push({ name: this.state.datasourceDetail.name, path: `/catalog/${this.state.datasourceDetail.id}`, type: 'datasource' }, { name: response.name, path: '', type: 'dataset' });
                const hasDrift = Boolean(response.is_drift_enabled);
                if (hasDrift) {
                    const tabs = this.getTab([...appConstants.datasetDriftTabList]);
                    this.setState({
                        isDrift: hasDrift,
                        tabs: tabs,
                        tabIndex: this.loadTab(tabs)
                    });
                }
                this.props.getPageTitle(`${breadcrumb[1].name} | ${breadcrumb[2].name}`);
                this.setState({
                    datasetDetail: JSON.parse(JSON.stringify(response)),
                    breadcrumb,
                    datasetDetailItem: JSON.parse(JSON.stringify(response))
                });
            } else {
                this.props.history.push("/catalog");
            }
        });
    }

    getDatasetInfo() {
        this.props.getDatasetInfo(this.state.datasourceDetail.id ? this.state.datasourceDetail.id : this.props.location.state.datasourceId, this.props.match.params.id);
    }

    getAuditLogs = () => {
        this.props.getSourceAuditLogs(this.state.datasourceDetail.id ? this.state.datasourceDetail.id : this.props.location.state.datasourceId, this.props.match.params.id).then((response) => {
            if (response) {
                this.setState({ auditLogs: response.logs, auditLogsLength: response.logsLength });
            }
        });
    }

    getSourceFavorites() {
        this.props.getFavorites(this.props.match.params.id, "dataset").then((response) => {
            this.setState({ isFavourite: response ? response : false });
        });
    }

    getSourceCertified() {
        this.props.getCertified(this.props.match.params.id, "dataset").then((response) => {
            this.setState({ isCertified: response ? response : false });
        });
    }

    getDatasetShare(type) {
        this.props.getDatasetShare(this.props.match.params.id).then((response) => {
            if (response) {
                const userId = this.props.user && this.props.user.id ? this.props.user.id : 0;
                const isStewardUser = response.some((user) => user.is_steward_user && user.user_id === userId);
                this.setState({ governance: response ? response : [], isPermissionLoading: false, isStewardUser });
                if (type === "update") {
                    this.setState({ isGovernanceUpdate: true });
                }
            }
        });
    }

    updateGovernanceUpdate = () => {
        this.setState({ isGovernanceUpdate: false });
    }

    updateGovernance = () => {
        this.getDatasetShare("update");
    }

    onChangeDatasetDetail = (name, value) => {
        const { datasetDetail, breadcrumb } = this.state;
        if (name === "updateTags") { datasetDetail.tags = value; }
        else { datasetDetail[name] = value; }
        this.setState({ datasetDetail });
        if (name === "platform_use" || name === "updateTags" || name === "status") {
            this.updateDataseteDetail(name);
        } else if (name === "name") {
            datasetDetail[name] = value;
            breadcrumb[breadcrumb.length - 1].name = value;
            this.setState({ breadcrumb });
        } else if (name === 'removeDomain') {
            const domainToRemove = datasetDetail.domain_ids[value];
            datasetDetail.removeDomain = domainToRemove;
            datasetDetail.doPropagate = this.props.domainProperties.domain_propagation;
            this.props.updateDataset(this.props.match.params.id, datasetDetail).then((response) => {
                const { datasetDetail, domain } = this.state;
                if (response) {
                    if (datasetDetail.removeDomain && response.is_domain_deleted) {
                        const temp_domain = domain.filter((obj) => obj.id !== datasetDetail.removeDomain);
                        this.setState({ domain: [...temp_domain] });
                    }
                    datasetDetail.domains.splice(value, 1);
                    datasetDetail.domain_ids.splice(value, 1);
                    datasetDetail.doPropagate = this.props.domainProperties.domain_propagation;
                    this.setState({ datasetDetail: { ...datasetDetail } });
                }
            });
            this.updateAuditLogs(name);
        }
    }

    exportDataset = (type) => {
        this.setState({ isExportLoading: true });
        const requestParams = {
            "organization": 0,
            "datasource": 0,
            "dataset": this.props.match.params.id,
            "include_metadata": true,
            "include_profile": true,
            "include_curate": true

        };
        this.props.getExportReport(requestParams, type).then((response) => {
            if (response) {
                this.updateAuditLogs("export");
            }
            this.setState({ isExportLoading: false });
        });
    }

    exportProperties = (type) => {
        this.setState({ isExportLoading: true });
        const dataset_id = this.props.match.params.id ? parseInt(this.props.match.params.id, 10) : null;
        this.props.getAttributePropertiesList(dataset_id).then((attributeDetails) => {
            const requestParams = {
                datasources: [this.state.datasourceDetail.id],
                datasets: [dataset_id],
                attributes: attributeDetails ? attributeDetails : []
            };
            this.props.getExportMetadata(requestParams).then((response) => {
                if (response) {
                    this.updateAuditLogs("export");
                }
                this.setState({ isExportLoading: false });
            });
        });
    }

    exportRulesProperties = () => {
        const dataset_id = this.props.match.params.id ? parseInt(this.props.match.params.id, 10) : null;
        const requestParams = {
            dataset_id: dataset_id
        };
        this.props.getExportRulesValidity(requestParams).then((rulesDetails) => {
        });
    }

    updateDataseteDetail = (property, value) => {
        property = (property === "updateTags") ? "tags" : property;
        const condition = property === "tags" || property === "updateTags" || property === "platform_use" ? JSON.parse(JSON.stringify(this.state.datasetDetail[property])) !== JSON.parse(JSON.stringify(this.state.datasetDetailItem[property])) : this.state.datasetDetail[property] !== this.state.datasetDetailItem[property];
        const model = this.state.datasetDetail;
        if (condition || property === "createDomain") {
            if (property === 'domain') {
                if (model.domain === "") {
                    model["domain_id"] = "";
                } else if ((model.domain).charAt(0) === " ") {
                    this.props.createDomain("Error in Name", this.props.domainProperties.create_new_domain);
                    return false;
                } else {
                    this.props.createDomain({ name: model.domain, create_new_domain: this.props.domainProperties.create_new_domain, is_user_created: true }).then((response) => {
                        if (response) {
                            this.setState({ domain: [...this.state.domain, { name: response.name, id: response.id }] });
                            model["domain_id"] = response.id;
                            model.doPropagate = this.props.domainProperties.domain_propagation;
                            this.props.updateDataset(this.props.match.params.id, model);
                        }
                    });
                }
            }
            if (property === 'description' && (this.state.datasetDetail.description).charAt(0) === " ") {
                setTimeout(() => { this.props.createDomain("Error in Name", this.props.domainProperties.create_new_domain); }, 1000);
                return false;
            }
            if (property === "createDomain" && value !== "") {
                this.props.createDomain({ name: value, create_new_domain: this.props.domainProperties.create_new_domain, is_user_created: true }).then((response) => {
                    if (response) {
                        model.domain_id = response.id;
                        model.domain = response.name;
                        model.domains.push(value);
                        model.domain_ids.push(response.id);
                        model.doPropagate = this.props.domainProperties.domain_propagation;
                        this.setState({ datasetDetail: { ...model } });
                        this.setState({ domain: [...this.state.domain, { name: response.name, id: response.id }] });
                        this.props.updateDataset(this.props.match.params.id, model);
                    }
                });
            }
            if (property === "description" || property === "tags" || property === "name" || property === "terms_description" || property === "status") {
                this.props.updateDataset(this.props.match.params.id, this.state.datasetDetail);
            }
            this.updateAuditLogs(property);
            const { datasetDetailItem } = this.state;
            datasetDetailItem[property] = this.state.datasetDetail[property];
            this.setState({ datasetDetailItem: JSON.parse(JSON.stringify(datasetDetailItem)) });
        }
        if (property === 'updateDomain') {
            model.doPropagate = this.props.domainProperties.domain_propagation;
            this.props.updateDataset(this.props.match.params.id, model);
        }
    };

    updateAuditLogs = (property) => {
        const data = getAuditLogText(property, this.state.datasetDetail.name, "dataset");
        const requestParams = {
            "source_id": this.state.datasourceDetail.id,
            "audit_logtext": data.text,
            "type": data.type,
            "dataset_id": this.props.match.params.id
        };
        this.props.createAuditLog(requestParams).then((response) => {
            if (response) {
                const { auditLogs } = this.state;
                auditLogs.unshift(response);
                this.setState({ auditLogs });
            }
        });
    }

    createSourceShare = (requestParams) => {
        this.props.createDatasetShare(requestParams).then((response) => {
            if (response) {
                this.getAuditLogs();
            }
        });
    };

    deleteDataSet = () => {
        this.setState({ isDeleteDataset: true });
        this.getAuditLogs();
    }

    deleteDatasetInfo = () => {
        const model = {
            datasets: [this.props.match.params.id]
        };
        this.props.deleteDataSet(model).then((response) => {
            if (response) {
                this.props.history.push(`/catalog/${this.state.datasourceDetail.id}`);
                this.getAuditLogs();
            }
        });
    }

    changeFavorite = () => {
        const requestParams = {
            "source_id": this.props.match.params.id,
            "favorite": this.state.isFavourite,
            "type": "dataset"
        };
        this.setState({ isFavourite: !this.state.isFavourite });
        this.props.updateFavorites(requestParams).then(() => {
            this.getAuditLogs();
        });
    }

    changeCertified = () => {
        const requestParams = {
            "source_id": this.props.match.params.id,
            "certified": this.state.isCertified,
            "type": "dataset"
        };
        this.setState({ isCertified: !this.state.isCertified });
        this.props.updateCertified(requestParams).then(() => {
            this.getAuditLogs();
        });
    }

    setSelectedAttribute = (attribute) => {
        this.setState({ selectedAttribute: attribute, tabAttribute: attribute });
        this.props.setAttributeName(attribute);
    }

    getAttributeTabHeader = (name) => {
        let tabTitle = name;
        let totalAttributesCount = 0;
        let selectedAttributesCount = 0;
        if (this.props.propertiesList) {
            selectedAttributesCount = this.props.propertiesList.length;
        }
        switch (name) {
            case 'Attributes':
                if (this.props.datasetInfo && this.props.datasetInfo.total_attributes) {
                    totalAttributesCount = this.props.datasetInfo.total_attributes;
                    selectedAttributesCount = totalAttributesCount;
                }
                if (selectedAttributesCount >= totalAttributesCount) {
                    totalAttributesCount = selectedAttributesCount;
                }
                tabTitle = `${name} (${totalAttributesCount})`;
                break;
            case 'Properties':
                tabTitle = `${name} (${selectedAttributesCount})`;
                break;
            default:
                tabTitle = `${name}`;
                break;
        }
        return tabTitle;
    }

    getDomain = () => {
        this.props.getDomain().then((response) => {
            if (response) {
                this.setState({ domain: response });
            }
        });
    }

    onSelectDomain = (domain) => {
        const { datasetDetail } = this.state;
        datasetDetail.domain = domain.name;
        datasetDetail["domain_id"] = domain.id;
        datasetDetail.domains = [...datasetDetail.domains, domain.name];
        datasetDetail.domain_ids = [...datasetDetail.domain_ids, domain.id];
        this.setState({ datasetDetail }, () => this.updateDataseteDetail("updateDomain"));
    }

    /**
     * Get Fields List
     */
    getAllFieldsByLevel = () => {
        if (this.state.datasourceDetail.type === "Tableau") {
            this.setState({ tabs: [...appConstants.datasetTableauList], isTableau: true });
        }

        this.setState({ customFieldLoading: true });
        Promise.all([this.props.getCustomFields(appConstants.fieldKeys.dataset), this.props.getCustomTabs(0, "catalog")]).then((response) => {
            if (response && response.length > 0) {
                let tabFields = [];
                if (response[0]) {
                    const fieldList = response[0].filter((data) => data.tab_name === "");
                    tabFields = response[0].filter((data) => data.tab_name !== "");
                    tabFields = tabFields.sort((a, b) => b.display_order > a.display_order);
                    if (fieldList.length === 0) {
                        let datasetTabs = [...this.state.tabs];
                        datasetTabs = this.discardDetailTab(datasetTabs);
                        this.setState({ tabs: datasetTabs });
                    } else {
                        this.setState({ tabs: [...this.state.tabs, appConstants.fieldKeys.tabName] });
                    }
                    this.setState({ fields: [...fieldList], tabFields: [...tabFields] }, () => {
                        if (this.state.locationHistory && this.state.locationHistory.dqscore) {
                            this.setState({ tabIndex: this.state.tabs.indexOf("Profile") });
                        } else if (this.state.locationHistory && this.state.locationHistory.isdrift) {
                            this.setState({ selectedAttribute: this.state.locationHistory.attribute ? this.state.locationHistory.attribute : "", selectedRule: this.state.locationHistory.ruleName ? this.state.locationHistory.ruleName : "" });
                            this.props.setAttributeName(this.state.locationHistory.attribute);
                            this.setState({ tabIndex: this.state.tabs.indexOf("Drift") });
                        } else if (this.state.locationHistory && this.state.locationHistory.attribute) {
                            this.setState({
                                tabIndex: this.state.tabs.indexOf("Properties"),
                                subTabIndex: this.state.locationHistory.propertyTabIndex ? this.state.locationHistory.propertyTabIndex : 0
                            });
                        } else if (this.state.locationHistory && this.state.locationHistory.curate) {
                            this.setState({ tabIndex: this.state.tabs.indexOf("Curate") });
                        } else if (this.state.locationHistory && this.state.locationHistory.executionLogs) {
                            this.setState({ tabIndex: this.state.tabs.indexOf("Execution Logs") });
                        } else if (this.state.locationHistory && this.state.locationHistory.attributeList) {
                            this.setState({ tabIndex: this.state.tabs.indexOf("Attributes") });
                        } else if (this.state.locationHistory && this.state.locationHistory.monitoring) {
                            this.setState({ tabIndex: this.state.tabs.indexOf("Monitoring") });
                        } else if (this.state.locationHistory && this.state.locationHistory.exportmetrics) {
                            this.setState({ tabIndex: this.state.tabs.indexOf("Exportmetrics") });
                        } else if (this.state.locationHistory && this.state.locationHistory.info) {
                            this.setState({ tabIndex: this.state.tabs.indexOf("Info") });
                        }
                    });

                    this.getFieldPropertyByLevel();
                    this.clearLocationHistory();
                } else {
                    let datasetTabs = [...this.state.tabs];
                    datasetTabs = this.discardDetailTab(datasetTabs);
                    this.setState({ fields: [], tabs: datasetTabs }, () => {
                        if (this.state.locationHistory && this.state.locationHistory.dqscore) {
                            this.setState({ tabIndex: this.state.tabs.indexOf("Profile") });
                        } else if (this.state.locationHistory && this.state.locationHistory.isdrift) {
                            this.setState({ selectedAttribute: this.state.locationHistory.attribute ? this.state.locationHistory.attribute : "", selectedRule: this.state.locationHistory.ruleName ? this.state.locationHistory.ruleName : "" });
                            this.props.setAttributeName(this.state.locationHistory.attribute);
                            this.setState({ tabIndex: this.state.tabs.indexOf("Drift") });
                        } else if (this.state.locationHistory && this.state.locationHistory.attribute) {
                            this.setState({
                                tabIndex: this.state.tabs.indexOf("Properties"),
                                subTabIndex: this.state.locationHistory.propertyTabIndex ? this.state.locationHistory.propertyTabIndex : 0
                            });
                        } else if (this.state.locationHistory && this.state.locationHistory.curate) {
                            this.setState({ tabIndex: this.state.tabs.indexOf("Curate") });
                        } else if (this.state.locationHistory && this.state.locationHistory.executionLogs) {
                            this.setState({ tabIndex: this.state.tabs.indexOf("Execution Logs") });
                        } else if (this.state.locationHistory && this.state.locationHistory.monitoring) {
                            this.setState({ tabIndex: this.state.tabs.indexOf("Attributes") });
                        } else if (this.state.locationHistory && this.state.locationHistory.attributeList) {
                            this.setState({ tabIndex: this.state.tabs.indexOf("Monitoring") });
                        } else if (this.state.locationHistory && this.state.locationHistory.info) {
                            this.setState({ tabIndex: this.state.tabs.indexOf("Info") });
                        }
                    });
                    this.clearLocationHistory();
                }
                if (response[1] && response[1].length !== 0) {
                    const tabs = response[1].filter((data) => data.name !== 'Overview' && tabFields.some((a) => a.tab_name.toLowerCase() === data.name.toLowerCase())).map((data) => data.name);
                    this.loadCustomTabs([...tabs]);
                }
            }
            this.setState({ customFieldLoading: false });
        }).then(() => this.searchAttribute());
    }

    discardDetailTab = (datasetTabs) => {
        const index = datasetTabs.indexOf(appConstants.fieldKeys.tabName);
        if (index > -1) {
            datasetTabs.splice(index, 1);
        }
        return datasetTabs;
    }

    loadCustomTabs = (tabFields) => {
        const tabs = [...this.state.tabs];
        this.setState({ tabs: [...tabs, ...tabFields], customTabls: tabFields });
    }

    /**
     * Get Field Property List
     */
    getFieldPropertyByLevel = () => {
        this.props.getFieldPropertyByLevel(this.props.match.params.id, appConstants.fieldKeys.dataset, this.state.datasourceDetail.id).then((response) => {
            if (response && response.length > 0) {
                this.setState({ fieldProperties: response });
            }
        });
    }

    /**
     * get Tab Index
     * @param {*} tabName
     * @returns
     */
    getTabIndex = (tabName) => {
        const index = this.state.tabs.indexOf(tabName);
        return index;
    }

    /**
     * Get Dynamic Element On Change Event
     * @param {*} name
     * @param {*} value
     * @param {*} data_type
     */
    dyanamicEleOnChange = (value, field, saveDB, isBoolean) => {
        let properties = [...this.state.fieldProperties];
        const fieldIndex = properties.findIndex((i) => parseInt(i.custom_field) === parseInt(field.id) && parseInt(i.datasource) === parseInt(this.state.datasourceDetail.id) && parseInt(i.dataset) === parseInt(this.props.match.params.id));

        if (fieldIndex > -1) {
            // Update field in state
            properties[fieldIndex].value = value;
            this.setState({ fieldProperties: properties });

            if (saveDB) {
                if (properties[fieldIndex].id && properties[fieldIndex].id !== '') {
                    // Update field in db
                    this.props.createFieldProperty(properties[fieldIndex]);
                } else {
                    // Create field in db
                    this.props.createFieldProperty(properties[fieldIndex]).then((response) => {
                        if (!response) {
                            return;
                        }
                        properties[fieldIndex].id = response.id;
                        this.setState({ fieldProperties: properties });
                    });
                }
            }
        } else {
            // Create new field in state
            const obj = {
                id: '',
                organization: this.props.user.organization,
                custom_field: field.id,
                datasource: this.state.datasourceDetail.id,
                dataset: parseInt(this.props.match.params.id),
                value: value
            };

            properties = [...properties, obj];
            this.setState({ fieldProperties: properties });

            if (isBoolean) {
                // Create field in db
                this.props.createFieldProperty(obj).then((response) => {
                    if (!response) {
                        return;
                    }
                    obj.id = response.id;
                    properties = [...properties, obj];
                    this.setState({ fieldProperties: properties });
                });
            }
        }
    }

    getActiveConnection = () => {
        const inputConnections = this.props.config && this.props.config.input_connectors ? this.props.config.input_connectors : [];
        const connections = this.props.connectionTypes.filter((connection) => inputConnections.includes(connection.id));
        const datasourceType = this.state.datasourceDetail && this.state.datasourceDetail.type ? this.state.datasourceDetail.type : "";
        return connections.some((data) => data?.type?.replace(' ', '').toLowerCase() === datasourceType?.replace(' ', '').toLowerCase());
    }

    updateTab = (index) => {
        if (this.state.tabs[index] === 'Properties' || this.state.tabs[index] === 'Profile' || this.state.tabs[index] === 'Drift' || this.state.tabs[index] === 'Curate') {
            this.setState({ tabAttribute: this.state.selectedAttribute });
        } else {
            this.setState({ tabAttribute: null });
        }
        this.setState({ tabIndex: index });

        if (this.state.tabs[index] === 'Overview' && this.props.match.params.id) {
            this.getDuplicate(this.props.match.params.id);
        }
        this.props.updateTabIndex(this.state.tabs[index]);
    }

    navigateOverview = () => {
        this.setState({ tabIndex: 0, tabAttribute: null });
    }

    onResetDriftRule = () => {
        const locationHistory = { ...this.state.locationHistory };
        delete locationHistory.filterRule;
        this.setState({ selectedRule: "", locationHistory: locationHistory });
    }

    onSetDriftRule = (ruleName) => {
        const locationHistory = { ...this.state.locationHistory };
        delete locationHistory.filterRule;
        this.setState({ selectedRule: ruleName, locationHistory: locationHistory });
    }

    getIcon = (sourceType) => {
        let icon;
        if (sourceType) {
            icon = datasourceTypes.find((p) => p.type.toLowerCase() === sourceType.toLowerCase());
        }
        if (icon && icon.logo && typeof (icon.logo) === 'object') {
            return icon.logo.default;
        }
        return icon ? icon.logo : null;
    }

    getPath = () => {
        const datasourcePath = this.state.datasourceDetail.configuration && typeof this.state.datasourceDetail.configuration === "string" ? JSON.parse(this.state.datasourceDetail.configuration) : {};
        const path = this.state.datasetDetail.configuration && typeof this.state.datasetDetail.configuration === "string" ? JSON.parse(this.state.datasetDetail.configuration) : {};
        const datasetPath = `${datasourcePath?.connection_type ?? 'File'} / ~ / ${datasourcePath?.database ?? datasourcePath?.bucketname ?? ''} / ~ / ${path?.schema ?? path?.input_config?.table ?? ''}`;
        return datasetPath;
    }

    handleCollapse = () => {
        const { collapseFlag } = this.state;
        this.setState({ collapseFlag: !collapseFlag });
    }

    render() {
        const { classes } = this.props;
        let stewardUser = this.state.governance.find((user) => user.is_steward_user);
        if (stewardUser) {
            const user = this.props.users.find((user) => user.id === stewardUser.user_id);
            stewardUser = { ...user, ...stewardUser };
        } else {
            stewardUser = {};
        }
        let sensitivityLevel = this.state.governance.find((data) => data.user_id === this.props.user.id);
        sensitivityLevel = sensitivityLevel ? sensitivityLevel.access_level : 1;
        const connectionStatus = this.getActiveConnection();
        let attributeCrumb = this.state.breadcrumb;
        if (this.state.tabAttribute) {
            const { breadcrumb } = this.state;
            const arrayTemp = breadcrumb.filter((crumb) => crumb.type !== 'attribute');
            arrayTemp.push({ name: this.state.tabAttribute, path: ``, type: 'attribute' });
            attributeCrumb = [...arrayTemp];
        }
        let workbook_id = '';
        const workbook_name = this.state.datasetDetail.name ? this.state.datasetDetail.name : '';
        if (this.state.datasetDetail && this.state.datasetDetail.configuration) {
            const parsed_config = JSON.parse(this.state.datasetDetail.configuration);
            workbook_id = parsed_config.workbook_id ? parsed_config.workbook_id : '';
        }
        return (
            <ValidatorForm form="datasource" onSubmit={() => { }}>
                <Grid className={classes.datasourceSection}>
                    <DatasourceInfo
                        source={this.state.datasetDetail}
                        dq_score_threshold={this.state.datasourceDetail.dq_score_threshold}
                        breadcrumb={attributeCrumb}
                        onChange={(name, value) => this.onChangeDatasetDetail(name, value)}
                        onSave={(property) => this.updateDataseteDetail(property)}
                        history={this.props.history}
                        type="dataset"
                        deleteData={() => this.deleteDataSet()}
                        datasourceId={this.state.datasourceDetail.id}
                        datasetId={this.props.match.params.id}
                        favorite={this.state.isFavourite}
                        isCertified={this.state.isCertified}
                        changeFavorite={() => this.changeFavorite()}
                        isEditable={this.state.isStewardUser}
                        users={this.props.users}
                        updateAuditLog={this.updateAuditLog}
                        createSourceShare={this.props.createDatasetShare}
                        governance={this.state.governance}
                        sourceId={this.state.datasourceDetail.id}
                        inviteSourceUser={this.props.inviteSourceUser}
                        getAuditLogs={this.getAuditLogs}
                        user={this.props.user}
                        getDatasetShare={this.props.getDatasetShare}
                        loading={this.state.isPermissionLoading}
                        deleteShare={this.props.deleteDatasetShare}
                        profileTab={this.getTabIndex("Profile")}
                        redirectProfile={(index) => this.setState({ tabIndex: index })}
                        updateUser={this.props.updateUser}
                        exportReport={(type) => this.exportDataset(type)}
                        isExportLoading={this.state.isExportLoading}
                        exportProperties={(type) => this.exportProperties(type)}
                        exportRulesProperties={() => this.exportRulesProperties()}
                        updateGovernance={this.updateGovernance}
                        isGovernanceUpdate={this.state.isGovernanceUpdate}
                        updateGovernanceUpdate={this.updateGovernanceUpdate}
                        sourceName={""}
                        connectionStatus={connectionStatus}
                        dataSetLink={Boolean(this.state.tabAttribute)}
                        navigateOverview={this.navigateOverview}
                        connector_type={this.state.datasourceDetail.type}
                        workbookId={this.state.isTableau ? this.props.match.params.id : 0}
                        getIcon={this.getIcon}
                        getPath={this.getPath}
                        changeCertified={() => this.changeCertified()}
                    />
                </Grid>
                <Grid className={classes.datasourceTabContainer} id="tab-container">
                    <Tabs
                        value={this.state.tabIndex}
                        indicatorColor="secondary"
                        textColor="secondary"
                        onChange={(event, index) => this.updateTab(index)}
                        aria-label="dataset tabs"
                        variant="scrollable"
                        scrollButtons="auto">
                        {
                            this.state.tabs.map((name, index) =>
                                <Tab key={index} label={this.getAttributeTabHeader(name)} />
                            )
                        }
                    </Tabs>
                </Grid>
                <Grid className={classNames(classes.marginTop15, classes.datasetOverviewTable, classes.relative)}>
                    <TabPanel value={this.state.tabIndex} index={this.getTabIndex("Overview")} className={classNames(classes.scrollFix)}>
                        {
                            (this.state.tabIndex === this.getTabIndex("Overview")) &&
                            <Grid container spacing={2} className={classNames(classes.marginTopm8)}>
                                <Grid item xs={this.state.collapseFlag ? 8 : 12} style={{ display: "flex", flexDirection: "column", position: "relative" }}>
                                    <DatasourceDetail
                                        dataset={this.state.datasetDetail}
                                        onChange={(name, value) => this.onChangeDatasetDetail(name, value)}
                                        onSave={(property, value) => this.updateDataseteDetail(property, value)}
                                        onSaveDuplicateCloumn={(columns) => this.onSaveDuplicateCloumn(this.props.match.params.id, columns)}
                                        isEditable={(this.state.isStewardUser || (this.props.user.role && this.props.user.role.properties_permission && this.props.user.role.properties_permission.is_edit)) && connectionStatus}
                                        onSelectDomain={(domain) => this.onSelectDomain(domain)}
                                        domain={this.state.domain ? this.state.domain : []}
                                        type="dataset"
                                        updateDuplicate={(value) => this.updateDuplicate(value)}
                                        properties={this.props.propertiesList}
                                        duplicateValues={this.state.duplicateColumnList}
                                        createNewDomain={this.props.domainProperties.create_new_domain}
                                        isDomainEnabled={this.props.config.domain}
                                        isSkipDuplicate={this.props.isSkipDuplicate}
                                    />
                                    <DatasetDetail type="dataset" datasetInfo={this.props.datasetInfo} isSkipDuplicate={this.props.isSkipDuplicate} />
                                    <IconButton className={classes.collapseIcon} onClick={() => this.handleCollapse()}>
                                        {
                                            !this.state.collapseFlag &&
                                            <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                                            </svg>
                                        }
                                        {
                                            this.state.collapseFlag &&
                                            <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                                            </svg>
                                        }
                                    </IconButton>
                                </Grid>
                                {
                                    this.state.collapseFlag &&
                                    <Grid item xs={4} className={classes.auditLogAlertContainer}>
                                        <AuditLogComponent auditLogs={this.state.auditLogs} auditLogsLength={this.state.auditLogsLength} sourceId={this.props.match.params.id} users={this.props.users} history={this.props.history} logtype={'Dataset'} fileType={this.state.datasourceDetail.type} connector_type={this.state.datasourceDetail.type} />
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Permission
                                        source={JSON.parse(JSON.stringify(this.state.datasetDetail))}
                                        onChange={(name, value) => this.onChangeDatasetDetail(name, value)}
                                        onSave={(property) => this.updateDataseteDetail(property)}
                                        users={this.props.users}
                                        updateAuditLog={this.updateAuditLog}
                                        createSourceShare={this.props.createDatasetShare}
                                        governance={this.state.governance}
                                        sourceId={this.state.datasourceDetail.id}
                                        inviteSourceUser={this.props.inviteSourceUser}
                                        getAuditLogs={this.getAuditLogs}
                                        user={this.props.user}
                                        type="dataset"
                                        datasetId={this.props.match.params.id}
                                        getDatasetShare={this.props.getDatasetShare}
                                        loading={this.state.isPermissionLoading}
                                        isEditable={((this.state.isStewardUser) || (this.props.user.role && this.props.user.role.properties_permission && this.props.user.role.properties_permission.is_edit)) && connectionStatus}
                                        deleteShare={this.props.deleteDatasetShare}
                                        updateUser={this.props.updateUser}
                                        hideplatform
                                        showComment={Boolean(this.props.datasetCatalogInfo && this.props.datasetCatalogInfo.comment && this.props.datasetCatalogInfo.comment.length !== 0)}
                                        comment={(this.props.datasetCatalogInfo && this.props.datasetCatalogInfo.comment) ? this.props.datasetCatalogInfo.comment : ""}
                                        updateGovernance={this.updateGovernance}
                                        isGovernanceUpdate={this.state.isGovernanceUpdate}
                                        updateGovernanceUpdate={this.updateGovernanceUpdate}
                                        dispatch={this.props.dispatch}
                                    />
                                </Grid>
                                {
                                    (this.props.config && this.props.config.collaboration) &&
                                    <Grid item xs={12}>
                                        <Grid className={classes.card}>
                                            <Comments type="dataset" updateAuditLog={this.updateAuditLogs} datasourceId={this.state.datasourceDetail.id} datasetId={this.props.match.params.id} />
                                        </Grid>
                                    </Grid>
                                }
                                {
                                    this.state.tabFields && this.state.tabFields.length > 0 &&
                                    <Grid item xs={12} style={{ marginBottom: 50 }}>
                                        <DynamicElementByDataType
                                            fields={[...this.state.tabFields.filter((tab) => tab.tab_name === 'Overview')]}
                                            key={this.state.datasourceDetail.id}
                                            properties={this.state.fieldProperties}
                                            dyanamicEleOnChange={this.dyanamicEleOnChange}
                                            level={appConstants.fieldKeys.dataset}
                                            datasource={this.state.datasourceDetail.id}
                                            dataset={parseInt(this.props.match.params.id)}
                                        />
                                    </Grid>
                                }
                                {
                                    this.state.customFieldLoading &&
                                    <Loader />
                                }
                            </Grid>
                        }
                    </TabPanel>
                    <TabPanel value={this.state.tabIndex} index={this.getTabIndex("Details")}>
                        {
                            (this.state.tabIndex === this.getTabIndex("Details")) &&
                            <DynamicElementByDataType
                                key={this.state.datasourceDetail.id}
                                fields={this.state.fields}
                                properties={this.state.fieldProperties}
                                dyanamicEleOnChange={this.dyanamicEleOnChange}
                                level={appConstants.fieldKeys.dataset}
                                datasource={this.state.datasourceDetail.id}
                                dataset={parseInt(this.props.match.params.id)} />
                        }
                    </TabPanel>
                    <TabPanel value={this.state.tabIndex} index={this.getTabIndex("Attributes")}>
                        {
                            (this.state.tabIndex === this.getTabIndex("Attributes")) &&
                            <DataSetAttributes dataset={this.state.datasetDetail}
                                isEditable={(this.state.isStewardUser && this.props.isLicensedValidated && connectionStatus)}
                                sensitivityLevel={sensitivityLevel} />
                        }
                    </TabPanel>
                    {
                        this.state.isTableau &&
                        <TabPanel value={this.state.tabIndex} index={this.getTabIndex("Items")}>
                            {
                                (this.state.tabIndex === this.getTabIndex("Items")) &&
                                <WorkbookItems
                                    datasourceId={this.state.datasourceDetail.id}
                                    workbookId={workbook_id}
                                    history={this.props.history}
                                    datasource={this.state.datasourceDetail}
                                    workbook={this.state.datasetDetail}
                                />
                            }
                        </TabPanel>
                    }
                    {
                        this.state.isTableau &&
                        <TabPanel value={this.state.tabIndex} index={this.getTabIndex("Datasources")}>
                            {
                                (this.state.tabIndex === this.getTabIndex("Datasources")) &&
                                <WorkbookDatasource
                                    datasourceId={this.state.datasourceDetail.id}
                                    workbookId={workbook_id}
                                    name={workbook_name}
                                />
                            }
                        </TabPanel>
                    }
                    <TabPanel value={this.state.tabIndex} index={this.getTabIndex("Properties")}>
                        {
                            (this.state.tabIndex === this.getTabIndex("Properties")) &&
                            <DatasetProperties setSelectedAttribute={this.setSelectedAttribute}
                                datasetId={this.props.match.params.id}
                                isEditable={this.state.isStewardUser && connectionStatus}
                                isEditPermission={(this.props.user.role && this.props.user.role.properties_permission && this.props.user.role.properties_permission.is_edit) && connectionStatus}
                                isDeletePermission={this.props.user.role && this.props.user.role.properties_permission && this.props.user.role.properties_permission.is_delete && connectionStatus}
                                tabIndex={this.state.tabIndex}
                                currentSlide={this.state.slideIndex}
                                profileTab={this.getTabIndex("Profile")}
                                changeTab={(index) => this.setState({ tabIndex: index })}
                                selectedAttribute={this.state.selectedAttribute}
                                subTabIndex={this.state.subTabIndex} />
                        }
                    </TabPanel>
                    <TabPanel value={this.state.tabIndex} index={this.getTabIndex("Profile")}>
                        {
                            (this.state.tabIndex === this.getTabIndex("Profile")) && this.props.propertiesList && this.props.propertiesList.length > 0 ?
                                <DatasetProfile
                                    getProfileAnalysis={this.props.getProfileAnalysis}
                                    dq_score_threshold={this.state.datasourceDetail.dq_score_threshold}
                                    getCurationAnalysis={this.props.getCurationAnalysis}
                                    datasetName={this.state.datasetDetail.name}
                                    datasetId={this.props.match.params.id}
                                    attributeList={this.props.propertiesList}
                                    getTrending={this.props.getTrending}
                                    tabIndex={this.state.tabIndex}
                                    properties={this.props.properties}
                                    filterInputData={this.props.filterInputData}
                                    config={this.props.config}
                                    profileRuleEnable={this.props.profileRuleEnable}
                                    getProfileRules={this.props.getProfileRules}
                                    createdDate={this.state.datasetDetail.created_date}
                                    selectedAttribute={this.state.selectedAttribute}
                                    getAttributePropertyRules={this.props.getAttributePropertyRules}
                                    enableCountStatisticsRule={this.props.updateAttributePropertyRules}
                                    sensitivityLevel={sensitivityLevel}
                                    setSelectedAttribute={this.setSelectedAttribute}
                                    getAttributeStatistics={this.props.getAttributeStatistics}
                                    updateDatasetProperties={this.props.updateDatasetProperties}
                                    getInvalidQueries={this.props.getInvalidQueries} />
                                : <Loader />
                        }
                    </TabPanel>
                    <TabPanel value={this.state.tabIndex} index={this.getTabIndex("Monitoring")}>
                        {
                            (this.state.tabIndex === this.getTabIndex("Monitoring")) ?
                                <DatasetMonitoring
                                    isSelected={this.state.tabIndex === this.getTabIndex("Monitoring")}
                                    datasetId={this.props.match.params.id}
                                    datasourceId={this.state.datasourceDetail.id}
                                    properties={this.props.properties} />
                                : <NoResultFound />
                        }
                    </TabPanel>
                    <TabPanel value={this.state.tabIndex} index={this.getTabIndex("Exportmetrics")}>
                        {
                            (this.state.tabIndex === this.getTabIndex("Exportmetrics")) ?
                                <DatasetExport
                                    isSelected={this.state.tabIndex === this.getTabIndex("Exportmetrics")}
                                    datasetId={this.props.match.params.id}
                                    datasourceId={this.state.datasourceDetail.id}
                                    properties={this.props.properties} />
                                : <NoResultFound />
                        }
                    </TabPanel>
                    {
                        this.state.isDrift &&
                        <TabPanel value={this.state.tabIndex} index={this.getTabIndex("Drift")}>
                            {
                                this.state.tabIndex === this.getTabIndex("Drift")
                                    ? <DatasetLearning getProfileAnalysis={this.props.getProfileAnalysis}
                                        getCurationAnalysis={this.props.getCurationAnalysis}
                                        datasetName={this.state.datasetDetail.name}
                                        datasetId={this.props.match.params.id}
                                        tabIndex={this.state.tabIndex}
                                        selectedAttribute={this.state.selectedAttribute}
                                        setSelectedAttribute={this.setSelectedAttribute}
                                        getDriftRules={this.props.getDriftRules}
                                        driftAcceptRules={this.props.driftAcceptRules}
                                        getDriftTrending={this.props.getDriftTrending}
                                        driftAttributes={this.state.driftAttributes}
                                        getAttributeStatistics={this.props.getAttributeStatistics}
                                        updateDatasetProperties={this.props.updateDatasetProperties}
                                        properties={this.props.properties}
                                        selectedRule={this.state.selectedRule}
                                        filterRule={this.state.locationHistory.filterRule ? this.state.locationHistory.filterRule : ""}
                                        onResetDriftRule={() => this.onResetDriftRule()}
                                        onSetDriftRule={(ruleName) => this.onSetDriftRule(ruleName)} />
                                    : <NoResultFound />
                            }
                        </TabPanel>
                    }
                    <TabPanel value={this.state.tabIndex} index={this.getTabIndex("Curate")}>
                        {
                            (this.props.propertiesList && this.props.propertiesList.length > 0 && this.state.tabIndex === this.getTabIndex("Curate"))
                                ? <DatasetCuration
                                    getProfileAnalysis={this.props.getProfileAnalysis}
                                    dq_score_threshold={this.state.datasourceDetail.dq_score_threshold}
                                    getCurationAnalysis={this.props.getCurationAnalysis}
                                    datasetName={this.state.datasetDetail.name}
                                    datasetId={this.props.match.params.id}
                                    attributeList={this.props.propertiesList}
                                    getTrending={this.props.getTrending}
                                    tabIndex={this.state.tabIndex}
                                    properties={this.props.properties}
                                    filterInputData={this.props.filterInputData}
                                    selectedAttribute={this.state.selectedAttribute}
                                    setSelectedAttribute={this.setSelectedAttribute}
                                    getAttributeStatistics={this.props.getAttributeStatistics}
                                    updateDatasetProperties={this.props.updateDatasetProperties}
                                    config={this.props.config}
                                />
                                : <NoResultFound />
                        }
                    </TabPanel>
                    <TabPanel value={this.state.tabIndex} index={this.getTabIndex("Info")}>
                        {
                            (this.state.tabIndex === this.getTabIndex("Info")) &&
                            <CatalogInfo datasourceId={
                                this.state.datasetDetail && this.state.datasetDetail.source_id
                                    ? this.state.datasetDetail.source_id : 0
                            }
                                datasetId={this.props.match.params.id}
                                type="dataset" />
                        }
                    </TabPanel>
                    <TabPanel value={this.state.tabIndex} index={this.getTabIndex("Comments")}>
                        {
                            (this.state.tabIndex === this.getTabIndex("Comments")) ?
                                <Comments type="dataset" updateAuditLog={this.updateAuditLogs} datasourceId={this.state.datasourceDetail.id} datasetId={this.props.match.params.id} />
                                : <NoResultFound />
                        }
                    </TabPanel>
                    <TabPanel value={this.state.tabIndex} index={this.getTabIndex("Execution Logs")}>
                        {
                            (this.state.tabIndex === this.getTabIndex("Execution Logs")) ?
                                <ExecutionLogs datasourceId={
                                    this.state.datasetDetail && this.state.datasetDetail.source_id
                                        ? this.state.datasetDetail.source_id : 0
                                }
                                    datasetId={
                                        this.state.datasetDetail && this.state.datasetDetail.id
                                            ? this.state.datasetDetail.id : 0
                                    }
                                    logType={'dataset'}
                                    tabIndex={this.state.tabIndex}
                                    connectorType={this.state.datasourceDetail?.type ? this.state.datasourceDetail.type : null}
                                    isEditable={((this.state.isStewardUser && this.props.isLicensedValidated && connectionStatus) || (this.props.user.role && this.props.user.role.properties_permission && this.props.user.role.properties_permission.is_edit))} />
                                : <NoResultFound />
                        }

                    </TabPanel>
                    <TabPanel value={this.state.tabIndex} index={this.getTabIndex("Lineage")}>
                        {
                            (this.state.tabIndex === this.getTabIndex("Lineage")) &&
                            <LineageTableau
                                datasource_id={this.state.datasourceDetail.id}
                                workbook_id={workbook_id}
                            />
                        }
                    </TabPanel>
                    {
                        this.state.customTabls.map((field, index) =>
                            <TabPanel key={index} value={this.state.tabIndex} index={this.getTabIndex(field)} className={classes.test}>
                                {
                                    this.state.tabIndex === this.getTabIndex(field) &&
                                    <DynamicElementByDataType
                                        fields={[...this.state.tabFields.filter((tab) => tab.tab_name === field)]}
                                        key={this.state.datasourceDetail.id}
                                        properties={this.state.fieldProperties}
                                        dyanamicEleOnChange={this.dyanamicEleOnChange}
                                        level={appConstants.fieldKeys.dataset}
                                        datasource={this.state.datasourceDetail.id}
                                        dataset={parseInt(this.props.match.params.id)}
                                    />
                                }
                            </TabPanel>
                        )
                    }
                    {(this.state.isPermissionLoading && this.state.isDataLoading) && <Loader />}
                </Grid>
                <AlertDialog title="Delete Dataset"
                    message={`Are you sure you want to delete the dataset ${this.state.datasetDetail ? this.state.datasetDetail.name : ''}?`}
                    okButtonText="OK"
                    cancelButtonText="Cancel"
                    show={this.state.isDeleteDataset}
                    onClickOK={() => this.deleteDatasetInfo()}
                    onClickCancel={() => this.setState({ isDeleteDataset: false })} />
            </ValidatorForm >
        );
    }
}

DatasetOverview.propTypes = {
    classes: PropTypes.object,
    match: PropTypes.object,
    getOrganizationUser: PropTypes.func,
    getDataSetDetail: PropTypes.func,
    users: PropTypes.array,
    datasource: PropTypes.object,
    getSourceAuditLogs: PropTypes.func,
    location: PropTypes.object,
    updateDataset: PropTypes.func,
    createAuditLog: PropTypes.func,
    history: PropTypes.object,
    inviteSourceUser: PropTypes.func,
    user: PropTypes.object,
    getDatasetInfo: PropTypes.func,
    datasetInfo: PropTypes.object,
    deleteDataSet: PropTypes.func,
    updateFavorites: PropTypes.func,
    getFavorites: PropTypes.func,
    getDatasetShare: PropTypes.func,
    createDatasetShare: PropTypes.func,
    properties: PropTypes.object,
    propertiesList: PropTypes.array,
    setSelectedDatasetId: PropTypes.func,
    getDatasetRules: PropTypes.func,
    getProfileAnalysis: PropTypes.func,
    getCurationAnalysis: PropTypes.func,
    getTrending: PropTypes.func,
    getDriftTrending: PropTypes.func,
    filterInputData: PropTypes.func,
    config: PropTypes.object,
    profileRuleEnable: PropTypes.func,
    getProfileRules: PropTypes.func,
    getDriftRules: PropTypes.func,
    driftAcceptRules: PropTypes.func,
    deleteDatasetShare: PropTypes.func,
    getUserAttributes: PropTypes.func,
    updateUser: PropTypes.func,
    setSelectedDatasourceId: PropTypes.func,
    getActionItemCount: PropTypes.func,
    getDataSource: PropTypes.func,
    getConversationCount: PropTypes.func,
    getDriftAttribute: PropTypes.func,
    curationRules: PropTypes.array,
    references: PropTypes.array,
    getCurationRules: PropTypes.func,
    getReferences: PropTypes.func,
    getMasterFieldTypes: PropTypes.func,
    getCustomFields: PropTypes.func,
    getFieldPropertyByLevel: PropTypes.func,
    createFieldProperty: PropTypes.func,
    getExportReport: PropTypes.func,
    // exportPropertiesReport: PropTypes.func,
    loadDataset: PropTypes.func,
    getDomain: PropTypes.func,
    createDomain: PropTypes.func,
    getAttributePropertyRules: PropTypes.func,
    updateAttributePropertyRules: PropTypes.func,
    setPropertySliderIndex: PropTypes.func,
    setPropertyView: PropTypes.func,
    clearCatalogInfo: PropTypes.func,
    saveDuplicate: PropTypes.func,
    getDuplicateColumn: PropTypes.func,
    datasetPropertiesTab: PropTypes.func,
    getDatasetCatalog: PropTypes.func,
    getAttributesCatalog: PropTypes.func,
    getProfileProperties: PropTypes.func,
    getStorage: PropTypes.func,
    systemConfiguration: PropTypes.bool,
    datasetCatalogInfo: PropTypes.object,
    getCustomTabs: PropTypes.func,
    connectionTypes: PropTypes.array,
    clearPreviewData: PropTypes.func,
    getAttributeStatistics: PropTypes.func,
    updateDatasetProperties: PropTypes.func,
    getPropertiesList: PropTypes.func,
    getAttributePropertiesList: PropTypes.func,
    setSelectedDataset: PropTypes.func,
    getExportMetadata: PropTypes.func,
    getExportRulesValidity: PropTypes.func,
    licenseConfig: PropTypes.object,
    isLicensedValidated: PropTypes.bool,
    domainProperties: PropTypes.object,
    updateSelectedAttribute: PropTypes.func,
    selected: PropTypes.string,
    getInvalidQueries: PropTypes.func,
    isSkipDuplicate: PropTypes.bool,
    dispatch: PropTypes.func,
    updateCertified: PropTypes.func,
    getCertified: PropTypes.func,
    updateTabIndex: PropTypes.func,
    setAttributeName: PropTypes.func,
    getPageTitle: PropTypes.func,
    user_config: PropTypes.object
};

const mapStateToProps = ({ datasource, dataset, account, setting, search }) => {
    return {
        ...datasource,
        ...dataset,
        ...account,
        ...setting,
        selected: search.selectedAttr
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        dispatch, getOrganizationUser, getDataSetDetail, getDomain, updateDataSource, getSourceAuditLogs,
        updateDataset, createAuditLog, createSourceShare, setSelectedDatasetId, setPropertySliderIndex, setPropertyView,
        getDatasetInfo, deleteDataSet, updateFavorites, getFavorites, getDatasetShare, inviteSourceUser, getProfileProperties, getStorage,
        createDatasetShare, getDatasetRules, getProfileAnalysis, getCurationRules, getCurationAnalysis, getTrending, getDriftTrending, filterInputData, getReferences, profileRuleEnable, getProfileRules, getDriftRules, driftAcceptRules, deleteDatasetShare, createDomain,
        updateUser, loadDataset, getUserAttributes, setSelectedDatasourceId, getActionItemCount, getDataSource, getConversationCount, getDriftAttribute, getMasterFieldTypes, getFieldPropertyByLevel, createFieldProperty, getExportReport, getAttributePropertyRules, updateAttributePropertyRules,
        clearCatalogInfo, saveDuplicate, getDuplicateColumn, datasetPropertiesTab, getDatasetCatalog, getAttributesCatalog, getCustomTabs, clearPreviewData, getPropertiesList, getAttributePropertiesList, getAttributeStatistics, updateDatasetProperties, setSelectedDataset, updateSelectedAttribute,
        getInvalidQueries, getCustomFields, getExportMetadata, getExportRulesValidity, updateCertified, getCertified, updateTabIndex, setAttributeName
    }, dispatch);

};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles((theme) => ({
    ...Styles(theme)
}), { withTheme: true })(DatasetOverview));