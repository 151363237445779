import config from "./QueryBuilderConfig.jsx";
import ProfileChartFilterOperators from "../../constants/chartOperators.js";
import { nanoid } from 'nanoid';
import moment from "moment";
import functions from './QueryBuilderFunctionConfig.jsx';

let groupByField = '';

export const isRuleGroup = (rule) => {
    return Boolean(rule.connector);
};

export const isNumeric = (dataType) => {
    return (dataType.toLowerCase() === 'integer' || dataType.toLowerCase() === 'numeric'
        || dataType.toLowerCase() === 'money');
};

export const isDateTime = (dataType) => {
    return (dataType.toLowerCase() === 'date' || dataType.toLowerCase() === 'datetime'
        || dataType.toLowerCase() === 'datetimeoffset' || dataType.toLowerCase() === 'time');
};

export const getFieldType = (dataType) => {
    let fieldType = 'text';
    if (!dataType) { return fieldType; }

    if (isNumeric(dataType)) { fieldType = 'numeric'; }
    else if (isDateTime(dataType)) { fieldType = 'datetime'; }
    else { fieldType = 'text'; }
    return fieldType;
};


export const createRule = (ruleParams) => {
    const defaultField = ruleParams?.defaultField ?? '';
    const defaultFieldType = ruleParams?.defaultFieldType ?? '';
    const attributeType = ruleParams?.attributeType ?? 'text';
    const attributeFieldType = ruleParams?.attributeFieldType ?? '';
    const isComplexRule = ruleParams?.isComplexRule ?? false;
    const isTriggerRule = ruleParams?.isTriggerRule ?? false;
    const connectionType = ruleParams?.connectionType ?? '';
    const isScan = ruleParams?.isScan ?? false;

    const fieldType = defaultFieldType ? getFieldType(defaultFieldType) : '';
    const operator = getDefaultOperator(fieldType);
    const isSql = (connectionType && connectionType.toLowerCase() === 'sql');
    if (operator && isScan && isSql) {
        operator.operator = operator.sqlOperator;
    }
    return {
        id: `rule-${nanoid()}`,
        field: defaultField ? defaultField : '',
        valueType: 'Value',
        fieldType,
        operator,
        attributeType,
        attributeFieldType,
        isComplexRule,
        isTriggerRule,
        connectionType,
        isScan
    };

};

export const createRuleGroup = (ruleGroupParams) => {
    const defaultField = ruleGroupParams?.defaultField ?? '';
    const defaultFieldType = ruleGroupParams?.defaultFieldType ?? '';
    const attributeType = ruleGroupParams?.attributeType ?? 'text';
    const attributeFieldType = ruleGroupParams?.attributeFieldType ?? '';
    const isComplexRule = ruleGroupParams?.isComplexRule ?? false;
    const isTriggerRule = ruleGroupParams?.isTriggerRule ?? false;
    const connectionType = ruleGroupParams?.connectionType ?? '';
    const isScan = ruleGroupParams?.isScan ?? false;

    const ruleParams = {
        defaultField,
        defaultFieldType,
        attributeType,
        attributeFieldType,
        isComplexRule,
        isTriggerRule,
        connectionType,
        isScan
    };
    const rule = createRule(ruleParams);
    return {
        id: `group-${nanoid()}`,
        connector: config.connectors[0].value,
        not: false,
        isComplexRule,
        isTriggerRule,
        connectionType,
        isScan,
        rules: [
            {
                ...rule
            }
        ]
    };
};


export const preparePatternRuleGroup = (ruleGroup, attribute) => {
    if (!ruleGroup.id) {
        ruleGroup.id = ruleGroup.id ? ruleGroup.id : `group-${nanoid()}`;
    }
    ruleGroup.connector = ruleGroup.connector.toLowerCase();
    ruleGroup.isComplexRule = false;
    ruleGroup.isTriggerRule = false;
    ruleGroup.multi = true;
    const rules = [...ruleGroup.rules];
    const type = attribute.fieldType ? attribute.fieldType : 'text';
    const operators = getOperators(type);
    const operator = operators.find((p) => p.label === 'matches');
    for (let rule of rules) {
        if (isRuleGroup(rule)) {
            rule = preparePatternRuleGroup(rule, attribute);
        } else {
            rule.id = rule.id ? rule.id : `rule-${nanoid()}`;
            rule.field = attribute.attributeName;
            rule.valueType = 'Value';
            rule.fieldType = attribute.fieldType;
            rule.attributeType = attribute.attributeType;
            rule.attributeFieldType = attribute.attributeFieldType;
            rule.operator = operator;
            rule.isComplexRule = false;
            rule.isTriggerRule = false;
            rule.value = rule.pattern;
            rule.multi = true;
            rule.not = ruleGroup.not;
            rule.connectionType = ruleGroup.connectionType;
            rule.isScan = ruleGroup.isScan;
        }
    }
    return ruleGroup;
};

export const getOperators = (type, isComplexRule = false, ops = []) => {
    type = type ? type : 'text';
    let operators = [];
    if (isComplexRule) {
        operators = config.Operators.filter((p) => p.types.indexOf(type.toString().toLowerCase()) > -1 && p.isComplexRule);
    } else {
        operators = config.Operators.filter((p) => p.types.indexOf(type.toString().toLowerCase()) > -1);
    }
    const ruleNames = ops.map((rule) => rule.label);
    operators = operators.filter((rule) => !ruleNames.includes(rule.label));
    return [...operators, ...ops];
};

export const getDefaultOperator = (type) => {
    let operator = {
        label: '',
        value: '',
        type: ''
    };
    const nullField = type === "null";
    if (nullField) {
        type = '';
    }
    type = type ? type : 'text';
    const operators = getOperators(type);
    if (operators && operators.length > 0) {
        if (nullField) {
            operator = operators.find((p) => p.operator === "is null");
        } else {
            operator = operators.find((p) => p.isDefault);
        }
    }
    return operator;
};

export const getConditionOperators = () => {
    return [...config.Operators.filter((p) => p.isConditional)];
};

export const getSelectedAttributes = (ruleGroup) => {
    if (!ruleGroup) {
        return [];
    }
    let selectedAttributes = prepareSelectedAttributes(ruleGroup);
    if (selectedAttributes) {
        selectedAttributes = selectedAttributes.split(',');
        selectedAttributes = [...new Set(selectedAttributes)];
    }
    const attributes = [];
    for (const attribute of selectedAttributes) {
        attributes.push(attribute);
    }
    return attributes;
};

const prepareSelectedAttributes = (ruleGroup) => {
    if (!ruleGroup) {
        return;
    }
    const selectedAttributes = ruleGroup.rules.map((rule) => {
        if (isRuleGroup(rule)) {
            return prepareSelectedAttributes(rule);
        }
        if (rule.isComplexRule) {
            return `${rule.field}${rule.value && typeof (rule.value) !== 'string' ? `,${rule.value.name}` : ''}`;
        }
        return rule.field;
    });
    return selectedAttributes.join(',');
};

export const getQueryString = (ruleGroup, isConditional = false) => {
    if (!ruleGroup) {
        return '';
    }
    const isSql = (ruleGroup?.connectionType?.toLowerCase() === 'sql');
    const isScan = ruleGroup?.isScan ?? false;

    groupByField = '';
    let queryString = processRuleGroup(ruleGroup, isConditional);
    if (!ruleGroup.isComplexRule && queryString) {
        if (queryString.includes('<unique>')) {
            if (isScan && isSql) {
                queryString = `select * from <table_name> where ${groupByField} in ( select ${groupByField} from ( select ${groupByField}, count(*) as temp_rank from <table_name> group by ${groupByField} having ${queryString}) as temp_table)`;
            } else {
                queryString = ` select * from <table_name> where ${groupByField}.null_format in ( select ${groupByField}.null_format as ${groupByField} from ( select ${groupByField}, count(*) as temp_rank from <table_name> group by ${groupByField} having ${queryString}) as temp_table ) `;
            }
        } else {
            queryString = `select * from <table_name> where (${queryString})`;
        }
    }
    if (!ruleGroup.isComplexRule && groupByField && !queryString.includes('<unique>')) {
        if (isScan && isSql) {
            queryString = queryString.replace("<table_name>", `(select *, row_number() over(partition by ${groupByField} order by ${groupByField}) as temp_rank from <table_name>) as temp_table`);
        } else {
            queryString = queryString.replace("<table_name>", `(select *, row_number() over(partition by ${groupByField} order by ${groupByField}) as temp_rank from <table_name>) as temp_table`);
        }
    }
    queryString = queryString.replace('<unique>', '');
    return queryString;
};

const prepareField = (rule, field, isConditional = false) => {
    let fieldName = rule.chartType === "Count" ? field : `${field}.format`;
    if (rule.fieldType === "datetime" && (isNaN(Date.parse(rule.value)) || (rule.value.toString().length < 5))) {
        fieldName = field;
    }
    if (rule.isComplexFunction && rule.functionOpeator) {
        fieldName = "";
        fieldName = rule.functionOpeator.operator.replace('field.null_format', `${field}.format`);
        if (rule.functionOpeator && rule.functionOpeator.valueRequired) {
            if (rule.isAttributeRequired) {
                fieldName = fieldName.replace("<value>", rule.fieldType.toLowerCase() === "text" ? `"${rule.functionValue ? rule.functionValue.name : ''}"` : rule.functionValue);
            } else {
                fieldName = fieldName.replace("<value>", rule.fieldType.toLowerCase() === "text" ? `"${rule.functionValue}"` : rule.functionValue);
            }
        }
        if (rule.functionOpeator && rule.functionOpeator.isSecondaryValue) {
            fieldName = fieldName.replace("<secondaryValue>", rule.fieldType.toLowerCase() === "text" ? `"${rule.secondaryValue}"` : rule.secondaryValue);
        }
    }
    if (rule.isComplexRule && !isConditional) {
        fieldName = fieldName.replace('.format', '');
    }
    return fieldName;
};

const iff = (condition, then, otherwise) => (condition ? then : otherwise);

const processRuleGroup = (ruleGroup, isConditional = false, ruleIndex) => {
    if (!ruleGroup) {
        return '';
    }

    let processedRules = ruleGroup.rules.map((rule, index) => {
        if (isRuleGroup(rule)) {
            return processRuleGroup(rule, false, index);
        }
        const query = processRule(rule, isConditional);
        if (ruleGroup.isComplexRule && !isConditional) {
            return `join <table_name> on ${query} `;
        }
        return query;
    });

    processedRules = processedRules.filter((p) => Boolean(p));
    processedRules = processedRules.join(` ${ruleGroup.isComplexRule && !isConditional ? ' ' : ruleGroup.connector} `);

    processedRules = `${ruleGroup.not ? iff(processedRules, ` not (${processedRules})`, '') : processedRules}`;
    return processedRules;
};

const processRule = (rule, isConditional = false) => {
    if (!rule.operator || (rule.operator && rule.operator.type
        && rule.operator.valueRequired && !rule.value)) {
        return null;
    }
    let query = '';
    let operator = rule.operator;
    const isSql = (rule?.connectionType?.toLowerCase() === 'sql');
    const isScan = rule?.isScan ?? false;
    if (operator && isScan && isSql) {
        if (!operator.sqlOperator) {
            let inputOperator = config.Operators.find((p) => p.label.toLowerCase() === operator.label.toLowerCase());
            if (!inputOperator) {
                inputOperator = ProfileChartFilterOperators.find((p) => p.label.toLowerCase() === operator.label.toLowerCase());
            }
            if (inputOperator?.sqlOperator) {
                operator.operator = inputOperator.sqlOperator;
            }
        } else {
            operator.operator = operator.sqlOperator;
        }
    }
    const chartType = operator.chartType ? operator.chartType : '';
    let value = '';

    let field = rule.field;
    if (!field) {
        return '';
    }
    field = rule.field.toString();
    if (rule.isComplexRule) {
        field = rule.attributeSchema ? rule.attributeSchema.toString() : '';
        if (rule.fieldType !== 'text' && !rule.value && rule.input_value && typeof (rule.input_value) === 'string') {
            field = `${field}.format`;
        }
        if (rule.isComplexRule && !isConditional) {
            field = field.replace('.format', '');
        }
    }
    if (!field) {
        return '';
    }
    const valueParser = operator.disableCase ? '' : `.lower`;
    if (!rule.isTriggerRule) {
        field = valueParser.includes('.lower') ? `lower(${field})` : `${field}`;
    }
    let from = null;
    let to = null;

    field = prepareField(rule, field);
    field = field ? field.trim() : '';

    switch (operator.label.toLowerCase()) {
        case 'is null':
        case 'is not null':
            /*
             * if (isScan && field.includes('format')) { -- Removed code
             * adding isScan will result in count mismatch in PULL - null count (Extreme Values)
             */
            if (field.includes('format')) {
                field = field.replace('.format', '');
                field = field.replace('.format', '');
            }

            operator = operator.operator;
            query = `${field} ${operator}`;
            break;
        case 'is equal to':
        case 'is not equal to':
            if (isScan && field.includes('format') && rule.fieldType.toLowerCase() !== 'datetime') {
                field = field.replace('.format', '.null_format');
            }
            if (!isScan && field.includes('format') && rule.fieldType.toLowerCase() !== 'datetime') {
                field = field.replace('.format', '');
                field = rule.chartType === "Count" ? `nvl(${field}, 'xDQx')` : `nvl(${field}, '')`;
            }
            value = getValue(rule, false, false);
            operator = operator.operator;
            query = `${field} ${operator} ${value}`;
            break;
        case 'is empty':
        case 'is not empty':
            const label = operator.label;
            operator = operator.operator;
            if (!isScan) {
                query = `nvl(${field}, 'xDQx') ${operator}`;
            }
            if (isScan) {
                query = `${field} ${operator}`;
                if (label.toLowerCase() === 'is not empty') {
                    query = `${field} ${operator}`;
                }
            }
            break;
        case 'contains':
        case 'starts with':
        case 'ends with':
            if (!rule.isTriggerRule) {
                if (rule.isComplexRule) {
                    field = field.includes('lower(') ? `lower(${rule.attributeSchema.toString()})` : `${rule.attributeSchema}`;
                } else {
                    field = field.includes('lower(') ? `lower(${rule.field.toString()})` : `${field}`;
                }
            }
            field = field.trim();
            value = getValue(rule, true);
            operator = operator.operator;
            operator = operator.replace("value", rule.operator.label.toLowerCase() !== 'contains' ? value : value.toString().toLowerCase());
            query = (value || value === 0) ? `trim(${field}) ${operator}` : '';
            if (isSql && isScan) {
                query = (value || value === 0) ? `${field} ${operator}` : '';
            }
            if (isSql && isScan && rule.not) {
                query = query.replace(/(%%)/g, '%');
            }
            break;
        case 'length of character':
            if (!rule.isTriggerRule) {
                if (rule.isComplexRule) {
                    field = field.includes('lower(') ? `lower(${rule.attributeSchema.toString()})` : `${rule.attributeSchema}`;
                } else {
                    field = field.includes('lower(') ? `lower(${rule.field.toString()})` : `${field}`;
                }
            }
            field = field.trim();
            value = getValue(rule, true);
            if (operator.isChartOperation) {
                value = `${rule.value}`;
            }
            operator = operator.operator;
            operator = operator.replace("<value>", rule.operator.disableCase ? value : value.toLowerCase());
            operator = operator.replace("<condition_opertaor>", rule.conditionOperator && rule.conditionOperator.operator ? rule.conditionOperator.operator : '==');
            operator = operator.replace("<count>", rule.count ? rule.count : '');
            query = '';
            if ((value || value === 0) && (rule.count || rule.count === 0)) {
                operator = operator.replace(/(<field>)/g, field);
                query = operator;
            }
            if (isSql && isScan && rule.not) {
                query = `not (${query})`;
            }
            break;
        case 'case matches':
        case 'case not matches':
            value = rule.value ? `${rule.value.value}` : '';
            operator = operator.operator;
            operator = operator.replace("value", value);
            if (rule.value.name && rule.value.name.toLowerCase() === "others") {
                operator = operator.replace('rlike', 'not rlike');
            }
            query = '';
            if (value) {
                query = operator.replace("field", `${rule.field}.null_format`);
            }
            break;
        case 'sound matches':
        case 'sound not matches':
            value = getValue(rule, true);
            operator = operator.operator;
            operator = operator.replace("value", value);
            query = '';
            if (value) {
                query = operator.replace("field", rule.field);
            }
            break;
        case 'is unique':
        case 'is not unique':
            if (!operator.removerFormatter) {
                field = field.includes('lower(') ? `lower(${rule.field.toString()})` : `${field}`;
            }
            if (operator.operator.includes('.null_format')) {
                field = field.replace('.format', '');
            }
            const hasUniqueFormat = field.includes('format');
            if (hasUniqueFormat && operator.removeDateParser && rule.fieldType.toLowerCase() === 'datetime') {
                field = field.replace('.format', '');
            }
            field = field.trim();
            groupByField = field;
            query = `<unique>${operator.operator} `;
            break;
        case 'is duplicate':
        case 'is distinct':
            if (!operator.removerFormatter) {
                field = field.includes('lower(') ? `lower(${rule.field.toString()})` : `${field}`;
            }
            if (operator.operator.includes('.null_format')) {
                field = field.replace('.format', '');
            }
            const hasFormat = field.includes('format');
            if (hasFormat && operator.removeDateParser && rule.fieldType.toLowerCase() === 'datetime') {
                field = field.replace('.format', '');
            }
            field = field.trim();
            groupByField = field;
            query = `${operator.operator} `;
            break;
        case 'is missing':
        case 'is not missing':
            if (!operator.removerFormatter) {
                field = field.includes('lower(') ? `lower(${rule.field.toString()})` : `${field}`;
            }
            if (operator.operator.includes('.null_format')) {
                field = field.replace('.format', '');
            }
            if (field.includes('format')) {
                field = field.replace('.format', '');
            }
            field = field.trim();
            operator = operator?.operator ?? '';
            operator = operator.replace('field', field);
            query = operator ? `${operator} ` : '';
            break;
        case 'matches':
        case 'not matches':
            if (rule.isComplexRule) {
                field = field.includes('lower(') ? `${rule.attributeSchema.toString()}.null_format` : `${rule.attributeSchema}.null_format`;
            } else {
                field = field.includes('lower(') ? `${rule.field.toString()}.null_format` : `${field}.null_format`;
            }
            field = field.trim().replace('.format', '');
            let format = false;
            if (rule.isComplexRule && isConditional) {
                format = field.includes('format');
            }
            if (!rule.isComplexRule) {
                format = field.includes('format');
            }
            value = getValue(rule, true, format);
            operator = operator.operator;
            if ((isSql && isScan) && value.startsWith('(') && value.endsWith(')') && value.includes('|')) {
                value = value.slice(1, -1);
                const values = value.split('|');
                const inputValues = [];
                for (const inputValue of values) {
                    inputValues.push(`'${inputValue.toLowerCase()}'`);
                }
                value = inputValues.join(',');
                value = `(${value})`;
                operator = operator.replace("field.null_format", "lower(field)");
                operator = operator.replace('like', 'in');
                if (rule.not) {
                    operator = operator.replace('in', 'not in').replace(/(%%)/g, '%');
                }
                operator = operator.replace("field", field);
                operator = operator.replace("'value'", `${value}`);

                query = `${operator} `;
            } else {
                operator = operator.replace("field.null_format", "field");
                if (rule.is_lower) {
                    operator = operator.replace("field", "lower(field)");
                }
                operator = operator.replace("value", value);
                operator = operator.replace("field", field);

                query = `${operator} `;
                /*
                 * if (!rule.multi && rule.not) {
                 *     query = `(not ${query})`;
                 * }
                 */
                if (rule.multi && rule.not && (isSql && isScan)) {
                    query = query.replace(/(%%)/g, '%');
                }
            }
            break;
        case 'is between':
            if (!rule.isTriggerRule) {
                field = field.includes('lower(') ? `lower(${rule.field.toString()})` : `${field}`;
            }
            from = rule.value[0];
            to = rule.value[1];
            from = rule.fieldType.toLowerCase() === 'datetime' ? parseDateTime(from, rule.attributeType.toLowerCase()) : `'${from}'`;
            to = rule.fieldType.toLowerCase() === 'datetime' ? parseDateTime(to, rule.attributeType.toLowerCase()) : `'${to}'`;

            operator = operator.operator;
            operator = operator.replace("{from}", from);
            operator = operator.replace("{to}", to);
            field = field.trim();

            if (rule.isComplexRule) {
                query = field ? operator.replace("{field}", field) : '';
            } else {
                query = rule.value ? operator.replace("{field}", field) : '';
            }
            break;
        default:
            if (operator.isChartOperation) {
                if (!operator.removerFormatter) {
                    field = field.includes('lower(') ? `lower(${rule.field.toString()})` : `${field}`;
                }
                if (operator.operator.includes('.null_format')) {
                    field = field.replace('.format', '');
                }
                const hasFormat = field.includes('format');
                if (hasFormat && operator.removeDateParser && rule.fieldType.toLowerCase() === 'datetime') {
                    field = field.replace('.format', '');
                }
                value = getChartBasedValue(chartType, rule);
                operator = rule.connectionType === "oracle" && operator.label === "length is equal to" && value && value.trim() === "0" ? "length(field.null_format) is null" : operator.operator;
                operator = operator.replace("value", value);
                if (operator.includes('null_format') && field.includes('lower')) {
                    operator = operator.replace('.null_format', '');
                    field = field.trim().replace(")", ".null_format)");
                }
                field = field.trim();
                operator = operator.replace("field", field);
                query = `${operator} `;
                /*
                 * const not = Boolean(rule.not);
                 * if (not) {
                 *     query = `(not ${query})`;
                 * }
                 */
            } else {
                if (!rule.isTriggerRule) {
                    field = field.includes('lower(') ? `lower(${rule.field.toString()})` : `${field}`;
                }
                const hasFormat = field.includes('format');
                if (hasFormat && operator.removeDateParser && rule.fieldType.toLowerCase() === 'datetime') {
                    field = field.replace('.format', '');
                }
                let format = false;
                if (rule.isComplexRule && isConditional) {
                    format = hasFormat;
                }
                if (!rule.isComplexRule) {
                    format = hasFormat;
                }
                value = getValue(rule, false, format);
                operator = operator.operator;
                if (rule.isComplexRule) {
                    query = rule.input_value ? `${field} ${operator} ${value} ` : '';
                } else {
                    query = (rule.value || rule.value === 0) ? `${field} ${operator} ${value} ` : '';
                }
                if (!query && rule.operator.valueRequired) {
                    if (!field.includes('null_format')) {
                        field = field.trim().replace('.format', '');
                        field = `${field}.null_format`;
                    }
                    field = field.trim();
                    query = `${field} ${operator} ${value} `;
                }
                /*
                 * const not = Boolean(rule.not);
                 * if (not) {
                 *     query = `(not ${query})`;
                 * }
                 */
            }
            break;
    }
    return query.trim();
};

const getChartBasedValue = (chartType, rule) => {
    let value = '';
    const operator = rule.operator;
    if (operator && !operator.valueRequired) {
        value = '';
        return value;
    }
    if (!chartType) {
        value = `'${rule.value.toLowerCase()}'`;
        return value;
    }
    value = rule.value ? rule.value : '';
    if (rule.isComplexRule) {
        if (rule.input_value) {
            value = rule.value && rule.value.name === rule.input_value ? rule.value.name : rule.input_value;
            if (rule.value && rule.value.name === rule.input_value) {
                value = rule.value.name;
            }
            return value;
        }
    }
    switch (chartType) {
        case 'Length':
            value = `${value} `;
            break;
        case 'Pattern':
            value = `'${value}'`;
            break;
        case 'CaseSensitivity':
            value = typeof (inputValue) === "string" ? `'${value}'` : `'${value.value}'`;
            break;
        case 'Range':
            value = `'${value}'`;
            break;
        case 'Value':
            value = `'${operator.disableCase ? value.toString() : value.toString().toLowerCase()}'`;
            break;
        default:
            value = `'${value}'`;
            break;
    }
    return value;
};

const getValue = (rule, valueOnly = false, hasFormat = false) => {
    const operator = rule.operator;
    let value = rule.value ? rule.value : '';

    value = typeof (value) === "number" ? value.toString() : value;

    if (!rule.isComplexRule && value && value.toString().replace(/\s+/g, '').length === 0) {
        return `"${value}"`;
    }

    if (rule.isComplexRule) {
        if (rule.input_value) {
            if (rule.value && rule.value.name === rule.input_value && rule.value.attribute_schema) {
                value = hasFormat ? `trim(${rule.value.attribute_schema}.format)` : `${rule.value.attribute_schema}`;
            } else {
                value = rule.input_value ? `'${rule.input_value}'` : '';
                if (valueOnly) {
                    value = rule.input_value;
                }

                switch (rule.fieldType.toLowerCase()) {
                    case 'numeric':
                        value = `'${value.trim()}'`;
                        if (rule && operator && operator.label !== 'matches') {
                            value = value.toString().replace(/(?!^[-])[^0-9.]/g, '');
                            if (rule && rule.attributeType && rule.attributeType.toLowerCase() === 'integer') {
                                value = value.toString().replace(/(?!^[-])[^0-9]/g, '');
                            }
                            value = `${value.trim()}`;
                        }
                        break;
                    case 'datetime':
                        if (value.toString().length < 5) {
                            value = `'${value}'`;
                            if (value.charAt(0) === '"') {
                                value = value.substring(1, value.length - 1);
                            }
                        } else {
                            if (isNaN(Date.parse(value))) {
                                value = `'${value}'`;
                            } else {
                                value = parseDateTime(value, rule.attributeType.toLowerCase());
                            }
                        }
                        break;
                    default:
                        // value = value.toString().replace(/\s/g, ''); comment for complex rule custom add value with space its removed all spaces between text
                        value = value.toString().trim();
                        break;
                }
            }
        }
        return value;
    }
    if (!operator.valueRequired) {
        value = '';
    }
    else {
        switch (rule.fieldType.toLowerCase()) {
            case 'numeric':
                if (rule && operator && operator.label !== 'matches' && operator.label !== 'not matches') {
                    value = value.toString().replace(/(?!^[-])[^0-9.]/g, '');
                    if (rule && rule.attributeType && rule.attributeType.toLowerCase() === 'integer') {
                        value = value.toString().replace(/(?!^[-])[^0-9]/g, '');
                    }
                }
                value = valueOnly ? `${value.trim()}` : `'${value.trim()}'`;
                break;
            case 'datetime':
                if (!rule.isScan) {
                    if (value.toString().length < 5) {
                        value = `${value}`;
                    } else {
                        if (isNaN(Date.parse(value))) {
                            if (value.charAt(0) === '"') {
                                value = value.substring(1, value.length - 1);
                            }
                            value = `${value}`;
                        } else {
                            value = parseDateTime(value, rule.attributeType.toLowerCase());
                        }
                    }
                }
                break;
            default:
                if (valueOnly) {
                    return `${operator.disableCase ? value.toString() : value.toString().toLowerCase()}`;
                }
                value = `'${operator.disableCase ? value.toString() : value.toString().toLowerCase()}'`;
                break;
        }
    }
    return value;
};


const parseDateTime = (value, type) => {
    if (!value) {
        return '';
    }
    const dateFormat = 'MM-DD-YYYY';
    const timeFormat = 'HH:mm';
    const datetime = moment(value);
    if (!datetime.isValid()) {
        return '';
    }

    switch (type.toLowerCase()) {
        case 'datetime':
            value = `unix_timestamp("${datetime.format(dateFormat)} ${datetime.format(timeFormat)}", "MM-dd-yyyy HH:mm")`;
            break;
        case 'datetimeoffset':
            value = `unix_timestamp("${datetime.format(dateFormat)} ${datetime.format(timeFormat)}", "MM-dd-yyyy HH:mm")`;
            break;
        case 'date':
            value = `unix_timestamp("${datetime.format(dateFormat)}", "MM-dd-yyyy")`;
            break;
        case 'time':
            value = `unix_timestamp("${datetime.format(timeFormat)}", "HH:mm")`;
            break;
        default:
            value = `unix_timestamp("${datetime.format(dateFormat)}", "MM-dd-yyyy")`;
            break;
    }
    return value;
};

export const getFunctionalOpeartors = (type) => {
    return [...functions.filter((p) => p.types.indexOf(type.toString().toLowerCase()) > -1)];
};

export const getAttributeNames = (attributes, queryString) => {
    attributes = attributes.map((attribute) => attribute.name);
    queryString = queryString?.replace(/[!=><]/g, " ") ?? '';
    const query = queryString ? queryString.split(" ") : [];
    const attributeList = [];
    for (const data of query) {
        let dataQuery = data;
        if (data.includes('(')) {
            dataQuery = dataQuery.split("(")[1].split(")")[0];
        }
        if (data.includes(')')) {
            dataQuery = dataQuery.split(")")[0];
        }
        dataQuery = dataQuery.replace(/[,=]/g, "");
        const index = attributes.findIndex((attribute) => attribute.toLowerCase() === dataQuery.toLowerCase());
        if (index !== -1) {
            attributeList.push(attributes[index]);
        }
    }
    return attributeList;
};


export const getTableNames = (attributes, queryString) => {
    const tableAttributes = attributes.map((attribute) => { return { schema: attribute.dataset_schema, attribute: attribute.attribute_schema }; });
    attributes = attributes.map((attribute) => attribute.dataset_schema);
    const query = queryString ? queryString.split(" ") : [];
    const attributeList = [];
    for (const data of query) {
        let dataQuery = data;
        if (data.includes('(')) {
            dataQuery = dataQuery.split("(")[1].split(")")[0];
        }
        dataQuery = dataQuery.replace(/[,=]/g, "");
        if (dataQuery.includes(')')) {
            dataQuery = dataQuery.replace(')', '');
        }
        const index = attributes.findIndex((attribute) => attribute && attribute.includes(dataQuery));
        if (index !== -1) {
            attributeList.push(attributes[index]);
        } else {
            const selectedAttribute = tableAttributes.find((attribute) => attribute.attribute === dataQuery);
            if (selectedAttribute) {
                attributeList.push(selectedAttribute.schema);
            }
        }
    }
    return attributeList;
};

export const removeFormatting = (queryString) => {
    const attributeRegex = new RegExp(".format", 'g');
    queryString = queryString.replace(attributeRegex, "");
    return queryString;
};

export const functionQueryString = (query, ruleInputFields) => {
    const ruleGroupAttributes = ruleInputFields ? ruleInputFields : [];
    const queryData = query ? query.split(" ") : [];
    let prepareQuery = '';
    for (let data of queryData) {
        if (data.includes('max(') || data.includes('min(') || data.includes('avg(') || data.includes('sum(')) {
            const column = data.split("(")[1].split(")")[0];

            const attribute = ruleGroupAttributes.find((attribute) => attribute.name.toLowerCase() === column.toLowerCase());
            if (attribute) {
                const reg = new RegExp(attribute.name);
                if (attribute.datatype === "Integer" || attribute.datatype === "Numeric" || attribute.datatype === "Money" || attribute.datatype === "Text") {
                    data = data.replace(reg, `${attribute.name}.format`);
                }
            }
        }
        prepareQuery = `${prepareQuery} ${data}`;
    }
    return prepareQuery.trim();
};

export const createParamRule = (defaultFieldType = '') => {
    const fieldType = defaultFieldType ? getFieldType(defaultFieldType) : '';
    const operator = getDefaultOperator(fieldType);
    return {
        id: `rule-${nanoid()}`,
        operator,
        fieldType: fieldType
    };

};

export const createParamRuleGroup = (defaultFieldType = '') => {
    const rule = createParamRule(defaultFieldType);
    return {
        id: `group-${nanoid()}`,
        connector: config.connectors[0].value,
        not: false,
        rules: [
            {
                ...rule
            }
        ]
    };
};