import React, { Component } from 'react';
import { withStyles, Grid, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';
import classNames from 'classnames';
import moment from 'moment';
import RuleGroup from './RuleGroup.jsx';
import { isRuleGroup, createRule, createRuleGroup, getDefaultOperator, getFieldType } from './QueryBuilderUtil.jsx';
import RuleBuilderStyles from './QueryBuilderStyles.jsx';
import Styles from '../../layouts/Styles.jsx';

class QueryBuilder extends Component {
    constructor(props) {
        super(props);
        const initialRule = createRuleGroup({
            isComplexRule: props.isComplexRule,
            connectionType: props.selectedDatasource?.type,
            isScan: props.selectedDatasource?.scan
        });
        this.state = {
            ruleGroup: {
                ...initialRule
            },
            conditionalRuleGroup: {
                ...initialRule
            },
            isComplexRule: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.ruleGroup && props.ruleGroup.id !== state.ruleGroup.id) {
            return {
                ...state,
                ruleGroup: { ...props.ruleGroup, isComplexRule: props.isComplexRule }
            };
        }
        if (props.conditionalRuleGroup && props.conditionalRuleGroup.id !== state.conditionalRuleGroup.id) {
            return {
                ...state,
                conditionalRuleGroup: { ...props.conditionalRuleGroup, isComplexRule: props.isComplexRule }
            };
        }
        return {
            ...state,
            ruleGroup: {
                ...createRuleGroup({
                    isComplexRule: props.isComplexRule,
                    connectionType: props.selectedDatasource?.type,
                    isScan: props.selectedDatasource?.scan
                })
            }
        };
    }

    componentDidMount() {
        const { defaultField, defaultFieldType, attributeFieldType, isComplexRule } = this.props;
        const ruleGroup = createRuleGroup({
            defaultField,
            defaultFieldType: getFieldType(defaultFieldType),
            attributeType: defaultFieldType,
            attributeFieldType,
            isComplexRule,
            connectionType: this.props.selectedDatasource?.type,
            isScan: this.props.selectedDatasource?.scan
        });
        this.setState({ ruleGroup: { ...ruleGroup } });
    }

    onAddRule(parentRuleId, isConditionalRule) {
        const { isComplexRule, selectedDatasource } = this.props;
        const ruleGroup = isConditionalRule ? { ...this.props.conditionalRuleGroup } : { ...this.props.ruleGroup };
        const parentRule = this.findRule(parentRuleId, ruleGroup);
        const isTriggerRule = (isComplexRule && isConditionalRule);
        const ruleParams = {
            defaultField: !isComplexRule ? this.props.defaultField : '',
            defaultFieldType: !isComplexRule ? getFieldType(this.props.defaultFieldType) : '',
            attributeType: !isComplexRule ? this.props.defaultFieldType : '',
            attributeFieldType: !isComplexRule ? this.props.attributeFieldType : '',
            isComplexRule,
            isTriggerRule,
            connectionType: selectedDatasource?.type,
            isScan: selectedDatasource?.scan
        };
        const rule = createRule(ruleParams);
        rule.chartType = this.props.chartType ? this.props.chartType : '';
        rule.isComplexRule = this.props.isComplexRule;
        parentRule.rules.push({
            ...rule
        });
        this.onQueryChange(ruleGroup, isConditionalRule);
    }

    onAddRuleGroup(parentRuleId, isConditionalRule) {
        const { isComplexRule } = this.props;
        const ruleGroup = isConditionalRule ? { ...this.props.conditionalRuleGroup } : { ...this.props.ruleGroup };
        const parentRule = this.findRule(parentRuleId, ruleGroup);
        const isTriggerRule = (isComplexRule && isConditionalRule);
        const childRuleGroup = createRuleGroup({
            defaultField: !isComplexRule ? this.props.defaultField : '',
            defaultFieldType: !isComplexRule ? getFieldType(this.props.defaultFieldType) : '',
            attributeType: !isComplexRule ? this.props.defaultFieldType : '',
            attributeFieldType: !isComplexRule ? this.props.attributeFieldType : '',
            isComplexRule,
            isTriggerRule,
            connectionType: this.props.selectedDatasource?.type,
            isScan: this.props.selectedDatasource?.scan
        });
        parentRule.rules.push({
            ...childRuleGroup
        });
        this.onQueryChange(ruleGroup, isConditionalRule);
    }

    findRule(ruleId, parentRule) {
        if (parentRule.id === ruleId) { return parentRule; }

        for (const rule of parentRule.rules) {
            if (rule.id === ruleId) {
                return rule;
            } else if (isRuleGroup(rule)) {
                const subRule = this.findRule(ruleId, rule);
                if (subRule) { return subRule; }
            }
        }
    }

    onPropertyChange(property, value, ruleId, isConditionalRule) {
        const ruleGroup = isConditionalRule ? { ...this.props.conditionalRuleGroup } : { ...this.props.ruleGroup };
        const rule = this.findRule(ruleId, ruleGroup);
        rule.chartType = this.props.chartType ? this.props.chartType : '';
        if (property === 'field') {
            rule[property] = value.name;
            rule.attributeSchema = value.attributeSchema ? value.attributeSchema : '';
            if (rule.isComplexRule) {
                rule.inputParams = { ...value };
            }
            const fieldType = getFieldType(value.type);
            const operator = getDefaultOperator(fieldType.toLowerCase());

            rule[property] = value.name;
            rule.fieldType = fieldType;
            rule.attributeType = value.type;
            rule.operator = operator;
            rule.attributeFieldType = value.attributeFieldType;
            rule.value = '';
        } else if (property === 'operator') {
            rule[property] = value;
            if (value && value.label && value.label.toLowerCase() === 'is between') {
                rule.value = rule.fieldType.toLowerCase() === 'datetime'
                    ? [
                        moment(),
                        moment().add(1, 'days')
                    ]
                    : ['', ''];
            } else {
                rule.value = '';
                rule['input_value'] = '';
            }
        } else if (property === 'not') {
            rule[property] = value;
            rule['not_enabled'] = value;
        }
        else {
            rule[property] = value;
            if (rule.isComplexRule) {
                if (typeof (value) === 'string') {
                    rule['input_value'] = value;
                } else {
                    rule['input_value'] = (value && value.name) ? value.name : '';
                }
            }
        }
        this.onQueryChange(ruleGroup, isConditionalRule);
    }

    onRuleRemove(ruleId, parentId, isConditionalRule) {
        const { isComplexRule, selectedDatasource } = this.props;
        const ruleGroup = isConditionalRule ? { ...this.props.conditionalRuleGroup } : { ...this.props.ruleGroup };
        const parentRule = this.findRule(parentId, ruleGroup);
        const ruleIndex = parentRule.rules.findIndex((rule) => rule.id === ruleId);
        parentRule.rules.splice(ruleIndex, 1);

        if (ruleGroup.rules.length <= 0) {
            const ruleParams = {
                defaultField: !isComplexRule ? this.props.defaultField : '',
                defaultFieldType: !isComplexRule ? getFieldType(this.props.defaultFieldType) : '',
                attributeType: !isComplexRule ? this.props.defaultFieldType : '',
                attributeFieldType: '',
                isComplexRule,
                connectionType: selectedDatasource?.type,
                isScan: selectedDatasource?.scan
            };
            const rule = createRule(ruleParams);
            parentRule.rules.push({ ...rule });
        }

        this.onQueryChange(ruleGroup, isConditionalRule);
    }

    onGroupRemove(groupId, parentId, isConditionalRule) {
        const ruleGroup = isConditionalRule ? { ...this.props.conditionalRuleGroup } : { ...this.props.ruleGroup };
        const parentRule = this.findRule(parentId, ruleGroup);
        const ruleIndex = parentRule.rules.findIndex((group) => group.id === groupId);
        parentRule.rules.splice(ruleIndex, 1);
        this.onQueryChange(ruleGroup, isConditionalRule);
    }

    onChangeRuleType() {
        this.setState({ isComplexRule: !this.state.isComplexRule }, () => {
            if (this.props.onRuleTypeChange) {
                this.props.onRuleTypeChange(this.state.isComplexRule);
            }
        });
    }

    onQueryChange(ruleGroup, isConditionalRule) {
        if (this.props.onQueryChange) {
            this.props.onQueryChange(ruleGroup, isConditionalRule);
        }
    }

    renderQueryBuilder() {
        const { classes, fields, operators, ruleGroup, conditionalRuleGroup, isLoading, showSubmitButton, onClear, theme, isPatternChart, disabled, ...props } = this.props;
        const isComplexRule = this.props.isComplexRule;
        const builderConfig = {
            fields: fields ? [...fields] : [],
            operators: operators ? [...operators] : [],
            classes: classes,
            onAddRule: (...props) => this.onAddRule(...props),
            onAddRuleGroup: (...props) => this.onAddRuleGroup(...props),
            onPropertyChange: (...props) => this.onPropertyChange(...props),
            onRuleRemove: (...props) => this.onRuleRemove(...props),
            onGroupRemove: (...props) => this.onGroupRemove(...props),
            onChangeRuleType: (...props) => this.onChangeRuleType(...props),
            ...props
        };

        return (
            <Grid container className={classNames(classes.queryBuilder)}>
                <Grid item xs={12} className={classNames('ruleQueryBuilder')}>
                    <RuleGroup id={ruleGroup.id}
                        parentId={null}
                        rules={[...ruleGroup.rules]}
                        connector={ruleGroup.connector}
                        className={classes.parentRule}
                        not={ruleGroup.not}
                        theme={theme}
                        builderConfig={builderConfig}
                        isPatternChart={isPatternChart}
                        disabled={disabled}
                        isConditionalRule={0} />
                </Grid>
                {
                    isComplexRule &&
                    <Grid item xs={12} className={classNames('ruleQueryBuilder')}>
                        <RuleGroup id={conditionalRuleGroup.id}
                            parentId={null}
                            rules={[...conditionalRuleGroup.rules]}
                            connector={conditionalRuleGroup.connector}
                            className={classes.parentRule}
                            not={conditionalRuleGroup.not}
                            theme={theme}
                            builderConfig={builderConfig}
                            isPatternChart={isPatternChart}
                            disabled={disabled}
                            isConditionalRule={1} />
                    </Grid>
                }
                {
                    showSubmitButton &&
                    <Grid item xs={12}>
                        <Grid container direction="row" justify="flex-start">
                            <Grid item className={classes.btnContainer}>
                                <Button variant="contained"
                                    type="submit"
                                    disabled={isLoading}
                                    color="primary"
                                    className={classNames(classes.actionButton)}>
                                    {'Submit'}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button className={classNames(classes.cancelButton, classes.actionButton)}
                                    disabled={isLoading}
                                    onClick={() => onClear()}>
                                    {'Clear All'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        );
    }

    render() {
        const { classes, showSubmitButton, onSubmit } = this.props;
        if (showSubmitButton) {
            return (
                <ValidatorForm className={classes.fullwidth}
                    name="ruleForm"
                    autoComplete="off"
                    onSubmit={() => showSubmitButton && onSubmit()}>
                    {this.renderQueryBuilder()}
                </ValidatorForm>
            );
        }
        return (this.renderQueryBuilder());
    }
}

QueryBuilder.propTypes = {
    classes: PropTypes.object,
    showSubmitButton: PropTypes.bool,
    onSubmit: PropTypes.func,
    fields: PropTypes.array,
    operators: PropTypes.array,
    ruleGroup: PropTypes.object,
    isLoading: PropTypes.bool,
    onClear: PropTypes.func,
    theme: PropTypes.object,
    isPatternChart: PropTypes.bool,
    onQueryChange: PropTypes.func,
    defaultField: PropTypes.string,
    defaultFieldType: PropTypes.string,
    attributeFieldType: PropTypes.string,
    chartType: PropTypes.string,
    onRuleTypeChange: PropTypes.func,
    isComplexRule: PropTypes.bool,
    conditionalRuleGroup: PropTypes.object,
    selectedDatasource: PropTypes.object,
    disabled: PropTypes.bool
};

export default withStyles((theme) => ({
    ...RuleBuilderStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(QueryBuilder);