import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
    withStyles, Grid, Table, TableHead, TableBody, TableRow, TableCell, IconButton, MenuItem, FormControlLabel, Switch
} from '@material-ui/core';

import ConnectorStyles from './ConnectorStyles.jsx';
import TextBox from './../TextBox/TextBox.jsx';
import { appConstants } from './../../constants/appConstants.js';
import Styles from './../../layouts/Styles.jsx';
import ToolTipComponent from './../Tooltip/Tooltip.jsx';
import { updateDatasetQuery } from '../../actions/datasetActions.js';


const QuerySetContainer = (props) => {
    const { classes, datasets, onUpdateDataset, deleteSelectedDataset, isMultiLine, theme } = props;
    const dispatch = useDispatch();
    const userConfig = useSelector(({ setting }) => setting.user_config);

    const updateQuery = useCallback((dataset) => {
        if (!dataset?.dataset_id) {
            return;
        }
        const queryInput = {
            'dataset_id': dataset.dataset_id,
            'query': dataset.query_string
        };
        dispatch(updateDatasetQuery(queryInput));
    }, [dispatch]);


    return (
        <Table stickyHeader className={classes.querySetContainer}>
            <TableHead>
                {/* <TableCell align="center" className={'checkboxContainer'}>
                                    <CheckboxComponent
                                        className={"attributeSelectorCheck"}
                                        checked={isAllSelected}
                                        onClick={() => onSelectAll()}
                                    />
                                </TableCell> */}
                {
                    appConstants && appConstants.queryInputTableHeader &&
                    appConstants.queryInputTableHeader.filter((elem) => (userConfig.include_organization_domain_score ? true : elem !== 'Include DQScore')).map((header, index) => {
                        return (
                            <TableCell key={`queryset_table_header_${index}`}
                                colSpan={header === 'Query' ? 8 : null}
                                align={header === 'Actions' ? 'center' : 'left'}
                                className={header === 'Query' ? 'queryInput' : ''}
                            >
                                {header}
                            </TableCell>
                        );
                    })
                }
            </TableHead>
            <TableBody>
                {
                    datasets && datasets.map((dataset, index) => {
                        return (
                            <TableRow key={`queryset_table_row_${index}`}>
                                {/* <TableCell align="center" className={'checkboxContainer'}>
                                                    <CheckboxComponent
                                                        className={"attributeSelectorCheck"}
                                                        checked={Boolean(dataset.isSelected)}
                                                        onClick={(event) => event.stopPropagation()}
                                                        onChange={() => onSelectionChange(dataset)}
                                                    />
                                                </TableCell> */}
                                <TableCell className={"datasetInput"}>
                                    <TextBox
                                        name="name"
                                        value={dataset.name ? dataset.name : ''}
                                        onChange={(event) => onUpdateDataset(dataset, event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell colSpan={8} className={"queryInput"}>
                                    <TextBox
                                        name="query_string"
                                        multiline={isMultiLine}
                                        value={dataset.query_string ? dataset.query_string : ''}
                                        onChange={(event) => onUpdateDataset(dataset, event.target.name, event.target.value)}
                                        onBlur={() => updateQuery(dataset)}
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell className={classes.loadTypeInput}>
                                    <TextBox
                                        name="load_type"
                                        value={dataset.load_type ? dataset.load_type : appConstants.loadType[0]}
                                        disabled
                                        select
                                        onChange={(event) => onUpdateDataset(dataset, event.target.name, event.target.value)}
                                        fullWidth
                                    >
                                        {
                                            appConstants.loadType.map((loadType, index) => {
                                                return (
                                                    <MenuItem key={`loadType_${index}`} value={loadType}>
                                                        {loadType}
                                                    </MenuItem>
                                                );
                                            })
                                        }
                                    </TextBox>
                                </TableCell>
                                <TableCell className={classes.loadTypeInput}>
                                    <TextBox
                                        name="con_type"
                                        value={dataset.con_type ? dataset.con_type : appConstants.connectionType[0].value}
                                        select
                                        disabled={!dataset.isSelected || dataset.con_type}
                                        onChange={(event) => onUpdateDataset(dataset, event.target.name, event.target.value)}
                                        fullWidth
                                    >
                                        {
                                            appConstants.connectionType.map((conType, index) => {
                                                return (
                                                    <MenuItem key={`conType${index}`} value={conType.value}>
                                                        {conType.name}
                                                    </MenuItem>
                                                );
                                            })
                                        }
                                    </TextBox>
                                </TableCell>
                                {
                                    userConfig.include_organization_domain_score &&
                                    <TableCell align="center" onClick={(event) => event.stopPropagation()}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={dataset.include_organization_domain_score}
                                                    onChange={(event) => { onUpdateDataset(dataset, 'include_organization_domain_score', !dataset.include_organization_domain_score); }}
                                                    name="include_organization_domain_score"
                                                    disabled={!dataset.isSelected}
                                                />
                                            }
                                        />
                                    </TableCell>
                                }
                                <TableCell className={classes.actionItemContainer}>
                                    <Grid container direction="row" justify="center" alignItems="center" className={classes.actionItems} wrap="nowrap">
                                        <ToolTipComponent title="Delete" arrow>
                                            <IconButton
                                                onClick={(event) => deleteSelectedDataset(event, dataset)}>
                                                <svg version="1.1"
                                                    id="Layer_1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    x="0px"
                                                    y="0px"
                                                    width="22px"
                                                    height="22px"
                                                    viewBox="0 0 32 32">
                                                    <g id="Delete">
                                                        <path fill={theme.palette.primary.main}
                                                            stroke={theme.palette.primary.main}
                                                            strokeWidth="0.92"
                                                            strokeMiterlimit="10"
                                                            d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3
    L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3
    L22.3,25.8z" />
                                                        <path fill={theme.palette.primary.main}
                                                            stroke={theme.palette.primary.main}
                                                            strokeWidth="0.92"
                                                            strokeMiterlimit="10"
                                                            d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6
    c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2
    c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                                    </g>
                                                </svg>
                                            </IconButton>
                                        </ToolTipComponent>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        );
                    })
                }

            </TableBody>
        </Table>
    );

};

QuerySetContainer.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    datasets: PropTypes.array,
    isMultiLine: PropTypes.bool,
    onUpdateDataset: PropTypes.func,
    deleteSelectedDataset: PropTypes.func
};

export default withStyles((theme) => ({
    ...ConnectorStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(QuerySetContainer);