import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import { Paper, MenuItem, withStyles, Chip, Avatar, Backdrop, Grid, IconButton } from '@material-ui/core';
import TextBox from '../TextBox/TextBox.jsx';
import Styles from '../../layouts/Styles.jsx';
import MultiSelectStyles from './MultiSelectStyles.jsx';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import classNames from 'classnames';
import { getRandomColor, getInitials, returnImage } from '../../helpers/appHelpers';
import { appConstants } from '../../constants/appConstants';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import CloseIcon from '@material-ui/icons/Close';
import AlertDialog from '../AlertDialog/AlertDialog.jsx';


const MultiSelect = (props) => {

    const { classes, placeholder, dropDown, selectedList, availableList, selectType, type, displayValue, onKeyDown, onChange, onClose, onRemoveItem, disableRemove, level, tagTarget, disabled, ...rest } = props;

    const [deleteUser, setDeleteUser] = useState(null);
    const [keyEvent, setKeyEvent] = useState(null);

    const onDeleteUser = useCallback((username, type) => {
        if (!disabled) {
            setDeleteUser(username);
        }
    }, [disabled]);


    const onBackspace = (event) => {
        if (!disabled) {
            if (event.keyCode === 8 && event.target.value === "" && selectedList.length > 0) {
                event.persist();
                setKeyEvent(event);
                const userData = selectedList[selectedList.length - 1];
                setDeleteUser(userData.email);
            } else {
                onKeyDown(event);
            }
        }
    };

    const removeItem = () => {
        onRemoveItem(deleteUser, "Select");
        setDeleteUser(null);
    };

    const multiRemove = () => {
        if (onKeyDown) {
            onKeyDown(keyEvent);
        }
        setDeleteUser(null);
    };

    const renderInput = (inputProps) => {
        const { InputProps, inputValue, classes, ...other } = inputProps;
        return (
            <TextBox
                className={classNames(classes.inlinetxt, classes.input)}
                value={inputValue ? inputValue : ''}
                autoFocus
                id={tagTarget ? "tag_target" : ""}
                InputProps={
                    !deleteUser ?
                        {
                            ...InputProps
                        } :
                        null
                }
                {...other}
            />
        );
    };

    const renderList = (propList) => {
        const { selectedList, classes, selectType, displayValue } = propList;
        return (
            selectedList && selectedList.map((item, index) =>
                <Fragment key={index}>
                    {
                        selectType === "array" &&
                        <Chip
                            className={classes.chip}
                            label={item}
                            deleteIcon={<ClearRoundedIcon />}
                            onDelete={() => onRemoveItem(index)}
                        />
                    }
                    {
                        selectType === "array of object" &&
                        <Chip
                            className={classes.chip}
                            label={item[displayValue]}
                            deleteIcon={<ClearRoundedIcon />}
                            onDelete={() => onRemoveItem(index)}
                        />
                    }
                    {
                        selectType === "user" &&
                        <div>
                            {
                                (disableRemove && selectedList.length === 1) ?
                                    <Chip
                                        className={classes.chip}
                                        avatar={
                                            <Avatar
                                                style={
                                                    {
                                                        backgroundImage: `url(${returnImage(appConstants.API_BASE_URL, item.avatar)})`,
                                                        backgroundColor: getRandomColor(item.name)
                                                    }
                                                }
                                            >
                                                {
                                                    !item.avatar &&
                                                    <span>
                                                        {getInitials(item.name)}
                                                    </span>
                                                }
                                            </Avatar>
                                        }
                                        label={item[displayValue]}
                                    /> :
                                    <Grid className={classNames(classes.listChip, classes.chipList)}>
                                        <Chip
                                            className={classes.chip}
                                            avatar={
                                                <Avatar
                                                    style={
                                                        {
                                                            backgroundImage: `url(${returnImage(appConstants.API_BASE_URL, item.avatar)})`,
                                                            backgroundColor: getRandomColor(item.name)
                                                        }
                                                    }
                                                >
                                                    {
                                                        !item.avatar &&
                                                        <span>
                                                            {getInitials(item.name)}
                                                        </span>
                                                    }
                                                </Avatar>
                                            }
                                            label={item[displayValue]}
                                            deleteIcon={<ClearRoundedIcon />}
                                            onDelete={() => onDeleteUser(item.name, "Select")}
                                        />
                                        {
                                            (!item.is_owner) && !disabled &&
                                            <IconButton onClick={() => onDeleteUser(item.name, "Select")} className={classNames(classes.nopadding, classes.inlineClose, 'chipremove', 'inlineClose')}>
                                                <CloseIcon className={classes.chipIcon} />
                                            </IconButton>
                                        }
                                    </Grid>
                            }
                        </div>
                    }
                </Fragment>
            )
        );
    };

    const getSuggestions = (input, list, type, display) => {
        return list.filter((item) => {
            return type === "array" ? item.toLowerCase().includes(input.toLowerCase()) :
                item[display].toLowerCase().includes(input.toLowerCase());
        });
    };

    const shrinkText = (text) => {
        if (text.length > 8) {
            return text.slice(0, 8) + "...";
        } return text;
    };

    const renderSuggestion = (params) => {
        const { item, index, itemProps, highlightedIndex, displayValue, type } = params;
        const isHighlighted = highlightedIndex === index;
        return (
            type !== "drift" ?
                <MenuItem
                    {...itemProps}
                    key={item.id}
                    selected={isHighlighted}
                    component="div"
                    // onClick = {(event) => {event.stopPropagation(); onChange(item.username);}}
                    className={classes.menuItem}
                >
                    {selectType === "array" ? item : item[displayValue]}

                </MenuItem> :

                <ToolTipComponent title={selectType === "array" ? item : item[displayValue]} arrow>
                    <MenuItem
                        {...itemProps}
                        key={item.id}
                        selected={isHighlighted}
                        component="div"
                        onClick={(event) => { event.stopPropagation(); onChange(item.username); }}
                        className={classes.menuItem}
                    >
                        {selectType === "array" ? shrinkText(item) : shrinkText(item[displayValue])}
                    </MenuItem>
                </ToolTipComponent>

        );
    };


    return (
        <Fragment>
            <Downshift onChange={(event) => onChange(event)} {...rest}>
                {
                    ({
                        getInputProps,
                        getItemProps,
                        inputValue,
                        highlightedIndex,
                        toggleMenu,
                        isOpen
                    }) => (
                        <div className={`${classes.selectContainer} selectContainer`} >
                            <Backdrop className={classes.backdrop} open onClick={() => onClose()} />
                            {
                                renderList({
                                    classes,
                                    selectedList,
                                    selectType,
                                    displayValue,
                                    onRemoveItem
                                })
                            }
                            {
                                !disabled &&
                                <Fragment>
                                    {
                                        renderInput({
                                            classes,
                                            placeholder,
                                            inputValue,
                                            InputProps: {
                                                ...getInputProps({
                                                    onClick: (event) => {
                                                        event.stopPropagation();
                                                        toggleMenu();
                                                    },
                                                    onKeyDown: (event) => (onBackspace(event))
                                                })
                                            }
                                        })
                                    }
                                </Fragment>
                            }

                            {
                                isOpen && dropDown && (
                                    <Paper className={classNames(classes.paper, classes.dropBottom)} square>
                                        {
                                            getSuggestions(inputValue, availableList, selectType, displayValue).map((item, index) =>
                                                renderSuggestion({
                                                    item,
                                                    index,
                                                    itemProps: getItemProps({
                                                        item: selectType === "array" ? item : item[displayValue]
                                                    }),
                                                    highlightedIndex,
                                                    selectedList,
                                                    displayValue,
                                                    type
                                                })
                                            )
                                        }
                                    </Paper>
                                )
                            }
                        </div>
                    )
                }

            </Downshift>

            <AlertDialog title="Remove User"
                message={`Are you sure you want to delete user ${deleteUser}?`}
                okButtonText="OK"
                cancelButtonText="Cancel"
                show={Boolean(deleteUser)}
                onClickOK={() => (keyEvent ? multiRemove() : removeItem())}
                onClickCancel={() => setDeleteUser(null)} />
        </Fragment>
    );
};
MultiSelect.propTypes = {
    classes: PropTypes.object,
    placeholder: PropTypes.string,
    dropDown: PropTypes.bool,
    selectedList: PropTypes.array,
    availableList: PropTypes.array,
    selectType: PropTypes.string,
    displayValue: PropTypes.string,
    onKeyDown: PropTypes.func,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onRemoveItem: PropTypes.func,
    type: PropTypes.string,
    disableRemove: PropTypes.bool,
    level: PropTypes.string,
    tagTarget: PropTypes.bool,
    disabled: PropTypes.bool
};
export default withStyles((theme) => ({
    ...MultiSelectStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(MultiSelect);