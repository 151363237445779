import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import Circular from '../Charts/Circular.jsx';
import DatasetProfileStyles from './DatasetProfileStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import AttributeSelect from '../AttributeSelect/AttributeSelect.jsx';
import { getValue } from '../../helpers/appHelpers';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CurationCharts from '../Charts/CurationCharts.jsx';
import Loader from '../Loaders/Loader.jsx';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';

class DatasetCuration extends Component {

    constructor(props) {
        super(props);
        this.state = {
            attributes: [],
            curate: {},
            attributeStatistics: {},
            isLoading: true
        };
        this.containerRef = React.createRef();
    }

    componentDidMount() {
        this.getAttributes();
    }


    getAttributes() {
        const attributes = [];
        let selectedAttributeId = 0;
        for (const index in this.props.attributeList) {
            const attribute = this.props.attributeList[index];
            attributes.push({
                id: attribute.id ? attribute.id : 0,
                name: attribute.name ? attribute.name : "",
                isSelected: this.props.selectedAttribute === "" ? index === "0" : this.props.selectedAttribute === attribute.name,
                type: attribute.type
            });
            if ((this.props.selectedAttribute === "" && index === "0") || this.props.selectedAttribute === attribute.name) {
                selectedAttributeId = attribute.id;
            }
        }
        this.setState({ attributes: [...attributes] });
        this.getAttributeStatistics(selectedAttributeId);
        this.getCurationAnalysis(selectedAttributeId);
    }


    getAttributeStatistics = (attributeId) => {
        this.props.getAttributeStatistics(attributeId).then((response) => {
            if (response) {
                response.type = response.datatype ? response.datatype : "Text";
                response.fieldType = response.fieldtype ? response.fieldtype : "";
                this.setState({ attributeStatistics: { ...response } });
                const attributeKeys = Object.keys(this.props.properties);
                if (attributeKeys.indexOf(response.name) === -1) {
                    this.props.updateDatasetProperties({ ...this.props.properties, ...{ [response.name]: response } });
                }
            }
        });
    };


    getCurationAnalysis(attributeId) {
        this.setState({ isLoading: true });
        this.props.getCurationAnalysis(attributeId).then((response) => {
            if (response) {
                this.setState({ curate: { ...response } });
            }
            this.setState({ isLoading: false });
        });
    }


    onChangeAttribute(event) {
        if (!event.target.value) {
            return;
        }
        let selectedAttributeId = 0;
        const attributes = this.state.attributes.map((attribute) => {
            const attributeItem = attribute;
            attributeItem.isSelected = false;
            if (attributeItem.name === event.target.value) {
                attributeItem.isSelected = true;
                selectedAttributeId = attribute.id;
            }
            return attributeItem;
        });
        this.props.setSelectedAttribute(event.target.value);
        this.setState({ attributes: [...attributes], curate: {} }, () => { this.getCurationAnalysis(selectedAttributeId); this.getAttributeStatistics(selectedAttributeId); });
        this.containerRef.current.scrollTop = 0;
    }

    render() {
        const { classes, dq_score_threshold } = this.props;
        const selectedAttribute = this.state.attributeStatistics;
        return (
            <Grid className={classes.tabSection}>
                <Grid container>
                    <Grid item xs={12} className={classes.marginRight5}>
                        <Grid container alignItems="center" justify="space-between" direction="row" className={classNames(classes.card, classes.container)}>
                            <Grid item xs={3} className={classNames(classes.selectContainer, classes.CurationSelectAttribute)}>
                                <AttributeSelect
                                    label="Select Attribute"
                                    placeholder="Select Attribute"
                                    value={selectedAttribute.name ? selectedAttribute.name : ''}
                                    onChange={(event) => this.onChangeAttribute(event)}
                                    options={this.state.attributes}
                                    selectedValuePath="name"
                                    displayMemberPath="name"
                                    removeClass
                                    openSuggestionsOnClick
                                    name="Attribute"
                                    id="Attribute"
                                    fullWidth
                                />
                            </Grid>
                            {
                                this.props.config.profile &&
                                <Grid item xs={2}>
                                    <Grid className={classes.scoreContainer}>
                                        <Circular showPercentage showValue value={getValue(selectedAttribute.dqscore ? selectedAttribute.dqscore : 0)} size={55} dqScoreThreshold={dq_score_threshold} />
                                        <Grid>
                                            <Typography className={classes.dqscoreText}>
                                                {'DQScore'}
                                            </Typography>
                                            <Grid className={classes.curateScore}>
                                                <ArrowDropUpIcon className={classNames(classes.impactIcon, classes.curateIcon)} />
                                                <Typography className={classes.curateTxt}>
                                                    {`${getValue(selectedAttribute.impact_score ?? selectedAttribute.impact_score)}%`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            <Grid item xs={2}>
                                <Typography component="h5" variant="h5" className={classes.attributeAnalysisText}>
                                    {selectedAttribute.type ? selectedAttribute.type : 'Text'}
                                </Typography>
                                <Typography>
                                    Data Type
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography component="h5" variant="h5" className={classes.attributeAnalysisText}>
                                    {
                                        selectedAttribute.fieldType ? selectedAttribute.fieldType :
                                            "NA"
                                    }
                                </Typography>
                                <Typography>
                                    Field Type
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography component="h5" variant="h5" className={classes.attributeAnalysisText}>
                                    {selectedAttribute && selectedAttribute.total_records ? selectedAttribute.total_records : 0}
                                </Typography>
                                <Typography>
                                    Total Records
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography component="h5" variant="h5" className={classes.attributeAnalysisText}>
                                    {selectedAttribute.sensitivity ? selectedAttribute.sensitivity : 1}
                                </Typography>
                                <Typography>
                                    Sensitivity
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classNames(classes.tabProfileContainer, classes.paddingTop15)} ref={this.containerRef}>
                        <Grid item xs={12}>
                            {
                                (this.state.curate && Object.keys(this.state.curate).length) ?
                                    <CurationCharts datasetId={this.props.datasetId} attributeId={selectedAttribute.id} attribute={selectedAttribute.name} analysis={this.state.curate} /> :
                                    null
                            }
                        </Grid>
                    </Grid>
                    {this.state.isLoading && <Loader />}
                    {
                        this.state.curate && Object.keys(this.state.curate).length === 0 && !this.state.isLoading &&
                        <NoResultFound />
                    }
                </Grid>
            </Grid>
        );
    }
}

DatasetCuration.propTypes = {
    classes: PropTypes.object,
    datasetId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    attributeList: PropTypes.object,
    getCurationAnalysis: PropTypes.func,
    setSelectedAttribute: PropTypes.func,
    selectedAttribute: PropTypes.string,
    getAttributeStatistics: PropTypes.func,
    properties: PropTypes.object,
    updateDatasetProperties: PropTypes.func,
    config: PropTypes.object,
    dq_score_threshold: PropTypes.object
};

export default withStyles((theme) => ({
    ...DatasetProfileStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DatasetCuration);