import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Grid, withStyles, Button, Typography, IconButton, Switch, MenuItem, Avatar, TableContainer, Table, TableHead, TableCell, TableRow, TableBody } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Styles from '../../layouts/Styles.jsx';
import SettingComponentStyles from './SettingComponentStyles.jsx';
import TextBox from '../TextBox/TextBox.jsx';
import { getRoles, updateProfile, deleteUser } from '../../actions/settingActions';
import { getUsers, inviteUser } from '../../actions/accountActions';
import Loader from '../Loaders/Loader.jsx';
import AlertDialog from '../AlertDialog/AlertDialog.jsx';
import TooltipComponent from '../Tooltip/Tooltip.jsx';
import { getInitials, getRandomColor, returnImage } from '../../helpers/appHelpers';
import { appConstants } from '../../constants/appConstants';
import { successAlert } from '../../actions/alertActions.js';

const Users = (props) => {
    const { classes, theme, organizationId } = props;
    const dispatch = useDispatch();
    const [users, setUsers] = useState([]);
    const [isPageLoading, setPageLoading] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [roles, setRoles] = useState([]);
    const [userInfo, setUser] = useState({ "email": '', "role_id": '' });
    const [selectedUser, setSelectedUser] = useState(null);
    const userRole = useSelector(({ account }) => account.user.role);

    const getRolesData = useCallback(() => {
        dispatch(getRoles(organizationId)).then((response) => {
            if (response) {
                setRoles([...response]);
            }
        });
    }, [organizationId, dispatch]);

    const getUsersList = useCallback(() => {
        setPageLoading(true);
        dispatch(getUsers(organizationId)).then((response) => {
            setPageLoading(false);
            if (response) {
                setUsers([...response]);
            }
        });
    }, [dispatch, organizationId]);

    useEffect(() => {
        getRolesData();
        getUsersList();
    }, [getRolesData, getUsersList, organizationId]);

    const createUser = () => {
        setLoading(true);
        const model = {
            "organization_id": organizationId,
            "is_active": true,
            "username": userInfo.email,
            ...userInfo
        };
        dispatch(inviteUser(model)).then((response) => {
            if (response) {
                getUsersList();
            }
            setLoading(false);
        });
    };

    const copylink = (link) => {
        navigator.clipboard.writeText(link);
        dispatch(successAlert('Link Copied.'));
    };

    const onChange = (name, value) => {
        userInfo[name] = value;
        setUser({ ...userInfo });
    };

    const deleteUserInfo = () => {
        dispatch(deleteUser(selectedUser.id));
        const index = users.findIndex((user) => user.id === selectedUser.id);
        users.splice(index, 1);
        setUsers([...users]);
        setSelectedUser(null);
    };

    const onChangeUserInfo = (property, value, index) => {
        users[index][property] = value;
        setUsers([...users]);
        const model = {
            [property]: value
        };
        dispatch(updateProfile(model, users[index].id));
    };

    const splitName = (email) => {
        const name = email.split("@");
        return name[0];
    };

    return (
        <Grid className={classes.userContainer}>
            <ValidatorForm onSubmit={() => createUser()} className={classes.userAddContainer}>
                <Typography className={classes.userTitle}>
                    Add Users
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={3} className={classes.userRow}>
                        <TextBox
                            label="Enter Email ID"
                            value={userInfo.email}
                            name="email"
                            type="email"
                            fullWidth
                            validators={['required', 'isEmail']}
                            errorMessages={['Email is required', 'Email is not valid']}
                            onChange={(event) => onChange(event.target.name, event.target.value)}
                            disabled={isLoading}
                            className={classes.greyText}
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.userRow}>
                        <TextBox
                            label="Select an User Role"
                            value={userInfo.role_id}
                            name="role_id"
                            fullWidth
                            select
                            validators={['required']}
                            errorMessages={['Role is required']}
                            onChange={(event) => onChange(event.target.name, event.target.value)}
                            disabled={isLoading}
                            className={classes.greyText}
                            SelectProps={
                                {
                                    value: userInfo.role_id,
                                    onChange: (event) => onChange(event.target.name, event.target.value),
                                    MenuProps: {
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "center"
                                        },
                                        getContentAnchorEl: null
                                    }
                                    // IconComponent: () => DropDownIcon()
                                }
                            }
                        >
                            {
                                roles.map((role, index) =>
                                    <MenuItem key={index} value={role.id}>
                                        {role.name}
                                    </MenuItem>
                                )
                            }
                        </TextBox>
                    </Grid>
                    <Grid item xs={4} className={classes.userRow}>
                        <Button variant="contained" color="primary" type="submit" className={classes.actionButtons}>
                            Send Invite
                            {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                        </Button>
                    </Grid>
                </Grid>
            </ValidatorForm>
            <ValidatorForm onSubmit={() => { }} className={classes.userListContainer}>
                <Grid container>
                    <Grid item xs={12}>
                        <TableContainer className={classes.tableWrapperStyle}>
                            <Table className={classes.tableStyle}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="body1" className={classes.tableHeader}>
                                                Name
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1" className={classes.tableHeader}>
                                                Email
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1" className={classes.tableHeader}>
                                                Role
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1" className={classes.tableHeader} align="center">
                                                Status
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1" className={classes.tableHeader} align="center">
                                                Actions
                                            </Typography>
                                        </TableCell>
                                        {
                                            userRole.name === 'Admin' &&
                                            <TableCell>
                                                <Typography variant="body1" className={classes.tableHeader} align="center">
                                                    Activation
                                                </Typography>
                                            </TableCell>
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        users.map((user, index) =>
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <Grid item className={classes.referenceHeader1} style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                        <TooltipComponent title={user.name} arrow>
                                                            <Avatar
                                                                style={
                                                                    {
                                                                        backgroundImage: `url(${returnImage(appConstants.API_BASE_URL, user.avatar)})`,
                                                                        backgroundColor: getRandomColor(user.first_name !== "" ? `${user.first_name} ${user.last_name}` : user.email),
                                                                        marginRight: 8
                                                                    }
                                                                }>
                                                                {
                                                                    !user.avatar &&
                                                                    <span>
                                                                        {getInitials(user.first_name !== "" ? `${user.first_name} ${user.last_name}` : user.email)}
                                                                    </span>
                                                                }
                                                            </Avatar>
                                                        </TooltipComponent>
                                                        <Typography variant="body2">
                                                            {
                                                                (user.first_name === "" && user.last_name === "") ? splitName(user.email) :
                                                                    `${user.first_name} ${user.last_name}`
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell>
                                                    <Grid item className={classNames(classes.referenceHeader1, classes.emailSection)}>
                                                        <Typography variant="body2">
                                                            {user.email.toLowerCase()}
                                                        </Typography>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell>
                                                    <Grid item className={classNames(classes.referenceHeader1, classes.roleSection)}>
                                                        <TextBox
                                                            value={user.role_id ? user.role_id : user.role}
                                                            name="role_id"
                                                            fullWidth
                                                            select
                                                            validators={['required']}
                                                            errorMessages={['Role is required']}
                                                            disabled={userRole.name !== 'Admin'}
                                                            onChange={(event) => onChangeUserInfo(event.target.name, event.target.value, index)}
                                                            className={classNames(classes.inlinetxt, classes.referenceInput)}
                                                            SelectProps={
                                                                {
                                                                    value: user.role_id ? user.role_id : user.role,
                                                                    onChange: (event) => onChangeUserInfo(event.target.name, event.target.value, index),
                                                                    MenuProps: {
                                                                        anchorOrigin: {
                                                                            vertical: "bottom",
                                                                            horizontal: "center"
                                                                        },
                                                                        transformOrigin: {
                                                                            vertical: "top",
                                                                            horizontal: "center"
                                                                        },
                                                                        getContentAnchorEl: null
                                                                    }
                                                                }
                                                            }
                                                        >
                                                            {
                                                                roles.map((role, index) =>
                                                                    <MenuItem key={index} value={role.id}>
                                                                        {role.name}
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        </TextBox>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell>
                                                    <Grid item className={classNames(classes.referenceHeader1, classes.switchSection)} align="center">
                                                        <Switch color="secondary" size="small" checked={user.is_active} disabled={userRole.name !== 'Admin'} onChange={(event) => onChangeUserInfo("is_active", !user.is_active, index)} />
                                                    </Grid>
                                                </TableCell>
                                                <TableCell>
                                                    <Grid item className={classNames(classes.referenceHeader1, user.isLoading ? classes.userLoading : '')} align="center" style={{ position: 'relative' }}>
                                                        <TooltipComponent title="Delete user" arrow>
                                                            <IconButton className={classes.userDeleteIcon} disabled={userRole.name !== 'Admin'} onClick={() => setSelectedUser(user)}>
                                                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="15.879" height="18" viewBox="0 0 15.879 18">
                                                    <path fill={theme.palette.primary.main} d="M126.181,166H114.543a.449.449,0,0,0-.438.5l1.194,10.447a2.282,2.282,0,0,0,2.267,2.025h5.794a2.272,2.272,0,0,0,2.27-2.066l.991-10.411a.453.453,0,0,0-.113-.344A.446.446,0,0,0,126.181,166Zm-1.431,10.816a1.4,1.4,0,0,1-1.392,1.272h-5.794a1.4,1.4,0,0,1-1.39-1.246l-1.138-9.96H125.7Z" transform="translate(-112.422 -160.971)" />
                                                    <path fill={theme.palette.primary.main} d="M91.473,54.206H86.8v-.842A1.376,1.376,0,0,0,85.424,52h-2.9a1.376,1.376,0,0,0-1.374,1.364v.842H76.476a.441.441,0,0,0,0,.882h15a.441.441,0,0,0,0-.882Zm-5.557,0H82.033v-.842a.493.493,0,0,1,.492-.481h2.9a.493.493,0,0,1,.492.481Z" transform="translate(-76.035 -52)" />
                                                </svg> */}
                                                                <svg version="1.1" id="Layer_1" width="22" height="22" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 32">
                                                                    <g id="Delete">
                                                                        <path fill={theme.palette.primary.main}
                                                                            stroke={theme.palette.primary.main}
                                                                            strokeWidth="0.92"
                                                                            strokeMiterlimit="10"
                                                                            d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                                                                        <path fill={theme.palette.primary.main}
                                                                            stroke={theme.palette.primary.main}
                                                                            strokeWidth="0.92"
                                                                            strokeMiterlimit="10"
                                                                            d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                                                    </g>
                                                                </svg>
                                                            </IconButton>
                                                        </TooltipComponent>
                                                    </Grid>
                                                </TableCell>
                                                {
                                                    user.activation_link && userRole.name === 'Admin' &&
                                                    <TableCell>
                                                        <Grid item className={classNames(classes.referenceHeader1, classes.switchSection)} align="center">
                                                            <IconButton className={classes.nopadding} onClick={() => copylink(user.activation_link)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16.002" viewBox="0 0 16 16.002">
                                                                    <g id="link_1_" data-name="link (1)" transform="translate(-0.039 0)">
                                                                        <g id="Group_161506" data-name="Group 161506" transform="translate(0.039 5.144)">
                                                                        <g id="Group_161505" data-name="Group 161505" transform="translate(0)">
                                                                            <path id="Path_3040" data-name="Path 3040" d="M9.8,165.707a3.629,3.629,0,0,0-.627-.5,3.734,3.734,0,0,0-4.653.5L1.13,169.1a3.733,3.733,0,0,0,5.277,5.283l2.8-2.8a.267.267,0,0,0-.189-.456H8.911A4.488,4.488,0,0,1,7.2,170.8a.267.267,0,0,0-.291.059L4.9,172.872a1.6,1.6,0,0,1-2.264-2.264l3.405-3.4a1.6,1.6,0,0,1,2.261,0,1.093,1.093,0,0,0,1.5,0,1.056,1.056,0,0,0,0-1.5Z" transform="translate(-0.039 -164.614)" fill="#f38080"/>
                                                                        </g>
                                                                        </g>
                                                                        <g id="Group_161508" data-name="Group 161508" transform="translate(5.96 0)">
                                                                        <g id="Group_161507" data-name="Group 161507" transform="translate(0 0)">
                                                                            <path id="Path_3041" data-name="Path 3041" d="M198.488,1.094a3.733,3.733,0,0,0-5.28,0l-2.8,2.795a.268.268,0,0,0,.195.456h.1a4.48,4.48,0,0,1,1.7.333.267.267,0,0,0,.291-.059l2.008-2.005a1.6,1.6,0,0,1,2.264,2.264l-2.5,2.5-.021.024-.875.869a1.6,1.6,0,0,1-2.261,0,1.093,1.093,0,0,0-1.5,0,1.063,1.063,0,0,0,0,1.5,3.7,3.7,0,0,0,1.067.747c.056.027.112.048.168.072s.115.043.171.064.115.04.171.056l.157.043c.107.027.213.048.323.067a3.712,3.712,0,0,0,.4.037h.2l.16-.019c.059,0,.12-.016.189-.016h.091l.184-.027.085-.016.155-.032h.029a3.733,3.733,0,0,0,1.731-.981l3.395-3.395A3.733,3.733,0,0,0,198.488,1.094Z" transform="translate(-189.502 0)" fill="#f38080"/>
                                                                        </g>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </IconButton>
                                                        </Grid>
                                                    </TableCell>
                                                }
                                                {
                                                    !user.activation_link && userRole.name === 'Admin' &&
                                                    <TableCell />
                                                }
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    {isPageLoading && <Loader />}
                </Grid>
            </ValidatorForm>
            {/* {isPageLoading && <Loader />} */}
            {
                Boolean(selectedUser) &&
                <AlertDialog title="Delete User"
                    message={`Are you sure you want to delete the user ${selectedUser && selectedUser.first_name !== "" ? `${selectedUser.first_name} ${selectedUser.last_name}` : selectedUser.email}?`}
                    okButtonText="OK"
                    cancelButtonText="Cancel"
                    show={Boolean(selectedUser)}
                    onClickOK={() => deleteUserInfo(selectedUser)}
                    onClickCancel={() => setSelectedUser(null)} />
            }
        </Grid >
    );

};

Users.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    organizationId: PropTypes.number
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(Users);