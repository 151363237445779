import React, { useEffect, useState, useCallback } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as d3 from 'd3';
import Styles from '../../layouts/Styles.jsx';
import ChartContainer from '../ChartContainer/ChartContainer.jsx';
import ChartStyles from './ChartStyles.jsx';
import { appConstants } from '../../constants/appConstants';
import { wrapText } from '../../helpers/appHelpers';
import { createRule, getFieldType, preparePatternRuleGroup } from '../RuleBuilder/QueryBuilderUtil.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { learningRule } from '../../actions/scheduleActions';
import ProfileChartFilterOperators from '../../constants/chartOperators.js';

const PatternChart = (props) => {
    const { attribute, profileData, classes, theme, onFilter, tabIndex, config, profileRuleEnable, isActive, attributeChange, datasetId, invalidQuery } = props;
    const [menuType, setMenuType] = useState(appConstants.rangeFilterOptions[0]);
    const [chartData, setChartData] = useState({});
    const [patternData, setPatternData] = useState({});
    const [chartType, setChartType] = useState('Bar Plot');
    const [edit, setEdit] = useState(false);
    const [edited, setEdited] = useState(false);
    const datasource = useSelector(({ datasource }) => datasource.datasource);
    const properties = useSelector(({ dataset }) => dataset.properties);
    const dispatch = useDispatch();

    const loadChartData = useCallback((analysisData) => {
        setChartData(JSON.parse(JSON.stringify(analysisData)));
        setPatternData(JSON.parse(JSON.stringify(analysisData)));
    }, []);

    useEffect(() => {
        if (attributeChange) {
            const analysisData = profileData.Pattern;
            loadChartData({ ...analysisData });
        }
    }, [attribute, attributeChange, loadChartData, profileData]);

    const onChangeMenu = (value) => {
        setEdited(true);
        setMenuType(value);
    };

    const onFilterChange = useCallback((barValue) => {
        const validPatterns = [];
        for (const pattern of chartData.input_params) {
            if (!pattern.isvalid) {
                continue;
            }
            if (pattern.pattern) {
                validPatterns.push(`(${pattern.pattern})`);
            }
        }
        const inputParams = {
            chartType: appConstants.charts.pattern.type,
            chartName: appConstants.charts.pattern.name,
            patterns: chartData?.input_params,
            filterOptions: true,
            ruleName: 'Pattern',
            defaultQuery: ''
        };

        if (barValue) {
            const ruleParams = {
                defaultField: attribute.name,
                defaultFieldType: getFieldType(attribute.type),
                attributeType: attribute.type,
                connectionType: datasource?.type,
                isScan: datasource?.scan
            };
            const rule = createRule(ruleParams);
            const hasNot = Boolean(barValue?.not ?? false);
            const operators = ProfileChartFilterOperators.Pattern;
            let pattern = barValue.pattern;
            if (typeof (pattern) === 'string') {
                pattern = pattern.replace(/\\\\\\/g, '\\');
            } else {
                if (datasource?.scan) {
                    inputParams.multi = true;
                    const attributeInfo = {
                        attributeName: attribute.name,
                        fieldType: getFieldType(attribute.type),
                        attributeType: attribute.type
                    };
                    const ruleGroup = preparePatternRuleGroup(pattern, attributeInfo);
                    rule.ruleGroup = { ...ruleGroup };
                }
            }
            inputParams.data = barValue;
            let selectedOperator = barValue.key === "Others" ? operators[1] : operators[0];
            if (barValue.key === "Others" && pattern.startsWith("(^([+-]{1})?(?:90(?:(?:")) {
                selectedOperator = operators[2];
            }
            if (hasNot) {
                selectedOperator = operators[1];
            }
            rule.operator = { ...selectedOperator };
            rule.value = pattern;
            /*
             * const selectedPattern = inputParams.patterns.find((inputPattern) => inputPattern.name.toLowerCase() === barValue.key.toLowerCase());
             * rule.is_lower = Boolean(selectedPattern);
             */
            rule.patternType = barValue.key;
            inputParams.rule = rule;
            inputParams.isOthers = (barValue.key === "Others");
            inputParams.filterType = barValue.isvalid ? appConstants.QueryFilterTypes[0] : appConstants.QueryFilterTypes[1];
        } else {
            inputParams.filterOptions = false;
            if (invalidQuery) {
                inputParams.defaultQuery = `select * from <table_name> where ${invalidQuery}`;
            } else {
                let validPattern = validPatterns.join('|');
                validPattern = validPattern.replace('\\\\\\', '\\');
                const defaultQuery = (validPatterns && validPatterns.length > 0) ? `trim(${attribute.name}.null_format) not rlike '(${validPattern})'` : '';
                inputParams.defaultQuery = `select * from <table_name> where ${defaultQuery}`;
            }

        }

        onFilter(inputParams);
    }, [attribute.name, attribute.type, chartData.input_params, datasource?.scan, datasource?.type, invalidQuery, onFilter]);

    const switchChart = (type) => {
        setEdited(true);
        setChartType(type);
    };

    const interactVisual = useCallback((data, type) => {
        const analysis = JSON.parse(JSON.stringify(chartData));
        const index = analysis.input_params.findIndex((pattern) => JSON.stringify(pattern) === JSON.stringify(data));
        if (index !== -1) {
            analysis.input_params[index].isvalid = type !== "accept";
            setChartData(JSON.parse(JSON.stringify(analysis)));
            // setInteractionData({ ...chartData });
            setEdited(true);
        }
    }, [chartData]);

    const BarChart = useCallback((selectedPropData) => {
        const barWidth = 20;
        const barOpacity = 0.5;
        const barHighlightOpacity = 0.75;
        const yMax = d3.max(selectedPropData, (d) => { return d.value; });
        const calcWidth = Math.round(yMax + (Math.round(yMax / 10))).toString();
        // set the dimensions and margins of the graph
        const margin = { top: 40, right: 20, bottom: 50, left: calcWidth ? (calcWidth.length + 1) * 10 : 40 };
        let width = 960 - margin.left - margin.right;
        const height = 300;
        d3.select('.pattern-chart > *').remove();
        d3.select(".pattern-chart > svg").remove();
        d3.select(".pattern-chart > .pattern-legend").remove();
        const svg = d3.select(".pattern-chart").append("svg")
            .attr("width", "100%")
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("width", (d, i, nodes) => {
                width = nodes[i].parentNode.clientWidth;
                return width;
            })
            .attr("height", height + margin.top + margin.bottom)
            .attr("transform",
                "translate(" + (margin.left + 20) + "," + (margin.top / 2) + ")");

        const x = d3.scaleBand()
            .range([0, width - (margin.left + margin.right)])
            .padding(0.1);
        const y = d3.scaleLinear()
            .domain([0, yMax + (Math.round(yMax / 10))])
            .range([height, 0])
            .nice();

        x.domain(selectedPropData.map((d) => { return d.key; }));

        // Tooltip div
        d3.select(".pattern-chart > .tooltip").remove();
        const div = d3.select(".pattern-chart").append("div")
            .attr("class", "tooltip")
            .style("opacity", 0)
            .style("zIndex", 1);

        const minBarHeightThreshold = 2;
        svg.selectAll(".bar")
            .data(selectedPropData)
            .enter().append("rect")
            .attr("x", (d) => { return x(d.key) + ((x.bandwidth() / 2) - (barWidth / 2)); })
            .attr("y", (d) => { return height; })
            .attr("width", Math.min(x.bandwidth(), barWidth))
            .attr("fill", (d) => {
                return d.isvalid ? theme.palette.chartColors.valid : theme.palette.chartColors.inValid;
            })
            .attr("class", (d) => {
                const color = d.isvalid ? 'accepted' : 'not-accepted';
                return "bar " + color;
            })
            .style("opacity", barOpacity)
            .on('mouseover', (d, i, nodes) => {
                d3.select(nodes[i]).style("opacity", barHighlightOpacity);

                // Show Tooltip
                const rect = nodes[i].getBBox();
                div.transition()
                    .duration(200)
                    .style("opacity", 0.9);

                let tooltipText = `Pattern : ${d.pattern} <br/> Label : ${d.key} <br/> Percentage : ${d.value}% <br/> Count : ${d.count}`;
                if (typeof (d.pattern) === 'object') {
                    tooltipText = `Label : ${d.key} <br/> Percentage : ${d.value}% <br/> Count : ${d.count}`;
                }
                div.html(tooltipText)
                    .style("left", (rect.x + (barWidth / 2)) + "px")
                    .style("top", (rect.y - ((barWidth * 2) + barWidth)) + "px");
            })
            .on('mouseout', (d, i, nodes) => {
                d3.select(nodes[i]).style("opacity", barOpacity);
                div.transition()
                    .duration(500)
                    .style("opacity", 0);
            })
            .on('click', (d, i, nodes) => {
                onFilterChange(d);
            })
            .attr("height", 0)
            .transition()
            .duration(500)
            .delay((d, i) => {
                return i * 100;
            })
            .attr("y", (d, i) => {
                return height - y(d.value) > minBarHeightThreshold ? y(d.value) : y(d.value) - minBarHeightThreshold;
            })
            .attr("height", (d, i) => {
                return height - y(d.value) > minBarHeightThreshold ? height - y(d.value) : height - y(d.value) + minBarHeightThreshold;
            });
        svg.append("g")
            .attr("transform", "translate(0," + height + ")")
            .attr("color", theme.palette.chartColors.axis)
            .attr('class', 'axis-x')
            .call(d3.axisBottom(x));

        // text label for the x axis
        svg.append("text")
            .attr("transform",
                "translate(" + (width / 2) + " ," +
                (height + margin.top + 20) + ")")
            .style("text-anchor", "middle")
            .text("Pattern");

        // add the y Axis
        svg.append("g")
            .attr("color", theme.palette.chartColors.axis)
            .attr('class', 'axis-y')
            .call(d3.axisLeft(y).ticks(10));


        // text label for the y axis
        svg.append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 0 - (margin.left + 20))
            .attr("x", 0 - (height / 2))
            .attr("dy", "1em")
            .style("text-anchor", "middle")
            .text("Percentage");

        if (edit) {
            const accepted = `<svg  width="15px" height="15px" viewBox="0 0 20 20"><g><path style=" stroke:none;fill-rule:nonzero;fill:${theme.palette.chartColors.valid};fill-opacity:1;" d="M 19.226562 10 C 19.226562 15.09375 15.09375 19.226562 10 19.226562 C 4.90625 19.226562 0.773438 15.09375 0.773438 10 C 0.773438 4.90625 4.90625 0.773438 10 0.773438 C 15.09375 0.773438 19.226562 4.90625 19.226562 10 Z M 19.226562 10 "/><path style=" stroke:none;fill-rule:nonzero;fill:#525252;fill-opacity:1;" d="M 10 20 C 4.488281 20 0 15.515625 0 10 C 0 4.488281 4.488281 0 10 0 C 15.515625 0 20 4.488281 20 10 C 20 15.515625 15.515625 20 10 20 Z M 10 1.550781 C 5.339844 1.550781 1.550781 5.339844 1.550781 10 C 1.550781 14.660156 5.339844 18.449219 10 18.449219 C 14.660156 18.449219 18.449219 14.660156 18.449219 10 C 18.449219 5.339844 14.660156 1.550781 10 1.550781 Z M 10 1.550781 "/><path style=" stroke:none;fill-rule:nonzero;fill:#fff;fill-opacity:1;" d="M 8.792969 13.679688 C 8.585938 13.679688 8.386719 13.597656 8.238281 13.449219 L 5.382812 10.542969 C 5.082031 10.238281 5.085938 9.75 5.390625 9.449219 C 5.695312 9.148438 6.1875 9.152344 6.484375 9.457031 L 8.769531 11.777344 L 13.492188 6.574219 C 13.78125 6.257812 14.273438 6.234375 14.585938 6.523438 C 14.902344 6.808594 14.925781 7.300781 14.640625 7.617188 L 9.363281 13.425781 C 9.222656 13.585938 9.019531 13.675781 8.808594 13.679688 C 8.800781 13.679688 8.796875 13.679688 8.792969 13.679688 Z M 8.792969 13.679688 "/></g></svg>`;
            const unaccepted = `<svg width="15px" height="15px" viewBox="0 0 15 15"><g><path style=" stroke:none;fill-rule:nonzero;fill:${theme.palette.chartColors.inValid};fill-opacity:1;" d="M 7.5 0 C 3.363281 0 0 3.363281 0 7.5 C 0 11.636719 3.363281 15 7.5 15 C 11.636719 15 15 11.636719 15 7.5 C 15 3.363281 11.636719 0 7.5 0 Z M 10.535156 9.648438 C 10.65625 9.773438 10.65625 9.96875 10.535156 10.089844 L 10.089844 10.535156 C 9.96875 10.65625 9.773438 10.65625 9.648438 10.535156 L 7.5 8.382812 L 5.351562 10.535156 C 5.226562 10.65625 5.03125 10.65625 4.910156 10.535156 L 4.464844 10.089844 C 4.34375 9.96875 4.34375 9.773438 4.464844 9.648438 L 6.617188 7.5 L 4.464844 5.351562 C 4.34375 5.226562 4.34375 5.03125 4.464844 4.910156 L 4.910156 4.464844 C 5.03125 4.34375 5.226562 4.34375 5.351562 4.464844 L 7.5 6.617188 L 9.648438 4.464844 C 9.773438 4.34375 9.96875 4.34375 10.089844 4.464844 L 10.535156 4.910156 C 10.65625 5.03125 10.65625 5.226562 10.535156 5.351562 L 8.382812 7.5 Z M 10.535156 9.648438 "/></g></svg>`;
            svg.selectAll('.axis-x .tick').append('g')
                .attr('height', '15px')
                .attr('width', '15px')
                .attr("transform", "translate(-5,25)")
                .html((d, index, nodes) => {
                    const checkPatternValid = selectedPropData.some((data) => data.key === d && data.isvalid);
                    d3.select(nodes[index]).attr('class', `pattern${index} ${checkPatternValid}`);
                    d3.select(nodes[index]).raise();
                    return checkPatternValid ? unaccepted : accepted;
                }).on('click', (d) => {
                    const checkPatternValid = selectedPropData.some((data) => data.key === d && data.isvalid);
                    const patternInput = selectedPropData.find((p) => p.key === d);
                    interactVisual(patternInput, checkPatternValid ? "accept" : "non_accept");
                });
        }

        svg.selectAll('.tick').selectAll('text').attr('class', 'tick-text');
        svg.selectAll('.axis-x .tick-text').text("").append('tspan').text((d) => { return d; }).each((d, i, nodes) => {
            const textElement = d3.select(nodes[i]);
            wrapText(textElement, x.bandwidth());
        });

        const lines = ["valid", 'invalid'];
        const legendGap = 150;

        const legendX = lines.length > 1 ? (width / 1.1) - 210 : (width / 2) - (legendGap / 2);
        const legendGroup = svg.append("g")
            .attr("class", "legends")
            .attr("transform", "translate(" + (legendX - 50) + "," + (height + margin.top + 15) + ")");

        // Legend rectangle
        legendGroup.selectAll(".legend-group")
            .data(lines)
            .enter()
            .append("g")
            .attr("class", "legend-group")
            .style("opacity", 1)
            .on("mouseover", (d, i, nodes) => {
                d3.select(nodes[i]).style("opacity", barHighlightOpacity);
                const selectedBar = (d === "valid") ? "accepted" : "not-accepted";
                d3.selectAll(`.pattern-chart .bar.${selectedBar}`).style("opacity", barHighlightOpacity);
            })
            .on("mouseout", (_, i, nodes) => {
                d3.select(nodes[i]).style("opacity", 1);
                d3.selectAll(`.bar`).style("opacity", barOpacity);
            })
            .append("rect")
            .attr("class", "legend-rect")
            .attr("width", 14)
            .attr("height", 10)
            .attr('x', (_, index) => { return (index * legendGap); })
            .style("fill", (_, index) => { return index === 0 ? theme.palette.chartColors.valid : theme.palette.chartColors.inValid; });


        // Legend label
        legendGroup.selectAll(".legend-group")
            .append("text")
            .attr("class", "legend-text")
            .attr("x", (_, index) => { return index * legendGap + 20; })
            .attr("y", 6)
            .text((line, index) => {
                let percent = line === "valid" ? chartData.valid_percentage : chartData.invalid_percentage;
                if (Math.round(percent) !== percent && percent) {
                    percent = percent.toFixed(2);
                }
                return `${line} (${percent}%)`;
            })
            .attr("alignment-baseline", "middle");

    }, [chartData, edit, interactVisual, onFilterChange, theme.palette.chartColors.axis, theme.palette.chartColors.inValid, theme.palette.chartColors.valid]);


    const circularBrPlot = useCallback((selectedPropData) => {
        const margin = { top: 100, right: 0, bottom: 0, left: 20 };
        const width = 460;
        const height = 400;
        d3.select(".pattern-chart > *").remove();
        d3.select(".pattern-chart > svg").remove();
        d3.select(".pattern-chart > .pattern-legend").remove();
        const svg = d3.select(".pattern-chart")
            .append("svg")
            .style("margin", '0 auto')
            .attr("width", width * 2)
            .attr("height", height + margin.top + margin.top)
            .append("g")
            .attr("transform", "translate(" + (width - margin.left) + "," + ((height / 2) + 80) + ")");
        const innerRadius = selectedPropData.length === 1 ? 60 : 90;
        const outerRadius = Math.min(width, height) / 2;
        const x = d3.scaleBand()
            .range([0, 2 * Math.PI])
            .align(0)
            .domain(selectedPropData.map((d) => { return d.key; }));
        const y = d3.scaleLinear()
            .range([innerRadius, outerRadius])
            .domain([0, 100]);
        d3.select(".pattern-chart > .tooltip").remove();
        const div = d3.select(".pattern-chart").append("div")
            .attr("class", "tooltip")
            .style("opacity", 0);
        svg.append("g")
            .selectAll("path")
            .data(selectedPropData)
            .enter()
            .append("path")
            .attr("fill", (d) => {
                return d.isvalid ? theme.palette.chartColors.valid : theme.palette.chartColors.inValid;
            })
            .on('click', (d, i, nodes) => {
                onFilterChange(d);
            })
            .on('mouseover',
                (d) => {
                    let x = 0;
                    let y = 0;
                    div.attr("transform", (_, i, nodes) => {
                        const mouseCoords = d3.mouse(nodes[i].parentNode);
                        let xCo = 0;
                        let yCo = 0;
                        if (mouseCoords[0] + 10 >= width * 0.80) {
                            xCo = mouseCoords[0] - parseFloat(div
                                .attr("width"));
                            xCo = isNaN(xCo) ? mouseCoords[0] : xCo;
                            yCo = mouseCoords[1] + 10;
                        } else {
                            xCo = mouseCoords[0] + 10;
                            yCo = mouseCoords[1];
                        }
                        x = xCo;
                        y = yCo;
                    });
                    div.transition()
                        .duration(200)
                        .style("opacity", 0.9);
                    div.html(`Pattern : ${d.pattern} <br/> Label : ${d.key} <br/> Percentage : ${d.value}% <br/> Count : ${d.count}`)
                        .style("left", x + "px")
                        .style("top", y + "px");
                })
            .on('mouseout', (d, i, nodes) => {
                div.transition()
                    .duration(500)
                    .style("opacity", 0);
            })
            .attr("d", d3.arc()
                .innerRadius(innerRadius)
                .outerRadius((d) => { return y(d.value); })
                .startAngle((d) => { return x(d.key); })
                .endAngle((d) => { return x(d.key) + x.bandwidth(); })
                .padAngle(0.2)
                .padRadius(innerRadius));

        svg.append("g")
            .selectAll("g")
            .data(selectedPropData)
            .enter()
            .append("g")
            .attr("text-anchor", (d) => { return (x(d.key) + x.bandwidth() / 2 + Math.PI) % (2 * Math.PI) < Math.PI ? "end" : "start"; })
            .attr("transform", (d) => { return `rotate(${(x(d.key) + x.bandwidth() / 2) * 180 / Math.PI - 90})translate(${y(d.value) + 10},0)`; })
            .append("text")
            .text((d) => {
                if (d.key.length >= 10) {
                    const text = (d.key.slice(0, 10) + "...");
                    return (`${text} (${d.count})`);
                }
                return (`${d.key} (${d.count})`);
            })
            .attr("transform", (d) => { return (x(d.key) + x.bandwidth() / 2 + Math.PI) % (2 * Math.PI) < Math.PI ? "rotate(180)" : "rotate(0)"; })
            .style("font-size", "11px")
            .attr("alignment-baseline", "middle")
            .on('mouseover', (d, i, nodes) => {
                d3.select(nodes[i]).style("opacity", 0.4);
                // Show Tooltip
                div.transition()
                    .duration(200)
                    .style("opacity", 0.9);
                let x = 0;
                let y = 0;
                div.attr("transform", (_, i, nodes) => {
                    const mouseCoords = d3.mouse(nodes[i].parentNode);
                    let xCo = 0;
                    let yCo = 0;
                    if (mouseCoords[0] + 10 >= width * 0.80) {
                        xCo = mouseCoords[0] - parseFloat(div
                            .attr("width"));
                        yCo = mouseCoords[1] + 10;
                    } else {
                        xCo = mouseCoords[0] + 10;
                        yCo = mouseCoords[1];
                    }
                    x = isNaN(xCo) ? mouseCoords[0] : xCo;
                    y = isNaN(yCo) ? mouseCoords[1] : yCo;
                });
                div.html(`${d.key} (${d.count})`)
                    .style("left", x + "px")
                    .style("top", y + "px");
            })
            .on('mouseout', (d, i, nodes) => {
                d3.select(nodes[i]).style("opacity", 1);
                div.transition()
                    .duration(500)
                    .style("opacity", 0);
            });

        const legendGroup = d3.select('.pattern-chart').append("div")
            .attr("class", "pattern-legend");

        // Legend rectangle
        legendGroup.selectAll(".pattern-legendgroup")
            .data(['valid', 'invalid'])
            .enter()
            .append("div")
            .attr("class", "pattern-legendgroup")
            .append("div")
            .attr("class", (d) => {
                return d === "valid" ? 'lengend-valid' : 'lengend-invalid';
            });


        // Legend label
        legendGroup.selectAll(".pattern-legendgroup")
            .append("span")
            .text((line) => {
                let percent = line === "valid" ? chartData.valid_percentage : chartData.invalid_percentage;
                if (Math.round(percent) !== percent) {
                    percent = percent.toFixed(2);
                }
                return `${line} (${percent}%)`;
            });

    }, [chartData, onFilterChange, theme.palette.chartColors.inValid, theme.palette.chartColors.valid]);


    const plotChart = useCallback((selectedPropData) => {
        if (chartType === "Circular Bar Plot") {
            circularBrPlot(selectedPropData);
        } else {
            BarChart(selectedPropData);
        }
    }, [BarChart, chartType, circularBrPlot]);

    const discardEdit = useCallback(() => {
        setEdited(false);
    }, []);

    useEffect(() => {
        if (Object.keys(chartData).length !== 0 && (attributeChange || edited)) {
            const selectionRange = menuType === 'Top 10' ? 'topN' : 'bottomN';
            let patternData = [];
            if (chartData.input_params) {
                patternData = chartData.input_params.sort((a, b) => {
                    if (a.count > b.count) {
                        return selectionRange === "topN" ? -1 : 1;
                      }
                      if (a.count < b.count) {
                        return selectionRange === "topN" ? 1 : -1;
                      }
                      return 0;
                });
                patternData = patternData.slice(0, 10);
            }
            for (const pattern of patternData) {
                pattern.key = pattern.name;
                pattern.value = (pattern.count / chartData.total_records_processed) * 100;
                if (datasource?.type?.toLowerCase() === 'sql' && datasource?.scan) {
                    pattern.pattern = pattern.sqlPattern;
                }
            }
            patternData = patternData.filter((p) => p.count > 0);
            plotChart(patternData);
            if (edited) {
                discardEdit();
            }
        }
    }, [attributeChange, chartData, discardEdit, edited, menuType, plotChart, tabIndex, datasource]);

    const onEdit = (type) => {
        setEdited(true);
        if (type === "edit") {
            setEdit(true);
        } else {
            setEdit(false);
            if (type === "discard") {
                setChartData({ ...patternData });
            } else {
                learning();
            }
        }

    };

    const onRuleChange = (value) => {
        profileRuleEnable(appConstants.charts.pattern.type, value);
    };

    const learning = useCallback(() => {
        const patterns = chartData.input_params;
        const interactPattern = properties[attribute.name] ? properties[attribute.name].patterns : [];
        for (const data of patterns) {
            const index = interactPattern.findIndex((d) => d.pattern === data.pattern);
            if (index !== -1) {
                interactPattern[index].isvalid = data.isvalid;
            }
        }
        const model = {
            "attribute": attribute.name,
            "property": {
                "patterns": interactPattern
            },
            "type": "Profile"
        };
        dispatch(learningRule(attribute.id, datasetId, model));

    }, [attribute.name, datasetId, dispatch, properties, attribute.id, chartData]);

    return (
        <ChartContainer
            menuProperties={
                [
                    {
                        options: ["Circular Bar Plot", "Bar Plot"],
                        onChange: switchChart,
                        value: chartType
                    },
                    {
                        options: appConstants.rangeFilterOptions,
                        onChange: onChangeMenu,
                        value: menuType
                    }
                ]
            }
            editProperties={
                {
                    edited: edit,
                    edit: (chartType !== "Circular Bar Plot" && config.learning),
                    onEdit: onEdit
                }
            }
            filterProperties={
                {
                    enableFilter: config.query,
                    onFilter: onFilterChange
                }
            }
            ruleProperties={
                {
                    edit: true,
                    onRule: onRuleChange,
                    rule: isActive
                }
            }
            id={appConstants.charts.pattern.id}
            title={appConstants.charts.pattern.name}
            chartData={chartData} >
            <Grid container className={classNames(classes.chartStyles, "pattern-chart")} />
        </ChartContainer >
    );
};

PatternChart.propTypes = {
    classes: PropTypes.object,
    attribute: PropTypes.object,
    profileData: PropTypes.object,
    theme: PropTypes.object,
    tabIndex: PropTypes.number,
    onFilter: PropTypes.func,
    config: PropTypes.object,
    profileRuleEnable: PropTypes.func,
    isActive: PropTypes.bool,
    attributeChange: PropTypes.bool,
    datasetId: PropTypes.string,
    invalidQuery: PropTypes.string
};

export default withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(PatternChart);