import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';

import { createComment, getComments, deleteComment, updateComment } from '../../actions/notificationActions';
import { getSourceRating } from '../../actions/datasourceActions';
import CommentsList from './CommentsList.jsx';
import Styles from '../../layouts/Styles.jsx';
import CommentStyles from './CommentStyles.jsx';
import AddCommentBox from './AddCommentBox.jsx';

const Comments = (props) => {
    const { classes, type, updateAuditLog, datasourceId, datasetId, itemId, attributeId, attributeName, updateRating } = props;
    const dispatch = useDispatch();
    const user = useSelector(({ account }) => account.user);
    const [comment, setComment] = useState({ comment: '', rating: 0 });
    const [comments, setComments] = useState([]);

    const scrollToBottom = () => {
        const commentsContainer = document.getElementById('commentList');
        if (commentsContainer) {
            commentsContainer.scrollTop = commentsContainer.scrollHeight;
        }
    };

    useEffect(() => {
        if (comments.length > 0) {
            scrollToBottom();
        }
    }, [comments]);

    const onChange = (property, value) => {
        comment[property] = value;
        setComment({ ...comment });
    };

    const getRating = () => {
        dispatch(getSourceRating(datasourceId, type === "dataset" || type === "tableau_item" || type === "attribute" ? datasetId : 0, type === "tableau_item" ? itemId : 0, type === "attribute" ? attributeId : 0));
    };
    const addComment = () => {
        const emptyCheck = comment.comment ? comment.comment.replace(/(?:\r\n|\r|\n|\s)/g, '') : "";
        if (emptyCheck === '') {
            setComment({ comment: '', rating: 0 });
            return;
        }
        if (comment.comment !== "" && emptyCheck !== "") {
            const model = {
                "source_id": datasourceId,
                "dataset_id": type === "dataset" || type === "tableau_item" || type === "attribute" ? datasetId : null,
                "item_id": type === "tableau_item" ? itemId : null,
                "attribute_id": type === "attribute" ? attributeId : null,
                "attribute_name": type === "attribute" && attributeName ? attributeName : "",
                "comment": comment.comment,
                "rating": comment.rating,
                "isPinned": false
            };
            setComment({ comment: '', rating: 0 });
            const createDate = new Date();
            dispatch(createComment(model)).then((response) => {
                if (response) {
                    response.created_date = createDate;
                    comments.push({ ...response });
                    setComments([...comments]);
                    if (updateAuditLog) {
                        updateAuditLog("add comment");
                    }
                    if (updateRating) {
                        updateRating();
                    } else {
                        getRating();
                    }
                }
            });
        }
    };

    const deleteCommentList = (commentId) => {
        const index = comments.findIndex((comment) => comment.id === commentId);
        if (index !== -1) {
            comments.splice(index, 1);
            setComments([...comments]);
        }
        dispatch(deleteComment(commentId)).then(() => {
            if (updateAuditLog) {
                updateAuditLog("delete comment");
            }
            if (updateRating) {
                updateRating();
            } else {
                getRating();
            }
        });
    };

    const updateCommentList = (comment, commentId) => {
        const index = comments.findIndex((comment) => comment.id === commentId);
        if (index !== -1) {
            comments[index].is_edited = true;
            comments[index].comment = comment.comment;
            comments[index].rating = comment.rating;
            comments[index].isPinned = comment.isPinned;
            setComments([...comments]);
        }
        const model = {
            "comment_id": commentId,
            "comment": comment.comment,
            "rating": comment.rating,
            "isPinned": comment.isPinned
        };
        dispatch(updateComment(commentId, model)).then(() => {
            if (updateRating) {
                updateRating();
            } else {
                getRating();
            }
            if (updateAuditLog) {
                updateAuditLog("edit comment");
            }
        });

    };

    const getCommentList = useCallback(() => {
        dispatch(getComments(datasourceId, type === "dataset" || type === "tableau_item" || type === "attribute" ? datasetId : 0, type === "tableau_item" ? itemId : 0, type === "attribute" ? attributeId : 0)).then((response) => {
            if (response) {
                setComments([...response]);
            }
        });
    }, [attributeId, datasetId, datasourceId, dispatch, itemId, type]);

    useEffect(() => {
        getCommentList();
    }, [getCommentList, datasetId, datasourceId, type]);

    return (
        <Grid container className={classes.commentContainer}>
            <Grid item className={classes.commentWidth}>
                <Grid id="commentList" className={classes.listSection}>
                    {
                        comments.filter((filtercomment) => filtercomment.isPinned).map((comment, index) =>
                            <CommentsList
                                comment={comment}
                                key={index}
                                userId={user ? user.id : 0}
                                active
                                editComment={(comment, commentId) => updateCommentList(comment, commentId)}
                                deleteComment={(commentId) => deleteCommentList(commentId)}
                                isSuperuser={user ? user.is_superuser : false} />
                        )
                    }
                    {
                        comments.filter((filtercomment) => !filtercomment.isPinned).map((comment, index) =>
                            <CommentsList
                                comment={comment}
                                key={index}
                                userId={user ? user.id : 0}
                                editComment={(comment, commentId) => updateCommentList(comment, commentId)}
                                deleteComment={(commentId) => deleteCommentList(commentId)}
                                isSuperuser={user.is_superuser} />
                        )
                    }
                </Grid>
                <AddCommentBox
                    comment={{ ...comment }}
                    onChange={(property, value) => onChange(property, value)}
                    postComment={() => addComment()}
                />
            </Grid>
        </Grid>

    );
};

Comments.propTypes = {
    classes: PropTypes.object,
    type: PropTypes.string,
    updateAuditLog: PropTypes.func,
    datasourceId: PropTypes.number,
    datasetId: PropTypes.number,
    itemId: PropTypes.number,
    attributeId: PropTypes.number,
    attributeName: PropTypes.string,
    updateRating: PropTypes.func
};


export default withStyles((theme) => ({
    ...CommentStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(Comments);