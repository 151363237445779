import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Table, TableHead, TableRow, TableCell, TableBody, withStyles, Typography, Avatar, TableSortLabel } from '@material-ui/core';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { getMentionName, getInitials, getRandomColor, returnImage, sortTable, removeUnderscore } from '../../helpers/appHelpers';
import { appConstants } from '../../constants/appConstants.js';
import UserAuditLogDetails from './UserauditlogDetails.jsx';
import Styles from '../../layouts/Styles.jsx';
import NotificationStyles from './NotificationStyles.jsx';
import Loader from '../Loaders/Loader.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import Nomessages from '../../assets/images/NoNotification.svg';

const NotificationGridView = (props) => {
    const { classes, editData, onScrollChange, isPageLoading, userAudit, currentnotification, isLoadingcontent, getUserBasedActivity } = props;
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('asc');
    const notifications = useSelector(({ notification }) => notification.notifications);
    const users = useSelector(({ account }) => account.users);
    const [open, setLearningOpen] = useState(false);
    const datetimeFormat = 'MM/DD/YYYY HH:mm:ss';

    const onChangeOrder = useCallback((property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }, [order, orderBy]);

    const showdata = (userid) => {
        getUserBasedActivity(userid);
        setLearningOpen(true);
    };

    const closepop = () => {
        setLearningOpen(false);
    };


    const notificationAlertText = (notification) => {
        notification.notification_text_type = notification.notification_text_type ? notification.notification_text_type : "";
        switch (notification.notification_type.toLowerCase()) {
            case 'schedule':
                return (
                    <Fragment>
                        <Typography className={classes.inline}>
                            {notification.notification_text_type}
                            {' '}
                            {notification.notification_text}
                            {' for the '}
                        </Typography>
                        <Typography onClick={() => editData('datasoutce', notification)} className={classNames(classes.notificationTextHighlight, classes.cursor, classes.inline)} >
                            {notification.source__name ? notification.source__name : "Untitled Datasource"}
                        </Typography>
                        {' in '}
                        <Typography onClick={() => editData('dataset', notification)} className={classNames(classes.notificationTextHighlight, classes.cursor, classes.inline)} >
                            {notification.dataset__name ? notification.dataset__name : "Untitled Dataset"}
                        </Typography>
                    </Fragment>);
            case 'useractivity':
                return `${removeUnderscore(notification.notification_text_type)}`;
            case 'conversation':
                return `added a new convesation "${removeUnderscore(notification.notification_text_type)} ${removeUnderscore(notification.notification_text)}"`;
            case 'actionitem':
                return (
                    <Fragment>
                        <Typography>
                            {notification.notification_text_type}
                            {' '}
                            a new action item
                        </Typography>
                        <Typography className={classes.notificationText} dangerouslySetInnerHTML={{ __html: `"${getMentionName(notification.description, users)}"` }} />
                    </Fragment>
                );
            case 'drift':
                return (
                    <span className={classNames(classes.notificationTextHighlight, classes.cursor)} onClick={() => editData('drift', notification)}>
                        {notification.notification_text ? notification.notification_text : ''}
                    </span>
                );
            case 'rule':
                return (
                    <span className={classNames(classes.notificationTextHighlight, classes.cursor)} onClick={() => editData('rule', notification)}>
                        {notification.notification_text ? notification.notification_text : ''}
                    </span>
                );
            case 'permissioning':
                return (
                    <Fragment>
                        <Typography className={classes.inline}>
                            {removeUnderscore(notification.notification_text_type)}
                        </Typography>
                        {
                            notification.notification_text_type === "Share" &&
                            <Typography className={classes.inline}>
                                {` Level ${notification.notification_text}`}
                            </Typography>
                        }
                        {notification.source_id ? ' for the ' : ''}
                        <Typography onClick={() => editData('datasoutce', notification)} className={classNames(classes.notificationTextHighlight, classes.cursor, classes.inline)} >
                            {notification.source__name ? notification.source__name : "Untitled Datasource"}
                        </Typography>
                        {notification.dataset_id ? ' in' : ''}
                        {
                            notification.dataset_id &&
                            <Typography onClick={() => editData('dataset', notification)} className={classNames(classes.notificationTextHighlight, classes.cursor, classes.inline)} >
                                {notification.dataset__name ? notification.dataset__name : "Untitled Dataset"}
                            </Typography>
                        }
                    </Fragment>);
            case 'audit':
                if (notification.notification_text_type.toLowerCase() === "updated properties") {
                    return (
                        <Fragment>
                            <span>
                                {notification.notification_text_type ? removeUnderscore(notification.notification_text_type) : ''}
                            </span>
                            <span className={classes.marginLeft5}>
                                {'Attribute'}
                            </span>
                            <Typography onClick={() => editData('model', notification)} className={classNames(classes.notificationTextHighlight, classes.cursor)} >
                                {notification.attribute__name ? notification.attribute__name : "NA"}
                            </Typography>
                        </Fragment>
                    );
                } else if (notification.notification_text_type.toLowerCase() === "updated domain" || notification.notification_text_type.toLowerCase() === "updated dataset name" || notification.notification_text_type.toLowerCase() === "updated tags" || notification.notification_text_type.toLowerCase() === "updated description" || notification.notification_text_type.toLowerCase() === "updated terms" || notification.notification_text_type.toLowerCase() === "add comment" || notification.notification_text_type.toLowerCase() === "edit comment") {
                    return (
                        <Fragment>
                            <span>
                                {notification.notification_text_type ? removeUnderscore(notification.notification_text_type) : ''}
                            </span>
                            <span className={classes.marginLeft5}>
                                {'for the'}
                            </span>
                            <Typography onClick={() => editData('datasource', notification)} className={classNames(classes.notificationTextHighlight, classes.cursor, classes.inline)} >
                                {notification.source__name ? notification.source__name : "Untitled Datasource"}
                            </Typography>
                            {notification.dataset_id || notification.worksheet_id ? " in " : ""}
                            {
                                notification.worksheet_id ?
                                    <Typography onClick={() => editData('dataset', notification)} className={classNames(classes.notificationTextHighlight, classes.cursor, classes.inline)} >
                                        {notification.worksheet__name ? notification.worksheet__name : "Untitled Worksheet"}
                                    </Typography> :
                                    notification.dataset_id &&
                                    <Typography onClick={() => editData('dataset', notification)} className={classNames(classes.notificationTextHighlight, classes.cursor, classes.inline)} >
                                        {notification.dataset__name ? notification.dataset__name : "Untitled Dataset"}
                                    </Typography>
                            }

                        </Fragment>
                    );
                } else if (notification.notification_text_type.toLowerCase() === "semantics") {
                    return (
                        <Fragment>
                            <span>
                                {notification.notification_text ? removeUnderscore(notification.notification_text_type) : ''}
                            </span>
                            <span className={classes.marginLeft5}>
                                {'in'}
                            </span>
                            <Typography onClick={() => editData('model', notification)} className={classNames(classes.notificationTextHighlight, classes.cursor, classes.inline)} >
                                {notification.semantic__name ? notification.semantic__name : "NA"}
                            </Typography>
                        </Fragment>);
                } else if (notification.notification_text && notification.notification_text.toLowerCase() === "dashboard") {
                    return (
                        <Fragment>
                            <span>
                                {`${notification.notification_text_type} a`}
                            </span>
                            <Typography onClick={() => editData('Dashboard', notification)} className={classNames(classes.notificationTextHighlight, classes.cursor, classes.inline)} >
                                {'Dashboard'}
                            </Typography>
                        </Fragment>);
                } else if (notification.notification_text && notification.notification_text.toLowerCase() === "domain") {
                    return (
                        <Fragment>
                            <span>
                                {`${removeUnderscore(notification.notification_text_type)} a ${notification.description} in`}
                            </span>
                            <Typography onClick={() => editData('settings', notification)} className={classNames(classes.notificationTextHighlight, classes.cursor)} >
                                {notification.notification_text ? removeUnderscore(notification.notification_text) : ''}
                            </Typography>
                        </Fragment>);
                } else if ((notification.notification_text_type === "added" || notification.notification_text_type === "updated")) {
                    return (
                        <Fragment>
                            <span>
                                {`${removeUnderscore(notification.notification_text_type)} a`}
                            </span>
                            <Typography onClick={() => editData('Dashboard', notification)} className={classNames(classes.notificationTextHighlight, classes.cursor, classes.inline)} >
                                {notification.notification_text ? removeUnderscore(notification.notification_text) : ''}
                            </Typography>
                        </Fragment>);
                }
                else if (notification.notification_text_type.toLowerCase() === "created workbook") {
                    return (
                        <Fragment>
                            <span>
                                Created a new Workbook in
                            </span>
                            <Typography onClick={() => editData('datasource', notification)} className={classNames(classes.notificationTextHighlight, classes.cursor)} >
                                {notification.source__name ? notification.source__name : "NA"}
                            </Typography>
                        </Fragment>);
                }
                else if (notification.notification_text_type.toLowerCase() === "created dataset") {
                    return (
                        <Fragment>
                            <span>
                                Created a new Dataset in
                            </span>
                            <Typography onClick={() => editData('datasource', notification)} className={classNames(classes.notificationTextHighlight, classes.cursor)} >
                                {notification.source__name ? notification.source__name : "NA"}
                            </Typography>
                        </Fragment>);
                }
                else if (notification.notification_text_type.toLowerCase() === "deleted dataset") {
                    return (
                        <Fragment>
                            <span>
                                Deleted a dataset in
                            </span>
                            <Typography onClick={() => editData('datasource', notification)} className={classNames(classes.notificationTextHighlight, classes.cursor)} >
                                {notification.source__name ? notification.source__name : "NA"}
                            </Typography>
                        </Fragment>);
                } else if (notification.notification_text_type.toLowerCase() === "updated datasource name") {
                    return (
                        <Fragment>
                            <span>
                                Update datasource name in
                            </span>
                            <Typography onClick={() => editData('datasource', notification)} className={classNames(classes.notificationTextHighlight, classes.cursor)} >
                                {notification.source__name ? notification.source__name : "NA"}
                            </Typography>
                        </Fragment>);
                }
                return `${removeUnderscore(notification.notification_text)} ${removeUnderscore(notification.notification_text_type)} ${removeUnderscore(notification.description)}`;
            default:
                return `${removeUnderscore(notification.notification_text_type)} ${notification.notification_text ? removeUnderscore(notification.notification_text) : ''}`;
        }
    };

    const onScroll = (event) => {
        if (currentnotification.toLowerCase() !== "useractivity") {
            if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight && onScrollChange) {
                onScrollChange();
            }
        }
    };

    return (
        <Grid className={classNames(classes.notificationContainer, classes.tableWrapperStyle)} onScroll={onScroll}>
            {
                notifications.length !== 0 && currentnotification.toLowerCase() === "useractivity" &&
                <Grid>
                    <Table stickyHeader className={classes.tableStyle}>
                            <TableHead className={classes.bgWhite}>
                                <TableRow>
                                    {
                                        appConstants.notificationUserActivityTableHeaders.map((header, index) =>
                                            <TableCell key={`notification_${index}`} className={classes.tableHeadCell}>
                                                <TableSortLabel
                                                    active={orderBy === header.key}
                                                    direction={orderBy === header.key ? order : 'asc'}
                                                    onClick={() => onChangeOrder(header.key)}
                                                >
                                                    {header.value}
                                                </TableSortLabel>
                                            </TableCell>
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    sortTable(notifications, order, orderBy).map((notification, index) =>
                                        <TableRow className={classes.tableRow} key={`data_${index}`}>
                                            <TableCell className={classes.tableCell}>
                                                <ToolTipComponent title={notification.first_name !== "" ? `${notification.first_name} ${notification.last_name}` : notification.email} arrow>
                                                    <Grid className={classes.flex}>
                                                        <Avatar
                                                            style={
                                                                {
                                                                    backgroundImage: `url(${returnImage(appConstants.API_BASE_URL, notification.avatar)})`,
                                                                    backgroundColor: getRandomColor(notification.first_name !== "" ? `${notification.first_name} ${notification.last_name}` : notification.email),
                                                                    marginRight: 8
                                                                }
                                                            }>
                                                            {
                                                                !notification.avatar &&
                                                                <span>
                                                                    {getInitials(notification.first_name !== "" ? `${notification.first_name} ${notification.last_name}` : notification.email)}
                                                                </span>
                                                            }
                                                        </Avatar>
                                                        <Typography className={classNames(classes.notificationTextHighlight, classes.notifiUserEmail)}>
                                                            {notification.first_name !== "" ? `${notification.first_name} ${notification.last_name}` : notification.email}
                                                        </Typography>
                                                    </Grid>
                                                </ToolTipComponent>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                {notification.last_login}
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                {notification.number_of_audits}
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                {notification.total_login_count}
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                {notification.ave_minutes}
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                {notification.min_minutes}
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                {notification.max_minutes}
                                            </TableCell>
                                            {
                                                parseInt(notification.number_of_audits) > 0 &&
                                                <TableCell className={classes.tableCell} style={{ cursor: 'pointer' }} onClick={() => showdata(notification.id)}>
                                                    {'Click Here View Audits'}
                                                </TableCell>
                                            }
                                            {
                                                parseInt(notification.number_of_audits) === 0 &&
                                                <TableCell className={classes.tableCell} style={{ color: '#969899' }}>
                                                    {'Click Here View Audits'}
                                                </TableCell>
                                            }
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                    </Table>
                    <UserAuditLogDetails open={open} isLoadingcontent={isLoadingcontent} userAudit={userAudit} closepop={() => closepop()} />
                </Grid>
            }
            {
                notifications.length !== 0 && currentnotification.toLowerCase() !== "useractivity" &&
                <Table stickyHeader className={classes.tableStyle}>
                    <TableHead className={classes.bgWhite}>
                        <TableRow>
                            {
                                appConstants.notificationTableHeaders.map((header, index) =>
                                    <TableCell key={`notification_${index}`} className={classes.tableHeadCell}>
                                        <TableSortLabel
                                            active={orderBy === header.key}
                                            direction={orderBy === header.key ? order : 'asc'}
                                            onClick={() => onChangeOrder(header.key)}
                                        >
                                            {header.value}
                                        </TableSortLabel>
                                    </TableCell>
                                )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            sortTable(notifications, order, orderBy).map((notification, index) =>
                                <TableRow className={classes.tableRow} key={`data_${index}`}>
                                    <TableCell className={classes.tableCell}>
                                        {notification.notification_type === "audit" ? "audit log" : notification.notification_type}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <ToolTipComponent title={notification.source__name === "" && notification.source_id ? "Untitled Datasource" : notification.source__name} arrow>
                                            <Typography className={classNames(classes.anchorLink, classes.title)} onClick={() => editData('datasource', notification)}>
                                                {notification.source__name === "" && notification.source_id ? "Untitled Datasource" : notification.source__name}
                                            </Typography>
                                        </ToolTipComponent>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <ToolTipComponent title={notification.dataset__name === "" && notification.dataset_id ? "Untitled Dataset" : notification.dataset__name} arrow>
                                            <Typography className={classNames(classes.anchorLink, classes.title)} onClick={() => notification.source__name !== "" && editData('dataset', notification)}>
                                                {notification.dataset__name === "" && notification.dataset_id ? "Untitled Dataset" : notification.dataset__name}
                                            </Typography>
                                        </ToolTipComponent>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <ToolTipComponent title={notification.attribute__name ? notification.attribute__name : ""} arrow>
                                            <Typography className={classNames(classes.anchorLink, classes.title)} onClick={() => editData('attribute', notification)}>
                                                {notification.attribute__name ? notification.attribute__name : ""}
                                            </Typography>
                                        </ToolTipComponent>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {notification.type ? notification.type : ""}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <ToolTipComponent title={notification.created_by__first_name !== "" ? `${notification.created_by__first_name} ${notification.created_by__last_name}` : notification.created_by__email} arrow>
                                            <Grid className={classes.flex}>
                                                <Avatar
                                                    style={
                                                        {
                                                            backgroundImage: `url(${returnImage(appConstants.API_BASE_URL, notification.created_by__avatar)})`,
                                                            backgroundColor: getRandomColor(notification.created_by__first_name !== "" ? `${notification.created_by__first_name} ${notification.created_by__last_name}` : notification.email),
                                                            marginRight: 8
                                                        }
                                                    }>
                                                    {
                                                        !notification.created_by__avatar &&
                                                        <span>
                                                            {getInitials(notification.created_by__first_name !== "" ? `${notification.created_by__first_name} ${notification.created_by__last_name}` : notification.created_by__email)}
                                                        </span>
                                                    }
                                                </Avatar>
                                                <Typography className={classNames(classes.notificationTextHighlight, classes.notifiUserEmail)}>
                                                    {notification.created_by__first_name !== "" ? `${notification.created_by__first_name} ${notification.created_by__last_name}` : notification.created_by__email}
                                                </Typography>
                                            </Grid>
                                        </ToolTipComponent>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {moment(notification.created_date).format(datetimeFormat)}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {notificationAlertText(notification)}
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            }
            {
                !isPageLoading && notifications.length === 0 &&
                <Grid container justify="center" alignItems="center" direction="column" className={classes.height100p}>
                    <img className={classes.noNotificationImg} src={Nomessages} alt="No Notification yet" />
                    <Typography variant="h4" color="textSecondary" className={classes.marginTop10}>
                        No Notifications yet
                    </Typography>
                </Grid>
            }
            {isPageLoading && <Loader />}
        </Grid>
    );

};

NotificationGridView.propTypes = {
    classes: PropTypes.object,
    userAudit: PropTypes.object,
    editData: PropTypes.func,
    onScrollChange: PropTypes.func,
    isLoadingcontent: PropTypes.func,
    isPageLoading: PropTypes.bool,
    getUserBasedActivity: PropTypes.func,
    currentnotification: PropTypes.string
};

export default withStyles((theme) => ({
    ...NotificationStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(NotificationGridView);