import { datasetActions } from '../constants/actionTypes/datasetActionTypes';
import { scheduleActions } from '../constants/actionTypes/scheduleActionTypes';

const initialState = {
    isLoading: false,
    properties: {},
    datasetInfo: {},
    selectedDatasetId: 0,
    selectedDataset: null,
    propertySliderIndex: 0,
    propertyView: 'list',
    rules: {},
    selectedAttributes: [],
    propertyLoading: false,
    sharingInfo: [],
    propertiesTabIndex: 0,
    driftCustomRules: [],
    datasets: [],
    attributeLoading: [],
    attributesPreview: {},
    propertiesList: [],
    workbookInfo: {},
    datasetTabIndex: '',
    propertyTabIndex: '',
    selectedAttribute: '',
    datasetNames: [],
    attributeNames: [],
    ruleNames: [],
    recipientsNames: []
};

export const datasetReducer = (state = initialState, action) => {
    if (!action || (action && !action.type)) { return state; }
    switch (action.type) {
        case datasetActions.GET_PREVIEW_DATA_SUCCESS:
            return {
                ...state,
                attributesPreview: { ...action.responseData.data }
            };
        case datasetActions.CLEAR_PREVIEW_DATA:
        case datasetActions.GET_PREVIEW_DATA_FAILURE:
            return {
                ...state,
                attributesPreview: {}
            };
        case datasetActions.GET_DATASET_INFO_REQUEST:
        case datasetActions.GET_DATASET_INFO_FAILURE:
            return {
                ...state,
                datasetInfo: { sensitive_datasets: 0 }
            };
        case datasetActions.GET_LICENSE_REQUEST:
        case datasetActions.GET_LICENSE_SUCCESS:
        case datasetActions.GET_LICENSE_FAILURE:
            return {
                ...state
            };
        case datasetActions.GET_PROPERTIES_REQUEST:
            return {
                ...state,
                properties: {},
                propertyLoading: true
            };
        case datasetActions.GET_PROPERTIES_SUCCESS:
            return {
                ...state,
                properties: action.responseData.properties ? { ...action.responseData.properties } : {},
                selectedAttributes: action.responseData.selected_attributes ? [...action.responseData.selected_attributes] : [],
                propertyLoading: false
            };
        case datasetActions.GET_ATTRIBUTE_PROPERTIES_SUCCESS:
            const fileredProperties = action.responseData ? action.responseData : {};
            const existingProperties = Object.keys(state.properties);
            const currentProperties = Object.keys(fileredProperties);
            const updatedProperties = { ...state.properties };
            for (const attribute of currentProperties) {
                if (existingProperties.indexOf(attribute) > -1) {
                    continue;
                }
                updatedProperties[attribute] = { ...fileredProperties[attribute] };
            }
            return {
                ...state,
                properties: updatedProperties,
                propertyLoading: false
            };
        case datasetActions.UPDATE_ATTRIBUTES_SUCCESS:
            return {
                ...state,
                selectedAttributes: [...action.responseData]
            };
        case datasetActions.GET_PROPERTIES_FAILURE:
            return {
                ...state,
                properties: {},
                propertyLoading: false
            };
        case datasetActions.GET_DATASET_INFO_SUCCESS:
            return {
                ...state,
                datasetInfo: action.responseData ? action.responseData : {}
            };
        case datasetActions.SET_PROPERTY_SLIDER_INDEX:
            return {
                ...state,
                propertySliderIndex: action.index
            };
        case datasetActions.SET_PROPERTY_VIEW:
            return {
                ...state,
                propertyView: action.view
            };
        case datasetActions.SET_SELECTED_DATASET_ID:
            return {
                ...state,
                selectedDatasetId: action.datasetId
            };
        case datasetActions.SET_SELECTED_DATASET:
            return {
                ...state,
                selectedDataset: action.dataset
            };
        case datasetActions.GET_DATASET_RULES_SUCCESS:
            return {
                ...state,
                rules: { ...action.responseData }
            };
        case datasetActions.GET_DATASET_RULES_FAILURE:
            return {
                ...state,
                rules: {}
            };
        case datasetActions.UPDATE_RULE_ATTRIBUTES:
            const rules = state.rules;
            if (action.responseData) {
                if (!rules[action.responseData.type]) {
                    rules[action.responseData.type] = {};
                }
                if (!rules[action.responseData.type][action.responseData.attribute]) {
                    rules[action.responseData.type][action.responseData.attribute] = [];
                }
                const ruleType = action.responseData['rule_type'];
                let attributeRules = rules[action.responseData.type][action.responseData.attribute];
                attributeRules = attributeRules.filter((p) => p['rule_type'] !== ruleType);
                rules[action.responseData.type][action.responseData.attribute] = [...attributeRules, ...action.responseData.rules];
            }
            return {
                ...state,
                rules: { ...rules }
            };
        case scheduleActions.DATASET_LEARNING_SUCCESS:
            const selectedProperty = action.responseData.property;
            const attributeName = action.responseData.name;
            const properties = state.properties;
            if (attributeName && selectedProperty) {
                properties[attributeName] = selectedProperty;
            }
            return {
                ...state,
                properties: { ...properties }
            };
        case datasetActions.UPDATE_PROPERTY_SUCCESS:
        case datasetActions.UPDATE_DATASET_PROPERTIES:
            return {
                ...state,
                properties: { ...action.responseData }
            };
        case datasetActions.UPDATE_LEARNING_ATTRIBUTE:
            const propertiesList = state.properties;
            if (!propertiesList[action.responseData.name]["curate_learning"]) {
                propertiesList[action.responseData.name]["curate_learning"] = {
                    accept: [],
                    decline: []
                };
            }
            if (!propertiesList[action.responseData.name]["curate_learning"][action.responseData.property]) {
                propertiesList[action.responseData.name]["curate_learning"][action.responseData.property] = [];
            }
            propertiesList[action.responseData.name]["curate_learning"][action.responseData.property] = [...propertiesList[action.responseData.name]["curate_learning"][action.responseData.property], { ...action.responseData.data }];
            propertiesList[action.responseData.name]["curate_learning"][action.responseData.property] = [
                ...new Map(propertiesList[action.responseData.name]["curate_learning"][action.responseData.property].map((item) =>
                    [item.input_value && item.curated_value, item])).values()
            ];
            return {
                ...state,
                properties: { ...propertiesList }
            };
        case datasetActions.UPDATE_LEARNING_ATTIBUTE_PROPERTY:
            const propertiesItem = state.properties;
            propertiesItem[action.responseData.attribute] = { ...propertiesItem[action.responseData.attribute], ...action.responseData.value, name: action.responseData.attribute };
            return {
                ...state,
                properties: propertiesItem
            };
        case datasetActions.GET_DATASET_SHARE_REQUEST:
        case datasetActions.GET_DATASET_SHARE_FAILURE:
            return {
                ...state,
                sharingInfo: []
            };
        case datasetActions.GET_DATASET_SHARE_SUCCESS:
            return {
                ...state,
                sharingInfo: action.responseData ? action.responseData : []
            };

        case datasetActions.GET_DRIFT_CUSTOM_RULES_REQUEST:
        case datasetActions.GET_DRIFT_CUSTOM_RULES_FAILURE:
            return {
                ...state,
                driftCustomRules: []
            };
        case datasetActions.GET_DRIFT_CUSTOM_RULES_SUCCESS:
            return {
                ...state,
                driftCustomRules: action.responseData ? action.responseData : []
            };

        case datasetActions.GET_DATASET_PROPERTY_SEARCH:
            return {
                ...state,
                search: action.search
            };
        case datasetActions.GET_DATASET_PROPERTIES_TAB:
            return {
                ...state,
                propertiesTabIndex: action.responseData
            };
        case datasetActions.CREATE_DATASET_SHARE_SUCCESS:
            const users = action.responseData ? action.responseData : [];
            return {
                ...state,
                sharingInfo: [...users]
            };
        case datasetActions.DELETE_DATASET_SHARE:
            const toDelete = action.responseData ? action.responseData : [];
            let currentList = state.sharingInfo;
            toDelete.map((removeUser) => {
                currentList = currentList.filter((user) => user.user_id !== removeUser);
                return currentList;
            });
            return {
                ...state,
                sharingInfo: [...currentList]
            };
        case datasetActions.GET_ORGANIZATION_DATASET_LIST_SUCCESS:
            return {
                ...state,
                datasets: action.responseData ? action.responseData : []
            };
        case datasetActions.GET_ORGANIZATION_DATASET_LIST_REQUEST:
        case datasetActions.GET_ORGANIZATION_DATASET_LIST_FAILURE:
            return {
                ...state,
                datasets: []
            };
        case datasetActions.GET_ORGANIZATION_ATTRIBUTE_LIST_REQUEST:
            return {
                ...state,
                attributeLoading: true
            };
        case datasetActions.GET_ORGANIZATION_ATTRIBUTE_LIST_FAILURE:
        case datasetActions.GET_ORGANIZATION_ATTRIBUTE_LIST_SUCCESS:
            return {
                ...state,
                attributeLoading: false
            };
        case datasetActions.GET_PROPERTIES_LIST_REQUEST:
            return {
                ...state,
                propertiesList: [],
                propertyLoading: true,
                propertyView: 'list'
            };
        case datasetActions.GET_PROPERTIES_LIST_SUCCESS:
            return {
                ...state,
                propertiesList: action.responseData ? action.responseData : [],
                propertyLoading: false
            };
        case datasetActions.GET_PROPERTIES_LIST_FAILURE:
            return {
                ...state,
                propertiesList: [],
                propertyLoading: false
            };
        case datasetActions.GET_ATTRIBUTE_RULES_SUCCESS:
            const existRules = state.rules;
            if (action.responseData && existRules && Object.keys(existRules).length > 0) {
                const attribute = action.responseData && action.responseData.profile && Object.keys(action.responseData.profile).length ? Object.keys(action.responseData.profile)[0] : "";
                existRules.profile[attribute] = [...action.responseData.profile[attribute]];
            }
            return {
                ...state,
                rules: { ...existRules }
            };
        case datasetActions.GET_WORKBOOK_INFO_REQUEST:
            return {
                ...state,
                workbookInfo: {}
            };
        case datasetActions.GET_WORKBOOK_INFO_SUCCESS:
            return {
                ...state,
                workbookInfo: action.responseData ? action.responseData : {}
            };
        case datasetActions.GET_WORKBOOK_INFO_FAILURE:
            return {
                ...state,
                workbookInfo: {}
            };
        case datasetActions.UPDATE_TABINDEX_REQUEST:
            return {
                ...state
            };
        case datasetActions.UPDATE_TABINDEX_SUCCESS:
            return {
                ...state,
                datasetTabIndex: action.responseData
            };
        case datasetActions.UPDATE_PROPERTY_TABINDEX_REQUEST:
            return {
                ...state
            };
        case datasetActions.UPDATE_PROPERTY_TABINDEX_SUCCESS:
            return {
                ...state,
                propertyTabIndex: action.responseData
            };
        case datasetActions.SET_SELECTED_ATTRIBUTE_NAME:
            return {
                ...state,
                selectedAttribute: action.attributename
            };
        case datasetActions.GET_DATASET_NAMES_SUCCESS:
            return {
                ...state,
                datasetNames: action.responseData
            };
        case scheduleActions.GET_DATASET_ATTRIBUTE_SUCCESS:
            return {
                ...state,
                attributeNames: action.responseData
            };
        case scheduleActions.GET_ATTRIBUTES_RULES_SUCCESS:
            return {
                ...state,
                ruleNames: action.responseData
            };
        case scheduleActions.GET_RECIPIENTS_USERS_SUCCESS:
            return {
                ...state,
                recipientsNames: action.responseData
            };
        default:
            return state;
    }
};